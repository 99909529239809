import { v4 as uuidv4 } from 'uuid';
import { Message } from './message';

export class Log extends Message {

    /* ATTRIBUTES */
    
    level: string = ''
    route: string = '';
    status: 'Info' | 'Success' | 'Error' | 'Warning' | '' = '';
    statusCode: string = '';

    sourceAgent: string = '';
    sourceIp: string = '';
    sourceOrigin: string = '';
    
    destinationIp: string = '';
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
     public constructor(json?) {
        super(json);
        Object.assign(this, json || {});
        this.type = "Log";
    }

}
