import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.less']
})
export class ImageInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Output() imageEvent = new EventEmitter();
  @Input('value') val: any;
  @Input() contentType: string; // image type; MINE type
  @Input() caption: string;             // label on the left top of outline box
  @Input() placeholder: any;            // displayed text in input when input has empty '' value
  @Input() hint: string;
  @Input() prefix: string;              // prefix text in front of input. Foe example, $ sign
  @Input() required: any;               // whether input is required
  @Input() readonly: boolean;           // whether input is readonly (no edit mode)

  @Input() isViewMode = false;
  @Input() viewModeClass = 'w-container'; // eg, ['class1', 'class2']
  @Input() nameClass = 'w-item-0 bold'; // eg, ['class1', 'class2']
  @Input() nameStyle = { 'max-width': '100%'  }; // eg, {'color':'red', 'font-size':'17px'}
  @Input() valueClass = 'w-item-0';
  @Input() valueStyle: string;
  @Input() defaultImage: string;

  @Input() background: string;

  get value() {
    if (this.val) {
      return this.val;
    }
    return null;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.value = this.defaultImage;
  }


  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes.disableInput) {
    }
  }


  public writeValue(obj: any): void {
      this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  onChange = (value: any) => {
    this.imageEvent.emit(value);
  }
  onTouched: any = () => {

  }


  public pickImage(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.handleInputChange(file); // turn into base64
    } else {
      alert('No file selected');
    }
  }

  handleInputChange(files) {
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.contentType = file.type;
    // reader.onloadend = this._handleReaderLoaded.bind(this);
    // reader.readAsDataURL(file);
    if (file.type === 'image/svg+xml') {
      // read SVG as text
      reader.onloadend = this._handleTextReaderLoaded.bind(this);
      reader.readAsText(file);
    } else {
      // read other images as dataUrl (base64)
      reader.onloadend = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    const base64result = reader.result;
    this.writeValue(base64result);
  }

  _handleTextReaderLoaded(e) {
    const reader = e.target;
    const textResult = reader.result;

    this.writeValue(textResult);
  }

  public resetImage() {
      this.value = undefined;
  }

  public sanitize(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  public isImageSvg() {
    // return this.contentType === 'image/svg+xml';
    return this.value.indexOf('data:') < 0;
  }

}
