<mat-form-field class="w-100" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-select [name]="formFieldName" [(ngModel)]="model" (ngModelChange)="selection()" [disabled]="isReadOnly" multiple>      
        <div class="filter">
            <mat-icon>filter_list</mat-icon><mat-label class="filter-icon">Filter</mat-label>
            <input class="filter-input" matInput placeholder="{{placeholder}}" (input)="filter($event.target.value)" (keydown)="$event.stopPropagation()"/>
        </div>
        <mat-option *ngFor="let item of this.filteredItems | sortArray: displayKey" [value]="item[valueKey]">
            {{ item[displayKey] }}
        </mat-option>
    </mat-select>
</mat-form-field>