<!-- PAGE -->

<div class="notifications">
    <b>NOTIFICATIONS</b>
    <div class="notification" *ngFor="let notification of messageService.getNotificationsNotYetRead() | arraySort:'createDate':'desc'" 
        (click)="open(notification)">
        <div *ngIf="notification.referenceType === 'Alert'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
        </div>
        <div *ngIf="notification.referenceType === 'Approval'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
        </div>
        <div *ngIf="notification.referenceType === 'Chat'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
            {{ notification.reference?.text }}
        </div>
        <div *ngIf="notification.referenceType === 'Channel'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
        </div>
        <div *ngIf="notification.referenceType === 'Finding'">
            <div class="finding">
                <div class="container-fluid nopadding">
                    <div class="title">
                        <div class="row no-gutters">                        
                            <div class="col no-gutters">
                                <div class="d-flex">                                        
                                    <div>
                                        FINDING
                                    </div>                                
                                    <div class="badge uppercase" *ngIf="notification.reference?.riskExposure">
                                        {{ notification.reference?.riskExposure[0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto no-gutters">
                                {{ notification.createDate | date:'short' }}
                            </div>
                        </div>
                    </div>
                    <div class="text">
                        <div class="row no-gutters">
                            <div>
                                {{ notification.text }}
                            </div>
                        </div>
                    </div>
                    <div class="actions">                        
                        <div class="row no-gutters">                        
                            <div class="col no-gutters">
                            </div>
                            <div class="col-auto no-gutters">
                                <a (click)="ignore(notification)">Ignore</a><a class="pl-2" (click)="publish(notification)">Publish</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="notification.referenceType === 'Help'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
        </div>
        <div *ngIf="notification.referenceType === 'Model'">
            {{ notification.text }} on {{ notification.createDate | date:'short' }}
        </div>
    </div>
    <div class="notification" *ngIf="!(messageService.getNotificationsNotYetRead().length > 0)">
        You do not have any new, unread notifications
    </div>
    <div class="notification" *ngIf="messageService.getNotifications().length > 0">        
        <a class="pb-4" (click)="openDialog(viewNotifications)">View All</a>
    </div>
</div>

<!-- COMPONENTS -->

<ng-template #viewNotifications>
    <!-- <button class="btn-no-border right close-btn" (click)="close()"><span class="material-icons">close</span></button> -->
    <app-close-button></app-close-button>
    <div class="center">            
        <div class="editor pt-5">
            <div class="editor-title uppercase">
                <div class="title">
                    NOTIFICATIONS ({{ messageService.getNotifications().length || 0 }})
                </div>
            </div>
            <div class="filters">
                <mat-form-field class="w-100 mr-3 mt-3 pb-0 search" appearance="outline">
                    <input matInput type="search" placeholder="Search" name="search" (keyup)="filter($event.target.value)">
                </mat-form-field>
            </div>
            <div class="editor-section">
                <div class="pb-3">
                    <button (click)="markAllAsRead()" mat-button color="secondary">MARK ALL AS READ</button>
                    <button (click)="clearAll()" mat-button color="secondary">CLEAR ALL</button>
                </div>
                <div class="table-responsive">
                    <table class="w-100" mat-table [dataSource]="dataSource" matSortActive="createDate" matSortDirection="desc" matSort>                    
                        <ng-container matColumnDef="fromName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                            <td mat-cell *matCellDef="let item">
                                {{item.fromName}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="content">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Content</th>
                            <td mat-cell *matCellDef="let item">                            
                                <a class="message-content" (click)="open(item)">{{ item.text }}</a> 
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="organizationName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Organization
                            </th>
                            <td mat-cell *matCellDef="let item">
                                {{ item.fromOrganizationName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Created Date
                            </th>
                            <td mat-cell *matCellDef="let item">
                                {{ item.createDate | date: 'short' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="updateDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Updated Date
                            </th>
                            <td mat-cell *matCellDef="let item">
                                {{ item.updateDate | date: 'short' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef mat-header></th>
                            <td mat-cell *matCellDef="let item">
                                <div *ngIf="item.isAwaitingApproval && item.isPendingInvite">
                                    <a href="javascript:void(0);" (click)="updateInvite(item,'Accepted')">Accept</a> / <a href="javascript:void(0);" (click)="updateInvite(item,'Rejected')">Reject</a>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [ngClass]="element.isViewed ? '' : 'unread'"></tr>
                    </table>
                    <mat-paginator [length]="dataSource.data.length" [pageSize]="25" [pageSizeOptions]="[25, 50, 75, 100]">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</ng-template>
