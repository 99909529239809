
import { Component, AfterViewInit, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { SecurityService } from '@src/shared/services/security.service';


@Component({
  selector: 'app-incomplete-process-table',
  templateUrl: './incomplete-process-table.component.html',
  styleUrls: ['./incomplete-process-table.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})

export class IncompleteProcessTableComponent implements AfterViewInit, OnInit {

    @Input() incompletePhases;
    @Input() controls;

    @Output() goToControl: EventEmitter<any> = new EventEmitter<any>();
    @Output() filteredForms: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild('controlsSort', {static: false}) controlsSort: MatSort;

    private cache: Cache;
    public config;
    public asset;
    public organization;
    public user;

    public columnsToDisplay = ['name', 'displayType', 'phase', 'completionPercent'];
    public columnWidths = { name: '70%', displayType: '10%', phase: '10%', completionPercent: '10%'};
    public incompleteForms = [];
    public incompleteForms_ = [];
    public incompleteFormsDataSource: MatTableDataSource<any> = new MatTableDataSource();

    public controlsColumnsToDisplay = ['id', 'title', 'level', 'overlays', 'status'];
    public controlsColumnWidths = { id: '10%', title: '55%', level: '10%', overlays: '10%', status: '15%' };
    public incompleteControls = [];
    public incompleteControls_ = [];
    public incompleteControlsDataDource: MatTableDataSource<any> = new MatTableDataSource();

    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public poamDataSource: MatTableDataSource<any> = new MatTableDataSource();

    public poams: any[] = []; // list of all POAMs for this Asset
    public poamsToDisplay: any[] = [];; // POAMs displayed on the table

    /* CONSTANTS */

    // The navigation links are used to hold the links to pre-built system 
    // components. The navigation component looks up the links by type, which
    // is associated with each process step. New process steps have a route field
    // which will be used instead of the navlinks object. We keep the nav links object
    // as a fallback incase the step isn't configured correctly with the route

    navLinks = {
        'assign-control-families': config.routes['PRIVATE_CONTROL_ASSIGN_CONTROL_FAMILIES'],
        'businessprocess': config.routes['PRIVATE_ASSET_EDIT_BUSINESS_IMPACT'],
        'controls-review': config.routes['PRIVATE_CONTROL_BROWSE_CONTROLS_ASSESSMENT'],
        'controls-submit': config.routes['PRIVATE_CONTROL_BROWSE_CONTROLS'],
        'data': config.routes['PRIVATE_ASSET_EDIT_INFORMATION_TYPES'],
        'documents': config.routes['PRIVATE_PROCESS_EDIT_DOCUMENTS'],
        'inheritance': config.routes['PRIVATE_ASSET_EDIT_INHERITANCE'],
        'questionnaire': config.routes['PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE'],
        'technology': config.routes['PRIVATE_ASSET_EDIT_TECHNOLOGY']
    };

    public LIST_IMPACT_LEVEL = {
        0: 'Low',
        1: 'Moderate',
        2: 'High',
        100: 'N/A'
    };

    private canEditForms: boolean;
    private canViewForms: boolean;


    constructor(
        private securityService: SecurityService,
        private router: Router
    ) { 
        this.config = config;
    }

    ngAfterViewInit() {
        this.incompleteFormsDataSource.sort = this.sort;
        this.incompleteControlsDataDource.sort = this.controlsSort;
    }

    async ngOnInit() {
        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.user = this.cache.getValue(Cache.KEYS.USER);

        this.canEditForms = await this.securityService.checkRoute(config.routes.PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE, this.asset.pkId, this.user, this.organization)
        this.canViewForms = await this.securityService.checkRoute(config.routes.PRIVATE_QUESTIONNAIRE_VIEW_QUESTIONNAIRE, this.asset.pkId, this.user, this.organization)

        // SET INCOMPLETE PHASE TABLE
        this.incompleteForms = this.unwind(this.incompletePhases, 'steps');
        this.incompleteForms = this.incompleteForms.filter(p => p.completionPercent !== 100);
        
        this.filteredForms.emit(this.incompleteForms);


        for (let phase of this.incompleteForms) {
            if (phase.referenceType === 'questionnaire') { 
                phase.displayType = 'form'
            } else {
                phase.displayType = 'component'
            }
        }
        this.incompleteForms_ = this.incompleteForms.slice(0, 5);

        this.incompleteFormsDataSource = new MatTableDataSource(this.incompleteForms_);
        this.incompleteFormsDataSource.sort = this.sort;

        // SET INCOMPLETE CONTROLS TABLE
        this.incompleteControls = this.controls
            .sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
            .filter(control => !(control.status === 'Passed' || control.status === 'Failed'));
        this.incompleteControls_ = this.incompleteControls.slice(0, 5);
        this.incompleteControlsDataDource = new MatTableDataSource(this.incompleteControls_);
        this.incompleteControlsDataDource.sort = this.controlsSort;
    }

    /**
     * Adds more/less rows to the poams table by increments of 5
     */
    show(type: string, formType: string) {
        switch (type) {
            case 'all': {
                if (formType === 'forms') {
                    this.incompleteForms_ = this.incompleteForms;
                    this.incompleteFormsDataSource.data = this.incompleteForms_;
                } else if (formType === 'controls') {
                    this.incompleteControls_ = this.incompleteControls;
                    this.incompleteControlsDataDource.data = this.incompleteControls_;
                }
                break;
            }
            case 'more': {
                if (formType === 'forms') {
                    const index = this.incompleteForms_.length;
                    this.incompleteForms_ = this.incompleteForms_.concat(this.incompleteForms.slice(index, index + 5));
                    this.incompleteFormsDataSource.data = this.incompleteForms_;
                } else if (formType === 'controls') {
                    const index = this.incompleteControls_.length;
                    this.incompleteControls_ = this.incompleteControls_.concat(this.incompleteControls.slice(index, index + 5));
                    this.incompleteControlsDataDource.data = this.incompleteControls_;
                }
                break;
            }
            case 'less': {
                if (formType === 'forms') {
                    this.incompleteForms_ = this.incompleteForms.slice(0, 5);
                    this.incompleteFormsDataSource.data = this.incompleteForms_;
                } else if (formType === 'controls') {
                    this.incompleteControls_ = this.incompleteControls.slice(0, 5);
                    this.incompleteControlsDataDource.data = this.incompleteControls_;
                }
                break;
            }
        }
    }

    /**
     * unwind data by property
     */
    public unwind(arr: any[], selectKey: string): any[] {
        let output = [];
        for (const obj of arr) {
            for (let key of obj[selectKey]) {
                output.push({
                    phasePkId: obj.pkId,
                    phase: obj.name,
                    ...key
                })
            }
        }
        return output;
    }

    /**
     * Set the step, and navigate to the component.
     * For questionnaires, we check if the user has edit priveledge or view priveledge first.
     */
    gotoComponent(phase, step) {
        if (step) {
            let link;
            if (step.referenceType === 'questionnaire') {
                if (this.canEditForms) {
                    link = config.routes.PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE;
                } 
                else if (this.canViewForms) {
                    link = config.routes.PRIVATE_QUESTIONNAIRE_VIEW_QUESTIONNAIRE;
                }
            }
            else {
                link = step.route ? step.route : this.navLinks[step.referenceType];
            }

            if (link) {
                // fix that idk how, it breaks navigation
                this.cache.setValue(Cache.KEYS.PHASE, this.incompletePhases.find(p => p.name === phase));
                this.cache.setValue(Cache.KEYS.STEP, step);
                this.router.navigateByUrl(link);
            }
        }
    }

    /**
     * Go to Control.
     */
    public gotoControl(control) {
        this.goToControl.emit(control);
    }

}
