<!-- FILTERS -->
<div class="filters" *ngIf="files?.length > 0">
    <div class="row nopadding">
        <div class="col nopadding">            
            <mat-icon>filter_list</mat-icon><b> Filter</b>
        </div>
    </div>
    <div class="row nopadding gap-2">
        <div class="col nopadding">
            <mat-form-field class="w-100" appearance="outline">
                <input matInput name="keyword" [(ngModel)]="keyword" (ngModelChange)="subject.next($event)" placeholder="Filter by Keyword">
                <mat-icon matSuffix *ngIf="keyword" (click)="keyword=''; applyFilters();">close</mat-icon>
            </mat-form-field>
        </div>    
        <div class="col-auto row nopadding gap-2">
            <!-- button that shows if there are files in dataSource -->
            <div *ngIf="(dataSource.data.length > 0 && !list) && isEditable">
                <input hidden type="file" #addDoc (change)="uploadDocument($event)" multiple />
                <div class="mb-3" *ngIf="isDuplicateFileName" style="color: red;">
                    File has already been uploaded!
                </div>
                <button 
                    mat-flat-button 
                    color="accent" 
                    (click)="addDoc.click()"
                    [componentIf]="{val: securityRoute}">
                    {{ buttonText }}
                </button>
            </div>
            <!-- button that shows if type selection dialog should be shown before uploading doc -->
            <div *ngIf="list && isEditable">
                <button 
                    mat-flat-button  
                    color="accent" 
                    (click)="openDialog(addDialog)"
                    [componentIf]="{val: securityRoute}">
                    {{ buttonText }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- UPLOADER -->
<div [componentIf]="{val: securityRoute}" [hidden]="dataSource.data.length > 0 || !isEditable || list">
    <app-uploader uploadLabel 
        (addFile)="uploadAll()" 
        (complete)="uploadComplete()"
        (completeItem)="updateTable($event)"
        route="/file/create" 
        [buildForm]="buildForm()"
        [uploaderOptions]="this.options">
    </app-uploader>
</div>
<div  *ngIf="dataSource.data.length === 0 && isEditable && list">
    <button 
        class="w-100"
        mat-stroked-button  
        color="accent" 
        (click)="openDialog(addDialog)"
        [componentIf]="{val: securityRoute}"
        type="button">
        {{ buttonText }}
    </button>
</div>

<!-- TABLE -->
<div class="table-responsive pt-4">
    <table mat-table [dataSource]="dataSource" matSortActive="name" matSortDirection="asc" [hidden]="dataSource.data.length === 0" matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let item">
                {{item.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
            <td mat-cell *matCellDef="let item">
                {{item.updateDate | date: 'MMM dd, yyyy'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated By </th>
            <td mat-cell *matCellDef="let item">
                {{item.user.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let item">
                {{item.metadata.type}}
            </td>
        </ng-container>
        <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Label </th>
            <td mat-cell *matCellDef="let item">
                {{item.metadata.label}}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef aria-label="actions"> </th>
            <td width="10%" mat-cell *matCellDef="let item">
                <div class="flex- justify-content-end">
                    <a mat-button *ngIf='item?.name?.endsWith(".pdf")' (click)="view(item)">VIEW</a>
                    <button mat-icon-button (click)="download(item)"><mat-icon>download_outline</mat-icon></button>
                    <button mat-icon-button [disabled]="!isEditable" (click)="openDialog(deleteFileDialog, item)"
                        [componentIf]="{val: securityRoute}"><mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
    </table>
</div>

<!-- EMPTY TABLE -->
<div class="col align-center pl-3 pb-3 helper-text" *ngIf="!dataSource.data.length && !isEditable">
    Currently no supporting documents.
</div>


<!-- DIALOGS -->

<ng-template #addDialog let-data>
    <!-- [showX]="!uploading" -->
    <div class="center">
        <div class="editor">
            <div class="editor-title">
                <div class="title">UPLOAD DOCUMENT</div>
                <!-- <div class="subtitle"></div> -->
            </div>
            <div class="editor-section">
                <div class="items">
                    <div *ngIf="list?.options?.length > 0" class="item">
                        <mat-label>FILE TYPE</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select [(value)]="metadata['type']" placeholder="Select">
                                <mat-option *ngFor="let option of list.options" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item" [componentIf]="{val: securityRoute}">
                        <app-uploader #uploadBox uploadLabel 
                            (complete)="uploadComplete(); closeAll();"
                            (completeItem)="updateTable($event);"
                            (uploading)="uploading = $event;"
                            route="/file/create" 
                            [buildForm]="buildForm()"
                            [uploaderOptions]="this.options"
                            [tableItems]="dataSource.data"
                            [upload]="uploading"
                            [cancel]="canceling"
                            [showDetails]="showDetails">
                        </app-uploader>
                    </div>
                </div>
            </div>
            <div class="action-btns">   
                <button 
                    class="mr-3" 
                    mat-stroked-button 
                    color="accent" 
                    (click)="uploadBox.uploader.queue.length > 1 ? openDialog(discardAllDialog) : closeAll();" 
                    [disabled]="uploading">
                    Cancel
                </button>
                <button 
                    mat-flat-button 
                    color="accent" 
                    (click)="canceling=false; uploadBox.uploadAll();" 
                    [disabled]="uploadBox.uploader.queue.length === 0 || uploading">
                    Upload
                </button>             
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteFileDialog let-data>
    <div class="center">
        <div class="editor">
            <div class="editor-title">
                <div class="title">DELETE DOCUMENT</div>
                <!-- <div class="subtitle"></div> -->
            </div>
            <div class="editor-section">
                <div class="items">
                    <div class="item">
                        Are you sure you want to delete the <b>{{ data.name }}</b> document?
                    </div>
                </div>
            </div>
            <div class="action-btns">             
                <button mat-stroked-button color="accent" class="mr-3" (click)="closeAll()">Cancel</button>
                <button mat-flat-button color="warn" (click)="delete(data)">Delete</button>   
            </div>
        </div>
    </div>
</ng-template>

<ng-template #discardAllDialog let-data>
    <div class="center">
        <div class="editor">
            <div class="editor-title">
                <div class="title">DISCARD FILES FROM UPLOADING</div>
                <!-- <div class="subtitle"></div> -->
            </div>
            <div class="editor-section">
                <div class="items">
                    <div class="item">
                        All the files will be discarded from the queue.
                    </div>
                </div>
            </div>
            <div class="action-btns">                
                <button mat-stroked-button color="accent" class="mr-3" mat-dialog-close>Resume</button>
                <button mat-flat-button color="primary" (click)="canceling = true; uploading = false; closeAll();">Discard Files</button>
            </div>
        </div>
    </div>
</ng-template>
