import { Injectable } from '@angular/core';
import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeService extends AbstractService {
  /* ATTRIBUTES */

  private CHANGE_LIST = '/change/list';


  /* METHODS */

    /**
     * list changes for organization.
     */
    public list(referenceType?: string, referencePkId?: string, limit?: number) {
        return this.post(this.CHANGE_LIST, { referenceType, referencePkId, limit })
    }

}
