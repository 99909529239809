import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanNumeral'
})
export class RomanNumeralPipe implements PipeTransform {

  roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };

  transform(value: any): any {
    if (!+value) return value;
    let numValue: number = +value;
    let returnStr = '';
    for (const i of Object.keys(this.roman)) {
      const q = Math.floor(numValue / this.roman[i]);
      numValue -= q * this.roman[i];
      returnStr += i.repeat(q);
    }
    return returnStr;
  }

}
