<ng-container>
    <div class="main backdrop"> 
        <header class="main-header" *ngIf="config.isHeaderVisible"></header>
        <div class="">
            <div class="main-navigation" *ngIf="config.isNavigationVisible"> 
                <navigation></navigation>
            </div>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container>

<app-error></app-error>
<app-chat *ngIf="config.isChatAvailable"></app-chat>

<!-- 
    <mat-progress-bar class="page-progress-bar" mode="indeterminate" [@fade]></mat-progress-bar> 
-->

