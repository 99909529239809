<div #loaderSpinner id="loaderSpinner" [ngClass]="isResize?'load-resize-spinner':'load-default-spinner'" *ngIf="show && !isFullPage">
    <div class="container">
        <div class="petals">
            <div class="petal-1">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#83D2E1" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>          
            </div>
            <div class="petal-2">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">                
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#23A9BA" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>       
            </div>
            <div class="petal-3">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#0D636E" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>          
            </div>
        </div>
        <div class="text">{{text}}</div>
    </div>
</div>

    
<div #loaderSpinner id="loaderSpinner" [ngClass]="isResize?'load-resize-spinner':'load-default-spinner'" *ngIf="show && isFullPage">
    <div class="container-full">
        <div class="petals">
            <div class="petal-1">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#83D2E1" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>          
            </div>
            <div class="petal-2">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">                
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#23A9BA" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>       
            </div>
            <div class="petal-3">
                <svg width="596" height="268" viewBox="0 0 596 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path transform="rotate(-14 297.988 133.896)" stroke="null" id="svg_1" fill="#0D636E" d="m554.69675,335.89622l-330.287,-87.989c-137.8769,-36.757 -219.9195,-178.254 -183.13056,-316.011l330.28656,87.9887c137.877,36.7573 219.92,178.2543 183.131,316.0113z"/>
                </svg>          
            </div>
        </div>
        <div class="text">{{text}}</div>
    </div>
</div>