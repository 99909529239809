import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.less']
})
export class UploadDialogComponent implements OnInit {
  @Input('data') inputData;

  /**
   * .
   */
  relatedControl;
  uploadRoute;

  constructor(private dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initializeData(data);
  }

  initializeData(data) {
    if (data) {
      if (data.currentItem) {
        this.relatedControl = [ data.currentItem.id ];
      }
      if (data.route) {
        this.uploadRoute = data.route;
      }
    }
  }

  ngOnInit() {
    this.initializeData(this.inputData);
  }

  /**
   * closes the dialog with a false return value
   * to indicate creation did not happen
   */
  cancel() {
    this.dialogRef.close(false);
  }

  /**
   * closes the dialog with a true return value
   * to indicate upload process was successful
   */
  uploadComplete() {
    this.dialogRef.close(true);
  }

}
