
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.less'],
})
export class IconPickerComponent implements OnInit {

    /* ATTRIBUTES */

    defaultIcons = ['home', 'info', 'flag', 'outlined_flag', 'flag_circle', 'shield', 'security', 'policy', 'lock_outline', 'watch_later', 'face', 'lock',
    'vpn_key', 'pin', 'vpn_lock', 'enhanced_encryption', 'safety_check', 'layers', 'place', 'badge', 'local_police',
    'emergency', 'warehouse', 'crisis_alert', 'fort', 'sos', 'new_releases', 'star', 'star_border', 'warning', 'error',
    'error_outline', 'warning_amber', 'attach_money', 'computer', 'phone_android', 'memory', 'router', 'sim_card',
    'password', 'gpp_good', 'pin', 'system_security_update_warning', 'system_security_update', 'electric_bolt', 'dashboard'];
    defaultIcon = 'add_circle_outline'

    defaultEmojis = [];
    defaultEmoji = '';

    @Input() icon;
    @Input() default ='insert_emoticon'
    @Input() library;
    @Input() type: 'emoji' | 'icon' = 'icon';
    @Input() color; // = '#11818F';
    @Input() isForInput = false;

    @Output() selected = new EventEmitter();

    library_ = [];
    keyword = '';

    // default emoji icons
    unicodeStart = 127744;
    unicodeEnd = 129510;


    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor() { }

    /**
     * On Init.
     */
    ngOnInit() {
        switch (this.type) {
            case 'emoji':
                if (!this.library) {
                    for (let i = this.unicodeStart; i <= this.unicodeEnd; i++) {
                        this.defaultEmojis.push(`&#${i};`);
                    }
                }
                if (!this.icon) {
                    this.icon = this.defaultEmoji;
                }
                break;
            case 'icon':
                if (!this.icon) {
                    this.icon = this.defaultIcon;
                }
        }

        this.search();
    }


    /* METHODS */

    /**
     * Select an icon.
     */
    select(item) {
        if (!this.isForInput) {
            this.icon = item;
        }

        this.selected.emit(item);
    }

    /**
     * Search.
     */
    search(){
        if (!this.library) {
            switch(this.type) {
                case 'emoji':
                    this.library = this.defaultEmojis;
                    break;
                case 'icon':
                    this.library = this.defaultIcons;
                    break;
            }
        }
        this.library_ = this.library.filter(item => item.toLowerCase().includes(this.keyword.toLowerCase()));
    }

}
