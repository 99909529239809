<link href="https://fonts.googleapis.com/icon?family=Dancing+Script" rel="stylesheet" />


<div class="pt-4">
    <mat-tab-group preserveContent="true" dynamicHeight fitInkBarToContent mat-stretch-tabs="false" (selectedIndexChange)="changeTab($event)" [selectedIndex]="selectedTab">
        <mat-tab label="DRAW IT IN">
            <div class="row no-gutters">
                <div class="col signature-box">
                    <mat-divider class="relative signature-line" style="top: 80%"></mat-divider>
                    <div>
                        <button mat-icon-button (click)="clearSignatureCanvas()" style="z-index: 100; position: absolute;">
                            <mat-icon class="material-icons-outlined">cancel</mat-icon>
                        </button>
                        <canvas class="signature-canvas" id="signature-canvas"></canvas>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="TYPE">
            <div class="row no-gutters">
                <div class="col signature-box">
                    <div>
                        <mat-form-field class="w-100" appearance="fill" style="font-family: 'Dancing Script'; font-size: 2.75rem">
                            <input matInput [(ngModel)]="data.signature" (ngModelChange)="updateTextSignature($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="UPLOAD" disabled>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="pt-3 pb-3">
    By signing this document with an electronic signature, I agree that
    such signature will be as valid as handwritten signatures to the extent
    allowed by federal law.
</div>