import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartStyler } from '@src/shared/objects/chart-styler';
import { Cache } from '@src/shared/objects/cache';

/**
 * Bar Chart
 * Example: 
 * Input Data:
 */

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.less']
})
export class BarChartComponent implements OnInit, OnChanges {

    @Input() data = [];
    @Input() groups = [];

    @Input() title = '';
    @Input() xLabel = '';
    @Input() yLabel = '';

    @Input() height = 250;
  
    /* ATTRIBUTES */
  
    public chart; // the chart object
    private chartOptions; // the options used to render the chart

    private theme = ChartStyler.get();
  
    /* CONSTRUCTOR */
  
    /**
     * Constructor.
     */
    public constructor() {
    }
  
    /* EVENT HANDLING */
  
    /**
     * On Init.
     */
    public ngOnInit(): void {
        Cache.onChanges().subscribe(res => {
            if (res?.key === 'preferences') {
              this.theme = ChartStyler.get();
              this.generateChart();
            }
          });
    }
  
    /**
     * On Change.
     */
    public ngOnChanges(): void {
        this.generateChart();
    }
  
    /* METHODS */
  
    /**
     * Generate the chart.
     * Should be re-called when data or styles change.
     */
    public generateChart() {

        for (let series of this.data) {
            series.color = this.theme?.color[series.color_];
        }
  
        this.chartOptions = {
          chart: {
              margin: [60, 10, 55, 60],
              type: 'column',
              backgroundColor: 'transparent',
              height: this.height
          },
          title: {
              text: this.title,
              y: 35,
              align: 'left'
          },
          credits: {
              enabled: false
          },
          xAxis: {
              categories: this.groups
          },
          legend: {
              enabled: true,
              align: 'right',
              verticalAlign: 'top'
          },
          yAxis: {
            min: 0,
            title : {
                rotation: -90,
                x: 10,
                text: this.yLabel
            },
            stackLabels: {
                enabled: false,
            },
            gridLineColor: 'transparent',
        },
          toolTip: {
            outside: true
          },
          plotOptions: {
              column: {
                stacking: 'normal',
                animation: {
                    duration: 0
                },
                datalabels: {
                    enabled: true
                }
              }
          },
          series: this.data

        };
  
        this.chart = new Chart(this.chartOptions);
    }
}
