import { v4 as uuidv4 } from 'uuid';

/**
 * Access.
 */
export class Access {

    /* ATTRIBUTES */

    // GENERAL

    public pkId: string = ''; // the object id
    public organizationPkId: string = ''; // the organization pkid for which this help request belongs to
    public userPkId: string = ''; // user id
    public userName: string = ''; // user name

    public name: string = ''; // title of the request
    public description: string = ''; // description of the request
    public ticketNumber: string = ''; // the help ticket number
    public urgency: 'High' | 'Medium' | 'Low' = 'Low'; // sense of urgency
    public priority: 'High' | 'Medium' | 'Low' = 'Low'; // priority
    public status: 'Open' | 'Approved' | 'Rejected' = 'Open'; // status

    public group: Privilege[] = []; // list of directory service requested
    public hardware: Privilege[] = []; // list of hardware requested
    public instance: Privilege[] = []; // list of instances requested
    public software: Privilege[] = []; // list of software requested
    public virtual: Privilege[] = []; // list of virtual components requested  
        
    public isLocked = false; // determines if the request has been submitted and locked

    // DATE

    public createDate: Date = new Date(); // create date
    public updateDate: Date = new Date(); // last update date
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuidv4(); }
    }
    
}

/**
 * Privilege.
 */
class Privilege {
    pkId: string;
    name: string;
    environment: string;
    privileges?: string [];
}