<div class="container-fluid nopadding">
    <div class="row no-gutters gap-3 pb-3" *ngIf="this.isActionable">
        <div class="col-lg no-gutters">
        </div>
        <div class="col-lg-auto" no-gutters>                    
            <button class="" mat-flat-button color="primary" (click)="add();">ADD</button> 
        </div> 
    </div>
    <div class="row no-gutters pb-5" *ngIf="this.annotation">
        {{ this.annotation }}
    </div>
    <div class="p-3 " *ngFor="let item of transformations; let i = index">
        <div class="row no-gutters gap-3">
            <div class="col-lg-10 no-gutters">
                <div class="p-3">

                    <div class="pb-3">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Key</mat-label>
                            <mat-select [(ngModel)]="item.key" [name]="'key-'+i">
                                <mat-option *ngFor="let pair of object | keyvalue" [value]="pair.key">{{pair.key}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
        
                    <div class="p-3 config-container">
                        <div class="pb-3" *ngFor="let operation of item.pipeline; let j = index">
                            <div class="row no-gutters gap-3">
                                <div class="col-lg-1 no-gutters">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Index</mat-label>
                                        <input [name]="'index-'+i+'-'+j" readonly matInput [value]="j">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4 no-gutters">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Action</mat-label>
                                        <mat-select [(ngModel)]="operation.action" [name]="'action-'+i+'-'+j">
                                            <mat-option value="Custom">Custom</mat-option>
                                            <mat-option value="Map">Map</mat-option>
                                            <mat-option value="Split">Split</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> 
                                <div class="col-lg-4 no-gutters">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Argument</mat-label>
                                        <input [name]="'arg-'+i+'-'+j" matInput [(ngModel)]="operation.argument">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg no-gutters" style="border-left: 1px solid #CCC" >
                                    <button mat-icon-button (click)="deleteOperation(item, j)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="pt-3 d-flex justify-content-end" *ngIf="this.isActionable">
                            <button class="" mat-flat-button color="primary" (click)="addOperation(item)">ADD</button> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg no-gutters" style="border-left: 1px solid #CCC" >
                <button mat-icon-button (click)="delete(i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>