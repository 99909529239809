const config = {
    appName: 'Velicus',
    appPkId: 'fabfc627-4f5a-4719-81b5-3abd47705e1c',
    devApiBaseUrl: 'http://localhost:3100',   // only used for DEV env (localhost)
    defaultRoute: '/private',
    isChatAvailable: true,
    isDarkModeEnabled: true,
    isHeaderVisible: true,
    isNavigationVisible: true,
    navigation: [
        {
            icons: {
                inactive: 'assets/icons/event.svg',
                active: 'assets/icons/event.svg'
            },
            title: 'Events',
            link: '/private/deployments',
            active: /^\/private\/deployments\/(.*)/,
        },
        {
            icons: {
                inactive: 'assets/icons/users.svg',
                active: 'assets/icons/users.svg'
            },
            title: 'Users',
            link: '/private/user',
            active: /^\/private\/ususers\/(.*)/,
        },
        {
            icons: {
                inactive: 'assets/icons/admin.svg',
                active: 'assets/icons/admin.svg'
            },
            title: 'Admin',
            link: '/private/admin',
            active: /^\/private\/admin\/(.*)/,
        },
        {
            icons: {
                inactive: 'assets/icons/settings.svg',
                active: 'assets/icons/settings.svg',
            },
            title: 'System Settings',
            link: '/private/settings/roles',
            active: /^\/private\/settings\/(.*)/,
            permissioned: true,
        },
    ],
    routes: {
        PUBLIC                                  : '/public',
        PUBLIC_CONTENT                          : '/public/content',
        PUBLIC_ERROR                            : '/public/error',

        PRIVATE_AUTHORIZATION_APPROVE           : '/private/authorization/approve',

        PRIVATE                                     : '/private',
        PRIVATE_ASSET                               : '/private/asset',
        PRIVATE_ASSET_EDIT_ASSET                    : '/private/asset/edit-asset',
        PRIVATE_ASSET_EDIT_ASSET_EDIT               : '/private/asset/edit-asset/edit',
        PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN         : '/private/asset/edit-asset/edit-admin',
        PRIVATE_ASSET_EDIT_ATTACHMENTS              : '/private/asset/edit-attachments',
        PRIVATE_ASSET_EDIT_ATTACHMENTS_EDIT         : '/private/asset/edit-attachments/edit',
        PRIVATE_ASSET_EDIT_BUSINESS_IMPACT          : '/private/asset/edit-business-impact',
        PRIVATE_ASSET_EDIT_BUSINESS_IMPACT_EDIT     : '/private/asset/edit-business-impact/edit',
        PRIVATE_ASSET_EDIT_CONNECTIONS              : '/private/asset/edit-connections',
        PRIVATE_ASSET_EDIT_CONNECTIONS_EDIT         : '/private/asset/edit-connections/edit',
        PRIVATE_ASSET_EDIT_HARDWARE                 : '/private/asset/edit-hardware-inventory',
        PRIVATE_ASSET_EDIT_HARDWARE_CLEAR           : '/private/asset/edit-hardware-inventory/clear',
        PRIVATE_ASSET_EDIT_HARDWARE_EDIT            : '/private/asset/edit-hardware-inventory/edit',
        PRIVATE_ASSET_EDIT_INFORMATION_TYPES        : '/private/asset/edit-information-types',
        PRIVATE_ASSET_EDIT_INFORMATION_TYPES_EDIT   : '/private/asset/edit-information-types/edit',
        PRIVATE_ASSET_EDIT_INFORMATION_TYPES_VIEW   : '/private/asset/edit-information-types/view',
        PRIVATE_ASSET_EDIT_INHERITABILITY           : '/private/asset/edit-inheritability',
        PRIVATE_ASSET_EDIT_INHERITABILITY_EDIT      : '/private/asset/edit-inheritability/edit',
        PRIVATE_ASSET_EDIT_INHERITABILITY_SETTINGS  : '/private/asset/edit-inheritability/settings',
        PRIVATE_ASSET_EDIT_INHERITANCE              : '/private/asset/edit-inheritance',
        PRIVATE_ASSET_EDIT_INHERITANCE_EDIT         : '/private/asset/edit-inheritance/edit',
        PRIVATE_ASSET_EDIT_INHERITANCE_REQUEST      : '/private/asset/edit-inheritance/request',

        PRIVATE_CONTROL_EDIT_CONTROL            : '/private/control/edit-control',
        PRIVATE_CONTROL_CREATE_FINDING          : '/private/control/create-finding',
        PRIVATE_CONTROL_MIGRATE_CONTROLS        : '/private/control/migrate-controls',

        PRIVATE_MANAGE                          : '/private/technology',
        PRIVATE_MANAGE_CREATE_ASSET             : '/private/technology/create-asset',
        PRIVATE_MANAGE_IMPORT_ASSET             : '/private/technology/import-asset',
        PRIVATE_MANAGE_ASSETS                   : '/private/technology/manage-assets',
        PRIVATE_MANAGE_ASSETS_ADMIN             : '/private/technology/manage-assets/admin',
        PRIVATE_MANAGE_ASSETS_DELETE            : '/private/technology/manage-assets/delete',
        PRIVATE_MANAGE_ASSETS_EDIT              : '/private/technology/manage-assets/edit',
        PRIVATE_MANAGE_EXPORT                   : '/private/technology/export',
        PRIVATE_MANAGE_VIEW_ASSET_REPORT        : '/private/technology/view-asset-report',


        PRIVATE_PROCESS                         : '/private/process',
        PRIVATE_PROCESS_APPROVE                 : '/private/process/approve',
        PRIVATE_PROCESS_APPROVE_REQUEST         : '/private/process/approve/request',
        PRIVATE_PROCESS_APPROVE_AUTHORIZE       : '/private/process/approve/authorize',
        PRIVATE_PROCESS_ASSESS_PHASE            : '/private/process/assess-phase',
        PRIVATE_PROCESS_EDIT_DOCUMENTS          : '/private/process/edit-documents',
        PRIVATE_PROCESS_SEND_PHASE_FOR_REVIEW   : '/private/process/send-phase-for-review',
        PRIVATE_PROCESS_START_PROCESS           : '/private/process/start-process',
        PRIVATE_PROCESS_START_PROCESS_ADD       : '/private/process/start-process/add',
        PRIVATE_PROCESS_START_PROCESS_REMOVE    : '/private/process/start-process/remove',
        PRIVATE_PROCESS_SUBMIT                  : '/private/process/submit',
        PRIVATE_PROCESS_VIEW_OA_DASHBOARD       : '/private/process/view-oa-dashboard',
        PRIVATE_PROCESS_VIEW_PHASE              : '/private/process/view-phase',
        PRIVATE_PROCESS_VIEW_PROCESS_DASHBOARD  : '/private/process/view-process-dashboard',


        PRIVATE_DEPLOYMENTS                     : '/private/deployments',
        PRIVATE_ADMIN                           : '/private/admin',
        PRIVATE_ADMIN_REQUIREMENTS              : '/private/admin/requirements',
        PRIVATE_ADMIN_CREATE_REQUIREMENTS       : '/private/admin/create-requirements',
        PRIVATE_ADMIN_EDIT_REQUIREMENTS         : '/private/admin/edit-requirements',

        PRIVATE_QUESTIONNAIRE                   : '/private/questionnaire',
        PRIVATE_QUESTIONNAIRE_BUILD_QUESTIONNAIRE: '/private/questionnaire/build',
        PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE: '/private/questionnaire/edit',
        PRIVATE_QUESTIONNAIRE_PREVIEW_QUESTIONNAIRE: '/private/questionnaire/preview',
        PRIVATE_QUESTIONNAIRE_VIEW_QUESTIONNAIRE: '/private/questionnaire/view',

        PRIVATE_SETTINGS                        : '/private/settings',
        PRIVATE_SETTINGS_DOCUMENTS              : '/private/settings/documents',
        PRIVATE_SETTINGS_FIELDS                 : '/private/settings/fields',
        PRIVATE_SETTINGS_QUESTIONNAIRES         : '/private/settings/questionnaires',
        PRIVATE_SETTINGS_LIST                   : '/private/settings/list',
        PRIVATE_SETTINGS_LIST_EDIT              : '/private/settings/list/edit',
        PRIVATE_SETTINGS_LIST_REMOVE            : '/private/settings/list/remove',
        PRIVATE_SETTINGS_OVERLAYS               : '/private/settings/overlays',
        PRIVATE_SETTINGS_PROCESS                : '/private/settings/process',
        PRIVATE_SETTINGS_ROLES                  : '/private/settings/roles',
        PRIVATE_SETTINGS_STANDARDS              : '/private/settings/standards',
        PRIVATE_SETTINGS_STANDARDS_EDIT         : '/private/settings/standards/edit',
        PRIVATE_SETTINGS_TRANSLATOR             : '/private/settings/translator',
        PRIVATE_SETTINGS_TYPE_AHEAD             : '/private/settings/type-ahead',
        PRIVATE_SETTINGS_USER                   : '/private/settings/user',

        PRIVATE_USER_MANAGE_USERS               : '/private/user/manage-users',
        PRIVATE_USER_ASSIGN_ROLES               : '/private/user/assign-roles',
        PRIVATE_USER_LOGS                       : '/private/user/logs',
    },
    components: {
        PRIVATE_SETTINGS: [
            { index: 7, label: "Roles", link: '/private/settings/roles' },
            { index: 5, label: "Documents", link: '/private/settings/documents' },
        ]
    }
};

export default config;
