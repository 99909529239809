import {Component, ViewChild, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import {CdkPortal,DomPortalHost} from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'window',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `
})
export class WindowComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(CdkPortal) portal: CdkPortal;

  @Output() closed = new EventEmitter<any>();

  private externalWindow: Window = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector){}


  ngOnInit(){

  }

  ngAfterViewInit(): void {
    this.externalWindow = window.open('', '', 'width=500,height=800,left=200,top=200');
    this.externalWindow.document.write(`
    <html>
    <style>
    button {border: none;}
    button:hover {cursor:pointer;}
    </style>
    <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" crossorigin="anonymous" />
    <link rel="stylesheet" type="text/css" href="../../styles.css">
    <link rel="stylesheet" type="text/css" href="../../dark.css">
    
    </head>
    <body>`);

    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
      );


    host.attach(this.portal);
    const instance = this;
    this.externalWindow.onbeforeunload = function() {instance.closed.emit();}
  }

  ngOnDestroy(){
    this.externalWindow.close()
  }
}