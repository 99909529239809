
import { AfterViewInit, Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';


@Directive({
  selector: '[filtering]'
})
export class FilteringDirective implements AfterViewInit, OnChanges, OnDestroy {

    @Input() set filtering(data: [MatTableDataSource<any>, string, {[key: string]: string}, string[]]) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => { this.setFilters(data); }, this.delay);
    }

    /* ATTRIBUTES */

    public timeout;
    public delay = 0; // set to 0 from 1000 due to causing issue with mat-sort

    /* CONSTRUCTOR */

    /**
     * Constructor. 
     */
    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    /**
     * After View Init.
     */
    public ngAfterViewInit() {
    }

    /**
     * On Changes.
     */
    public ngOnChanges(changes: SimpleChanges) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => { this.setFilters(changes.filtering.currentValue); }, this.delay);
    }

    /**
     * On Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Set filters.
     */
    public setFilters(
        [dataSource, keyword, filters, fields]: [MatTableDataSource<any>, 
        string, 
        { [key: string]: string }, 
        string[]]) {

        this.viewContainer.clear();
        const embededViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        // embededViewRef.markForCheck();
        embededViewRef.detectChanges();
        const toFilter = Object.keys(filters || {}).reduce((acc, curr) => acc = acc || filters[curr].length > 0, false) || keyword;
        if (dataSource) {
            if (toFilter) {
              dataSource.filterPredicate = this.filterPredicate.bind(this);
              dataSource.filter = JSON.stringify({keyword, filters, fields});
            } 
            else {
                dataSource.filterPredicate = (data: any, filter: string) => true;
                dataSource.filter = '';
            }
        }
    }

    /**
     * Custom Datasource filtering predicate.
     */
    private filterPredicate(data: any, filter: string): boolean {
        // convert the passed in filter string back into an object
        const { filters, keyword, fields } = JSON.parse(filter);
        const keyFilters = Object.keys(filters || {}).reduce((andAcc, key) => {
            const filterValue = filters[key];
            let dataValue: any = this.getProperty(data, key);          
            const result = filterValue.reduce((orAcc, value) => {
                let stringMatch = false;

                // if filtering for empty string, compare directly and return result
                if (value === '') {
                    if (dataValue === value) {
                        return true;
                    } 
                    else {
                        return false;
                    }
                } 
                else if (value.nested) {
                    dataValue = data[value.nested][value.key] || '';
                    value = value.value;

                    // console.log("FILTERS = ", filters);
                    // console.log("KEY = ", key);
                    // console.log("FILTER VLAUE = ", filterValue);
                    // console.log("DATA VALUE = ", dataValue);
                    // console.log("VALUE = ", value);

                    if (dataValue === value || (Array.isArray(dataValue) && dataValue.length >= value)) {
                        return true;
                    } 
                    else {
                        return false;
                    }
                } 
                else if (value.key) {
                    // handle map values with '.' to get nested values
                    dataValue = value.key.split('.').reduce((a, b) => { return a ? a[b] : ''; }, data) || '';
                    value = value.value;
                    if (dataValue === value || (Array.isArray(dataValue) && dataValue.length >= value)) {
                        return true;
                    } 
                    else {
                        return orAcc;
                    }
                } 
                else if (Array.isArray(dataValue)) {
                    if (value.exactMatch) {
                        return dataValue.includes(value.value);
                    } 
                    else {
                        if (JSON.stringify(dataValue).toLowerCase().includes(value.toLowerCase())) {
                            return true;
                        } 
                        else {
                            return orAcc;
                        }
                    }
                }

                if (typeof value === 'boolean') {
                    const isTrueSet = (dataValue === 'true');
                    stringMatch = isTrueSet === value;
                } 
                else if (value.exactMatch ) {
                    stringMatch = dataValue === (value.value || value);
                }
                else if ( typeof value === 'string' ) {
                    stringMatch = dataValue.toLowerCase().includes(value.toLowerCase());
                }
                else {
                    stringMatch = dataValue === value.toString().toLowerCase();
                }
                
                orAcc = orAcc || stringMatch;
                return orAcc;
              
            }, false) || filterValue.length === 0;

            andAcc = andAcc && result;
            return andAcc;
            
        }, true);

        if (fields) {
            const filteredData = {};
            for (let field of fields) {
                filteredData[field] = data[field];
            }
            data = filteredData;
        }
        
        const keywordFilter = JSON.stringify(data).toLowerCase().includes(keyword.toLowerCase());
        return keyFilters && keywordFilter;
    }

    /**
     * Get property.
     */
    private getProperty(data, key): string {
        if (data[key] || data[key] === 0 || data[key] === false) {
            if (Array.isArray(data[key])) {
                return data[key];
            } 
            else {
                return data[key].toString();
            }
        }
        if (key.includes('.')) {
            const keys = key.split('.');
            return keys.reduce((acc, curr) => {
              if ( acc[curr]) acc = acc[curr];
              return acc;
            }, data).toString();
        } 
        else 
          return '';
    }

}
