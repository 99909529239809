import { Injectable, Injector } from '@angular/core';

import { List } from "../objects/list";
import { lastValueFrom } from 'rxjs';

import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: "root",
})
export class ListService extends AbstractService {

    /* ATTRIBUTES */

    private map = new Map<string, List>(); // the map of all lists keyed by the system name

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private i: Injector) {
        super(i);

        // let list = await this.listService.get({ systemName: 'openCloseStatusList' }).toPromise();
        // this.LIST_STATUS_OPEN_CLOSE = list.options;
        // list = await this.listService.get({ systemName: 'softwareTypeList' }).toPromise();
        // this.LIST_TYPE_SOFTWARE = list.options;
        // list = await this.listService.get({ systemName: 'environmentTypeList' }).toPromise();
        // this.LIST_TYPE_ENVIRONMENT = list.options;

    }

    /**
     * Load all of the lists from the server and store it in memory.
     */
    public async init(organizationPkId: string) {
        if (organizationPkId) {
            let lists = await lastValueFrom(this.all(organizationPkId));
            for (let list of lists) {
                this.map.set(list.systemName.toLowerCase(), list);
            }  
            
            // this.all(organization.pkId).subscribe(res => {
            //     for (let list of res) {
            //         this.map.set(list.systemName, list);
            //     }                
            //     // console.log("/shared/services/list/init: LISTS = ", this.map);
            // });
        }
    }

    /* METHODS */

    //#region CLIENT

    /**
     * Get the list by the system name. First, lookup the list by organization, then
     * by global.
     */
    public getList(key, organization?) {
        let list = this.map.get(key.toLowerCase());
        return list;
    }

    //#endregion

    //#region SERVER

    /**
     * Get entities.
     */
    public all(pkId) {
        return this.post("/list/all", { pkId: pkId });
    }

    /**
     * Create entity.
     */
    public create(entity: List) {
        return this.post("/list/create", { entity });
    }

    /**
     * Delete entity.
     */
    public delete(pkId: string) {
        return this.post("/list/remove", { pkId: pkId });
    }

    /**
     * Export list options.
     */
    public exportOptions(pkId: string) {
        this.post('/list/options/export', {pkId}, { isDownload: true }).subscribe(data => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Get entities for a given asset.
     */
    public get(query) {
        return this.post("/list/get", { query });
    }

    /**
     * Export list options.
     */
    public importOptions(file: File) {
        this.post('/list/options/import', {file}, { isDownload: true }).subscribe(data => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }
    
    /**
     * Save the entity.
     */
    public update(pkId: string, update) {
        return this.post("/list/update", { pkId, update });
    }

    //#endregion
}









