import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';

import { AssetService } from '@src/shared/services/asset.service';
import { ProcessService } from '@src/shared/services/process.service';
import { SecurityService } from '@src/shared/services/security.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, AfterViewInit {

    /* ATTRIBUTES */

    public cache; // the session cache

    public asset; // the selected asset
    public configuration; // the organization configuration
    public organization; // the organization
    public phase; // the selected phase
    public processes; // the processes configured for the organization
    public process; // the process assigned to the asset
    public step; // the selected step

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,

        private assetService: AssetService,
        private processService: ProcessService,
        private securityService: SecurityService) { 

    }

    /* EVENT HANDLING */

    /**
     * The authentication service redirects the user to the signin component after 
     * s/he has successful authenticated through the identity provider and internal 
     * application registry. The user information is passed to signin. The signin 
     * initializes the cache so that the first route can be checked by the role guard. 
     * The signin also passes the user metadata to the security service to be saved 
     * into the application database.
     */
    public async ngOnInit() {

        this.route.queryParams.subscribe(async params => {

            //#region INIT
            
            // Get the data from the parameters in the URL. The data could
            // contain information such as the user profile that is required
            // to initialize the application security.

            let decoded;
            if (params?.data) { decoded = decodeURIComponent(params?.data); }
            await this.securityService.init(decoded);      
            
            //#endregion

            //#region CACHE

            // We use the session storage to cache small data sets and the local storage 
            // to cache large data and improve system performance. The list of users for 
            // large organizations must be cached and lazily initialized since this 
            /// component is used by many other components.

            this.cache = Cache.get(); // the session cache
            this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
            this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
            this.processes = this.configuration.processes;

            if (this.organization) {
                let local = Cache.get(); // the local cache
                let value = local.getValue(Cache.KEYS.USERS);
                if (!value) {         
                    this.securityService.getUsersByOrganization(this.organization.orgPkId).subscribe(res => {
                        if (res) {
                            local.setValue(Cache.KEYS.USERS, res);
                        }
                    });
                }
            }

            //#endregion

            //#region PAGE

            // Get the page from the parameters in the URL. If a page exists
            // then exract the settings and set up the cache with the proper
            // data before routing to the page.

            let page = params?.page;
            if (page) {     
                page = atob(page); // decode using base 64
                let url = new URL(page);
                let assetPkId = url.searchParams.get("assetPkId");
                let processPkId = url.searchParams.get("processPkId");
                let phasePkId = url.searchParams.get("phasePkId");
                let stepPkId = url.searchParams.get("stepPkId");

                if (assetPkId) {
                    this.assetService.getAsset(assetPkId).subscribe(res => {
                        this.asset = res;
                        if (this.asset) {                        
                            this.cache.setValue(Cache.KEYS.ASSET, this.asset);      
                            this.cache.setValue(Cache.KEYS.ASSET_PKID, this.asset.pkId);
                            if (processPkId) {                  
                                this.process = this.processes?.find(item => item.pkId === processPkId);
                                if (this.process) {
                                    this.cache.setValue(Cache.KEYS.PROCESS, this.process);
                                    if (phasePkId) {
                                        this.phase = this.process.phases.find(p => p.pkId === phasePkId);
                                        this.cache.setValue(Cache.KEYS.PHASE, this.phase);
                                        if (stepPkId) {
                                            this.step = this.phase.steps.find(s => s.pkId === stepPkId || s.referencePkId === stepPkId);
                                            this.cache.setValue(Cache.KEYS.STEP, this.step);
                                        }
                                    }

                                    // Get the full path and query parameters from the page that is
                                    // stored inside the url. Then, insert the signin route into the
                                    // location history to redirect the user upon going back. Then,
                                    // redirect the user to the page.

                                    let path = url.pathname + url.search;
                                    this.location.go('/signin'); 
                                    this.router.navigateByUrl(path, { replaceUrl: false });

                                    // console.log("/signin/ngOnInit: PAGE = ", page);
                                    // console.log("/signin/ngOnInit: PATH = ", path);
                                }
                            }
                        }
                    });
                }
                else {                    
                    window.location.replace(page);
                }
            }
            else if (config.defaultRoute) {
                this.router.navigateByUrl(config.defaultRoute);
            }

            //#endregion
        });

    }

    /**
     * After View Init.
     */
    public ngAfterViewInit() {

    }

    /* METHODS */

}
