import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';

import { Observable } from 'rxjs';

import { AbstractService } from '../services/abstract.service';

@Directive({
  selector: '[ngUniqueCheck]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueCheckDirective, multi: true }]
})
export class UniqueCheckDirective implements AsyncValidator {
  @Input('ngUniqueCheck') uniqueCheck: {apiUrl: string, lookupField: string, format: string};

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    return new Promise((resolve, reject) => {
      if (!control.value) {
        resolve(null);
      }
      const apiUrl = this.uniqueCheck.apiUrl;
      const lookupField = this.uniqueCheck.lookupField;
      const format = this.uniqueCheck.format;
      if (format) {
        const valid = (new RegExp(format)).test(control.value);
        if (!valid) {
          return resolve({ 'uniqueCheck': { message: `Invalid format` } });
        }
      }

      this.http.post(apiUrl, { [lookupField]: control.value }).subscribe(data => {
        resolve(data.length !== 0 ? { 'uniqueCheck': { message: `${control.value} is not unique` } } : null);
      }, error => {
        reject(error.message);
      });
    });
  }
  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error("Method not implemented.");
  }

  constructor(private http: AbstractService) { }

}
