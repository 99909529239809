import { AfterContentInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList } from '@angular/core';

import { StepperStepComponent } from './step/step.component';

@Component({
  selector: 'us-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less']
})
export class StepperComponent implements OnInit, AfterContentInit {
  
    @Input('selected') selected = false;
    @Input('disabled') set setDisabled(disabled) {
      this.disabled = !!disabled;
    }
    @Input('status') set setStatus(status) {
      this.pending = status === 'Pending';
      this.approved = status === 'Approved' || status === 'Passed' || status === 'Not Required';
      this.failed = status === 'Failed';
    }
    @Input('horizontal') set setHorizontal(horizontal) {
      this.horizontal = true;
    }
    @Input('noNavigation') noNavigation = false;
  
    @ContentChildren(StepperStepComponent) steps: QueryList<StepperStepComponent>;

    /* ATTRIBUTES */

    last = false;

    pending = false;
    approved = false;
    failed = false;
    horizontal = false;
    disabled = false;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(
      private elementRef: ElementRef) { 

    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    ngOnInit() {
        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.parentNode && this.elementRef.nativeElement.parentNode.lastElementChild) {
              const lastChild = this.elementRef.nativeElement.parentNode.lastElementChild;
              if (lastChild === this.elementRef.nativeElement) {
                  this.last = true;
              }
        }
    }

    /**
     * After Content Init.
     */
    ngAfterContentInit() {
        const lastChild: StepperStepComponent = this.steps.last;
        setTimeout(() => { if (lastChild) lastChild.last = true; } );
    }

    /* METHODS */

}
