<div class="row p-0 m-0">
    <div class="justify-content-center align-items-center col-auto flex- pl-0 pr-0" [ngClass]="{ 'disabled': disabled }">
        <div class="step-circle" [ngClass]="complete ? 'approved-border' : 'none-border'"></div>
        <mat-icon class="stepper-horizontal-icon" *ngIf="complete">done</mat-icon>
    </div>
    <div class="col title" [ngClass]="{ 'selected': selected, 'secondary-color': selected, 'disabled': disabled }">
        <ng-content select="[title]"></ng-content>
    </div>
</div>
<div class="ml-2" [ngClass]="{ 'step-border-left': !last, 'step-border-selected': selected && !last, 'disabled': disabled }">
    <div style="height: 1rem"></div>
    <ng-content></ng-content>
</div>
