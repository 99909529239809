<div>
    <p class="mb-2">{{editorLabel}}</p>
    <editor #tinymce 
        [name]="name" 
        (onPostRender)="initializeEditor()" 
        (onFocus)="showToolbar()" 
        (onBlur)="hideToolbar()" 
        (ngModelChange)="contentChanged($event)" 
        [(ngModel)]="content" 
        [init]="this.TINYMCE_CONFIG" 
        [disabled]="readonly">
    </editor>
</div>