import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-infinite-sort',
  templateUrl: './infinite-sort.component.html',
  styleUrls: ['./infinite-sort.component.less'],
})
export class InfiniteSortComponent {

    /* INPUTS */

    // REQUIRED

    // OPTIONAL

    /* OUTPUTS */

    @Output() action = new EventEmitter<{action:string, data: any}>();

    /* COMPONENTS */


    /* ATTRIBUTES */

    public state: 'default' | 'ascending' | 'descending' = 'default';
    public value: number = null;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() {        
    }

    // /* EVENT HANDLING */

    public changeState() {
        switch (this.state) {
            case 'default': this.state = 'ascending';
                this.emit('string', {value: 1});
                break;
            case 'ascending': this.state = 'descending';
                this.emit('string', {value: -1});
                break;
            case 'descending': this.state = 'default';
                this.emit('string', {value: 0});
                break;
            default: 
                this.state = 'default';
                this.emit('string', {value: 0});
                break;
        }
    }


    /* PUBLIC METHODS */

    /**
     * Send action event to parent.
     */
    public emit(action, data) {
        this.action.emit({action, data});
    }

    /* PRIVATE METHODS */    

}
