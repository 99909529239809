import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Finding } from '@src/shared/objects/finding';

import { AbstractService } from '@src/shared/services/abstract.service';
import { IService } from './service.interface';

@Injectable({
  providedIn: 'root'
})
export class FindingService extends AbstractService implements IService  {

    /**
     * Assign the user to the finding.
     */
    public assign(finding: Finding, assigneePkId: string): Observable<any> {
        return this.post('/finding/assign', { pkId : finding.pkId, assigneePkId : assigneePkId });
    }

    /**
     * Delete a finding.
     */
    public delete(pkId: string): Observable<any> {
      return this.post('/finding/delete', { pkId });
    }

    /**
     * Get the entity.
     */
    public get(pkId: string): Observable<any> {
        return this.post('/finding/get', { pkId });
    }

    /**
     * Get the poams based on assetPkId.
     */
    public getPoams(pkId: string): Observable<any> {
        return this.post('/finding/getPoams', { pkId });
    }
    
    /**
     * Get the entity.
     */
    public find(assetPkId: string, processPkId: string, controlId?: string): Observable<any> {
        return this.post('/finding/find', { assetPkId, processPkId, controlId });
    }

    /**
     * Get many findings
     */
    public findMany(query: any): Observable<any> {
        return this.post('/finding/findMany', query);
    }

    /**
     * Downloads a file from the asset's attachments.
     */
    public downloadFile(bucket: string, key: string, name: string) {
        this.post('/file/download/s3', { bucket, key, name },{ isDownload: true }).subscribe((data) => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Delete a file from the asset's attachments.
     */
    public deleteFile(bucket: string, key: string) {
        return this.post('/file/delete/s3', {bucket, key});
    }

    /**
     * Get the entity.
     */
    public getCve(cve: string): Observable<any> {
        return this.post('/external/cve/get', { cve });
    }

    /**
     * Get Index.
     */
    public index(organizationPkId, assetPkId?): Observable<any> {
        return this.post('/finding/index', { organizationPkId, assetPkId });
    }

    /**
     * Get all of the findings for the selected asset.
     */
    public list(organizationPkId: string): Observable<any> {
        return this.post('/finding/list', { organizationPkId });
    }

    /**
     * Get all of the findings for the selected asset.
     */
    public listAssetFindings(organizationPkId: string, assetPkId: string): Observable<any> {
        return this.post('/finding/listAssetFindings', {organizationPkId, assetPkId});
    }

    /**
     * Synchronize.
     */
     public synchronize(organizationPkId: string): Observable<any> {
        return this.post('/finding/synchronize', { organizationPkId });
    }

    /**
     * Updates one or more findings.
     */
    public update(finding:any): Observable<any> {
        // console.log("/shared/services/finding/update: FINDING = ", finding);
        return this.post('/finding/update', { finding: finding });
    }

    /**
     * Gets a file from S3. Frontend will download if browser cannot open in window.
     */
    public getFile(bucket: string, key: string, name: string) {
        return this.post('/file/view/s3', { bucket, key, name }, { isDownload: true }
        );
    }
}
