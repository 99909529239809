import { AfterViewInit, Component,Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Cache } from '@src/shared/objects/cache';

@Component({
  selector: 'app-screen-mode',
  templateUrl: './screen-mode.component.html',
  styleUrls: ['./screen-mode.component.less']
})
export class ScreenModeComponent implements OnInit, AfterViewInit {
    
    @Input() route;

    /* CONSTANTS */
    

    /* ATTRIBUTES */

    public cache: Cache; // the session cache
    public current; // the current user

    public isFullScreen = false;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private location: Location,
        private router: Router) {

    }

    /**
     * On init.
     */
    public ngOnInit() {
        this.cache = Cache.get();
        this.current = this.cache.getValue(Cache.KEYS.USER);        
        if (this.current) {
            this.isFullScreen = this.current.preference?.isFullScreen;
            setTimeout(() => 
              this.refresh(),
              500
            );
        }
    }

    /**
     * After view init.
     */
    public ngAfterViewInit() {
    }

    /* METHODS */

    /**
     * Collapse
     */
    public collapse() {
        this.isFullScreen = !this.isFullScreen;
        this.current.preference.isFullScreen = this.isFullScreen;
        this.cache.setValue(Cache.KEYS.USER, this.current);
        this.refresh();
    }

    /**
     * Expand
     */
    public expand() {
        this.isFullScreen = !this.isFullScreen;
        this.current.preference.isFullScreen = this.isFullScreen;
        this.cache.setValue(Cache.KEYS.USER, this.current);
        this.refresh();
    }

    /**
     * Refresh the user interface.
     */
    public refresh() {
        if (window.matchMedia("(max-width: 600px)").matches) {
            // mobile styles
        }
        else {
            let editor = document.getElementById("editor");
            if (editor) {
                if (this.isFullScreen) {
                    editor.style.width = '100%';
                    editor.style.padding = '50px';
                }
                else {
                    editor.style.width = '1024px';
                }
            }
    
            let workspace = document.getElementById("workspace");
            if (workspace) {
                if (this.isFullScreen) {
                    workspace.style.width = '100%';
                    workspace.style.padding = '50px';
                }
                else {
                    workspace.style.width = '1280px';
                }
            }
            
            // console.log("/shared/components/screen-mode/expand: EDITOR = ", editor);
            // console.log("/shared/components/screen-mode/expand: CURRENT USER = ", this.current);
        }

    }

}
