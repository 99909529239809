import { Injectable } from '@angular/core';

import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService extends AbstractService {

    /* CONSTRUCTOR */

    /* METHODS */

    
}
