import * as _ from "lodash";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { MatDrawer } from "@angular/material/sidenav";
import { Router } from "@angular/router";

import config from "@app/app";

import { AuthenticationService } from "@src/shared/services/authentication.service";
import { NavItem } from '../../../shared/objects/nav-item';
import { Cache } from "@src/shared/objects/cache";

import { Topic } from "@src/shared/objects/topic";
import { TopicService } from "@src/shared/services/topic.service";
import { Tree } from "@src/shared/objects/tree";

@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.less"],
  animations: [
    trigger("navAnimation", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ transform: "translateY(0%)" })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0%)" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ transform: "translateY(-100%)" })
        ),
      ]),
    ]),
    trigger("navSlideLeftAnimation", [
      transition(":enter", [
        style({ transform: "translateX(-100%)", position: "fixed" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ transform: "translateX(0%)" })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0%)", position: "fixed" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ transform: "translateX(-100%)" })
        ),
      ]),
    ]),
    trigger("navExpandAnimation", [
      transition(":enter", [
        style({ width: "0rem", position: "relative" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ width: "12.5rem" })
        ),
      ]),
      transition(":leave", [
        style({ width: "12.5rem", position: "relative" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ width: "0rem" })
        ),
      ]),
    ]),
    trigger("detailExpand", [
      transition(":enter", [
        style({ height: "0px", minHeight: "0" }),
        animate(
          "225ms 225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ height: "*" })
        ),
      ]),
      transition(":leave", [
        style({ height: "*" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ height: "0px", minHeight: "0" })
        ),
      ]),
    ]),
    trigger("searchMenuInputExpand", [
      state(
        "opened",
        style({
          width: "20rem",
          backgroundColor: "white",
        })
      ),
      state(
        "closed",
        style({
          width: "0rem",
          backgroundColor: "transparent",
        })
      ),
      transition("opened => closed", [
        style({ width: "20rem", backgroundColor: "white" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ width: "0", backgroundColor: "transparent" })
        ),
      ]),
      transition("closed => opened", [
        style({ width: "0", backgroundColor: "transparent" }),
        animate(
          "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ width: "20rem", backgroundColor: "white" })
        ),
      ]),
    ]),
  ],
})
export class NavigationComponent implements OnInit, OnDestroy {

    @ViewChild("drawer") drawer: MatDrawer;
    @ViewChild("successSubmitDialog") successSubmitDialog: TemplateRef<any>;

    /* ATTRIBUTES  */

    public config;
    public isNavigationExpanded = true;
    public isNavigationSecondaryExpanded = false;
    public navigation: NavItem[] = [];

    private topics: Topic[];
    public topicTree: Tree;

    public parent: any;

    public preference;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        @Inject(DOCUMENT) private document: Document,
        public router: Router,
        private authenticationService: AuthenticationService,
        private topicService: TopicService) {

    }

    /* EVENT HANDLING */

    /**
     * Initialize component.
     */
    public async ngOnInit() {
        this.config = config;
        this.navigation = this.config.navigation;

        Cache.changeObservable.subscribe(changes => {
          if (changes.key === 'user' || changes.key === 'preferences') {
            this.preference = this.authenticationService.getPreference();
            this.isNavigationExpanded = this.preference.isNavigationExpanded;
            
            switch (this.preference.theme) {
                case 'light':
                    this.applyStyle('light.css');
                    break;
                case 'dark':
                    this.applyStyle('dark.css');
                    break;
                default:
                    break;
            }
          }
        }); 
        
        this.preference = this.authenticationService.getPreference();
        this.isNavigationExpanded = this.preference.isNavigationExpanded;
        
        switch (this.preference.theme) {
            case 'light':
                this.applyStyle('light.css');
                break;
            case 'dark':
                this.applyStyle('dark.css');
                break;
            default:
                break;
        }
    }

    /**
     * Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Load the style sheet dynamically.
     */
    public applyStyle(styleName: string) {   
        const head = this.document.getElementsByTagName('head')[0];
        let themeLink = this.document.getElementById('theme') as HTMLLinkElement;

        if (themeLink) {
            themeLink.href = styleName;
        } 
        else {
            const style = this.document.createElement('link');   
            style.id = 'theme';
            style.rel = 'stylesheet';
            style.href = `${styleName}`;
            head.appendChild(style);
        }
    }

    /**
     * Check url.
     */
    public checkMatch(url: string | RegExp | (string | RegExp)[]): boolean {
        if (!url) {return false; }
        switch (url.constructor.name) {
            case "Array":
                return (url as (string | RegExp)[]).reduce((acc, curr) => {
                    let currResult = false;
                    switch (curr.constructor.name) {
                        case "RegExp":
                            currResult = (url as RegExp).test(this.router.url);
                            break;
                        case "String":
                            currResult = (url as string) === this.router.url;
                            break;
                  }
                  return (acc = acc || currResult);
              }, false as boolean);
            case "RegExp":
                return (url as RegExp).test(this.router.url);
            case "String":
                return (url as string) === this.router.url;
        }
    }

    /**
     * Get Topics.
     */
    public getTopics() {
      if (config.navigation.some(item_ => item_['type'] === 'Training')) {
        this.topicService.list('Training').subscribe(res => {
          this.topics = res;
          this.topicTree = new Tree();
          this.topicTree.createTree(this.topics);
        });
      }
    }

    /**
     * Show or hide the side navigation and save the user's preference.
     */
    public showHide() {
        this.isNavigationExpanded = !this.isNavigationExpanded; 
        this.preference = this.authenticationService.getPreference();
        this.preference.isNavigationExpanded = this.isNavigationExpanded;
        this.authenticationService.setPreference(this.preference);
        this.hideSecondary();
    }

    /**
     * Show or hide secondary navigation menu.
     */
    public hideSecondary() {
      this.isNavigationSecondaryExpanded = false;
      this.parent = undefined;
    }

    /**
     * Select parent item.
     */
    public selectParent(item) {
      if (this.parent === item) {
        this.parent = undefined;
        this.isNavigationSecondaryExpanded = false;
      }
      else {
        this.parent = item;
        if (item?.type === 'Training') {
          this.getTopics();
        }
        this.isNavigationSecondaryExpanded = true;
      }
    }

    /**
     * Toggle Theme between light mode and dark mode.
     */
    public toggleTheme(ev) {
        if (ev.checked) {
            this.applyStyle('dark.css');
            this.preference.theme = 'dark';
        } 
        else {
            this.applyStyle('light.css');
            this.preference.theme = 'light';
        }
        this.authenticationService.setPreference(this.preference);
    }

}
