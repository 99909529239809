import { Pipe, PipeTransform } from '@angular/core';
/**
 * Takes in array of strings
 * Returns list string
 */
@Pipe({name: 'arrayList'})
export class ArrayListPipe implements PipeTransform {
  transform(arr: any, key?:string): string {
      return key ? (arr ? arr.map(a=> a[key]).join(', ') : '') : (arr ? arr.join(', ') : '');
  }
}
