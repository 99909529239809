import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyTransformPipe implements PipeTransform {
  transform(num) {
    return num > 100000000
      ? (num / 1000000000).toFixed(2) + 'B'
      : num > 100000
        ? (num / 1000000).toFixed(2) + 'M'
        : num > 100
          ? (num / 1000).toFixed(2) + 'K'
          : num > 0
            ? num
            : 0;
  }
}
