import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrls: [],
  animations: [
    trigger('navAnimation', [
        // transition(':enter', [
        //   style({ height: '0rem' }),
        //   animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '6rem'}))
        // ]),
        transition(':leave', [
          style({ height: '6rem'}),
          animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '0rem' }))
        ])
      ]
    )
  ]
})
export class InlineAlertComponent implements OnInit {

  @Input('permanant') set setPermanant(value) {
    this.permanant = value || value === '' ? true : false
  }

  @Input('background') set setBackground(value) {
    this.background = value;
  }

  @Input('color') set setColor(value) {
    this.color = value;
  }

  // style="background: ;color: ;"
  permanant = false;
  background;
  color;
  visible = true;

  constructor() { }

  ngOnInit() {
  }

  hide() {
    this.visible = false;
  }
}
