import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AbstractService } from '@src/shared/services/abstract.service';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

import { debounceTime } from 'rxjs/operators';

import {v4 as uuid } from 'uuid';
import _ from 'lodash';


@Component({
  selector: 'app-infinite-table',
  templateUrl: './infinite-table.component.html',
  styleUrls: ['./infinite-table.component.less'],
})
export class InfiniteTableComponent implements OnInit, OnChanges, AfterViewInit {

    /* INPUTS */

    // REQUIRED

    @Input() url: string; // url for the api
    @Input() countUrl: string; // url to get the total document count

    // OPTIONAL
    
    @Input() apiBody = {start: 0, limit: 25, filters: {}, sort: {}}; // any additional fields needed for the api call
    @Input() actionItems: {icon: string, title: string, onClick: (args: any) => void}[] = [];
    @Input() buffer = 1; // ratio of data to get, vs currently viewable at a time
    @Input() columnsToDisplay = []; // to specify which columns to display
    @Input() columnWidths = {}; // to specify the width of each column
    @Input() columnMap: {title?: string, isHidden?: boolean, options?: any[], getValue?:(column: string) => string} = {}; // map column ids to colums options
    @Input() expandedDetailsTemplate; // if 'expand' column exists, template will be shown on row click
    @Input() filters = {};
    @Input() globalCellClassGetter: (item: any, column: any, rawDataMap?: any) => any; // injected method to return ngClass object for all cells (columnMap[].class will override)
    @Input() headerTemplate; // inject a header templat to be used instead for the default one.
    @Input() isDynamicHeader = false; // recalculate headers each time new data is recieved.
    @Input() isUsingDefaultPaginator = false; // use mat-paginator instead of custom paginator (Not Recommended)
    @Input() isUsingLocalData = false; // use local data instead of getting data from api
    @Input() isScrollTrigger = false; // is more data loaded on scroll to bottom?
    @Input() isTruncateCell = false; // truncate cell content to 4 lines (truncate-text-vertical)
    @Input() isUnloadData = true; // unload data not on page
    @Input() keywordSearch = ''; // for filtering directive
    @Input() localData: any[] = []; // data to be used if isUsingLocalData === true
    @Input() pageSize = 25; // items to display per page
    @Input() subObject; // use if data to be displayed is located in a sub object

    /* OUTPUTS */

    @Output() action = new EventEmitter<{action:string, data: any}>();
    @Output() data_ = new EventEmitter<any[]>();

    /* COMPONENTS */

    @ViewChild(MatSort) set matSort(ms: MatSort) { this.sort = ms; setTimeout(() => { this.dataSource.sort = this.sort;  }); }
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) { this.paginator = mp; this.dataSource.paginator = this.paginator;  }

    /* ATTRIBUTES */

    public data = [];
    public rawDataMap = {}; // if data to be displayed is nested within a subObject, map data back to the original using generated 'mappingPkId'
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public paginator: MatPaginator; // only used if isUsingDefaultPaginator === true;
    public sort; // for matSort

    public count = 0; // number of total items matching the current filters

    public expandedElement; // holds the currently expanded row
    public specialColumns = ['expand', 'expandedDetail', 'actions']; // rows that have unique functionality

    public limit = this.pageSize * this.buffer; // number of entries to get at a time (page size * buffer)
    public start: number = 0;
    public end: number = this.start + this.limit;

    public isLoading = false; // is the table loading data?

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private abstractService: AbstractService) {        
    }

    /* EVENT HANDLING */

    /**
     * On Changes
     */
    public ngOnChanges(changes: SimpleChanges) {
        
        // if the filters changed, reset table and re fetch the data.
        if (changes.filters && !changes.filters.firstChange) {
            this.data = [];
            this.start = 0;
            this.end = this.start + this.limit;
            this.getCount();
            this.getData(this.start, this.limit);
        }

        this.calculateColumns();
    }

    /**
     * On Init.
     */
    public ngOnInit(): void {
        this.getData(this.start, this.limit);
        this.getCount();
    }

    /**
     * Aftwer View Init.
     */
    public ngAfterViewInit() {
        if (this.isScrollTrigger) {
            const matTable = document.getElementById('tableContainer');
            fromEvent(matTable, 'scroll')
            .pipe(debounceTime(1000))
            .subscribe((e: any) => this.onTableScroll(e));
        }
    }

    /* PUBLIC METHODS */

    /**
     * Apply Filters.
     * Empty data array, reset indexes, recall api with filters.
     */
    public applyFilter() {
        this.data = [];
        this.dataSource.data = [];
        this.start = 0;
        this.end = this.start + this.limit;
        this.getData(this.start, this.limit);
    }

    /**
     * Compare two objects ;
     */
    public compare = function(a, b): boolean {
        return (a?.pkId ? a.pkId : a) === (b?.pkId ? b.pkId : b);
    }

    /**
     * Send action event to parent.
     */
    public emit(action, data) {
        this.action.emit({action, data});
    }

    /**
     * Expand row to see additional information
     */
    public expandElement(element) {
        if (this.columnsToDisplay.includes('expand')) {
            this.expandedElement = (this.expandedElement === element) ? null : element;
        }
    }

    /**
     * Default Paginator Page change event.
     */
    public pageChange(ev) {
        this.limit = ev.pageSize * this.buffer;
        if (ev.length - ((ev.pageIndex + 1) * ev.pageSize) <= (ev.pageSize * 2)) {
            this.getData(this.start, this.limit);
            this.updateIndex();
        }
    }

    /**
     * Navigate table pages.
     */
    public pageNavigate(direction: 'Previous' | 'Next') {
        if (direction === 'Previous') {
            this.limit = this.pageSize * this.buffer;
            if (this.start - (this.pageSize + this.buffer) < 0) {
                this.start = 0;
            } 
            else {
                this.start -= this.pageSize + this.buffer;
            }
            this.end = this.start + (this.pageSize * this.buffer);
            this.getData(this.start, this.limit);
        }
        else {
            this.limit = this.pageSize * this.buffer;
            this.start += (this.pageSize * this.buffer);
            this.end = this.start + (this.pageSize * this.buffer);
            this.getData(this.start, this.limit);
        }
    }


    /**
     * Refresh the data at the current index.
     */
    public refresh() {
        this.data = [];
        this.getData(this.start, this.limit);
        this.getCount();
    }

    /**
     * Round up number.
     */
    public roundUp(value: number): number {
        return Math.ceil(value);
    }

    /**
     * sort data.
     */
    public sortData(ev) {
        if (this.subObject) {
            this.apiBody.sort = {[`${this.subObject}.${ev.active}`]: ev.direction === 'asc' ? 1 : -1};
        }
        else {
            this.apiBody.sort = {[ev.active]: ev.direction === 'asc' ? 1 : -1};
        }
        this.getData(this.start, this.limit);
    }

    /**
     * Send data to parent
     */
    public sendNewList(newList) {
        this.data_.emit(newList);
    }

    /* PRIVATE METHODS */    

    /**
     * Calculate Column headers and widths
     */
    private calculateColumns() {
        // if columns to display not given, automatically generate them
        if (!this.columnsToDisplay?.length || this.isDynamicHeader) {
            if (this.data?.length) {
                let keys = [];
                for (let item of this.data) {
                    keys = keys.concat(Object.keys(item));
                }
                this.columnsToDisplay = Array.from(new Set(keys));
                this.columnsToDisplay.splice(this.columnsToDisplay.indexOf("mappingPkId"), 1);

                if (this.actionItems?.length > 0 && !this.columnsToDisplay.includes('actions')) {
                    this.columnsToDisplay.push('actions');
                }

                if (this.columnMap) {
                    this.columnsToDisplay = this.columnsToDisplay.sort( (a, b) => {
                        return (this.columnMap[a]?.order || this.columnsToDisplay.length) - (this.columnMap[b]?.order || this.columnsToDisplay.length);
                    })
                }
            }
        }

        // if columnWidths not given, calculate them.
        if (!this.columnWidths || this.isDynamicHeader) {
            this.columnWidths = {};
            let maxWidth = 100;
            let numColumns = this.columnsToDisplay.length;
            if (this.columnsToDisplay.includes('actions')) {
                maxWidth = 99;
                numColumns --;
            }
            for (let col of this.columnsToDisplay) {
                if (col === 'actions') {
                    this.columnWidths[col] = '1%';
                }
                else {
                    this.columnWidths[col] = maxWidth / numColumns + '%';
                }
            }
        }
    }

    /**
     * Get Count.
     */
    private getCount() {
        if (this.isUsingLocalData) {
            this.count = this.localSearch(this.localData, this.filters)?.length;
        }
        else {
            this.apiBody.filters =  {...this.apiBody.filters, ...this.filters};
            this.abstractService.post(this.countUrl, this.apiBody).subscribe(data => {
                this.count = data.count;
            });
        }

    }

    /**
     * Get Data.
     */
    private getData(start, limit) {
        if (this.isUsingLocalData) {
            this.dataSource.data = this.localSearch(this.localData, this.filters).slice(start, start + limit);
        }
        else {
            this.apiBody.start = start;
            this.apiBody.limit = limit;
            this.apiBody.filters =  {...this.apiBody.filters, ...this.filters};
            this.isLoading = true;
    
            const filters_ = {...this.filters};
            this.abstractService.post(this.url, this.apiBody).subscribe(data => {
                // console.log("/shared/components/infinite-table/getData: DATA = ", this.data);
                if (data) {
                    if (_.isEqual(filters_, this.filters)) {
                        if (this.isUnloadData) { this.data = []; }    
                        if (this.subObject) {
                            data = data.map(item =>  {
                                const mappingPkId = uuid();
                                this.rawDataMap[mappingPkId] = item;
                                return {...item[this.subObject], mappingPkId };
                            });
                        }
                        
                        this.isLoading = false;
                        this.data = this.data.concat(data);
                        this.dataSource.data = this.data;
                        this.sendNewList(this.data)
                        this.calculateColumns();
                    }
                }
            });
        } 
    }

    /**
     * Search.
     */
    private localSearch(arr: any[], filters: any) {
        return arr.filter(item => {
            for (let key of Object.keys(filters)) {
                if (key === 'search') {
                    if (!JSON.stringify(item).includes(filters[key])){
                        return false;
                    }
                }
                else {
                    if (filters[key] !== item[key]) {
                        return false;
                    }
                }
            }
            return true;
        });
    }

    /**
     * On Table Scroll, get next data.
     */
    private onTableScroll(e) {
        const tableViewHeight = e.target.offsetHeight // viewport
        const tableScrollHeight = e.target.scrollHeight // length of all table
        const scrollLocation = e.target.scrollTop; // how far user scrolled

        // If the user has scrolled within 200px of the bottom, add more data
        const buffer = 200;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation > limit) {
          this.getData(this.start, this.limit);
          this.updateIndex();
        }
    }

    /**
     * Update the current index for the data.
     */
    private updateIndex() {
        this.start = this.end;
        this.end = this.limit + this.start;
    }

}
