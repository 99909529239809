<!-- <div class="mat-elevation-z10 card px-3 py-2">
    <h4 class="uppercase mb-2">Risk Summary</h4> -->

    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="poams">
            <th [width]="columnWidths.poams" mat-header-cell *matHeaderCellDef>
                <div>
                    FINDINGS
                </div>
            </th>
            <td [width]="columnWidths.poams" mat-cell *matCellDef="let item">
                <div class="uppercase">
                    {{item.poams}}
                </div>
            </td>
        </ng-container>  
        <ng-container matColumnDef="high">
            <th [width]="columnWidths.high" mat-header-cell *matHeaderCellDef> 
                <div class="col-left">
                    High
                </div> 
            </th>
            <td [width]="columnWidths.high" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.high}}
                </div>
            </td>
        </ng-container>       
        <ng-container matColumnDef="medium">
            <th [width]="columnWidths.medium" mat-header-cell *matHeaderCellDef> 
                <div class="col-left">
                    Medium                 
                </div>
            </th>
            <td [width]="columnWidths.medium" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.moderate}}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="low">
            <th [width]="columnWidths.low" mat-header-cell *matHeaderCellDef> 
                <div class="col-left">
                    Low
                </div>
            </th>
            <td [width]="columnWidths.low" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.low}}
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row tabIndex *matRowDef="let item; columns: columnsToDisplay;"></tr>
    </table>

    <p class="mt-3">
        * ORs are considered open risks and are counted in the total number of risks identified for a system.
        A CSP should be seeking ways to mitigate or eliminate the risks associated with ORs.
        <br/><br/>
        **VDs are considered open risks that must be counted in the total number of risks identified for a system.
        A CSP should be actively seeking ways to mitigate or eliminate the risks associated with VDs.
        If a VD is ongoing, a CSP may have to migrate to a different technology/vendor.
    </p>

<!-- </div> -->

<!-- LIST OF POAMS TABLE -->
<table class="mt-4" mat-table [dataSource]="poamDataSource" matSort>
    <ng-container matColumnDef="id">
        <th [width]="poamColumnWidths.id" mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>
                ID
            </div>
        </th>
        <td [width]="poamColumnWidths.id" mat-cell *matCellDef="let poam">
            <a class="uppercase" [routerLink]="[config.routes.PRIVATE_CONTROL_EDIT_FINDING, poam.pkId]">
                {{poam.id}}
            </a>
        </td>
    </ng-container>  
    <ng-container matColumnDef="name">
        <th [width]="poamColumnWidths.name" mat-header-cell *matHeaderCellDef mat-sort-header> 
            <div class="col-left">
                Name
            </div> 
        </th>
        <td [width]="poamColumnWidths.name" mat-cell *matCellDef="let poam">
            <a class="col-left" [routerLink]="[config.routes.PRIVATE_CONTROL_EDIT_FINDING, poam.pkId]">
                {{poam.name}}
            </a>
            <div>
                <a class="row-subtext" *ngFor="let control of poam.controls" (click)="gotoControl(poam, control)">
                    {{ control.controlId | readable }}
                </a>
            </div>
        </td>
    </ng-container>       
    <ng-container matColumnDef="assetName">
        <th [width]="poamColumnWidths.assetName" mat-header-cell *matHeaderCellDef mat-sort-header> 
            <div class="col-left">
                Asset
            </div> 
        </th>
        <td [width]="poamColumnWidths.assetName" mat-cell *matCellDef="let poam">
            <a class="col-left" >
                {{asset.alias}}
            </a>
        </td>
    </ng-container>  
    <ng-container matColumnDef="lifecycle">
        <th [width]="poamColumnWidths.lifecycle" mat-header-cell *matHeaderCellDef mat-sort-header> 
            <div class="col-left">
                Lifecycle
            </div> 
        </th>
        <td [width]="poamColumnWidths.lifecycle" mat-cell *matCellDef="let poam">
            <a class="col-left" >
                {{asset.status}}
            </a>
        </td>
    </ng-container>       
    <ng-container matColumnDef="risk">
        <th [width]="poamColumnWidths.risk" mat-header-cell *matHeaderCellDef> 
            <div>
                RISK                 
            </div>
        </th>
        <td [width]="poamColumnWidths.risk" mat-cell *matCellDef="let poam">
            <div>
                {{ poam.adjustedRisk || poam.riskExposure | titlecase}}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th [width]="poamColumnWidths.status" mat-header-cell *matHeaderCellDef> 
            <div>
                STATUS
            </div>
        </th>
        <td [width]="poamColumnWidths.status" mat-cell *matCellDef="let poam">
            <div>
                {{poam.status}}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="createDate">
        <th [width]="poamColumnWidths.createDate" mat-header-cell *matHeaderCellDef mat-sort-header> 
            <div class="col-left">
                Create Date
            </div> 
        </th>
        <td [width]="poamColumnWidths.createDate" mat-cell *matCellDef="let poam">
            <a class="col-left" >
                {{poam.createDate | date:'mediumDate'}}
            </a>
        </td>
    </ng-container>   

    <tr mat-header-row *matHeaderRowDef="poamColumnsToDisplay"></tr>
    <tr mat-row tabIndex *matRowDef="let item; columns: poamColumnsToDisplay;"></tr>
</table>
<div class="show-more-less gap-2 mt-1">
    <div class="show-btn" (click)="show('less')" *ngIf="poamsToDisplay.length > 5">Show less</div>
    <div class="show-btn" (click)="show('more')" *ngIf="poamsToDisplay.length < poams.length">Show more</div>
    <div class="show-btn" (click)="show('all')" *ngIf="poamsToDisplay.length < poams.length">Show All</div>
</div>

<div class="p-3" *ngIf="!poamDataSource?.filteredData.length">There are no POA&Ms for this asset.</div>
