
import { Component, AfterViewInit, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { FindingService } from '@src/shared/services/finding.service';


@Component({
  selector: 'app-risk-acceptance-table',
  templateUrl: './risk-acceptance-table.component.html',
  styleUrls: ['./risk-acceptance-table.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RiskAcceptanceTableComponent implements AfterViewInit, OnInit {

    @Output()
    goToControl: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatSort, {static: false}) sort: MatSort;

    private cache: Cache;
    public config;
    public asset;

    public columnsToDisplay = ['poams', 'high', 'medium', 'low'];
    public columnWidths = { poams: '70%', high: '10%', medium: '10%', low: '10%' };

    public poamColumnsToDisplay = ['id', 'name', 'assetName', 'lifecycle', 'risk', 'status', 'createDate'];
    public poamColumnWidths = { id: '15%', name: '25%', assetName: '20%', lifecycle: '10%', risk: '10%', status: '10%', createDate: '10%' };

    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public poamDataSource: MatTableDataSource<any> = new MatTableDataSource();
    
    public poams: any[] = []; // list of all POAMs for this Asset
    public poamsToDisplay: any[] = [];; // POAMs displayed on the table

    public data = [
        {
            poams: 'open',
            high: 0,
            moderate: 0,
            low: 0
        },
        {
            poams: 'in Progress',
            high: 0,
            moderate: 0,
            low: 0
        },
        {
            poams: 'in Review',
            high: 0,
            moderate: 0,
            low: 0
        },
        {
            poams: 'remediated',
            high: 0,
            moderate: 0,
            low: 0
        },
        {
            poams: 'remaining',
            high: 0,
            moderate: 0,
            low: 0
        },
        // NEW FOR SAR
        {
            poams: 'operational requirement*',
            high: 0,
            moderate: 0,
            low: 0
        },
        {
            poams: 'vendor dependency**',
            high: 0,
            moderate: 0,
            low: 0
        }
    ];

    constructor(
        private findingService: FindingService
    ) { 
        this.config = config;
    }

    ngAfterViewInit() {
        this.poamDataSource.sort = this.sort;
    }

    ngOnInit() {

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        // this.findingService.getPoams(this.asset.pkId).subscribe(result => {
        this.findingService.listAssetFindings(this.asset.organizationPkId, this.asset.pkId).subscribe(result => {
            // updated to grab all findings
            this.poams = result;
            this.poamsToDisplay = this.poams.slice(0, 5);

            this.poamDataSource.data = this.poamsToDisplay;
            this.poamDataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();

            for(const poam of result) {
                if (poam.status === 'Closed') {
                    this.data[3][poam.riskExposure]++;
                } else if (poam.status === 'Open') {
                    this.data[0][poam.riskExposure]++;
                } else if (poam.status === 'In Progress') {
                    this.data[1][poam.riskExposure]++;
                } else if (poam.status === 'Pending Review') {
                    this.data[2][poam.riskExposure]++;
                } else {
                    this.data[4][poam.riskExposure]++;
                }
            }

            for (const poam of result) {
                if (poam.deviation?.type === 'Operational Requirement') {
                    this.data[5][poam.riskExposure]++;
                } else if (poam.deviation?.type === 'Vendor Dependency') {
                    this.data[6][poam.riskExposure]++;
                }
            }
        });

        this.dataSource = new MatTableDataSource(this.data);
    }

    /**
     * Adds more/less rows to the poams table by increments of 5
     */
    show(type: string) {
        const index = this.poamsToDisplay.length;
        switch (type) {
            case 'all': {
                this.poamsToDisplay = this.poams;
                this.poamDataSource.data = this.poamsToDisplay;
                break;
            }
            case 'more': {
                this.poamsToDisplay = this.poamsToDisplay.concat(this.poams.slice(index, index + 5));
                this.poamDataSource.data = this.poamsToDisplay;
                break;
            }
            case 'less': {
                this.poamsToDisplay = this.poams.slice(0, 5);
                this.poamDataSource.data = this.poamsToDisplay;
                break;
            }
        }
    }

    /**
     * Go to Control.
     */
    public gotoControl(item, control) {
        control.id = control.controlId;
        this.goToControl.emit(control);
    }

}
