import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import SignaturePad from 'signature_pad';
import { Signature } from '../../objects/signature';


@Component({
  selector: 'app-signature-input',
  templateUrl: './signature-input.component.html',
  styleUrls: ['./signature-input.component.less']
})
export class SignatureInputComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() data: Signature; // the signature 
    @Input() disabled; 
    @Input() isReadonly;
    @Input() isRequired;

    @Output() result = new EventEmitter<any>();

    /* ATTRIBUTES */

    public required = false;

    public selectedTab = 0;
    public signaturePad: SignaturePad;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() { 
    }

    /**
     * On Init.
     */
    public ngOnInit(): void {
    }

    /**
     * On Init.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        this.selectedTab = this.data.type === 'Text' ? 1 : 0; 
    }

    /**
     * After View Init.
     */
    public ngAfterViewInit(): void {
        this.initializeSignaturePad();
    }

    /* METHODS */

    /**
     * Initialize Signature pad.
     */
    private initializeSignaturePad() {
        if (!this.signaturePad) {
            const canvas = document.querySelector('canvas');
            if (canvas) {
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
                this.signaturePad = new SignaturePad(canvas);
                if (this.data.type === 'Drawing') {
                    this.signaturePad.fromDataURL(this.data.signature);
                }
                this.signaturePad.addEventListener('endStroke', () => {
                    this.data.signature = this.signaturePad.toDataURL();
                    this.data.type = 'Drawing';
                });
            }
        }
    }

    /**
     * Change Tab
     */
    public changeTab(ev) {
        this.selectedTab = ev;
        this.data.signature = '';
        
        setTimeout(()=> {
            this.initializeSignaturePad();
            this.clearSignatureCanvas();
        })

    }

    /**
     * Clear signature drawing pad.
     */
    public clearSignatureCanvas() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    /**
     * Clear signature field.
     */
    public clearSignatureField(context) {
        context.signature = '';
        context.date = undefined;
        this.data.signature = '';
        this.result.emit({});
    }

    /**
     * Update Text Signature. 
     */
    public updateTextSignature(ev) {
        this.data.signature = ev;
        this.data.type = 'Text';
    }

    // UTILITIES

}

