<div *ngIf="horizontal" [ngClass]="{ 'step-connector': !last, 'pending-connector': pending, 'approved-connector': approved, 'none': !pending && !approved }"></div>
<div *ngIf="horizontal" [ngClass]="{ 'step-connector-background': !last }"></div>
<div class="row p-0 m-0" [ngClass]="{ 'col': horizontal }">
    <div class="justify-content-center align-items-center col-auto flex- pl-3 pr-0" style="z-index: 2" [ngClass]="{ 'flex-column': horizontal, 'disabled': disabled }">
        <div *ngIf="horizontal" [ngClass]="{ 'selected': selected, 'disabled': disabled }">
            <ng-content select="[sup-title]"></ng-content>
        </div>
        <div class="step-circle" *ngIf="!horizontal" [ngClass]="pending ? 'pending-border' : approved ? 'approved-border' : 'none-border'"></div>
        <div class="step-circle" *ngIf="horizontal" [ngClass]="pending ? 'pending-horizontal' : approved ? 'approved-horizontal' : 'none-horizontal'"></div>
        <mat-icon class="stepper-icon-pending" *ngIf="pending && !horizontal">hourglass_empty</mat-icon>
        <mat-icon class="stepper-icon" *ngIf="approved && !horizontal">done</mat-icon>
        <mat-icon class="stepper-icon-failed" *ngIf="failed && !horizontal">priority_high</mat-icon>
        <mat-icon class="stepper-horizontal-icon" *ngIf="approved && horizontal">done</mat-icon>
        <div class="col" *ngIf="horizontal" [ngClass]="{ 'selected': selected, 'disabled': disabled }">
            <ng-content select="[sub-title]"></ng-content>
        </div>
    </div>
    <div class="mx-2 title" *ngIf="!horizontal" [ngClass]="{ 'selected': selected, 'disabled': disabled, 'no-navigation': noNavigation }">
        <ng-content select="[title]"></ng-content>
    </div>

</div>
<div [ngClass]="{'disabled': disabled }">
    <div class="ml-4 pl-4 pt-3" *ngIf="!horizontal" [ngClass]="{ 'step-border-left': !last, 'step-border-selected': selected && !last }">
        <ng-content></ng-content>
    </div>
</div>