import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
  
})
export class SearchableDropDown implements OnInit, OnChanges{

    @Input() isReadOnly;
    @Input() items: any[]; // list of items to populate dropdown list

    @Input() label; // the configuration for the organization
    @Input() placeholder;
    @Input() formFieldName: string = "searchableDropDown";
    @Input() model; // the ngModel to use for list

    @Input() displayKey;
    @Input() valueKey;

    @Input() required;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    /* ATTRIBUTES */

    public filteredItems: any[] = [];

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() {

    }

    /* INIT */

    /**
     * On Init.
     */
    public async ngOnInit() {
          
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.filteredItems = this.items;
    }

    /* METHODS */

    /**
     * Filter items by display key.
     */
    public filter(value: string) {
        this.filteredItems = this.items.filter(item_ => item_[this.displayKey]?.toLowerCase().includes(value?.toLowerCase()));
    }

    /**
     * Is the item filtered out?
     */
    public isHidden(item) {
        return !this.filteredItems.find(item_ => item_[this.valueKey] === item[this.valueKey]);
    }

    /**
     * On Selection.
     */
    public selection() {
        this.modelChange.emit(this.model);
    }
}