import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, lastValueFrom, Subject } from 'rxjs';
import { FileUploaderOptions } from 'ng2-file-upload';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Cache } from '@src/shared/objects/cache';
import { File } from '@src/shared/objects/file';
import { List } from '@src/shared/objects/list';

import { UploaderComponent } from '@src/shared/components/uploader/uploader.component';

import { FileService } from '@src/shared/services/file.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit, OnChanges {

    @ViewChild(UploaderComponent) upload: UploaderComponent;
    @ViewChild(MatSort, { static: true }) set matSort(ms: MatSort) {
        this.sort = ms;
        this.dataSource.sort = this.sort;
    }

    /* ATTRIBUTES */

    @Input() allowedMimeType = [
        'image/jpeg', 
        'image/png', 
        'text/plain', 
        'application/pdf', 
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ];

    @Input() assetPkId;
    @Input() metadata = {};
    @Input() organizationPkId;
    @Input() processPkId;
    @Input() referencePkId;
    @Input() referenceType;

    @Input() buttonText = 'UPLOAD FILE';
    @Input() ignoreReference;
    @Input() isEditable = true;
    @Input() maxFileSize?: number;
    @Input() queueLimit = 1;
    @Input() options: FileUploaderOptions = {};
    @Input() securityRoute = location.pathname; // route user needs priveldge to in order to edit
    @Input() showDetails = false; // Determines whether uploader will show error/upload progress details

    @Output() uploadStart = new EventEmitter();
    @Output() fileUploaded = new EventEmitter();
    @Output() filesLength = new EventEmitter();

    @Input() list: List = new List();

    public cache: Cache;

    public asset;
    public configuration;
    public user;
    public users;

    @Input() 
    public columnsToDisplay = [ 'name', 'lastUpdated', 'userName', 'actions' ];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public sort; // for matSort

    public files: File[] = []; // files
    public files_: File[] = []; // the filtered files
    public keyword = ''; // search term
    public subject = new Subject<string>(); // for debounce    

    public isDuplicateFileName; // prevents same file from being uploaded many times    
    public uploading = false; // Uploading status, to pass to child uploader
    public canceling = false; // Canceling status, to pass to child uploader

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        private fileService: FileService,
        private snackBar: MatSnackBar) {

        this.subject.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
            this.applyFilters()
        });
    }

    /* INIT. */

    /**
     * On Init.
     */
    public ngOnInit(): void {

        // this.cache = Cache.get();

        // this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        // this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        // this.user = this.cache.getValue(Cache.KEYS.USER);
        // this.users = this.asset.users;

        this.options.allowedMimeType = this.allowedMimeType;
        this.options.maxFileSize = this.maxFileSize;
        this.options.queueLimit = this.queueLimit;
    }

    /**
     * On Changes (file upload/deletion changes)
     */
    public ngOnChanges() {
        if (!this.list) {this.list = new List()}
        if (!this.isEditable) {this.isEditable = true}
        if (this.ignoreReference) {
            this.fileService.getByAsset(this.assetPkId).subscribe(res => {
                this.files = res;
                this.files_ = this.files;
                this.dataSource.data = this.files_;
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            });
        }
        else if (this.referencePkId && this.referenceType) {
            this.fileService.getByReference(this.referencePkId, this.referenceType, this.assetPkId).subscribe(res => {
                this.files = res;
                this.files_ = this.files;
                this.dataSource.data = this.files_;
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
                this.filesLength.emit(this.files.length);
            });
        }
    }

    /* METHODS */

    /**
     * Search a table.
     */
    public applyFilters() {
        this.files_ = this.files.filter(item => item.name.toLowerCase().includes(this.keyword.toLowerCase()));            
        this.dataSource.data = this.files_;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    }

    /**
     * Build form to create File.
     */
    public buildForm(): any {
        return {
            referencePkId: this.referencePkId,
            referenceType: this.referenceType,
            organizationPkId: this.organizationPkId,
            assetPkId: this.assetPkId,
            processPkId: this.processPkId,
            metadata: this.metadata
        };
    }

    /**
     * Delete File.
     */
    public delete(ev) {
        this.uploadStart.emit();
        this.fileService.delete(ev).subscribe(res => {});
        const index = this.files.indexOf(ev);
        this.files.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.files);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.fileUploaded.emit(this.files);
        this.filesLength.emit(this.files.length);
        this.closeAll();
    }

    /**
     * Download File.
     */
    public download(ev) {
        this.fileService.download(ev);
    }

    /**
     * Edit File.
     */
    public edit(ev) {
        delete ev.edit;
        this.fileService.update(ev).subscribe(res => {});
    }

    /**
     * Sort.
     */
    public sortingDataAccessor(data: any, sortHeaderId: string) {
        if (sortHeaderId === 'lastUpdated') {
            return data.updateDate?.toLowerCase();
          }
        if (sortHeaderId === 'userName') {
          return data.user?.name?.toLowerCase();
        }
        if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId]?.toLowerCase()?.trim();
        }
        if (sortHeaderId === 'type') {
            return data.metadata[sortHeaderId];
        }
        if (sortHeaderId === 'label') {
            return data.metadata[sortHeaderId];
        }
        return data[sortHeaderId];
    }

    /**
     * Update table of files upon completion of upload.
     */
    public async updateTable(ev) {
        const result = JSON.parse(ev);
        if (result.resultError) {
            this.snackBar.open(`Unable to Upload File`, '', {
                duration: 5000
            });
        }
        else {
            this.files.push(result);
            this.fileUploaded.emit(this.files);
            this.filesLength.emit(this.files.length);
            this.dataSource = new MatTableDataSource(this.files);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        }
    }

    /**
     * Upload all files in queue.
     */
    public async uploadAll() {
        this.uploadStart.emit();
        await this.upload.uploadAll();
    }

    /**
     * Emit event indicate upload has been completed.
     */
    public uploadComplete() {
        this.fileUploaded.emit(this.files);
        this.uploading = false;
        this.canceling = false;
    }

    /**
     * Adds File to queue, and then uploads.
     */
    public async uploadDocument($event) {
        const duplicateFile = this.dataSource.data.filter(document =>
            document.name.toLowerCase() === $event.target.files[0].name.toLowerCase());
        duplicateFile.length > 0 ? this.isDuplicateFileName = true : this.isDuplicateFileName = false;

        if (!this.isDuplicateFileName) {
            this.uploadStart.emit();

            this.upload.uploader.addToQueue($event.target.files);
            await this.uploadAll();
        }
    }

    /**
     * View File. (Only for pdfs)
     */
    public async view(ev) {
        const file = await lastValueFrom(this.fileService.view(ev));
        const fileURL = URL.createObjectURL(file.file);
        window.open(fileURL, '_blank');
    }

    /* UTILITIES */
    /**
     * Close all dialogs.
     */
    public closeAll() {
        this.dialog.closeAll();
    }

    /**
     * Open dialog.
     */
    public openDialog(component, data?) {

        // Set the dialog properties and open the dialog using those properties.
        // All dialogs are inteded to fill the entire screen. The dialog styles can
        // be found in the global styles.

        let properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: data  };
        const dialogRef = this.dialog.open(component, properties);

        // After the dialog is opened and closed, process the returned data
        // and perform cleanup operations.

        dialogRef.afterOpened().subscribe(open => {
            // this.dialogAnimationDisabled = false;
        });

        dialogRef.afterClosed().subscribe(closed => {
            // this.dialogAnimationDisabled = true;
        });
    }
}
