import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.less'],
})
export class DefaultComponent implements OnInit, OnChanges {

    @ViewChild('chatScrollContainer') private ChatScrollContainer: ElementRef;

    @Input() user; // current user
    @Input() users; // list of users taggable
    @Input() messages; // list of messages
    @Input() isFullScreen; // the screen mode
    @Input() scrollToSubject: Observable<string>; // used to scroll the chat window to the latest message

    @Output() eventEmitter = new EventEmitter<any>();

    /* ATTRIBUTES */

    public chatModel = '';
    public chatControl = new FormControl();

    public users_: Observable<string[]>; // the list of users that have been filtered using the @ selector
    public toPkIds: string[] = []; // the list of users selected by the autocomplete

    public autocompleteSearchTerm = ''; // the term used to find a user in the auto complete popup
    public isAutocompleteEnabled = false; // an indicator that @ is used to tag somone
    public specialCharacterPosition; // the position of the @ in the user input
    public storedInput = ''; // stored input string up to the last parse @ character

    /* CONSTRUCTOR */

    /**
     * Contructor.
     */
    public constructor() { 
    }

    /* EVENT HANDLING */

    /**
     * OnInit.
     */
    public ngOnInit(): void {
        
        // console.log("Welcome, " + this.user.firstName);
        // console.log("/private/communications/chat/ngOnInit: USERS = ", this.users);

        this.scrollToBottom();
        this.users_ = this.chatControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filter()),
        );

        // event listener for when to scroll the chat
        if (this.scrollToSubject) {
            this.scrollToSubject.subscribe(messagePkId => {
                if (messagePkId) {
                    this.scrollToMessage(messagePkId);
                }
                else {
                    this.scrollToBottom();
                }
            });
        }
    }

    /**
     * On Changes.
     * Scroll to bottom when input value changes.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.messages) {
            this.scrollToBottom();
        }
    }

    /* METHODS */

    /**
     * User filter for @ / ping.
     */
    private filter(): any[] {
        const filterValue = this.autocompleteSearchTerm.toLowerCase();
        return this.users?.filter(user => (user.firstName + user.lastName).toLowerCase().includes(filterValue));
    }

    /**
     * Get the initials from a full name.
     */
    public getInitials(name) {
        if (name) {
            let initials = '';
            const tokens = name.split(' ');
            if (tokens.length > 1) {
                initials = tokens.shift().charAt(0) + tokens.pop().charAt(0);
            }
            else {
                initials = tokens.pop().charAt(0);
            }
            return initials.toUpperCase();
        }
        return '';
    }

    /**
     * Returns whether the responder is a robot.
     */
    public isRobot(name) {
        if (name) {
            return name.toLowerCase() === 'robot';
        }
        return false;
    }

    /**
     * Handle keydown Events.
     */
    public keyDownEvent(ev, autocomplete) {
        
        if ((ev.ctrlKey || ev.shiftKey) && ev.code === 'Enter') {
            this.chatModel += '\n';
        } 
        else if (this.chatModel && this.chatModel.trim().length > 0 && ev.code === 'Enter'){
            this.send({ value: this.chatModel });
        }

        // prevent enter key from adding new line
        if (ev.code === 'Enter') {
            ev.preventDefault();
        }
    }

    /**
     * Check the input to see if the user has tagged anyone in the chat
     * using the @ character.
     */
    public keyUpEvent(ev, chatInput, autocompleteTrigger) {

        const cursorPosition = chatInput.selectionEnd;
        if (this.chatModel[cursorPosition - 1] === '@') {
            this.isAutocompleteEnabled = true;
            this.specialCharacterPosition = cursorPosition - 1;
            this.storedInput = this.chatModel;
        }

        if (this.isAutocompleteEnabled) {
            const term = this.chatModel.substring(this.specialCharacterPosition + 1, cursorPosition);
            if (term.indexOf(' ') > -1) {
                // user types space to deactivate autocomplete
                this.isAutocompleteEnabled = false;
                autocompleteTrigger.closePanel();
                this.autocompleteSearchTerm = '';
            }
            else if (this.chatModel[this.specialCharacterPosition] !== '@') {
                // user deleted @ or is typing somewhere else to deactivate autocomplete
                this.isAutocompleteEnabled = false;
                autocompleteTrigger.closePanel();
                this.autocompleteSearchTerm = '';
            }
            else {
                this.autocompleteSearchTerm = term;
            }
        }
    }

    /**
     * Select the users that are tagged in the chat message by parsing the 
     * user input, extract the selected users, and save them to the local 
     * array.
     */
    public parse(content: string) {

        let charIndex = 0;
        content = content.replace('.',' ');
        content = content.replace(',',' ');
        content = content.replace('?',' ');
        for (let char of content) {
            if (char === '@') {
                const start = charIndex + 1;
                const end = (content.slice(charIndex).indexOf(' ') > -1 ? charIndex + content.slice(charIndex).indexOf(' ') : content.length);

                const term = content.substring(start, end);
                const splitTerm = term.split('_');
                const user = this.users.find(user => {
                    if (user.firstName.toLowerCase() === splitTerm[0].toLowerCase() && user.lastName.toLowerCase() === splitTerm[1].toLowerCase()) {
                        return user;
                    }
                });

                if (user) {
                    this.toPkIds.push(user.pkId);
                }
            }
            charIndex++;
        }
    }

    /**
     * Scroll Chat box to bottom.
     */
    public async scrollToBottom() {
        try {
            await new Promise(f => setTimeout(f, 250)); // delay to wait until chat box has rendered
            this.ChatScrollContainer.nativeElement.scrollTop = this.ChatScrollContainer.nativeElement.scrollHeight;
        } 
        catch(err) { 
        }
    }

    /**
     * Scroll Chat box to bottom.
     */
    public async scrollToMessage(messagePkId) {
        try {
            await new Promise(f => setTimeout(f, 250)); // delay to wait until chat box has rendered
            const el = document.getElementById(messagePkId)
            el.scrollIntoView()    
        } 
        catch(err) { 
        }
    }

    /**
     * Select the user using autocomplete. When the use types in the @
     * character, the system displays a list of people for the user to select
     * from. If the user selects a person, the system inserts a string 
     * @<firstName>_<lastName> into the chat text.
     */
    public select(ev) {
        const selectedUser = ev.option.value;               
        this.chatModel =`${this.storedInput}${selectedUser.firstName}_${selectedUser.lastName}`;
        this.isAutocompleteEnabled = false;
    }

    /**
     * Emit send event, which will be handled by the chat component.
     */
    public send(input) {

        let content = input?.value;
        if (input?.value) { input.value = ''; this.chatModel = ''; }
        
        this.parse(content); // get selected users
        this.eventEmitter.emit({ type: 'Chat', reference : content, toPkIds: this.toPkIds});
        this.scrollToBottom();
        this.toPkIds = [];
    }

}
