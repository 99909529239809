<div class="row">
    <div class="col-auto p-0">
        <div class="px-0 mb-2">
            <div *ngIf="process">
                <us-stepper *ngFor="let phase of phases; let pi=index" 
                    [status]="processMetadata?.phases[phase.name | keySafe]?.status">
                    <div title (click)="gotoPhase(phase)" tabIndex>PHASE {{pi + 1 | romanNumeral}} - {{phase.name | uppercase }} 
                        <span>({{(processMetadata?.phases[phase.name | keySafe]?.completionPercent | number: '1.0-0') || 0}}%)</span>
                    </div>
                    <ng-container *ngFor="let step of phase?.steps; let stepIndex=index">
                        <ng-container *ngIf="step.referenceType && step.active">
                            <us-step [complete]="processMetadata?.phases[phase.name | keySafe]?.steps[step.name | keySafe]?.completionPercent === 100">
                                <div title (click)="gotoComponent(phase, step)" tabIndex>{{step.name}} 
                                    <span *ngIf="processMetadata?.phases[phase.name | keySafe]?.steps[step.name | keySafe]?.completionPercent < 100">
                                        ({{(processMetadata?.phases[phase.name | keySafe]?.steps[step.name | keySafe]?.completionPercent | number: '1.0-0') || 0}}%)
                                    </span>
                                </div>
                            </us-step>
                        </ng-container>
                    </ng-container>
                </us-stepper>
            </div>
        </div>
    </div>
</div>