import { v4 as uuidv4 } from 'uuid';
import { List } from './list';

import { Signature } from './signature';

/**
 * Approval.
 */
export class Approval {

    /* ATTRIBUTES */

    // GENERAL

    public pkId: string = ''; // the object id
    public organizationPkId: string = ''; // the organization
    public list: List;
    public listPkId: string = ''; // the list of approvers
    public listComponent = ''; // the type of component that the list is used for
    public listSystemName = ''; // the list system name used in lookups
    public referenceName: string = ''; // the name of what's being approved
    public referencePkId: string = ''; // the pkid of what's being approved
    public referenceRoute: string = ''; // the route to the referenced item
    public referenceType: string = ''; // the type of what's being approved
    public reference: any; // the item being approved
  
    public approvers = []; // the list of approvers linked to this approval chain
    public owners = []; // the list of owners who need to know when all approvals have been submitted
    public workers = []; // the list of workers who need to know when all approvals have been submitted
  
    // DATE

    public createDate: Date = new Date(); // create date
    public updateDate: Date = new Date(); // last update date
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
     public constructor(json?) {
        Object.assign(this, json || {});
        // if (!this.pkId) { this.pkId = uuidv4(); }
    }

    /* METHODS */

    /**
     * Return whether all users have signed this approval.
     */
     public isClosed() {
        for (let signature of this.approvers) {
            if (!signature.isApproved()) {
                return false;
            }
        }
        return true;
    }
    
}
