import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

import { AuthenticationService } from '@src/shared/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.less']
})
export class UploaderComponent implements OnInit {

    @Input() buildForm: any;
    @Input() cancel = false; // Flag for parent has canceled upload
    @Input() customClass = 'upload';
    @Input() dropZoneText = 'Upload from Computer';
    @Input() isSingleRequestMode;
    @Input() isDisabled; // disabled the upload button
    @Input() showDetails = false; // Flag to show error/upload status details in different tables
    @Input('route') set setRoute(newRoute) { if (newRoute) this.route = newRoute; }
    @Input() upload = false; // Flag for if parent has started upload
    @Input() uploadLabel: string;
    @Input() uploaderOptions: FileUploaderOptions;
    @Input() tableItems = []; // Already uploaded items, for determining duplicate file names

    @Output() complete: EventEmitter<any> = new EventEmitter<any>();
    @Output() completeItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() addFile: EventEmitter<any> = new EventEmitter<any>();
    @Output() uploading: EventEmitter<any> = new EventEmitter<any>(); // Flag to let parent know if uploading is still in progress

    /* ATTRIBUTES */

    public hasBaseDropZoneOver = false;

    public fileTypes: string[];
    private route: any = '/file/create';
    private URL: string;
    public uploader: FileUploader;

    public errorFiles: any[] = []; // Error files
    public uploadedFiles: any[] = []; // Uploaded files & upload progress

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
      private snackBar: MatSnackBar,
      private authenticationService: AuthenticationService) {

    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit() {
      this.URL = this.authenticationService.getApiUrl() + this.route;
      this.uploader = new FileUploader(Object.assign({
        url: this.URL,
        queueLimit: 10,
        removeAfterUpload: false, // must be false
        authTokenHeader: 'Authorization',
        headers: [
          { name: 'Access-Control-Expose-Headers', value: 'X-Auth-SID' },
          { name: 'X-Auth-SID', value: this.authenticationService.getSID() }
        ]
      }, this.uploaderOptions));
      if (this.uploader.options.allowedMimeType) {
        this.setFileTypes();
      }
      this.attachmentsProcess();
    }

    /**
     * On Complete All.
     */
    public onCompleteAll() {
      if (this.uploader.queue.length === 0) {
        this.complete.emit();
      }
    }

    /* METHODS */

    /**
     * Sets all the uploader's listeners functions.
     */
    public attachmentsProcess() {

      this.uploader.onBuildItemForm = (fileItem, form) => {
        const formData = form as FormData;
        Object.keys(this.buildForm).forEach(key => {
          if (key === 'metadata') form.append(key, JSON.stringify(this.buildForm[key]));
          else form.append(key, this.buildForm[key]);
        });
        // form.append('assetPkId', this.assetService.getSelectedAsset());
        // form.append('relatedControls', JSON.stringify(this.relatedControls));

        return { fileItem, form };
      };

      this.uploader.onAfterAddingFile = (file) => {
        this.addFile.emit(file);
        const fileName = file.file.name;

        const found = this.uploader.queue.filter(f => f.file.name === fileName);
        const found_ = this.tableItems.filter(f => f.name === fileName);

        if (found.length > 1) {
          // duplication found. Remove them
          this.uploader.queue = this.uploader.queue.filter(function(item, pos, self) {
            const foundIndex = self.indexOf(self.find(f => f.file.name === item.file.name));
            return foundIndex === pos;
          });
          this.snackBar.open(`Duplicated Files "${fileName}". Duplication is removed out of the upload queue`, '', {
            duration: 5000
          });

          this.errorFiles.push({name: fileName, error: 'File already in queue.'});
        }
        else if (found_.length > 0) {
          // existing file name found. Remove them from queue
          this.uploader.queue = this.uploader.queue.filter(function(item, pos, self) {
            const foundIndex = self.indexOf(self.find(f => f.file.name === item.file.name));
            return foundIndex !== pos;
          });
          this.snackBar.open(`File "${fileName}" has already been uploaded. Duplication is removed out of the upload queue`, '', {
            duration: 5000
          });

          this.errorFiles.push({name: fileName, error: 'File already uploaded.'});
        }
        else if (file.file.size === 0) {
          const index = this.uploader.queue.indexOf(found[0]);
          this.uploader.queue.splice(index, 1);

          this.snackBar.open(`Unable to Upload "${fileName}". File size is 0 bytes.`, '', {
            duration: 5000
          });

          this.errorFiles.push({name: fileName, error: 'File size is 0 bytes.'});
        }

      };

      this.uploader.onWhenAddingFileFailed = (file, filter, options) => {
        let errorMsg = '';
        switch (filter.name) {
          case 'fileSize':
            errorMsg = 'File size is too large.';
            break;
          case 'mimeType':
            errorMsg = 'File Type is not allowed.';
            break;
          case 'queueLimit':
            errorMsg = 'File Queue limit reached.';
            break;
          default:
            errorMsg = 'Unknown error';
            break;
        }
        this.snackBar.open(`Unable to Upload "${file.name}". ${errorMsg}`, '', {
          duration: 5000
        });
        // this.complete.emit();
        this.errorFiles.push({name: file.name, error: errorMsg});
      };

      this.uploader.onCompleteAll = this.onCompleteAll;
    }

    /**
     * File dragged over dropzone variable.
     */
    public fileOverBase(e: any): void {
      this.hasBaseDropZoneOver = e;
    }

    /**
     * Remove file.
     */
    public remove(source, item) {
      switch (source) {
        case 'error':
          const index = this.errorFiles.findIndex(file => file === item);
          this.errorFiles.splice(index, 1);
          break;
        case 'uploaded':
          this.uploadedFiles = this.uploadedFiles.filter(file => file.name !== item.name);

          const found = this.uploader.queue.find(file => file.file.name === item.name);
          if (found) {
            this.uploader.removeFromQueue(found);
          }
          break;
        default: console.log('ERROR Invalid Source: ', source);
          break;
      }
    }

    /**
    * determines file types allowed for upload, if any
    */
    public setFileTypes() {
      this.fileTypes = [];
      for (let type of this.uploader.options.allowedMimeType) {
        switch (type) {
          case 'image/jpeg': this.fileTypes.push('.jpg, .jpeg');
            break;
          case 'image/png': this.fileTypes.push('.png');
            break;
          case 'text/plain': this.fileTypes.push('.txt');
            break;
          case 'application/json': this.fileTypes.push('.json');
            break;
          case 'application/pdf': this.fileTypes.push('.pdf');
            break;
          case 'application/vnd.ms-excel': this.fileTypes.push('.xls');
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': this.fileTypes.push('.xlsx');
            break;
          case 'application/msword': this.fileTypes.push('.doc');
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': this.fileTypes.push('.docx');
            break;
          case 'application/vnd.ms-powerpoint': this.fileTypes.push('.ppt');
            break;
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': this.fileTypes.push('.pptx');
            break;
          case 'application/x-zip-compressed': this.fileTypes.push('.zip');
            break;
          case 'application/zip': this.fileTypes.push('.zip');
            break;
          case 'text/csv': this.fileTypes.push('.csv');
            break;
          case 'video/webm': this.fileTypes.push('.webm');
            break;
          case 'video/ogg': this.fileTypes.push('.ogv');
            break;
          case 'video/mp4': this.fileTypes.push('.mp4');
            break;
        }
      }
      this.fileTypes = [...new Set(this.fileTypes)];
    }

    /**
     * Upload all.
     */
    public async uploadAll() {
      this.uploading.emit(true);
      if (this.isSingleRequestMode) {
        this.uploadAllOneRequest();
        this.uploading.emit(false);
      }
      else {
        return new Promise<any[]>(resolve => {
          const responses = [];
          for (const file of this.uploader.queue) {
            this.uploadedFiles.push({
              name: file.file.name,
              progress: 0,
              size: file.file.size / 1024 / 1024,
              status: 'In Queue'});
          }

          this.uploader.uploadAll();

          this.uploader.onBeforeUploadItem = (item: FileItem) => {
            const status = {
              name: item.file.name,
              progress: 0,
              size: item.file.size / 1024 / 1024,
              status: 'Pending'
            };
            const found = this.uploadedFiles.findIndex(file => file.name === item.file.name);

            if (found >= 0) {
              this.uploadedFiles.splice(found, 1, status);
            }
          };

          this.uploader.onProgressItem = (item: FileItem, progress: any) => {

            if (this.cancel) {
              this.uploader.cancelAll();
              this.uploading.emit(false);
            }


            const status = {
              name: item.file.name,
              progress: progress,
              size: item.file.size / 1024 / 1024,
              status: 'Uploading...'
            };
            const found = this.uploadedFiles.findIndex(file => file.name === item.file.name);

            if (found >= 0) {
              this.uploadedFiles.splice(found, 1, status);
            }
          };

          this.uploader.onCompleteItem = (item, response, status, headers) => {
            if (this.uploader.queue.length > 0) {
              this.uploader.removeFromQueue(item);
            }

            const status_ = {
              name: item.file.name,
              progress: 100,
              size: item.file.size / 1024 / 1024,
              status: ''
            };

            const found_ = this.uploadedFiles.findIndex(file => file.name === item.file.name);
            if (found_ >= 0) {
              this.uploadedFiles.splice(found_, 1, status_);
            }

            if (!item.isCancel) {
              responses.push(response);
              this.completeItem.emit(response);
            }
          };
          this.uploader.onCompleteAll = () => {
            this.onCompleteAll();
            this.uploading.emit(false);
            resolve(responses);
          };
        });
      }
    }

    /**
     * Upload all files in queue in a single requiest.
     */
    public async uploadAllOneRequest() {
        var xhr = new XMLHttpRequest();
        var sendable = new FormData();
        var fakeitem: FileItem = null;
        this.uploader.onBuildItemForm(fakeitem, sendable);

        for (const item of this.uploader.queue) {
          item.isReady = true;
          item.isUploading = true;
          item.isUploaded = false;
          item.isSuccess = false;
          item.isCancel = false;
          item.isError = false;
          item.progress = 0;

          if (typeof item._file.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
          }
          sendable.append('file', item._file, item.file.name);
        }

        if (this.uploader.options.additionalParameter !== undefined) {
          Object.keys(this.uploader.options.additionalParameter).forEach((key) => {
            sendable.append(key, this.uploader.options.additionalParameter[key]);
          });
        }

        xhr.onload = (ev) => {
          this.completeItem.emit(ev);
          var gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
          var method = 'on' + gist + 'Item';
          this.uploader[method](fakeitem, null, xhr.status, null);

        };


        xhr.onerror = () => {
          this.uploader.onErrorItem(fakeitem, null, xhr.status, null);
        };

        xhr.onabort = () => {
          this.uploader.onErrorItem(fakeitem, null, xhr.status, null);
        };

        xhr.open('POST', this.uploader.options.url, true);
        xhr.withCredentials = true;
        if (this.uploader.options.headers) {
          for (var _i = 0, _a = this.uploader.options.headers; _i < _a.length; _i++) {
            var header = _a[_i];
            xhr.setRequestHeader(header.name, header.value);
          }
        }
        if (this.uploader.authToken) {
          xhr.setRequestHeader(this.uploader.authTokenHeader, this.uploader.authToken);
        }
        xhr.send(sendable);
    }

}
