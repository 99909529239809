import { Injectable } from '@angular/core';

import { AbstractService } from './abstract.service';
import { Topic } from '../objects/topic';

@Injectable({
  providedIn: 'root'
})
export class TopicService extends AbstractService {

    /* CONSTANTS */

    public CONTENT_TYPE = [
        'Entertainment',
        'Strategy',
        'Training'
    ]

    public FILE_TYPES_ALLOWED = [
        'video/webm',
        'video/ogg',
        'video/mp4',
    ];

    public FISCAL_YEARS: Number[] = [
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030
    ];

    /* METHODS */  

    /**
     * Create Entity.
     */
    public create(topic: Topic) {
        return this.post('/topic/create', { topic });
    }

    /**
     * Delete Entity.
     */
    public delete(pkId: string) {
        return this.post('/topic/delete', { pkId });
    }

    /**
     * Get the Entity.
     */
    public get(pkId: string) {
        return this.post('/topic/get', { pkId });
    }

    /**
     * list Entities.
     */
    public list(type) {
        return this.post('/topic/list', {type});
    }

    /**
     * Update the entity.
     */
    public update(pkId, update) {
        return this.post('/topic/update', { pkId, update });
    }

}
