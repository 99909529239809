import { v4 as uuidv4 } from 'uuid';

/**
 * Signature.
 */
export class Signature {

    /* ATTRIBUTES */

    // GENERAL
    
    // public approvalPkId: string = ''; // the approval pkid

    public pkId: string = ''; // the user pkid
    public email: string = ''; // the approver's email
    public firstName: string = ''; // the approver's first name
    public middleName: string = ''; // the approver's middle name
    public lastName: string = ''; // the approver's last name
    public title: string = ''; // the approver's title (typically a role name)
    public titlePkId: string =''; // pkId reference to title (typically a role pkId)
    public division: string = ''; // the approver's division
    public office: string = ''; // the approver's office
    public status: 'Approve' | 'Approve With Condition' | 'Reject' | '' = ''; // the approval status
    public comment = ''; // the comment associated with this approval

    public type: 'Certificate' | 'Drawing' | 'Photo' | 'Text' = 'Text'; // the type of signature
    public signature: string = ''; // the digital signature


    // DATE

    public createDate: Date = new Date(); // create date
    public updateDate: Date = new Date(); // last update date
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
     public constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuidv4(); }
    }

    /* METHODS */

    /**
     * Return whether the user has approved and signed the approval record.
     */
    public isApproved() {
        if (this.status) {
            if (this.status === 'Approve' || this.status === 'Approve With Condition') {
                return this.signature;
            }
        }
        return false;
    }
   
}

