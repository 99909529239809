import { AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import app from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { Log } from '@src/shared/objects/log';
import { MessageService } from '@src/shared/services/message.service';


@Component({
  selector: 'app-route-tracker',
  templateUrl: './route-tracker.component.html',
})
export class RouteTrackerComponent implements OnInit, AfterViewInit {

    /* ATTRIBUTES */

    cache;

    organization;
    user;

    /**
     * Constructor.
     */
    constructor(private route: ActivatedRoute, private messageService: MessageService) {
    }

    /* INIT. */

    /**
     * On Init.
     */
    ngOnInit(): void {
        this.cache = Cache.get();
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.user = this.cache.getValue(Cache.KEYS.USER);
        
    }

    ngAfterViewInit() {
        let url = location.pathname;
        const params = this.route.snapshot.params
        if (params) {
            for (let param of Object.values(params)) {
                url = url.replace(param, '');
            }
        }

        const log = new Log();
        log.type = 'Log';
        // user
        log.fromPkId = this.user?.pkId;
        log.fromName = `${this.user?.firstName} ${this.user?.lastName}`;
        log.fromEmail = this.user?.email;
        // organization
        log.fromOrganizationPkId = this.organization?.orgPkId;
        log.fromOrganizationName = this.organization?.OrgName;
        // application
        log.fromApplicationPkId = app.appPkId;
        log.fromApplicationName = app.appName;
        // logging
        log.route = url;
        log.status = 'Info';
        log.statusCode = '200';

        log.sourceAgent = window.navigator.userAgent;
        log.sourceIp = ''
        log.sourceOrigin = window.location.origin;    
        log.destinationIp = '';
        this.messageService.log(log);
    }

    /* METHODS */


}
