import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import config from '@app/app';

import { Configuration } from '@src/shared/objects/configuration';
import { Cache } from '@src/shared/objects/cache';

import { AssetService } from '@src/shared/services/asset.service';
import { ConfigurationService } from '@src/shared/services/configuration.service';
import { ListService } from '@src/shared/services/list.service';
import { ProcessService } from '@src/shared/services/process.service';
import { SecurityService } from '@src/shared/services/security.service';
import { UserService } from '@src/shared/services/user.service';

@Component({
  selector: 'app-asset-team',
  templateUrl: './asset-team.component.html',
  styleUrls: ['./asset-team.component.less'],
})
export class AssetTeamComponent implements OnInit, OnDestroy {

    
    
    /* ATTRIBUTES */
    @Input() data;
    @Output() updateCompletion = new EventEmitter();

    public cache: Cache; // the session cache
    public config; // the application configuration

    public asset; // the selected asset
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization

    public columnsToDisplay = ['name', 'actions']; // columns for the teams table
    public columnWidth = { name: '75%', actions: '25%' }; // column width for the teams table

    public index = [];  // an index of all the users in the organization
    public map = new Map<string, any>(); // the map of all authorized users keyed by role

    public roles = []; // all the roles defined by the organization
    public users = []; // the list of users that can access this asset

    public selected = []; // selected emails to add
    public filtered = []; // filtered users list
    public keyword = '';

    public isLoading = true;
    public isReadonly = false;
    public isNavigationAllowed = true;

    // security
    public canAdd = false;
    public canRemove = false;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        public snackBar: MatSnackBar,
        private configurationService: ConfigurationService,
        private listService: ListService,
        private securityService: SecurityService,
        private userService: UserService) {

        // Apply timeout to force events to be processed in sequence and avoid the error
        // 'Expression has changed after it was checked'. See also reference article:
        // "https://blog.angular-university.io/angular-debugging/"
        
        this.config = config;
        // setTimeout(() => {
        //     config.isHeaderVisible = true;
        //     config.isNavigationVisible = true;
        // });
    }

    /**
     * On Init.
     */
    public async ngOnInit() {

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.cache = Cache.get();
        this.configuration = new Configuration (this.cache.getValue(Cache.KEYS.CONFIGURATION));
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);

        await this.listService.init(this.organization.pkId); // used to filter list of authorized users for assign teams

        this.securityService.getUsersByOrganization(this.organization.orgPkId).subscribe(res => {
            this.index = res;
        });

        this.roles = await lastValueFrom (this.configurationService.getRoles(this.organization.orgPkId));
        this.users = await lastValueFrom (this.securityService.getUsersByAsset(this.organization.orgPkId, this.asset.pkId));

        // Lastly, set up the security so that only the asset owner can assign the 
        // team member. Note thet once the owner assigns either an author or assessor, 
        // they can each assign their own roles to others.

        if (this.asset.ownerPkId === this.current.pkId) {
            this.canAdd = true;
            this.canRemove = true;
        } 
        else {
            this.canAdd = await this.securityService.checkRoute(config.routes.PRIVATE_PROCESS_START_PROCESS_ADD);
            this.canRemove = await this.securityService.checkRoute(config.routes.PRIVATE_PROCESS_START_PROCESS_REMOVE);
        }

        this.roles = this.roles.map((role) => ({
            ...role,
            users: new MatTableDataSource(
                this.users.filter((user) => user.applications[0].roles.find(
                    (userRole) => {
                        return userRole.rolePkId === role.pkId && userRole.assetPkId === this.asset.pkId;
                    })
                )
            ),
        }));

        // We now have to sort the data within each role so that the list of team 
        // members are alphabetized and easier to manage. The sorting is done based 
        // on full name. Also, we must build the map of all remaing users authorized 
        // to be assigned to each role. Note, that we use the list to define who is 
        // authorized by role. The list name must match the role name.

        for (let role of this.roles) {
            this.sort(role);
            let assigned = role.users.filteredData.map(u => u.email); // all users already assigned to a role
            let list = this.listService.getList(role.name);
            if (list) {
                if (list.type === 'User') {
                    this.map[list.systemName] = [];
                    for (let option of list.options) {
                        let user = this.index.find(u => u.pkId === option.referencePkId);
                        if (user) {
                            if (!assigned.includes(user.email)) {
                                this.map[list.systemName].push(user);
                            }                            
                        }
                    }
                }
            }
            else {            
                let key = role.name.toLowerCase();
                this.map[key] = [];
                for (let user of this.index) {                    
                    if (!assigned.includes(user.email)) {
                        this.map[key].push(user);
                    }
                }    
            }
        }
        this.isLoading = false;
        this.updateCompletion.emit();
    }

    /**
     * On Destroy.
     */
    public ngOnDestroy() {}

    /* METHODS */

    /**
     * Assign a role to the specified person, who is identified by his or her email. 
     * Check if the new user exists in the index. If the new user does not exist in 
     * the index, then that user was either corrupted or removed from the organization.
     * Note: the index only holds the user pkid, first name, last name, and email.
     */
    public async add(email, role) {
        const index = this.index.find(user => user.email === email.toLowerCase());
        if (index) {
            // When adding a new user to the team, we have to initialize the user application
            // and roles because the index does not contain that information due to performance
            // constraints.

            if (!index.applications) {
                index.applications = [];
                index.applications.push({ applicationPkId: role.applicationPkId, roles: [] });
            }

            // After initializing the user application and roles, we have to add the newly
            // assigned role into the user object. Note, the new user is passed by reference
            // to the list of members.

            const role_ = index.applications[0].roles.find(role_ => role_.rolePkId === role.pkId && role_.assetPkId === this.asset.pkId);
            if (!role_)
            {
                index.applications[0].roles.push({
                    pkId: role.pkId,
                    alias: role.alias,
                    applicationPkId: role.applicationPkId,
                    description: role.description,
                    isActive: role.isActive,
                    name: role.name,
                    organizationPkId: role.organizationPkId,
                    routes: role.routes
                });

                role.users.data.push(index);
                role.users = new MatTableDataSource(role.users.data);
                this.userService.createRole(index.pkId, this.organization.orgPkId, this.asset.pkId, role.pkId).subscribe(res => {
                    this.sort(role);
                    this.cache.save();
                    this.map[role.name.toLowerCase()] = this.map[role.name.toLowerCase()].filter(u => u.pkId != index.pkId);
                    this.updateCompletion.emit();
                });
            }
        }
        else {
            this.configurationService.snack('A user with this email does not exist in the current organization.', 3000);
        }
        this.closeAll();
    }

    /**
     * Assign the selected users to the specified role, then clear the 
     * selected values.
     */
    public async assign(emails, role) {
        if (role) {
            if (emails?.length > 0) {
                for (let email of emails) {
                    this.add(email, role);
                }
                this.selected = [];
            }
        }
    }

    /**
     * Unassign the user to the specified role. Then, we must remove the role from 
     * the user and also clean up the user object in the index.
     */
    public delete(user, role) {
        let position = user.applications[0].roles.indexOf(role);
        user.applications[0].roles.splice(position, 1);

        position = role.users.data.indexOf(user);
        role.users.data.splice(position, 1);
        role.users = new MatTableDataSource(role.users.data);
        this.userService.deleteRole(user.pkId, this.organization.orgPkId, this.asset.pkId, role.pkId).subscribe(res => {
            this.cache.save();          
            this.map[role.name.toLowerCase()].push(user);
            this.updateCompletion.emit();
        });

        this.closeAll();
    }

    /**
     * Filter the user list based on the term.
     */
    public filter(role, value, form)
    {
        let previousSelected = this.map[role.name.toLowerCase()].filter(x => this.selected.includes(x.email));
        if (role) {
            if (value || value === '') {
                let keywords = value.trim();
                this.filtered = [...new Set(this.map[role.name.toLowerCase()].filter(item =>
                {         
                    let name = item.firstName + " " + item.lastName;
                    if (name.toLowerCase().includes(keywords.toLowerCase())) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }).concat(previousSelected))];
            }
        }
    }

    /**
     * Initialize the role.
     */
    public init(role) {
        if (role?.name) {
            this.filtered = this.map[role.name.toLowerCase()];
        }
    }

    /**
     * Sort the data.
     */
    public sort(role) {
        if (role) {
            role.users.data = role.users.data.sort((a, b) => {
                let nameA = a.firstName.toUpperCase().replace(/\\|\//g, '') || String.fromCharCode(255);
                let nameB = b.firstName?.toUpperCase().replace(/\\|\//g, '') || String.fromCharCode(255);
                return (nameA < nameB ? -1 : 1);
            });
        }
    }  

    public getPercentComplete() {
        for (let role of this.roles) {
            if (role.users?.data?.length) {
                return 100;
            }
        }
        return 0;
    }

    // UTILITIES

    /**
     * Close the dialog.
     */
    public closeAll() {
        this.dialog.closeAll();
    }

    /**
     * Opens a dialog with a given template.
     */
    public openDialog(component, data?) {

        // We have to initialize the filtered list of users based on the selected
        // role to assign users to the role. Note: we do not need to initialize the
        // filtered list when removing a user from a role.

        this.init(data);

        // Set the dialog properties and open the dialog using those properties.
        // All dialogs are inteded to fill the entire screen. The dialog styles can
        // be found in the global styles.

        const properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: data, };
        const dialogRef = this.dialog.open(component, properties);

        // After the dialog is opened and closed, process the returned data
        // and perform cleanup operations.

        dialogRef.afterClosed().subscribe((result) => {            
        });
    }
}
