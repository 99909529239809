<div class="container-fluid nopadding">
    <div class="row no-gutters gap-3 pb-3" *ngIf="this.isActionable">
        <div class="col-lg no-gutters">
        </div>
        <div class="col-lg-auto" no-gutters>                    
            <!-- <button class="btn-round btn" (click)="save();">SAVE</button> -->
            <button class="" mat-flat-button color="primary" (click)="add();">ADD</button> 
        </div> 
    </div>
    <div class="row no-gutters pb-5" *ngIf="this.annotation">
        {{ this.annotation }}
    </div>
    <div class="pb-3" *ngFor="let item of object | keyvalue; let i = index">
        <div class="row no-gutters gap-3">
            <div class="col-lg-1 no-gutters">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Index</mat-label>
                    <input class="col-center" [name]="'index' + i" matInput [value]="i">
                </mat-form-field>
            </div>
            <div class="col-lg-3 no-gutters">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Key</mat-label>
                    <input [name]="'key' + i" matInput (change)="changeKey(item.key, $event.target.value)" [ngModel]="item.key">
                </mat-form-field>
            </div>
            <div class="col-lg-5 no-gutters">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Value</mat-label>
                    <input [name]="'value' + i" matInput (change)="changeValue(item.key, $event.target.value)" [ngModel]="item.value">
                </mat-form-field>
            </div>        
            <div class="col-lg-2 no-gutters" style="border-left: 1px solid #CCC" >
                <button mat-icon-button (click)="delete(item.key)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>