export class Preference {

    /* ATTRIBUTES */

    isNavigationExpanded: boolean = true;
    theme: string = 'light';       

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    
}
