<div class="search-bar search-bar-container row col-12 px-2 py-2" [style.backgroundColor]="backgroundColor">
  <img *ngIf="showLogo" class="search-bar-logo" src="assets/icons/app.logo.svg" alt="search button" />
  <div class="" style="margin-left: 1rem; flex: 1 1 auto;">
    <input #si
      class="col-12 p-0 border-0 outline-0 background-none input-reset"
      style="vertical-align: sub;line-height: 1.5rem;"
      name="keyword"
      type="text"
      (keyup)="onKeyup($event)"
      [placeholder]="placeholderText"
    />
  </div>
  <div class="justify-content-end p-0">
    <button class="search-bar-button cursor-pointer outline-0" (mouseup)="onSearchClick(si.value)">
      <img *ngIf="!showLogo"
        class="input-bar-icon center-block"
        style="height: 1.5rem"
        src="assets/icons/app.logo.svg"
        alt="search button"
      />
      <mat-icon *ngIf="showLogo" class="material-icons-outlined" >search
      </mat-icon>
    </button>
  </div>
</div>