import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { throttleTime } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stacked-snackbars',
  templateUrl: './stacked-snackbars.component.html',
  styleUrls: ['./stacked-snackbars.component.less']
})
export class StackedSnackbarsComponent implements OnInit, OnChanges {

  /* ATTRIBUTES */

    @Input() subject: Subject<{title: string, content: string, route: string, icon: string}>;
    @Input() duration = 5000;


    /**
     * Constructor.
     */
    constructor(private route: Router) { }

    /* INIT. */

    /**
     * On Init.
     */
    ngOnInit(): void {
      this.subject.pipe(throttleTime(200)).subscribe(res => {
        var el = document.createElement("div");
        el.className = "snackbar";
        var y = document.getElementById("snackbar-container");
        if (y) {
          el.innerHTML =`<span class="material-icons-outlined icon">${res.icon}</span><b>${res.title}:</b> ${res.content}`;
          y.append(el);
          el.className = "snackbar show";
          el.addEventListener('click', () => {
            if (res?.route) {
              this.route.navigate([res.route]);
            }
          });
          setTimeout(function(){ 
            el.className = el.className.replace("snackbar show", "snackbar"); 
            setTimeout(function(){ 
              el.remove();
            }, 1000);
          }, this.duration);
        }
      });
    }

    /**
     * On Changes (Input variable changes)
     */
    ngOnChanges() {

    }

    /* METHODS */

}
