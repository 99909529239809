import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showHide'
})
export class ShowHidePipe implements PipeTransform {

    /**
     * Convert string to *******
     */
  transform(text: string, isHidden): any {
    if (text && (!isHidden || isHidden === 'true')) {
      return '*****'
    } else return text;
  }

}
