import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readable'
})
// tslint:disable: no-bitwise
export class ReadablePipe implements PipeTransform {

  transform(id: string, ...args: any[]): any {
    const family = id?.substring(0, 2);
    const control = id?.substring(2, 4)?.replace(/^0+/, '');
    const enhnacment = id?.substring(4)?.replace(/^0+/, '');
    return family + '-' + control + (enhnacment ? ' (' + enhnacment + ')' : '');
  }

}
