import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Cache } from '../../objects/cache';

@Component({
  selector: 'app-object-field-editor',
  templateUrl: './object-field-editor.component.html',
  styleUrls: ['./object-field-editor.component.less']
})
export class ObjectFieldEditorComponent implements OnInit, OnDestroy {

    @Input() annotation; // the annotation text header
    @Input() isActionable = true; // show or hide the action button
    @Input() object; // the data object that holds the mapping

    /* ATTRIBUTES */

    public cache: Cache; // the session cache
    public organization; // the organization
    public user; // the current user

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        public snackBar: MatSnackBar) {
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit() {

        // Get all of the selected organization, configuration, and user from
        // the cache. The selected organization is passed from the authentication
        // service upon starting the application.

        this.cache = Cache.get();
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.user = this.cache.getValue(Cache.KEYS.USER);

    }

    /**
     * On Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Add.
     */
    public add() {
        this.object['Key'] = 'Value';
    }

    /**
     * Change the key.
     */
    public changeKey(prevKey, newKey) {
        const value = this.object[prevKey];
        delete this.object[prevKey];
        this.object[newKey] = value;
    }

    /**
     * Change the value for the specified key.
     */
    public changeValue(key, value) {
        this.object[key] = value;
    }

    /**
     * Delete the key and corresponding value.
     */
    public delete(key) {
        delete this.object[key];
    }

    /**
     * Save.
     */
    public save() {
    }

}
