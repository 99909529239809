
<!-- TOGGLE -->
<button class="navigation-full-toggle" mat-button (click)="showHide()" *ngIf="!isNavigationExpanded">
    <mat-icon>menu</mat-icon>
</button>
<!-- SMALL DEVICES -->
<div class="navigation-small" *ngIf="isNavigationExpanded">        
</div>
<!-- LARGE DEVICES -->
<div class="navigation-full" *ngIf="isNavigationExpanded" [@navSlideLeftAnimation]>
    <!-- NAVIGATION -->
    <div class="navigation-panel">
        <!-- CLOSE -->
        <mat-icon class="menu-close" (click)="showHide()">chevron_left</mat-icon>
        <!-- LOGO -->
        <div class="menu-app">    
            <div class="w-100 main-nav-logo">
                <div class="no-padding row no-gutters">
                    <img class="col-auto menu-app-icon" src="assets/icons/app.logo.svg" onerror="this.onerror=null; this.src='assets/images/icons/itemicon.svg'" alt="Application Logo"/>
                    <div class="col-auto ml-2 menu-app-label">{{config.appName}}</div>
                </div>
            </div>
        </div>
        <!-- MENU -->
        <div class="menu-nav">
            <ng-container *ngFor="let item of config.navigation">
                <!-- DIVIDER -->
                <div class="divider" *ngIf="(item.type) && (item.type === 'Divider')">
                    <div class="line"></div>
                </div>
                <!-- LINK -->
                <div *ngIf="(!item.type || item.type === 'Link') && !item.children">
                    <button mat-button class="menu-item w-100" *ngIf="!item.permissioned" [id]="item.title" [routerLink]="item.link" [queryParams]="item.params" [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }" (click)="hideSecondary()">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>

                    <button mat-button class="menu-item w-100" *ngIf="item.permissioned" [id]="item.title" [componentIf]="{val: item.link}" [routerLink]="item.link" [queryParams]="item.params" [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }"  (click)="hideSecondary()">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>
                </div>
                <!-- CHILDREN -->
                <ng-container *ngIf="item.children">
                    <button mat-button class="menu-item w-100" *ngIf="!item.permissioned" 
                        [id]="item.title" 
                        [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }" 
                        [routerLink]="item.link" [queryParams]="item.params"
                        (click)="selectParent(item)">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>

                    <button mat-button class="menu-item w-100" *ngIf="item.permissioned" 
                        [id]="item.title" 
                        [componentIf]="{val: item.link}" 
                        [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }" 
                        [routerLink]="item.link" [queryParams]="item.params"
                        (click)="selectParent(item)">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>
                </ng-container>
            </ng-container>
        </div>
        <!-- MODE -->
        <div class="light-dark-mode-toggle" *ngIf="config.isDarkModeEnabled">
            <mat-slide-toggle 
                [checked]="preference?.theme === 'dark'" 
                (change)="toggleTheme($event)"
                labelPosition = 'before'>
                {{preference?.theme || 'Light'}} Mode
            </mat-slide-toggle>
        </div>
    </div>
</div>

<!-- SECONDARY NAVIGATION -->
<div class="navigation-full navigation-full-secondary-position" *ngIf="isNavigationSecondaryExpanded" [@navSlideLeftAnimation]>
    <div class="navigation-panel pt-1">
        <!-- CLOSE -->
        <mat-icon class="menu-close" (click)="hideSecondary()">
            chevron_left
        </mat-icon>
        <!-- MENU -->
        <div class="menu-nav" *ngIf="parent?.children?.length > 0">
            <ng-container *ngFor="let item of parent.children">
                <!-- DIVIDER -->
                <div class="divider" *ngIf="(item.type) && (item.type === 'Divider')">
                    <div class="line"></div>
                </div>
                <!-- LINK -->
                <div *ngIf="(!item.type)">
                    <button mat-button class="menu-item w-100" *ngIf="!item.permissioned" [id]="item.title" [routerLink]="item.link" [queryParams]="item.params" [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>
                    <button mat-button class="menu-item w-100" *ngIf="item.permissioned" [id]="item.title" [componentIf]="{val: item.link}" [routerLink]="item.link" [queryParams]="item.params" [ngClass]="{ 'selected-menu-item': checkMatch(item.active) }">
                        <div class="row no-gutters ml-1" *ngIf="isNavigationExpanded">
                            <div class="col-auto menu-item-icon-active" [style.-webkit-mask]="'url(' + item.icons?.active + ')'" *ngIf="checkMatch(item.active)"></div>
                            <div class="col-auto menu-item-icon-inactive" [style.-webkit-mask]="'url(' + item.icons?.inactive + ')'" *ngIf="!checkMatch(item.active)"></div>
                            <div class="col-auto ml-2 menu-item-label" [ngClass]="{ 'active-text': checkMatch(item.active) }">{{ item.title }}</div>
                        </div>
                    </button>
                </div>
                <!-- CHILDREN -->
                <ng-container *ngIf="item.children">
                    <div style="overflow: hidden;"  [@detailExpand]>
                        <ng-container *ngFor="let subitem of item.children">
                            <button mat-button class="pl-5 col menu-label menu-item child-item" [routerLink]="subitem.link" [ngClass]="{ 'active-text': router.url.includes(subitem.link) }">{{ subitem.title }}</button>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- LEARNING CENTER -->
                <div class="learning-center" *ngIf="item.type === 'Training'">
                    <mat-list *ngIf="topicTree">
                        <!-- LEARNING TITLE -->
                        <mat-list-item class="learning-title">
                            <a [routerLink]="config.routes.PRIVATE_TOPIC_BROWSE" (click)="hideSecondary()" style="color: white;" >
                                TOPICS
                            </a>
                        </mat-list-item>
                        <!-- DIVIDER -->
                        <div class="divider" *ngIf="(item.type) && (item.type === 'Divider')">
                            <div class="line"></div>
                        </div>
                        <!-- LEARNING ITEMS -->
                        <ng-container
                            *ngFor="let node of topicTree.nodes"
                            [ngTemplateOutlet]="treeNode"
                            [ngTemplateOutletContext]="{ $implicit: node }">
                        </ng-container>
                    </mat-list>    
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- TREE STRUCTURE FOR TRAINING MENU -->
<ng-template #treeNode let-data>
    <mat-list-item class="learning-item">
        <a [routerLink]="config.routes.PRIVATE_TOPIC_VIEW + '/' + data.item?.pkId" (click)="hideSecondary()">
            {{data.item?.title}}
        </a>
    </mat-list-item>
    <!-- NOTE: Only showing parent level now - 12/21/2023 -->
    <!-- 
    <ng-container *ngIf="data.children?.length > 0">
        <mat-list class="ml-3 p-0">
            <ng-container
                *ngFor="let child of data.children"
                [ngTemplateOutlet]="treeNode"
                [ngTemplateOutletContext]="{ $implicit: child }">
            </ng-container>
        </mat-list>
    </ng-container> 
    -->
</ng-template>
