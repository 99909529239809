import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { SecurityService } from '@src/shared/services/security.service';
@Component({
  selector: 'tab-navigator',
  templateUrl: './tab-navigator.component.html',
  styleUrls: []
})
export class TabNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() processMetadata;


    /* CONSTANTS */

    // The navigation links are used to hold the links to pre-built system 
    // components. The navigation component looks up the links by type, which
    // is associated with each process step. New process steps have a route field
    // which will be used instead of the navlinks object. We keep the nav links object
    // as a fallback incase the step isn't configured correctly with the route

    navLinks = {
        'assign-control-families': config.routes['PRIVATE_CONTROL_ASSIGN_CONTROL_FAMILIES'],
        'businessprocess': config.routes['PRIVATE_ASSET_EDIT_BUSINESS_IMPACT'],
        'controls-review': config.routes['PRIVATE_CONTROL_BROWSE_CONTROLS'],
        'controls-submit': config.routes['PRIVATE_CONTROL_BROWSE_CONTROLS'],
        'data': config.routes['PRIVATE_ASSET_EDIT_INFORMATION_TYPES'],
        'documents': config.routes['PRIVATE_PROCESS_EDIT_DOCUMENTS'],
        'inheritance': config.routes['PRIVATE_ASSET_EDIT_INHERITANCE'],
        'questionnaire': config.routes['PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE'],
        'technology': config.routes['PRIVATE_ASSET_EDIT_TECHNOLOGY']
    };

    /* ATTRIBUTES */

    private cache: Cache; // the session cache

    public config; // app.ts

    public asset; // the selected asset
    public catalogs; // all control catalogs
    public configuration; // the organization configuration
    public organization; // the organization
    public phase; // the selected phase
    public processes; // the processes configured for the organization
    public process; // the process assigned to the asset
    public step; // the selected step
    public user; // the current user

    public phases = [];

    activeLink = 0;

    private canEditForms: boolean;
    private canViewForms: boolean;


    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(
        private securityService: SecurityService,
        private router: Router) {

            this.config = config;

    }

    /* EVENT HANDLING */

    /**
     * Init.
     */
    async ngOnInit() {

        // Get all of the organization configuration and build out the business 
        // process and workflow. All of the organization's configurations are 
        // stored in Cache.

        this.cache = Cache.get();

        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);
        this.processes = this.configuration.processes;
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.step = this.cache.getValue(Cache.KEYS.STEP);
        this.user = this.cache.getValue(Cache.KEYS.USER);

        this.phases = this.process?.phases?.filter(phase_ => !phase_.isProgressHidden) || [];

        this.canEditForms = await this.securityService.checkRoute(config.routes.PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE, this.asset, this.user, this.organization)
        this.canViewForms = await this.securityService.checkRoute(config.routes.PRIVATE_QUESTIONNAIRE_VIEW_QUESTIONNAIRE, this.asset, this.user, this.organization)
    }

    /**
     * After View Init.
     */
    ngAfterViewInit() {
    }

    /**
     * On Destroy.
     */
    ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Goto the phase.
     */
    gotoPhase(phase) {
        if (phase) {
            // console.log("/private/shared/components/tab/gotoPhase: PHASE = ", phase);
            this.cache.setValue(Cache.KEYS.PHASE, phase);
            // this.router.navigateByUrl(config.routes.PRIVATE_PROCESS_VIEW_PHASE +'?phase=' + phase.name);
            
            if (phase.steps.length === 1 && phase.steps[0].referenceType !== 'questionnaire') {
               this.cache.setValue(Cache.KEYS.STEP, phase.steps[0]);
               this.router.navigateByUrl(phase.steps[0].route);
            }
            else {
               this.router.navigateByUrl(this.config.routes.PRIVATE_PROCESS_VIEW_PHASE + '?phase=' + phase.name);
            }
        }
    }

    /**
     * Set the step, and navigate to the component.
     * For questionnaires, we check if the user has edit priveledge or view priveledge first.
     */
    gotoComponent(phase, step) {
        if (step) {
            let link;
            if (step.referenceType === 'questionnaire') {
                if (this.canEditForms) {
                    link = config.routes.PRIVATE_QUESTIONNAIRE_EDIT_QUESTIONNAIRE;
                } 
                else if (this.canViewForms) {
                    link = config.routes.PRIVATE_QUESTIONNAIRE_VIEW_QUESTIONNAIRE;
                }
            }
            else {
                link = step.route ? step.route : this.navLinks[step.referenceType];
            }

            if (link) { 
                this.cache.setValue(Cache.KEYS.PHASE, phase);
                this.cache.setValue(Cache.KEYS.STEP, step);
                this.router.navigateByUrl(link);
            }
        }
    }

}
