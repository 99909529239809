import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[flip]'
})
export class CardFlipDirective {

  /* ATTRIBUTES */

  /**
   * Time it takes the card to flip in milliseconds
   */
  @Input('flip-time') animationTime = 250;
  /**
   * The transition easing function of the card
   */
  @Input('flip-ease') animationFunction = 'linear';
  /**
   * Changes the flip perspective. Takes in a value from 0-100
   * 1 is very distorted, 100 is normal
   */
  @Input('flip-perspective')
  set animationPerspective(value: number) {
    if (value > 0) this._perspective = value;
  }
  /**
   * The flip axis, the input can be either 'X' or 'Y'
   * or can be true for 'X' and false for 'Y'
   */
  @Input('flip-vertical')
  set animationAxis(value) {
    this._axis = value || value === '' ? 'X' : 'Y';
  }
  /**
   * Determines if the card will flip clockwise or counterclockwise
   */
  @Input('flip-ccw')
  set animationRotation(value) {
    this._rotation = value || value === '' ? 1 : -1;
  }
  /**
   * When this input is used, the card will rotate in only one direction
   */
  @Input('circular')
  set animationCircular(value) {
    this._circular = value || value === '' ? 1 : -1;
  }
  /**
   * flips the card up if it's true and down if false
   * two cards meant to be alternated should have opposite values
   */
  @Input('flip')
  set show(show: boolean) {

    if (!this.initialFlip) {
      this._style.transition = `unset`;
      this.initialFlip = true;
      this.setDisplay(show ? 'inherit' : 'none');
    } else {
      this._style.transition = `transform ${this.animationTime}ms ${this.animationFunction}`;
    }
    this._rotation *= this._circular;

    if (show === undefined) this.setDisplay('none');
    if (this.isRotated(90 * this._rotation)) this.setRotate(-90 * this._rotation);

    const rotation = show ? 0 : 90 * this._rotation;
    const display = show ? 'inherit' : 'none';

    this.delayRotate(rotation, <any>show * (this.animationTime + 50));
    this.delayDisplay(display);

  }

  private _style = this.el.nativeElement.style;

  // default settings
  private _axis = 'Y';
  private _perspective = 70;
  private _rotation = 1;
  private _circular = -1;
  private initialFlip = false;

  /**
   * Constructor
   * @param el element refrence
   */
  constructor(private el: ElementRef) { }

  /* PRIVATE METHODS */

  /**
   * sets the native element's display property after animations have finished
   * @param style the new display style
   */
  private delayDisplay(style): void {
    setTimeout(() => this.setDisplay(style), this.animationTime);
  }

  /**
   * sets the native Element's rotateY tranform property
   * @param deg degrees to rotate by
   * @param timeout waiting time before setting
   */
  private delayRotate(deg, timeout): void {
    setTimeout(() => this.setRotate(deg), timeout);
  }

  /**
   * sets the native element's transform property
   * @param deg the amount of degrees to set
   */
  private setRotate(deg): void {
    this._style.transform = `perspective(${this._perspective}vw) rotate${this._axis}(${deg}deg)`;
  }

  /**
   * sets the native element's display property
   * @param style the new display style
   */
  private setDisplay(style): void {
    // this._style.display = style;
    if (style === 'none') {
      this._style.position = 'absolute';
      this._style.visibility = 'hidden';
    } else {
      this._style.position = 'inherit';
      this._style.visibility = 'visible';
    }
  }

  /**
   * returns a boolean if the native element's transform
   * has been rotated by a specified amount
   * @param amount the amount of rotation in degrees
   */
  private isRotated(amount): boolean {
    return this._style.transform.indexOf(`rotate${this._axis}(${amount}deg)`) !== -1;
  }
}
