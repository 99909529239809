
import { ActivatedRoute } from '@angular/router';
import { classToPlain, ClassTransformOptions, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';

export class Utility {

    //#region CAST

    /**
     * Clone an object using class-transformer
     */
    public static cast<T, V>(cls: ClassType<T>, plainObj: V, cleanObj: boolean = false, options?: ClassTransformOptions): T {
      const object = cleanObj && typeof plainObj === 'object' ? Utility.toCleanObj(<Object>plainObj) : plainObj;
      const result = plainToClass(cls, object, options);
      if (cleanObj) {
        Utility.clean(result);
      }
      return result;
    }

    /**
     * Clone an object array using class-transformer
     */
    public static castArray<T, V>(cls: ClassType<T>, plainObjs: V[], cleanObj: boolean = false, options?: ClassTransformOptions): T[] {
      let objects: any[] = plainObjs;
      if (cleanObj) {
        objects = plainObjs.map(o => {
          return typeof o === 'object' ? Utility.toCleanObj(<Object>o) : o;
        });
      }
      const results = plainToClass(cls, objects, options);
      if (cleanObj) {
        results.forEach(r => { Utility.clean(r); });
      }
      return results;
    }

    /**
     * Clone an object using class-transformer with excluding non-exposed properties
     */
    public static safeCast<T, V>(cls: ClassType<T>, plainObj: V, cleanObj: boolean = false): T {
      const object = cleanObj && typeof plainObj === 'object' ? Utility.toCleanObj(<Object>plainObj) : plainObj;
      const obj = plainToClass(cls, object, { excludeExtraneousValues: true });
      if (cleanObj) {
        Utility.clean(obj);
      }
      return obj;
    }
    
    /**
     * Clone an object array using class-transformer with excluding non-exposed properties
     */
    public static safeCastArray<T, V>(cls: ClassType<T>, plainObjs: V[], cleanObj: boolean = false): T[] {
      let objects: any[] = plainObjs;
      if (cleanObj) {
        objects = plainObjs.map(o => {
          return typeof o === 'object' ? Utility.toCleanObj(<Object>o) : o;
        });
      }
      const results = plainToClass(cls, objects, { excludeExtraneousValues: true });
      if (cleanObj) {
        results.forEach(r => { Utility.clean(r); });
      }
      return results;
    }

    //#endregion

    //#region CONVERT

    /**
     * Convert the string into camel case.
     */
    public static camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    /**
     * Convert a Type to JSON object.
     */
    public static toObject<T>(object: T, options?: ClassTransformOptions): Object {
      return classToPlain<T>(object, options);
    }

    /**
     * Convert Type array to array of JSON objects.
     */
    public static toObjects<T>(object: T[], options?: ClassTransformOptions): Object[] {
      return classToPlain<T>(object, options);
    }

    //#endregion

    //#region CLEAN

    /**
     * Remove all NULL properties of an object.
     */
    public static clean(obj: any) {
      Object.keys(obj).forEach(key => {
        if (obj[key] && obj[key].constructor === Array) {
          obj[key].forEach(o => Utility.clean(o));
        } else if (obj[key] && obj[key] instanceof Date) {
          // skip
        } else if (obj[key] && typeof obj[key] === 'object') {
          Utility.clean(obj[key]); // recursive
        } else {

          if (obj[key] == null) { delete obj[key]; }
        } // delete
      });
    }
    
    /**
     * Create a clone of an object which has all NULL property removed
     */
    public static toCleanObj(obj: object) {
      const newObj = {};

      Object.keys(obj).forEach(key => {
        if (obj[key] && obj[key].constructor === Array) {
          newObj[key] = obj[key].map(o => typeof o === 'object' ? Utility.toCleanObj(o) : o);
        } else if (obj[key] && obj[key] instanceof Date) {
          newObj[key] = obj[key];
        } else if (obj[key] && typeof obj[key] === 'object') {
          newObj[key] = Utility.toCleanObj(obj[key]); // recursive
        } else if (obj[key] != null) {
          newObj[key] = obj[key]; // copy value
        }
      });

      return newObj;
    }

    //#endregion

    //#region COMPARE / SORT

    /**
     * Sort object array by field name.
     */
    public static sortArray<T>(array: T[], field: string, sort: 'asc'|'desc') {
      if (!array || array.length < 1) { return array; }
      if (!field) { return array; }
      if (!sort) { return array; }

      const sortedArray = array.sort((n1, n2) => {
        if (n1[field] > n2[field]) {
          return sort === 'asc' ? 1 : -1;
        }

        if (n1[field] < n2[field]) {
          return sort === 'asc' ? -1 : 1;
        }

        return 0;
      });
      return sortedArray;
    }

    /**
     * Compare 2 objects usually used in sort.
     */
    public static compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * Get Date difference between 2 dates.
     */
    public static dateDiff(first: Date, second: Date) {
      const dayMS = 1000 * 60 * 60 * 24;
      // Take the difference between the dates and divide by milliseconds per day.
      return Math.round((second.getTime() - first.getTime()) / dayMS);
    }

    //#endregion

    //#region HTML

    /**
     * Sanitize string by removing html tags, and characters unsafe for word docs.
     */
      public static stripHTML(str: string): string {
      if (typeof str !== 'string') {
          return str;
      }
      else {
        return str ? str.replace(/(<([^>]+)>)|(&nbsp;|&|'|<|>|#)/g,"") : '';
      }
    }

    //#endregion

    //#region JSON 

      /**
       * Check to see if the obj is a valid json. 
       */
      public static isValidJson (obj) {
        try {
            if (obj) {
                if (typeof obj ===  "object") {
                    return true;
                }
                else {
                    var result = JSON.parse(obj);
                    if (result && typeof result === "object") {
                        return true;
                    }
                }
            }
        }
        catch (e) {           
        }    
        return false;
    }

    //#endregion

    //#region MISCELLANEOUS

    /**
     * Get the action from current route. Assumes module is following standard:
     * 
     * /private/questionnaire => [questionnaire module]
     * /private/questionnaire/view => action = 'view'
     * /private/questionnaire/build/<uuid> => action = 'build'
     */
    public static getComponentAction(route: ActivatedRoute){
      return route.snapshot.url[0]?.path;
    }

    /**
     * Get number of days from frequency string.
     */
    public static getDaysFromFreqString(freqString: string) {
      switch (freqString) {
        case 'Daily': return 1;
        case 'Every 2 days': return 2;
        case 'Every 3 days': return 3;
        case 'Every 4 days': return 4;
        case 'Every 5 days': return 5;
        case 'Every 6 days': return 6;
        case 'Weekly': return 7;
        case 'Every 10 days': return 10;
        case 'Bi-monthly': return 15;
        case 'Monthly': return 30;
        case 'Every two months': return 30 * 2;
        case 'Every 6 months': return 30 * 6;
        case 'Yearly': return 365;
        default: return 0;
      }
    }

    /**
     * Check if a value is empty (NULL, UNDEFINED or BLANK).
     */
    public static isEmpty(value: any) {
      if (!value) {
        return true;
      }
      if (Object.entries(value).length === 0 && value.constructor === Object) {
        return true;
      }
      if (typeof value === 'string') {
        if (value.trim() === '') {
          return true;
        }
      }
      if (value instanceof String) {
        if (value.trim() === '') {
          return true;
        }
      }
      return false;
    }

    /**
     * Verify that the typed character is a valid character of an IPv4 address
     */
    public static checkIPV4Input(event: KeyboardEvent) {
      const ALLOWED_VALUES = '0123456789.-'
      let key = event.key
      if (ALLOWED_VALUES.includes(key) || 
          key === 'Backspace' || 
          key === 'Delete' || 
          key === 'ArrowLeft' || 
          key === 'ArrowRight' || 
          key === 'ArrowUp'|| 
          key === 'ArrowDown'  || 
          ((event.ctrlKey || event.metaKey) && key === 'v') ||
          ((event.ctrlKey || event.metaKey) && key === 'x') ||
          ((event.ctrlKey || event.metaKey) && key === 'z')
        ) return true;
      else return false;
  }

    /**
     * Verify that the typed character is a valid character of an IPv6 address
     */
    public static checkIPV6Input(event: KeyboardEvent) {
        const ALLOWED_VALUES = 'ABCDEFabcdef0123456789.:'
        let key = event.key
        if (ALLOWED_VALUES.includes(key) || 
            key === 'Backspace' || 
            key === 'Delete' || 
            key === 'ArrowLeft' || 
            key === 'ArrowRight' || 
            key === 'ArrowUp'|| 
            key === 'ArrowDown' || 
            ((event.ctrlKey || event.metaKey) && key === 'v') ||
            ((event.ctrlKey || event.metaKey) && key === 'x') ||
          ((event.ctrlKey || event.metaKey) && key === 'z')
          ) return true;
        else return false;
    }

    /**
       * Verify that the pasted value only contains allowed characters for an IPv4 address
       */
    public static checkIPV4Paste(event) {
      let lengthLimit = 15;
      let allowedValues =  '0123456789.-'
      let pastedValue = event.clipboardData.getData('text')

      if (pastedValue.length > lengthLimit) event.preventDefault();
      for (let char of pastedValue) {
          if (!allowedValues.includes(char)) event.preventDefault();
      }
    }

    /**
     * Verify that the pasted value only contains allowed characters for an IPv6 address
     */
    public static checkIPV6Paste(event) {
        let lengthLimit = 39;
        let allowedValues =  'ABCDEFabcdef0123456789.:'
        let pastedValue = event.clipboardData.getData('text')

        if (pastedValue.length > lengthLimit) event.preventDefault();
        for (let char of pastedValue) {
            if (!allowedValues.includes(char)) event.preventDefault();
        }
    }

    //#endregion
    
}
