<div class="page-section-items" *ngIf="asset">
    <mat-accordion>
        <mat-expansion-panel class="mb-4" #profile [expanded]="true" hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>PROFILE</h4>
                </mat-panel-title>
                <mat-icon class="accent-color">{{profile.expanded?'expand_less':'expand_more'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="items pt-3">
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input 
                            name="name" 
                            #name 
                            matInput 
                            type="text" 
                            [(ngModel)]="this.asset.name" 
                            (ngModelChange)="checkValidity()"
                            [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                            [showAsDisabled]='true'
                            [disabled]="isReadonly"
                            maxLength="100" 
                            required>
                             <mat-hint align="end">{{name.value.length}}/100</mat-hint>
                    </mat-form-field>
                    <mat-error *ngIf="form.control.get('name')?.errors?.notUnique">Name is already used by another asset.</mat-error>
                </div>
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Alias</mat-label>
                        <input name="alias" #alias matInput type="text" [(ngModel)]="this.asset.alias" (ngModelChange)="checkValidity()" required
                            [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}"
                            [showAsDisabled]='true' 
                            [disabled]="isReadonly"
                            maxlength="100">
                            <mat-hint align="end">{{alias.value.length}}/100</mat-hint>
                        </mat-form-field>
                    <mat-error *ngIf="form.control.get('alias')?.errors?.notUnique">Alias is already used by another asset.</mat-error>
                </div> 
                <div class="item">                    
                    <app-editor 
                        #editor 
                        class="content-editor"
                        name="editor"
                        ngModelGroup="description" 
                        [(content)]="this.asset.description"
                        [editorLabel]="'Description'"
                        [placeholder]="'Enter the description of your asset here.'"
                        (updatedContent)="asset.description = $event"
                        [readonly]="isReadonly">
                    </app-editor> 
                </div>
                <div class="item row align-items-center mr-0">
                    <mat-form-field class="col pr-1" appearance="outline">
                        <mat-label>Asset ID</mat-label>
                        <input name="prettyId" matInput type="text" [(ngModel)]="asset.prettyId" readonly='true' disabled>
                    </mat-form-field>
                    <mat-icon class="col-auto nopadding"
                        matTooltip='The Asset ID will also serve as the "Secret Key" that unlocks the inheritable controls for other assets.'
                        matTooltipPosition="above">
                        info_outline
                    </mat-icon>
                </div>  
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{configuration.translate('Custom ID')}}</mat-label>
                        <input name="customPkId" matInput type="text" [(ngModel)]="this.asset.customPkId" (ngModelChange)="checkValidity()" required
                            [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}"
                            [showAsDisabled]='true' 
                            [disabled]="isReadonly"
                            maxlength="100"
                            #customid
                            aria-label="custom id">
                            <mat-hint align="end">{{customid.value.length}}/100</mat-hint>
                    </mat-form-field>
                    <mat-error *ngIf="form.control.get('customPkId')?.errors?.notUnique">Custom ID is already used by another asset.</mat-error>
                </div>
                <div class="item">                            
                    <mat-form-field class="w-100" appearance="outline" >
                        <mat-label>Asset Owner</mat-label>
                        <mat-select name="owner" placeholder="Asset Owner" [(ngModel)]="asset.ownerPkId" required [disabled]="isReadonly">
                            <mat-option *ngFor="let user of users | sortArray: 'firstName'" [value]="user.pkId">{{user.firstName}} {{user.lastName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item">
                    <suggestion-dropdown
                        [isReadOnly]="isReadonly"
                        [objects]="assets"
                        [labelName]="configuration.translate('Division') + ' Name'"
                        [placeholder]="'Division'"
                        [required]="true"
                        [selectName]="'divisionName'"
                        [(model)]="asset.metadata.divisionName"
                        [field]="'metadata.divisionName'"
                        >
                    </suggestion-dropdown>
                </div>     
                <div class="item">
                    <suggestion-dropdown
                        [isReadOnly]="isReadonly"
                        [objects]="assets"
                        [labelName]="configuration.translate('Division') + ' Alias'"
                        [placeholder]="'Division Alias'"
                        [required]="true"
                        [selectName]="'division'"
                        [(model)]="asset.metadata.division"
                        [field]="'metadata.division'"
                        >
                    </suggestion-dropdown>
                </div>   
                <div class="item">
                    <suggestion-dropdown
                        [isReadOnly]="isReadonly"
                        [objects]="assets"
                        [labelName]="configuration.translate('Division') + ' Office'"
                        [placeholder]="'Division Office'"
                        [required]="false"
                        [selectName]="'divisionOffice'"
                        [(model)]="asset.metadata.divisionOffice"
                        [field]="'metadata.divisionOffice'"
                        >
                    </suggestion-dropdown>
                </div>     
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Version</mat-label>
                        <input name="version" matInput type="text" [(ngModel)]="this.asset.version" [disabled]="isReadonly" required>
                    </mat-form-field>
                </div>
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select name="type" [(ngModel)]="asset.type" [disabled]="isReadonly" required>
                            <mat-option [value]="option.value" *ngFor="let option of LIST_ASSET_TYPE?.options | sortArray:'name'">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item" *ngIf="asset.type !== 'Policy'">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Category</mat-label>
                        <mat-select name="category" [(ngModel)]="asset.category" [disabled]="isReadonly" required>
                            <mat-option [value]="option.value" *ngFor="let option of LIST_ASSET_CATEGORY?.options | sortArray:'name'">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- 
                <hr class="mb-4">
                <div class="item">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{configuration?.translate('Security Level')}}</mat-label>
                        <mat-select name="securityLevel" [(ngModel)]="asset.metadata.securityLevel" [disabled]="isReadonly" required>
                            <mat-option [value]="option.value" *ngFor="let option of LIST_SECURITY_LEVEL?.options | sortArray:'name'">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="asset.type !== 'Policy'">
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Operating Model</mat-label>
                            <mat-select name="operatingModel" [(ngModel)]="asset.metadata.operatingModel" [disabled]="isReadonly" required>
                                <mat-option [value]="option.value" *ngFor="let option of LIST_OPERATING_MODEL?.options | sortArray:'name'">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Operation Status</mat-label>
                            <mat-select name="operationStatus" [(ngModel)]="asset.status" required
                                #operationStatus
                                [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                                [showAsDisabled]='true'
                                [matElement]='operationStatus' 
                                [disabled]="isReadonly">
                                <mat-option [value]="option.value" *ngFor="let option of LIST_OPERATION_STATUS?.options | sortArray:'name'">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Security eAuth Level</mat-label>
                            <mat-select name="securityEauthLevel" [(ngModel)]="asset.metadata.securityEauthLevel">
                                <mat-option value="1">Low</mat-option>
                                <mat-option value="2">Moderate</mat-option>
                                <mat-option value="3">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Identity Assurance Level</mat-label>
                            <mat-select name="identityAssuranceLevel" [(ngModel)]="asset.metadata.identityAssuranceLevel">
                                <mat-option value="1">Low</mat-option>
                                <mat-option value="2">Moderate</mat-option>
                                <mat-option value="3">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Authenticator Assurance Level</mat-label>
                            <mat-select name="authenticatorAssuranceLevel" [(ngModel)]="asset.metadata.authenticatorAssuranceLevel">
                                <mat-option value="1">Low</mat-option>
                                <mat-option value="2">Moderate</mat-option>
                                <mat-option value="3">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Federation Assurance Level</mat-label>
                            <mat-select name="federationAssuranceLevel" [(ngModel)]="asset.metadata.federationAssuranceLevel">
                                <mat-option value="1">Low</mat-option>
                                <mat-option value="2">Moderate</mat-option>
                                <mat-option value="3">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Cloud Service Model</mat-label>
                            <mat-select name="cloudServiceModel" [(ngModel)]="asset.metadata.cloudServiceModel">
                                <mat-option value="iaas">IaaS</mat-option>
                                <mat-option value="paas">PaaS</mat-option>
                                <mat-option value="saas">SaaS</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item" *ngIf="asset.metadata.cloudServiceModel === 'other'">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Cloud Service Model Details</mat-label>
                            <input name="cloudDeploymentModelDetails" #name matInput type="text" [(ngModel)]="this.asset.metadata.cloudServiceModelDetails"
                                [disabled]="isReadonly"
                                maxLength="100">
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Cloud Deployment Model</mat-label>
                            <mat-select name="cloudDeploymentModel" [(ngModel)]="asset.metadata.cloudDeploymentModel">
                                <mat-option value="hybrid-cloud">Hybrid</mat-option>
                                <mat-option value="public-cloud">Public Cloud</mat-option>
                                <mat-option value="private-cloud">Private Cloud</mat-option>
                                <mat-option value="government-only-cloud">U.S. Government Only</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item" *ngIf="asset.metadata.cloudDeploymentModel === 'hybrid-cloud'">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Cloud Deployment Model Details</mat-label>
                            <input name="cloudDeploymentModelDetails" #name matInput type="text" [(ngModel)]="this.asset.metadata.cloudDeploymentModelDetails"
                                [disabled]="isReadonly"
                                maxLength="100">
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Authorization Type</mat-label>
                            <mat-select name="authorizationType" [(ngModel)]="asset.metadata.authorizationType">
                                <mat-option value="fedramp-agency">FedRAMP Agency ATO</mat-option>
                                <mat-option value="fedramp-jab">FedRAMP JAB P-ATO</mat-option>
                                <mat-option value="fedramp-li-saas">FedRAMP Tailored for LI-SaaS</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> 
                -->
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- 
    <mat-accordion>
        <mat-expansion-panel class="mb-4" #overlays [expanded]="false" hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>OVERLAYS</h4>
                </mat-panel-title>
                <mat-icon class="accent-color">{{overlays.expanded?'expand_less':'expand_more'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="pt-2">
                <div class=" pl-1">
                    <mat-checkbox name="isHighValue" 
                        [(ngModel)]="this.asset.metadata.isHighValue"
                        [checked]="this.asset.metadata.isHighValue"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                        >Is High Value Asset?</mat-checkbox>
                </div>
                <div class=" pl-1">
                    <mat-checkbox 
                        name="isFedramp" 
                        [(ngModel)]="this.asset.metadata.isFedramp" 
                        [checked]="this.asset.metadata.isFedramp" 
                        [disabled]="isReadonly"
                        >Is FedRAMP Overlay?</mat-checkbox>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion> 
    -->
    <!-- 
    <mat-accordion>
        <mat-expansion-panel class="mb-4" #attestations [expanded]="false" hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>SELF ATTESTATIONS</h4>
                </mat-panel-title>
                <mat-icon class="accent-color">{{attestations.expanded?'expand_less':'expand_more'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="pt-2">
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isMFA"
                        [(ngModel)]="this.asset.metadata.isMFA"
                        [checked]="this.asset.metadata.isMFA"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Multi-Factor Authentication (MFA)?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset’s compliance with Multi-factor Authentication (MFA).'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isDAR"
                        [(ngModel)]="this.asset.metadata.isDAR"
                        [checked]="this.asset.metadata.isDAR"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Data At Rest (DAR)?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset’s compliance with Data at Rest (DAR).'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isDIT"
                        [(ngModel)]="this.asset.metadata.isDIT"
                        [checked]="this.asset.metadata.isDIT"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Data In Transit (DIT)?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset’s compliance with Data in Transit (DIT).'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isCoop"
                        [(ngModel)]="this.asset.metadata.isCoop"
                        [checked]="this.asset.metadata.isCoop"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Continuity of Operations Planning (COOP)?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Continuity of Operations Planning (COOP) is the effort within individual agencies to ensure they can continue to perform their mission essential functions during a wide range of emergencies.'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isFinancialManagement"
                        [(ngModel)]="asset.metadata.isFinancialManagement"
                        [checked]="asset.metadata.isFinancialManagement"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Financial Management System?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset is a finiancial management system.'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isInternalFacing"
                        [(ngModel)]="asset.metadata.isInternalFacing"
                        [checked]="asset.metadata.isInternalFacing"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is Internal Facing System?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset is an internal facing system.'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
                <div class=" pl-1 d-flex align-items-center">
                    <mat-checkbox name="isExternalFacing"
                        [(ngModel)]="asset.metadata.isExternalFacing"
                        [checked]="asset.metadata.isExternalFacing"
                        [componentIf]="{val: config.routes.PRIVATE_ASSET_EDIT_ASSET_EDIT_ADMIN, asset: asset}" 
                        [showAsDisabled]='true' 
                        [disabled]="isReadonly"
                    >Is External Facing System?</mat-checkbox>
                    <mat-icon class="ml-1"
                        matTooltip='Reports the asset is an external facing system.'
                        matTooltipPosition="right">
                        info_outline
                    </mat-icon>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    -->
    <!-- 
    <mat-accordion>
        <mat-expansion-panel class="mb-4" #locations [expanded]="false" hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>LOCATIONS</h4>
                </mat-panel-title>
                <mat-icon class="accent-color">{{locations.expanded?'expand_less':'expand_more'}}</mat-icon>
            </mat-expansion-panel-header>
            <div class="items pt-3">
                <div class="item">                            
                    <mat-form-field class="w-100" appearance="outline" >
                        <mat-label>Location</mat-label>
                        <mat-select name="location" placeholder="Where is the asset hosted?" [(ngModel)]="this.asset.location" [disabled]="isReadonly" required>
                            <mat-option *ngFor="let item of LIST_LOCATIONS?.options" [value]="item.value">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item" *ngIf="(this.asset.location === 'Cloud' || this.asset.location === 'Hybrid')">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Hosting Environment</mat-label>
                        <input name="hostingEnvironment" matInput type="text" [(ngModel)]="this.asset.metadata.hostingEnvironment" [disabled]="isReadonly" [placeholder]="'Describe your hosting environment here.'">
                    </mat-form-field>
                </div>
                <div class="item location-container" *ngIf="(this.asset.location === 'On Prem' || this.asset.location === 'Hybrid')">
                    <div class="row" >
                        <div class="col d-flex align-items-center">
                            <h5>PHYSICAL LOCATIONS</h5>
                        </div>
                        <div class="right nopadding" *ngIf="!isReadonly">
                            <button mat-button type="button" color="accent" (click)="openDialog(createLocationDialog, {})">+ Add Location</button>   
                        </div>                  
                    </div>
                    <div class="row py-3" *ngFor="let location of asset.metadata.systemLocations; let i = index">
                        <div class="col">
                            <div class="pb-1">
                                <b>{{ location.name }}</b>
                            </div>
                            <div class="pb-1">
                                <p>{{ location.description }}</p>
                            </div>
                            <div>
                                <p>{{ location.address }} , {{ location.city }} , {{ location.state }} {{ location.zip }}, {{ location.country }}</p>
                            </div>
                            <div>
                                <p>Category: {{ location.category }} , Weight: {{ location.weight }}</p>
                            </div>
                        </div>
                        <div class="col-1" style="border-left: 1px solid #CCC" *ngIf="!isReadonly">
                            <button mat-icon-button type="button" (click)="openDialog(editLocationDialog, location)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button type="button" (click)="deleteLocation(i)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </div>      
                </div>
                <div class="item location-container">
                    <div class="row" >
                        <div class="col d-flex align-items-center">
                            <h5>SUBNETS</h5>
                        </div>
                        <div class="col d-flex flex-row-reverse align-items-center" *ngIf="!isReadonly">
                            <button mat-button type="button" color="accent" (click)="openDialog(createSubnetDialog, {})">+ Add Subnet</button>   
                        </div>                  
                    </div>
                    <div class="row py-3" *ngFor="let subnet of asset.metadata.systemSubnets; let i = index">
                        <div class="col">
                            <div class="pb-1">
                                <b>{{ subnet.name }}</b>
                            </div>
                            <div class="pb-1">
                                <p>{{ subnet.description }}</p>
                            </div>
                            <div>
                                <p>VPC: {{ subnet.vpc || 'N/A' }}, IPv4: {{ subnet.ipv4 || 'N/A'}}, IPv6: {{subnet.ipv6 || 'N/A'}}</p>
                            </div>
                        </div>
                        <div class="col-1" style="border-left: 1px solid #CCC" *ngIf="!isReadonly">
                            <button mat-icon-button type="button" (click)="openDialog(editSubnetDialog, subnet)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button type="button" (click)="deleteSubnet(i)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </div>      
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    -->
</div>

<!-- DIALOGS -->

<!-- 
<ng-template #createLocationDialog let-location>
    <dialog width="small">
        <div title>ADD LOCATION</div>
        <div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input name="name_" matInput [(ngModel)]="location.name" maxlength="40">
                    </mat-form-field>
                    <mat-error *ngIf="location.name?.length === 40">You have reached the maximum number of characters</mat-error>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">
                    <mat-form-field class="w-100 textarea-label" appearance="outline">
                        <mat-label>Description</mat-label>                        
                        <textarea 
                            class="textarea-150"
                            name="description_"
                            cdkTextareaAutosize
                            matInput                                      
                            [placeholder]="'Enter your location description here.'"
                            [(ngModel)]="location.description">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Category</mat-label>
                        <input name="category_" matInput [(ngModel)]="location.category">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Weight</mat-label>
                        <input name="weight_" matInput [(ngModel)]="location.weight">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input name="address_" matInput [(ngModel)]="location.address">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>City</mat-label>
                        <input name="city_" matInput [(ngModel)]="location.city"> 
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>State</mat-label>
                        <input name="state_" matInput [(ngModel)]="location.state">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Zipcode</mat-label>
                        <input name="zipcode_" matInput [(ngModel)]="location.zip">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">                
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input name="country_" matInput [(ngModel)]="location.country"> 
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" actions>
            <button mat-stroked-button color="accent" class="mr-3" (click)="closeDialogs()">Close</button>
            <button mat-flat-button color="accent" (click)="addLocation(location)">Save</button>
        </div>
    </dialog>
</ng-template>

<ng-template #createSubnetDialog let-subnet>
    <form #testForm="ngForm">
        <dialog width="small">
            <div title>ADD SUBNET</div>
            <div>
                <div class="row pb-3">
                    <div class="col">                  
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input name="name_" matInput [(ngModel)]="subnet.name" maxlength="40">
                        </mat-form-field>
                        <mat-error *ngIf="subnet.name?.length === 40">You have reached the maximum number of characters</mat-error>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col">
                        <mat-form-field class="w-100 textarea-label" appearance="outline">
                            <mat-label>Description</mat-label>                        
                            <textarea 
                                class="textarea-150"
                                name="description_"
                                cdkTextareaAutosize
                                matInput                                      
                                [placeholder]="'Enter your subnet description here.'"
                                [(ngModel)]="subnet.description">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>VPC</mat-label>
                            <input name="vpc_" matInput [(ngModel)]="subnet.vpc">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>IPv4</mat-label>
                            <input name="ipv4_" matInput [(ngModel)]="subnet.ipv4" [pattern]="REGEX.ipAddress" (keydown)="utility.checkIPV4Input($event)" (paste)="utility.checkIPV4Paste($event)">
                        </mat-form-field>
                        <mat-error *ngIf="testForm.control.get('ipv4_')?.invalid">Must be a valid IPv4 address</mat-error>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>IPv6</mat-label>
                            <input name="ipv6_" matInput [(ngModel)]="subnet.ipv6" [pattern]="REGEX.ipAddress" (keydown)="utility.checkIPV6Input($event)" (paste)="utility.checkIPV6Paste($event)">
                        </mat-form-field>
                        <mat-error *ngIf="testForm.control.get('ipv6_')?.invalid">Must be a valid IPv6 address</mat-error>
                    </div>
                </div>
            </div>
            <div class="row" actions>
                <button mat-stroked-button color="accent" class="mr-3" (click)="closeDialogs()">Close</button>
                <button mat-flat-button color="accent" (click)="addSubnet(subnet)" [disabled]="!testForm.control.valid">Save</button>
            </div>
        </dialog>
    </form>
</ng-template>

<ng-template #editLocationDialog let-location>
    <dialog width="small">
        <div title>EDIT LOCATION</div>
        <div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input name="name_" matInput [(ngModel)]="location.name" maxlength="40">
                    </mat-form-field>
                    <mat-error *ngIf="location.name.length === 40">You have reached the maximum number of characters</mat-error>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">
                    <mat-form-field class="w-100 textarea-label" appearance="outline">
                        <mat-label>Description</mat-label>                        
                        <textarea 
                            class="textarea-150"
                            name="description_"
                            cdkTextareaAutosize
                            matInput                                      
                            [placeholder]="'Enter your location description here.'"
                            [(ngModel)]="location.description">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Category</mat-label>
                        <input name="category_" matInput [(ngModel)]="location.category">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Weight</mat-label>
                        <input name="weight_" matInput [(ngModel)]="location.weight">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input name="address_" matInput [(ngModel)]="location.address">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>City</mat-label>
                        <input name="city_" matInput [(ngModel)]="location.city"> 
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>State</mat-label>
                        <input name="state_" matInput [(ngModel)]="location.state">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Zipcode</mat-label>
                        <input name="zipcode_" matInput [(ngModel)]="location.zip">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">                
                <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input name="country_" matInput [(ngModel)]="location.country"> 
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" actions>
            <button mat-stroked-button color="accent" class="mr-3" (click)="closeDialogs()">Close</button>
        </div>
    </dialog>
</ng-template>

<ng-template #editSubnetDialog let-subnet>
    <dialog width="small">
        <div title>EDIT SUBNET</div>
        <div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input name="name_" matInput [(ngModel)]="subnet.name" maxlength="40">
                    </mat-form-field>
                    <mat-error *ngIf="subnet.name.length === 40">You have reached the maximum number of characters</mat-error>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">
                    <mat-form-field class="w-100 textarea-label" appearance="outline">
                        <mat-label>Description</mat-label>                        
                        <textarea 
                            class="textarea-150"
                            name="description_"
                            cdkTextareaAutosize
                            matInput                                      
                            [placeholder]="'Enter your location description here.'"
                            [(ngModel)]="subnet.description">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>VPC</mat-label>
                        <input name="vpc_" matInput [(ngModel)]="subnet.vpc">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>IPv4</mat-label>
                        <input name="ipv4_" matInput [(ngModel)]="subnet.ipv4">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col">                  
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>IPv6</mat-label>
                        <input name="ipv6_" matInput [(ngModel)]="subnet.ipv6">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" actions>
            <button mat-stroked-button color="accent" class="mr-3" (click)="closeDialogs()">Close</button>
        </div>
    </dialog>
</ng-template> 
-->