import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { RoleGuard } from '@src/shared/guards/role-guard.guard';

@Directive({
  selector: '[routerLinkIf]'
})
export class RouterLinkIfDirective {

    /* ATTRIBUTES */

    route = '';

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private router: Router,
        private roleGuard: RoleGuard,
        private renderer: Renderer2) { 

    }

    /* METHODS */


    /**
     * Uses RoleGuard to check if the route is allowed. If it is not allowed the element
     * will be removed from the DOM similar to *ngIf. If it is allowed it will add a
     * mouseup listener that navigates to the route link similar to routerLink directive.
     * @param val route for the router link
     *  if an array is provided, the first route with access will be navigated to
     *
     * Examples:
     * Assume user has access to /a, /b, but not /c
     *
     * <div [routerLinkIf]="'/a'"></div>
     * // When clicked will route user to /a
     *
     * <div [routerLinkIf]="['/a', '/b']"></div>
     * // When clicked will route user to /a
     *
     * <div [routerLinkIf]="['/c', '/b']"></div>
     * // When clicked will route user to /b
     */
    @Input() set routerLinkIf(val: string | (string | boolean)[]) {

      // const isArray = Array.isArray || function (arr: any): boolean {
      //   return Object.prototype.toString.call( arr ) === '[object Array]';
      // };
      // /* If the input is an array pick the first item that has true components
      //    hide the element if all items in the list are false */
      // if (isArray(val)) {
      //   const _val = val as (string | boolean)[];
      //   const bools: boolean[] = _val.filter(item => typeof item === 'boolean' || item == null) as boolean[];
      //   const strings: string[] = _val.filter(item => typeof item !== 'boolean' && item != null) as string[];
      //   this.roleGuard.checkRoutes(strings).then((results: boolean[]) => {
      //     results = [...results, ...bools];
      //     if (results && results.some(result => result)) {
      //       this.route = strings.find((route, index) => results[index]);
      //       this.viewContainer.clear();
      //       const view = this.viewContainer.createEmbeddedView(this.templateRef);
      //       const el = view.rootNodes[0];
      //       if (el) el.tabIndex = 0;
      //       if (el.tagName === 'A' && el.href === '') el.href = 'javascript:void(0)';
      //       this.renderer.listen(el, 'mouseup', this.onClick.bind(this));
      //       this.renderer.listen(el, 'keyup', this.onClick.bind(this));
      //     } 
      //     else {
      //       this.viewContainer.clear();
      //     }
      //   });
      // /* If the input is NOT an array hide/show the element if the user has access */
      // } 
      // else {
      //   val = val as string;
      //   this.route = val;
      //   this.roleGuard.checkRoute(val).then(result => {
      //     if (result) {
      //       this.viewContainer.clear();
      //       const view = this.viewContainer.createEmbeddedView(this.templateRef);
      //       const el = view.rootNodes[0];
      //       if (el) el.tabIndex = 0;
      //       if (el.tagName === 'A' && el.href === '') el.href = 'javascript:void(0)';
      //       this.renderer.listen(el, 'mouseup', this.onClick.bind(this));
      //       this.renderer.listen(el, 'keyup', this.onClick.bind(this));
      //     } else {
      //       this.viewContainer.clear();
      //     }
      //   });
      // }
    }

    /**
     * uses router to navigate to route when called
     */
    onClick(event?) {
      if (event.code && event.code !== 'Space' && event.code !== 'Enter') return;
      if (this.route) this.router.navigateByUrl(this.route);
    }

}
