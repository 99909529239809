import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-phase-status',
  templateUrl: './phase-status.component.html',
  styleUrls: ['./phase-status.component.css']
})
export class PhaseStatusComponent implements OnChanges {
  @Input() phase;
  @Input() phaseIndex;
  @Input() phaseProgress;
  @Input() process;
  @Input() allCatalogs;
  @Input() asset;

  currentControlPackage;
  currentClassification;

  /* CONSTRUCTOR */

  constructor() { }

  /* EVENT HANDLING */

  /**
   * On Changes
   */
  ngOnChanges() {
    if (this.asset) this.currentClassification = this.returnClassificationString(this.asset.classification);
    this.getControlPackage();
  }

  /* METHODS */

 /**
  * Gets the control package for the asset
  * @returns
  */
  async getControlPackage() {
    if (!this.process || !this.allCatalogs) { return false; }
    this.process.phases.forEach(phase => {
          phase.steps.forEach(step => {
              if (step.selectedControl) {
                  if (this.allCatalogs) {
                      this.currentControlPackage = this.allCatalogs.find(cat => cat.pkId === step.selectedControl);
                  }
              }
          });
    });
  }

  /**
   * converts classification number to readable string value
   * @param classification
   * @returns 
   */
  returnClassificationString(classification) {
    switch (classification) {
      case 0:
        return 'High';
      case 1:
        return 'Moderate';
      case 2:
        return 'Low';
      default:
        return false;
    }
  }

}
