import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'us-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.less']
})
export class StepperStepComponent implements OnInit {
  @Input('selected') selected = false;
  @Input('disabled') set setDisabled(disabled) {
    this.disabled = !!disabled;
  }
  @Input('complete') complete = false;

  last = false;
  disabled = false;
  horizontal = false;

  constructor() { }

  ngOnInit() {
  }

}