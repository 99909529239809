import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

import { Asset } from '@src/shared/objects/asset';
import { Process } from '@src/shared/objects/process';

import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService extends AbstractService {

    //#region ASSET  
    

    /**
     * creates an asset record
     */
    public createAsset(asset: Asset, process?: Process): Observable<Asset> {
        return this.post('/asset/create', { asset, process: { name: process?.name, pkId: process?.pkId } }).pipe(
            map((response) => {
                return new Asset(response);
            })
        );
    }

    /**
     * Create an object in the array within the asset. The array is a 
     * string indicating the name of the array such as 'technology.components',
     * 'business.contingency', or 'data'.
     */
    public createItemInArray (asset: Asset, field: string, item: any) {
        return this.post('/asset/createItemInArray', { pkId : asset.pkId, field, item });
    }

    /**
    * Remove the asset from the system.
    */
    public deleteAsset(pkId: string): Observable<{ pkId: string; success: boolean }> {
        return this.post('/asset/delete', { pkId });
    }
    
    /**
     * Delete an object in the array within the asset. The array is a 
     * string indicating the name of the array such as 'technology.components',
     * 'business.contingency', or 'data'.
     */
    public deleteItemInArray (asset: Asset, field: string, item: any) {
        return this.post('/asset/deleteItemInArray', { pkId : asset.pkId, field, item });
    }

    /**
    * Get the asset by id.
    */
    public getAsset(pkId: string): Observable<Asset> {
      const observable = this.post('/asset/get', { pkId }).pipe(
        map((response) => {
          return new Asset(response);
        })
      );
      return observable;
    }

    /**
     * Get the index of all assets in the organization.
     */
    public indexAssets(orgPkId?: string): Observable<Asset[]> {
        return this.post('/asset/index', { orgPkId }).pipe(
            map((response) => response.map((asset) => new Asset(asset)))
        );
    }

    /**
     * Get the index of all children of a particular asset.
     */
    public indexChildrenAssets(organizationPkId: string, assetPkId): Observable<Asset[]> {
        return this.post('/asset/index/children', { organizationPkId, assetPkId }).pipe(
            map((response) => response.map((asset) => new Asset(asset)))
        );
    }

    /**
     * Get the index of all assets the user can access.
     */
    public indexUserAssets(organizationPkId: string): Observable<Asset[]> {
        return this.post('/asset/index/user', { organizationPkId });
    }

    /**
     * Get a list of all the assets that the user has access to.
     */
    public listAssets(orgPkId?: string): Observable<Asset[]> {
        return this.post('/asset/list', { orgPkId }).pipe(
            map((response) => response.map((asset) => new Asset(asset)))
        );
    }

    /**
     * Get the list of assets by type that the user has access to.
     */
    public listAssetsByType(objectType: string) {
        return this.post('/asset/listByType', { objectType }).pipe(
            map((response) => response.map((asset) => new Asset(asset)))
        );
    }

    /**
     * Get a list of all the assets that the user has access to and calculate SDLC
     */
    public listAssetsWithCalculation(orgPkId?: string): Observable<Asset[]> {
        return this.post('/asset/listWithCalculation', { orgPkId }).pipe(
            map((response) => response.map((asset) => new Asset(asset)))
        );
    }

    /**
     * Syncrhonize the asset's process data, analytics data, questionnaires, as well as clean
     * up data.
     */
    public synchronzieAsset(asset, isAnalytics = true, isAsset = true, isAssetStructure = true, isControls = true,
        isInterconnections = true, isProcess = true, isRelationships = true, isTests = true) {
        return this.post('/asset/synchronize', { pkId: asset.pkId, isAnalytics, isAsset, isAssetStructure, isControls,
            isInterconnections, isProcess, isRelationships, isTests });
    }

    /**
     * Syncrhonize the asset's controls with those in the organization's
     * configurations. The controls from the configurations are copied into
     * the asset, overwritting the static data.
     */
     public synchronizeAllAssets(organizationPkId: string, isAnalytics, isAsset, isAssetStructure, isControls, isInterconnections,
        isProcess, isRelationships, isTests) {
        return this.post('/asset/synchronizeAll', { organizationPkId: organizationPkId, isAnalytics, isAsset, isAssetStructure, isControls, isInterconnections,
            isProcess, isRelationships, isTests });
    }

    /**
     * Save the asset's core information, which includes all the metadata
     * except for the arrays. Only provide the meta data that has changed
     * in order to overwrite other users make changes to the asset at the
     * same time.
     */
    public updateAsset(pkId: string, asset: any): Observable<any> {
        return this.post('/asset/update', { pkId, asset });
    }
    
    /**
     * UPdate an object in the array within the asset. The array is a 
     * string indicating the name of the array such as 'technology.components',
     * 'business.contingency', or 'data'.
     */
    public updateItemInArray (asset: Asset, field: string, item: any) {
        return this.post('/asset/updateItemInArray', { pkId : asset.pkId, field, item });
    }

    //#endregion

    //#region ASSET.DATA

    /**
     * Save the asset's data categorization.
     */
    public updateData(pkId: string, data: any): Observable<any> {
        return this.post('/asset/data/update', { pkId, data });
    }

    //#endregion

    //#region ASSET.PROCESSES

    /**
     * Select the process and add the process and controls to the asset.
     */
    public selectProcess (asset: Asset, process: Process) {        
        return this.post('/asset/process/select', { asset, process });
    }

    /**
     * Unselect the process and remove the process and controls from the 
     * asset.
     */
    public unselectProcess (asset: Asset, process: Process) {        
        return this.post('/asset/process/unselect', { asset, process });
    }

    //#endregion


}
