
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less']
})
export class SearchBarComponent {

  @ViewChild('si') searchElement: ElementRef<HTMLInputElement>;
  @Input() placeholderText = '';
  @Input() searchTerm: (string | number) = '';
  @Input() set data(value: (string | number)) {
    this.searchTerm = value;
    // reset #si input back to this.searchTerm value if this.searchTerm is updated by caller
    // This allows developing in USAI app to reset/change the value of #si input
    if (this.searchElement) {
      const v = this.searchElement.nativeElement.value;
      if (v !== this.searchTerm) {
        this.searchElement.nativeElement.value = this.searchTerm.toString();
      }
    }

  }
  @Input() backgroundColor = '';

  @Input() showLogo = false;

  @Output() keyboardEvent = new EventEmitter<KeyboardEvent>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() buttonEvent = new EventEmitter<string>();

  constructor() { }

  onKeyup($event: KeyboardEvent): void {
    this.keyboardEvent.emit($event);
  }

  onSearchClick(inputValue: string): void {
    this.buttonEvent.emit(inputValue);
  }
}
