import { Pipe, PipeTransform } from '@angular/core';
import { Utility } from '../objects/utility';
/**
 * Takes in a string.
 * Returns string with no HTML formatting
 */
@Pipe({
  name: 'stripHTML'
})
export class StripHTMLPipe implements PipeTransform {

  transform(value: string): any {
    return Utility.stripHTML(value);
  }

}
