
import { Cache } from './cache';

export class ChartStyler {

    /* ATTRIBUTES */

    private static allowedThemes = ['light',  'dark'];

    private static themes = {
        dark: {
            color: {
                bar01: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#E0E2E7'],
                            [1, 'rgba(224, 226, 231, 0.4)']
                        ],
                },
                bar02: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#4ACBDC'],
                            [1, '#4A518F']
                        ],
                },
                bar03: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#FF7171'],
                            [1, '#FF7171']
                        ],
                },
                border01: '#dadada',
                label01: '#dadada',
                line01: '#E0E2E7', // white / grey 
                line02: '#4ACBDC', //  mat-secondary-color (aqua/light blue)
                gridLine: '#dadada1a', // mat-default-text-color, 10% opacity
                gaugeTrack01: '#3F5A7D',
                pieSlices01: [
                    { // redish orange
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#FFEDED '],[1, '#FF7171'] ],
                    },
                    { // black gradient
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#515E97'],[1, '#AFB8E'] ],
                    },
                    { // light blue
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#189CAE'],[1, '#77D8E5'] ],
                    },
                    { // light purple
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#BBB0F7'],[1, '#4D5572'] ],
                    },
                    { // black gradient
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '2E3B6B'],[1, '#4D5572'] ],
                    }, 
                    { // greyish purple
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#AFB8E1'],[1, '#B3B6C3'] ],
                    },
                    
                ],
            },
            gradients: {
                gradient01: { radialGradient: { cx: 0, cy: 0, r: 1, }, stops: [[0, 'rgba(0, 0, 0, 0.5)'], [1, 'rgba(0, 0, 0, 0.0)']] },
                gradient02: { radialGradient: { cx: .5, cy: 0.1, r: 1, }, stops: [[0, 'rgba(0, 0, 0, 0.5)'], [1, 'rgba(0, 0, 0, 0.0)']] },
            },
            stops: {
                gaugeLt50: [[0, '#FF7171'], [100, '#FD0000']],
                gaugeLt75: [[0, '#FFBF75'], [100, '#e3740b']],
                gaugeGt75: [[0, '#7C63FF'], [100, '#99DEEB'], ],
            },
            tooltip: {
                background: 'red',
                borderColor: 'green'
            },
        },

        light: {
            color: {
                bar01: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#E0E2E7'],
                            [1, '#E0E2E7']
                        ],
                },
                bar02: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#24A9BA'],
                            [1, '#9AE2EC']
                        ],
                },
                bar03: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: 
                        [
                            [0, '#FF7171'],
                            [1, '#FF7171']
                        ],
                },
                label01: 'black',
                line01: '#263054',
                line02: '#24A9BA',
                gridLine: '#dadada', // mat-default-text-color, 10% opacity
                gaugeTrack01: '#EEEEEE',
                border01: 'black',
                pieSlices01: [
                     '#3F5A7D',// redish orange
                        '#FF7171',
                    { // light blue
                        linearGradient: {x1: 0, x2: 0, y1: 0,y2: 1},
                        stops: [ [0, '#189CAE'],[1, '#77D8E5'] ],
                    },
                    '#7962F7', // light purple
                    '#000C36',// black gradient
                    '#77D8E5', // blue
                    
                ],
            },
            gradients: {
                gradient01: { radialGradient: { cx: 0.0, cy: 0.0, r: .9, }, stops: [[0, 'rgba(100, 100, 100, .4)'], [1, 'rgba(255, 255, 255, 0.1)']] },
                gradient02: { radialGradient: { cx: 0.5, cy: 0.5, r: .5, }, stops: [[0, 'rgba(100, 100, 100, 0.8)'], [1, 'rgba(100, 100, 100, 0.2)']] },
            },
            stops: {
                gaugeLt50: [[0, '#AE0000'], [100, '#FD0000']],
                gaugeLt75: [[0, '#DE7805'], [100, '#FFBF75']],
                gaugeGt75: [[0, '#11818F'], [100, '#9AE2EC']]
            },
        },
    }


    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor() {

    }

    /* METHODS */

    public static get(): any {
        const cache: Cache = Cache.get();
        const user = cache.getValue(Cache.KEYS.USER);
        const preference = user?.preference;
        const theme = this.allowedThemes.includes(preference?.theme) ? preference?.theme : 'light';
        return this.themes[theme];
    }

}
