import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.less']
})
export class ImageViewerComponent implements OnInit {

    @Input() image: string; // base64 image string or svg
    @Input() caption: string;
    @Input() background: string;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(private sanitizer: DomSanitizer) { 
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit(): void {
    }

    /* METHODS */

    /**
     * Sanitize.
     */
    public sanitize(value) {
        // return this.sanitizer.bypassSecurityTrustHtml(value);

        let html = value;
        if (!html) { return ''; }

        if (html.indexOf('class=') < 0) {
          html = html.replace('\<svg', '<svg class="svg-image"');
        }

        if (html.indexOf('preserveAspectRatio=') < 0) {
          html = html.replace('\<svg', '<svg preserveAspectRatio="xMinYMin meet"');
        }

        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    /**
     * Check if the image is a svg.
     */
    public isImageSvg() {
        // return this.contentType === 'image/svg+xml';
        return this.image.indexOf('data:') !== 0;
    }


}
