import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'non-chip',
  templateUrl: './non-chip.component.html',
  styleUrls: ['./non-chip.component.less']
})
export class NonChipComponent implements OnInit {
  @Input() color: string = "accent";
  @Input('outlined') set setInverted(value) {
    this.outlined = value || value === '' ? true : false
  }
  @Input('light') set setLight(value) {
    this.light = value || value === '' ? true : false
  }

  outlined = false;
  light = false;

  constructor() { }

  ngOnInit(): void {
  }

}
