<div *ngIf="visible" [@navAnimation]>
    <div class="mx-0 my-4 py-4 px-5 row relative inline-alert align-content-center" [style.background]="background" [style.color]="color">
        <div class="col row align-content-center">
            <div class="col-auto" color="warn">
                <ng-content select="[icon]"></ng-content>
            </div>
            <div class="col">
                <span>
                    <ng-content></ng-content>
                </span>
            </div>
        </div>
        <div class="col-auto" *ngIf="!permanant">
            <button mat-icon-button (click)="hide()">
                <mat-icon color="warn">
                    <mat-icon class="material-icons-outlined">cancel</mat-icon>
                </mat-icon>
            </button>
        </div>
    </div>
</div>