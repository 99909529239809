import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { io } from 'socket.io-client';

import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { Channel } from '@src/shared/objects/channel';
import { Cache } from '@src/shared/objects/cache';
import { Notification } from '@src/shared/objects/notification';
// import { Organization } from '@src/shared/objects/organization';
import { Help } from '@src/shared/objects/help';
import { User } from '@src/shared/objects/user';

import { AuthenticationService } from '@src/shared/services/authentication.service';
import { ChannelService } from '@src/shared/services/channel.service';
import { MessageService } from '@src/shared/services/message.service';
import { UserService } from '@src/shared/services/user.service';
import { HelpService } from '@src/shared/services/help.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less']
})
export class ChatComponent implements OnInit {
    
    @ViewChild('maximized') maximizedTemplate: TemplateRef<any>;

    /* ATTRIBUTES */

    private socketClient; // socket client

    private cache: Cache;
    public connections: User[]; // all user's connections
    public context: any; // dynamic context used to change the change window
    public current: User; // current user
    // public organization: Organization; // current organization
    public users: User[]; // @ / ping system

    public help: Help = new Help(); // must be created here and injected into the help request component
    public selectedChannel: Channel; // selected channel for the menu
    public userInput; // text value of the input box

    public title =''; // for help request
    public description =''; // for help request
    public isOpen = false; // whether the chat is open
    public isMenuOpen = false;
    public isFullScreen = false; // whether the chat is maximized

    public scrollToSubject: Subject<string> = new Subject<string>();

    // Close the menu upon clicking outside of the component.
    @HostListener("document:click", ['$event'])
    clickOut (event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isMenuOpen = false;
        }
    };

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        private eRef: ElementRef,

        private authenticationService: AuthenticationService,
        public channelService: ChannelService, // public for access from html
        private helpService: HelpService,
        public messageService: MessageService, // public for access from html
        private userService: UserService) {

    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public async ngOnInit() {
        this.cache = Cache.get();
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.context = this.cache.getValue(Cache.KEYS.CONTEXT);

        // Listen for changes to the user object in cache and reset the properties in
        // the chat window. The context is used to change the chat reference so that the
        // chat is always associated with whatever is being displayed.

        Cache.changeObservable.subscribe(res => {
            if (res.key === 'context') {
                this.context = res.value;
                // console.log("/main/chat/ngOnInit: CONTEXT = ", this.context);
            }
            if (res.key === 'user') {
                this.current = res.value;
            }
        });

        //#region CONNECTIONS

        // Get all of the user's connections such as people in the same
        // organization, communication channel, and private chats. Note, the
        // code below returns all users in the system so we must only return
        // what is required.


        this.userService.connections().subscribe(res => {
            this.connections = res;
            // console.log("/private/communications/chat/ngOnInit: CONNECTIONS = ", this.connections);
        });

        //#endregion

        //#region MESSAGES

        // When the user clicks on the notification in the header, the system
        // emits an event with the channel and message pkids. The chat component
        // listens to the event is automatically opens and scrolls to the
        // message in the window.

        this.messageService.openMessage.subscribe(async data => {

            // console.log("/main/chat/ngOnInit: OPEN MESSAGE DATA = ", data);

            const channelPkId = data.channelPkId;
            const messagePkId = data.messagePkId;

            if (channelPkId) {
                let channel: Channel;
                if (channelPkId === "global-chat") {
                    channel = this.channelService.globalChatChannel;
                    if (channel) {
                        this.isOpen = true;
                        this.changeChannel(channel);
                        await new Promise(f => setTimeout(f, 250)); // wait to ensure messages have loaded
                        this.scrollToSubject.next(data.messagePkId);
                    }
                }
                else if (channelPkId === "global-help") {
                    channel = this.channelService.globalHelpChannel;
                    if (channel) {
                        this.isOpen = true;
                        this.changeChannel(channel);
                        await new Promise(f => setTimeout(f, 250)); // wait to ensure messages have loaded
                        this.scrollToSubject.next(data.messagePkId);
                    }
                }
                else {
                    let channel_ = this.channelService.get(channelPkId).subscribe(res => {
                        if (res) {
                            channel = res.type === "Custom" ?
                                this.channelService.customChannels.find(channel => channel.pkId === channelPkId) :
                                this.channelService.chatChannels.find(channel => channel.pkId === channelPkId);

                            if (channel) {
                                this.isOpen = true;
                                this.changeChannel(channel);
                                this.scrollToSubject.next(data.messagePkId);
                            }
                        }
                    });
                }
            }
            else {

            }
        });

        this.messageService.receiveMessage.subscribe(async data => {

            const channelPkId = data.channelPkId;
            const messagePkId = data.messagePkId;

            if(channelPkId){
                if(channelPkId === this.selectedChannel.pkId){
                    this.isOpen = true;
                    await new Promise(f => setTimeout(f, 250));
                    this.scrollToSubject.next(messagePkId);
                }

            }
        });


        //#endregion

        //#region SOCKET

        this.socketClient = io (
            this.authenticationService.getComUrl(), this.authenticationService.getSocketSettings()
        );

        // CONNECT
        this.socketClient.on("connect", () => {
            // console.log("/private/communications/chat/init: CONNECTED APP.SOCKET.IO: ", this.socketClient.connected);
            this.socketClient.on("message", (data) => {
                if (typeof data !== 'object') { data = JSON.parse(data); }
                this.receive(data);
            });
        });

        // CONNECTION ERROR
        this.socketClient.on("connect_error", async(err) => {
            // console.log("/private/communications/chat/init: CONNECTION ERROR: ", err);
            this.socketClient.close();
            await new Promise(resolve => setTimeout(resolve, 10000)); // wait 10 seconds before reconnecting
            this.socketClient.connect();
        });

        // CONNECTION TIMEOUT
        this.socketClient.on("/private/communications/chat/init: connect_timeout", (err) => {
           // console.log("CONNECTION TIMEOUT: ", err);
        });

        // DISCONNECT
        this.socketClient.on("disconnect", () => {
            // console.log("/private/communications/chat/init: DISCONNECTED: ", this.socketClient.connected);
        });

        //#endregion

    }

    /* METHODS */

    //#region PUBLIC METHODS

    /**
     * close chat box.
     */
    public close() {
        this.dialog.closeAll();
        this.isOpen = false;
        this.isMenuOpen = false;
        this.isFullScreen = false;
    }

    /**
     * Change channel and get all the messages for the selected channel.
     */
    public changeChannel(channel: Channel) {

        // CHANNEL
        if (channel) {
            // Get the list of users who are authorized to participalte in
            // the channel. If the user has been invited to a channel, but has
            // not accepted, then exclude the user from the list.

            if (channel.members?.length) {
                const member = channel.members.find(member => member.userPkId === this.current.pkId);
                this.users = this.connections.filter(user => channel.members.find(member => (user.pkId === member.userPkId && member.status === 'Accepted' || member.status === 'Approved')));
            }
            else if (channel.organizationPkId || channel.applicationPkId) {
                this.users = this.filterUsers(this.connections, channel.organizationPkId, channel.applicationPkId);
            }
            else if (channel.pkId === 'global-chat' || channel.pkId === 'global-help') {
                this.users = this.connections;
            }
            else {
                this.users = [];
            }

            // Set the selected channel, get the chat messages, and save the
            // messages in-memory cache. The cache is used to refresh the component
            // with new messages dynamically.

            this.selectedChannel = channel;
            this.messageService.getMessagesByChannel(this.selectedChannel).subscribe(res => {
                if (res) {
                    this.messageService.messages[this.selectedChannel.pkId] = [];
                    this.messageService.messages[this.selectedChannel.pkId] = [...this.messageService.messages[this.selectedChannel.pkId], ...res];
                    this.scrollToSubject.next('');
                }
            });
        }
        // CONTEXT
        else {
            this.selectedChannel = null;
            if (this.context) {                
                this.messageService.getMessagesByContext(this.context).subscribe(res => {
                    if (res) {
                        this.messageService.messages[this.context.reference.pkId] = [];
                        this.messageService.messages[this.context.reference.pkId] = [...this.messageService.messages[this.context.reference.pkId], ...res];
                        this.scrollToSubject.next('');
                    }
                });
            }
        }
    }

    /**
     * Filter Users by organization and/or application
     */
    public filterUsers(connections: User[], organizationPkId:string, applicationPkId?:string) {
        return connections.filter(user => {
            return (organizationPkId ? (organizationPkId === 'All' ? true : user.memberOf.find(member => member.organizationPkId === organizationPkId)) : true) &&
                   (applicationPkId ? (applicationPkId === 'All' ? true : user.applications.find(app => app.applicationPkId === applicationPkId)) : true);
        });
    }

    /**
     * Opens the chat in a full screen dialog box.
     */
    public maximize() {
        this.isFullScreen = true;
        let properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: {selectedChannel: this.selectedChannel, users: this.users}};
        let dialog_ = this.dialog.open(this.maximizedTemplate, properties);
    }

    /**
     * Minimize full screen chat.
     */
    public minimize() {
        this.dialog.closeAll();
        this.isFullScreen = false;
    }

    /**
     * Open chat box.
     */
    public open() {    
        // this.context = this.cache.getValue(Cache.KEYS.CONTEXT); 
        console.log("/private/chat/ngOnInit: CONTEXT = ", this.context);        
        this.isOpen = true;           
    }

    /**
     * Open chat menu.
     */
    public openMenu() {        
        // this.context = this.cache.getValue(Cache.KEYS.CONTEXT); 
        console.log("/private/chat/ngOnInit: CONTEXT = ", this.context);
        this.isMenuOpen=true; 
    }

    /**
     * Open request details.
     */
    public redirect(selectedChannel) {
        if (selectedChannel) {
            this.dialog.closeAll();
            const reference = selectedChannel.reference;
            if (reference) {
                this.authenticationService.redirect('/private/help/view-request/' + reference.pkId).subscribe(result => {
                    if (result && result.domain) window.location = result.domain;
                });
            }
        }
    }

    /**
     * Process the message based on its type and send it to the server for
     * dispatching.
     */
    public send(response: any) {
        if (response?.type) {
            switch (response.type) {
                case 'Channel':
                    if (response.reference) {
                        let channel = response.reference;
                        let status = response.status;
                        if (status === 'Accepted') {
                            this.isOpen = true;
                        }
                        else if (status === 'Rejected') {
                            this.isOpen = false;
                            this.channelService.pop(channel);
                        }
                    }
                    break;

                case 'Chat':
                    // CHANNEL
                    if (this.selectedChannel) {
                        if (response.reference) {
                            this.messageService.sendChat('Channel', this.selectedChannel, response.reference, response.toPkIds);
                        }
                    }
                    // CONTEXT
                    else if (this.context) {
                        if (response.reference) {
                            this.messageService.sendChat('Context', this.context, response.reference, response.toPkIds);
                        }
                    }
                    break;

                case 'Help':
                    this.current = new User(this.current);
                    let help = response.reference;
                    help.parentChannelPkId = this.selectedChannel.pkId;
                    help.submitterPkId = this.current.pkId;
                    help.submitterName = this.current.getFullName();
                    help.userPkId = this.current.pkId;
                    help.userName = this.current.getFullName();
                    this.helpService.create(help).subscribe(res => {
                        if (res) {
                            let channel = new Channel(res);
                            this.channelService.customChannels.push(channel);
                            this.changeChannel(channel);
                            this.help = new Help();
                            // this.redirect(channel);
                            // this.router.navigate(['/private/help/view-request/' + channel.referencePkId]);
                        }
                    });
                    break;
            }
        }
    }

    //#endregion

    //#region PRIVATE METHODS

    /**
     * Receive message from the server, process the message, and place it in
     * the appropriate local cache.
     */
     private async receive(data): Promise<any>  {
        if (data) {
            switch(data.type) {
                case 'Notification':
                    let notification = new Notification(data);
                    // console.log("/chat/receive: NOTIFICATION = ", notification);
                    switch (notification.referenceType) {
                        case 'Channel':
                            if (notification.action === 'Create') {
                            }
                            else if (notification.action === 'Invite') {
                            }
                            else if (notification.action === 'Delete') {
                                if (this.selectedChannel?.pkId === notification.referencePkId) {
                                    this.close();
                                }
                            }
                            else if (notification.action === 'Uninvite') {
                                if (this.selectedChannel?.pkId === notification.referencePkId) {
                                    this.close();
                                }
                            }
                            break;
                        case 'Help':
                            if (notification.action === 'Create') {
                            }
                            else if (notification.action === 'Invite') {
                            }
                            else if (notification.action === 'Delete') {
                                if (this.selectedChannel?.pkId === notification.channelPkId) {
                                    this.close();
                                }
                            }
                            else if (notification.action === 'Uninvite') {
                                if (this.selectedChannel?.pkId === notification.channelPkId) {
                                    this.close();
                                }
                            }
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;

            }
        }
    }

    //#endregion

}
