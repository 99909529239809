
export class Comment {

    /* ATTRIBUTES */

    pkId: string;
    referencePkId: string;
    referenceType: string;
    user: {pkId: string, name: string};
    text: string;

    // dates
    createDate: Date;
    updateDate: Date;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json) {
        Object.assign(this, json);
    }

}
