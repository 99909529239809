import { Location } from '@angular/common';
import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})

/**
 * The breadcrumb includes a back link and the list representing
 * the path to help the user navigate back to where s/he came from.
 */
export class BreadcrumbComponent implements OnInit  {

    @Input() breadcrumb: {
        back: { name: string, link: string },
        items: []
    };

    /**
     * Constructor.
     */
    constructor(
        private location: Location,
        private router: Router) {

    }

    /**
     * On Init.
     */
    ngOnInit(): void {
    }

    /**
     * track *ngFor elements to avoid updating DOM when
     * no changes are made to the data.
     * - Fixes link not working on first click
     */
    trackByLink(index: number, item: any): string {
        return item.link;
    }

}
