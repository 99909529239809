import { ActivatedRoute } from '@angular/router';
import { Component, Input,OnInit } from '@angular/core';

import { ExportConfig } from '@src/shared/objects/export/export-config';
import { ExportService } from '@src/shared/services/export.service';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.less']
})
export class ExportButtonComponent implements OnInit{

    /* ATTRIBUTES */

    @Input() data: any[]; // additional items to be injected into the export menu

    public config;

    public exports: ExportConfig[];

    public isExporting = false;


    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private route: ActivatedRoute,
        private exportService: ExportService) {

    }

    /* INIT */

    /**
     * On Init.
     */
    public async ngOnInit() {
        let url = location.pathname;
        const params = this.route.snapshot.params
        if (params) {
            for (let param of Object.values(params)) {
                url = url.replace(param, '');
            }
        }

        this.exportService.listByRoute(url).subscribe(res => {
            this.exports = res;
        });
    }

    /* METHODS */

    public async export(config: ExportConfig) {
        this.isExporting = true;
        await this.exportService.download(config.pkId)
        this.isExporting = false
    }

    
}
