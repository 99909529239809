import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractService } from '@src/shared/services/abstract.service';
import { File } from '../objects/file';

@Injectable({
    providedIn: 'root'
})
export class FileService extends AbstractService {

    /* CONSTRUCTOR */

    /* METHODS */

    /**
     * Create file and upload to s3.
     */
    // tslint:disable-next-line: max-line-length
    public create(referencePkId: string, referenceType: string, organizationPkId: string, assetPkId: string, processPkId: string): Observable<any> {
        return this.post('/file/create', {referencePkId, referenceType, organizationPkId, assetPkId, processPkId});
    }

    /**
     * Delete file from file collection and s3.
     */
    public delete(file: File): Observable<any> {
        return this.post('/file/delete', {file});
    }

    /**
     * Download file from s3.
     */
    public download(file: File) {
        this.post('/file/download', {file}, { isDownload: true }).subscribe(data => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Download file from s3 for viewing in browser.
     * File will download if it cannot be viewed in browser.
     */
    public view(file: File): Observable<any> {
        return this.post('/file/download', {file}, { isDownload: true });
    }

    /**
     * Get File by asset.
     */
    public getByAsset(assetPkId: string): Observable<any> {
        return this.post('/file/getByAsset', {assetPkId});
    }

    /**
     * Get File by reference.
     */
    public getByReference(referencePkId: string, referenceType: string, assetPkId?: string): Observable<any> {
        return this.post('/file/getByReference', {referencePkId, referenceType, assetPkId});
    }

    /**
     * Get File by pkId.
     */
    public get(pkId: string): Observable<any> {
        return this.post('/file/get', {pkId});
    }

    /**
     * Get presigned URL.
     */
    public getPresignedUrl(pkId: string): Observable<any> {
        return this.post('/file/getPresignedUrl', {pkId});
    }

    /**
     * get file from s3.
     */
    public getFromS3(file: File) {
        return this.post('/file/download', {file}, { isDownload: true });
    }

    /**
     * get file from s3 by PkId.
     */
    public getFromS3ByPkId(filePkId: string) : Observable<any> {
        return this.post('/file/download', {filePkId}, { isDownload: true });
    }


    /**
     * Update file.
     */
    public update(file: File): Observable<any> {
        return this.post('/file/update', {file});
    }
}