import { Injectable } from "@angular/core";
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Cache } from '@src/shared/objects/cache';

@Injectable({
  providedIn: "root",
})
export class NavigationService {

  private history: string[] = [];

  private cache: Cache;
  private routerData;

  public navEventTrigger: string;
  public navEventUrl: string;

  // private navEventTrigger_: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  // private navEventUrl_: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // public navEventTrigger: Observable<string> = this.navEventTrigger_.asObservable();
  // public navEventUrl: Observable<string> = this.navEventUrl_.asObservable();


  /* CONSTRUCTOR */

  /**
   * Constructor.
   */
  public constructor(
    private router: Router
  ) {
    this.routerData = router.events.pipe(
      filter((event: NavigationEvent) => {
          return(event instanceof NavigationStart);
      }))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.setCacheData(event);
          this.history.pop();
        }
        else if (event.navigationTrigger === 'imperative') {
          this.history.push(event.url)
        }
        // this.navEventTrigger_.next(event.navigationTrigger);
        // this.navEventUrl_.next(event.url);
      })
  }


  /* METHODS */

  /**
   * Get Previous Route.
   */
  public getPreviousRoute() {
    return this.history.length > 0 ? this.history[this.history.length - 2] : '';
  }

  /**
   * Set Cache data depending on visited url
   */
  private setCacheData(event: any) {
    this.cache = Cache.get();
    let process = this.cache.getValue(Cache.KEYS.PROCESS);

    // if navigating BACK to process dashabord tab
    if (event.url === `/private/process/view-process-dashboard`) {
      this.cache.setValue(Cache.KEYS.PHASE, null);
      this.cache.setValue(Cache.KEYS.STEP, null);
    }

    if (process) {
      
      for (let phase of process?.phases) {
        // Navigating back to View Phase Page
        if (event.url === `/private/process/view-phase?phase=` + phase?.name) {
          this.cache.setValue(Cache.KEYS.PHASE, phase);
          this.cache.setValue(Cache.KEYS.STEP, null);
        }

        // Navigating back to step component page
        for (let step of phase?.steps) {
          // Not updating cache from questionnaire because they share the same route
          if (event.url.includes(step?.route) && step.referenceType !== 'questionnaire') {
            this.cache.setValue(Cache.KEYS.PHASE, phase);
            this.cache.setValue(Cache.KEYS.STEP, step);
          }
        }
      }
    }
  }


  /**
   * Unsubscribe from main router data observable
   */
  public unsubscribe() {
    this.routerData.unsubscribe();
  }

}









