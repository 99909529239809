<button mat-icon-button aria-label="Open change history panel" (click)="historyDrawer.toggle()">
    <mat-icon class="secondary-color">track_changes</mat-icon>
</button>

<!-- SLIDER / FINDINGS -->
<mat-drawer-container style="position: fixed;left: 0;top:0;height: 100%;z-index: 9999;overflow:visible" autosize>
    <mat-drawer #historyDrawer class="drawer py-4 pl-4" mode="over" position="end" color="primary">
        <div class="popout pr-4">
            <div class="popout-section">
                <div class="popout-section-header">                 
                    <h4 class="secondary-color">CHANGE LOG</h4>
                    <button mat-icon-button (click)="historyDrawer.toggle()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </button>
                </div>

                <div class="items">
                    <div class="item pr-3" *ngFor="let change of changes">
                        <div class="d-flex justify-content-between">
                            <span class="">{{ change.updatedBy?.name }}</span>
                            <span class="">{{ change.updateDate | date: 'short' }}</span>
                        </div>
                        <div class="">
                            <span class="">{{ change.action }}d {{ configuration?.translate(change.referenceType)?.toLowerCase() || change.referenceType }} <b>{{change.data?.name}}</b></span>
                        </div>
                        <div *ngIf="!change.text && change.differences?.length > 0">
                            <p class="font-size-9" *ngFor="let difference of change.differences">
                                Changed {{difference.key}} to: <i>{{difference.value}}</i>
                            </p>
                        </div>
                        <div *ngIf="change.text">
                            <p class="font-size-9">
                                {{change.text}}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>