
import { Component, OnInit} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.less'],
})

/**
 * Scroll back to top button.
 */
export class BackToTopComponent implements OnInit  {

    isShowing = false;

    /**
     * Constructor.
     */
    constructor() {
        fromEvent(window, 'scroll').subscribe((e: Event) => {
            const scrollPosition = e.target['scrollingElement']['scrollTop'];
            if (scrollPosition > window.innerHeight / 2) {
                this.isShowing = true;
            } else {
                this.isShowing = false;
            }
        });
    }

    /**
     * On Init.
     */
    ngOnInit(): void {
        // console.log(window);
    }

    /* UTILITY METHODS */

    scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

}
