
export class Transform {

    /* ATTRIBUTES */

    public key: string;
    public value: any;

    public pipeline: Operation[] = [];

    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }


    /* METHODS */

    /**
     * Add operation to the pipeline.
     */
    public addOperation(action: 'Custom' | 'Map' | 'Split', argument: any) {
        const operation = new Operation();
        operation.action = action;
        operation.argument = argument;
        this.pipeline.push(operation);
    }

}

class Operation {

    /* ATTRIBUTES */
    
    public action: 'Custom' | 'Map' | 'Split' | '';
    public argument: any;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */


}