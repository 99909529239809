import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[inputMask]'
})
export class InputMaskDirective implements OnInit, OnChanges {

  /**
   * input value, will be changed
   * this should typically be two-way bound.
   */
  @Input('inputMask') value;

  /**
   * constructor
   * @param el element
   */
  constructor(private el: ElementRef) { }

  /**
   * init
   */
  ngOnInit() {
    this.el.nativeElement.onblur = this.passiveUpdate;
    this.el.nativeElement.oninput = this.onInput;
  }

  /**
   * touches the element to trigger a passive update
   */
  ngOnChanges() {
    if (this.value !== undefined && (this.value >= 1000 || this.value <= -1000)) {
      setTimeout(() => {
        this.el.nativeElement.dispatchEvent(new Event('blur'));
      });
    }
  }

  /**
   * handles input of new numbers by removing all unallowed characters,
   * adding commas in the right places, and handling decimals
   * @param input the input event, should have property called data indicating the input string
   */
  private onInput(input) {
    // console.log(str.split("."));
    let str = this.value.replace(/[, ]+/g, '')
      .trim()
      .replace(/[^0-9.-]/g, '')     // remove chars except number, hyphen, point.
      .replace(/(?!^)-/g, '')       // remove middle hyphen.
      .replace(/^0+(\d)/gm, '$1');  // remove leading zeroes
    str = input.data === '.' && (str.split('.').length === 2) ? [str.replace(/(\..*)\./g, '$1')] : str.split('.');
    str[0] = str[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    this.value = str.filter(Boolean).join('.');
  }

  /**
   * updates the value property to include the right punctuation
   */
  private passiveUpdate() {
    this.value += '';
    const str = this.value.replace(/[, ]+/g, '').trim().split('.');
    str[0] = str[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    this.value = str.filter(Boolean).join('.');
  }

  /**
   * converts a value to a number type, removes commas and parses as float
   * @param value the item to convert to a number, should be a string
   */
  public getNumberValue(value) {
    if (value && typeof (value.replace) !== 'undefined') {
      return parseFloat(value.replace(/,/g, ''));
    }
    return value;
  }

}
