import app from "@app/app";

export default class GlobalConstants {
    
    public static APPLICATION_ARCHANGEL = 'Archangel';
    public static APPLICATION_ARCHANGEL_PKID = '0e5a76ea-aaef-4d95-b08f-480985249b47';
    public static APPLICATION_CERTIOR = 'Certior';
    public static APPLICATION_CERTIOR_PKID = '9b8c2c59-8887-43be-b9c2-3e60342a800d';
    public static APPLICATION_CUSTOMER_PORTAL = 'Customer Portal';
    public static APPLICATION_DISCOVER = 'Discover';
    public static APPLICATION_DISCOVER_PKID = '0ea3e17b-072b-48e4-b899-c12983403732';
    public static APPLICATION_EVOLUTION = 'Evolution';
    public static APPLICATION_EVOLUTION_PKID = '5bd47a69-8194-4f83-953b-47db710b8c4c';
    public static APPLICATION_FORSIGHT = 'Forsight';
    public static APPLICATION_FORSIGHT_PKID = '14fb47d1-c0f9-40b6-aa11-4e5de686cd8c';
    public static APPLICATION_GINI = 'Gini';
    public static APPLICATION_GINI_PKID = 'a701d827-6e60-4171-9e3e-5dae58d8130a';
    public static APPLICATION_ILLUMINATE = 'Illuminate';
    public static APPLICATION_ILLUMINATE_PKID = 'f7ae31be-b84a-4d24-97d7-9ebd939734b6';
    public static APPLICATION_SIQI = 'Siqi';
    public static APPLICATION_SIQI_PKID = '0f91821b-5ed2-4d63-8b75-7e0733be09cd';
    public static APPLICATION_VELICUS = 'Velicus';
    public static APPLICATION_VELICUS_PKID = 'fabfc627-4f5a-4719-81b5-3abd47705e1c';

    public static APPLICATION = app.appName; // change for each application
    public static APPLICATION_PKID = app.appPkId; // change for each application

}