import {v4 as uuid} from 'uuid';

import { User } from './user';

export class Channel {

    /* ATTRIBUTES */

    // ID

    pkId: string  = '';
  
    applicationPkId: string  = ''; // application pkid
    applicationName: string  = ''; // application name
    organizationPkId: string  = ''; // organization pkid
    organizationAlias: string  = ''; // organization alias
    organizationName: string  = ''; // organiation name
    referencePkId: string  = ''; // reference object pkid
    referenceType: string  = ''; // reference object type
    reference: any   = {}; // reference object

    ownerPkId: string = ''; // owner pkid
    ownerName: string = ''; // owner name
    parentPkId: string = ''; // parent pkid
    parentName: string = ''; // parent name

    // GENERAL

    name: string  = ''; // channel name
    description: string  = ''; // channel description
    type: 'Chat' | 'Custom' | 'Help' = 'Chat'; // channel type
    status: 'Open' | 'Close'; // channel status 
    numMembers = 0; // number of members
    numMessages = 0; // number of messages

    // SECURITY

    read: 'Admin' | 'Org Admin' | 'User' | 'Guest' | '' = 'User';
    write: 'Admin' | 'Org Admin' | 'User' | 'Guest' | '' = 'User';
    edit: 'Admin' | 'Org Admin' | 'User' | 'Guest' | '' = 'User';

    members: { userPkId: string, status: 'Requested' | 'Invited' | 'Assigned' | 'Approved' | 'Uninvited' | 'Accepted' | 'Rejected', createDate: Date, updateDate: Date }[] = [];
    isPrivate: boolean = false;
    // isPendingInvite: boolean = false;

    // DATE

    createDate: Date = new Date();
    updateDate: Date = new Date();

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuid();}
    }

    /* METHODS */

    /**
     * Get the name of the channel.
     */
    public getName() {
        if (this.type !== 'Custom') {
            return this.name;
        }
        else {
            return this.reference?.name;
        }
    }

    // /**
    //  * Returns whether the user is authorized to view this channel. NOTE:
    //  * we are going to shift the security check before sending the notification
    //  * on the server side.
    //  */
    // public isAuthorized(user: User) {
    //     if (user) {
    //
    //         if (user.isSysAdmin) { return true; }
    //         if (this.ownerPkId === user.pkId) { return true; }
    //
    //         if (this.isPrivate) {
    //             return this.members.find(m => m.userPkId === user.pkId);
    //         }
    //         else {
    //             if (this.read === 'Guest') {
    //                 return true;
    //             }
    //             else if (this.read === 'User') {
    //                 return true;
    //             }
    //             else if (this.read === 'Org Admin') {
    //                 // TODO 
    //             }
    //             else if (this.read === 'Admin') {
    //                 // TODO 
    //             }
    //         }
    //     }
    //     return false;
    // }

    /**
     * Returns whether this channel is viewable by the specified user.
     */
    public isViewable(user: User) {
        if (user) {
            if (this.isPrivate) {
                if (this.ownerPkId === user.pkId) {
                    return true;
                }
                else {
                    if (this.members.length > 0) {
                        const member = this.members.find(item => item.userPkId === user.pkId);
                        if (member) {
                            if (member.status === "Accepted" || member.status === "Approved") {
                                return true;
                            }
                        }
                    }
                }
            }
            else {
                return true;   
            }
        }     
        return false;
    }

}
