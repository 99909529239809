import { Injectable, Injector } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';

import { Cache } from '../objects/cache';
import { Channel } from '../objects/channel';
//import { Help } from '../objects/help';
import { Notification } from '../objects/notification';

import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class ChannelService extends AbstractService {

    /* ATTRIBUTES */

    private socketClient; // socket client

    public globalChatChannel: Channel; // the default global chat channel
    public globalHelpChannel: Channel; // the default global help channel

    // public all: Channel[] = []; // all the parent channels
    public chatChannels: Channel[] = []; // chat channels for display in chat menu
    public customChannels: Channel[] = []; // custom channels for sub menu
    public helpChannels: Channel[] = []; // help channels for display in help menu

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private i: Injector,
        private authenticationService: AuthenticationService) {
        super(i);

        this.init();
    }

    /**
     * Initialize.
     */
    public async init() {

        //#region CHANNELS

        this.list(undefined).subscribe(res => {

            // Create specific channels to show in the chat menu. We have to
            // remove the global channel from the list because it already is
            // shown as the first channel in the chat menu.

            let channels = [];
            for (let channel of res) {
                let channel_ = new Channel(channel);
                channels.push (channel_);
                if (channel.parentPkId === '') {
                    // this.all.push (channel_);
                }
            };

            this.globalChatChannel = channels.find(channel => channel.type === 'Chat' && channel.pkId === 'global-chat' && channel.ownerPkId === 'system');
            this.globalHelpChannel = channels.find(channel => channel.type === 'Help' && channel.pkId === 'global-help' && channel.ownerPkId === 'system');

            this.chatChannels = channels.filter(channel => channel.type === 'Chat' && channel.pkId !== 'globalChat' && channel.ownerPkId !== 'system');
            this.customChannels = channels.filter(channel => channel.type === 'Custom' && channel.ownerPkId !== 'system');
            this.helpChannels = channels.filter(channel => channel.type === 'Help' && channel.pkId !== 'globalHelp' && channel.ownerPkId !== 'system' && !channel.parentPkId);

            // //------------------------------------------------------------------------
            // // TEMP:
            // // We have to find a better way to refresh the reference object in the
            // // channel when the object has been changed.
            // //------------------------------------------------------------------------

            // for (let channel of this.customChannels) {
            //     if (channel.referenceType === "Help") {
            //         this.helpService.get(channel.referencePkId).subscribe(res => {
            //             channel.reference = res;
            //         });
            //     }
            // }

            // console.log("/shared/services/channel: ALL CHANNELS: ", channels);
            // console.log("/shared/services/channel: PRIMARY CHANNELS: ", this.all);
            // console.log("/shared/services/channel: CHAT CHANNELS: ", this.chatChannels);
            // console.log("/shared/services/channel: CUSTOM CHANNELS: ", this.customChannels);
            // console.log("/shared/services/channel: HELP CHANNELS: ", this.helpChannels);

        });

        //#endregion

        //#region SOCKET.IO

        this.socketClient = io (
            this.authenticationService.getComUrl(), this.authenticationService.getSocketSettings()
        );

        // CONNECT
        this.socketClient.on('connect', () => {
            console.log('/services/channel/init: CONNECTED COMMUNICATION.SOCKET.IO: ', this.socketClient.connected);
            this.socketClient.on('message', (data) => {
                if (typeof data !== 'object') { data = JSON.parse(data); }
                this.receive(data);
            });
        });

        // CONNECTION ERROR
        this.socketClient.on('connect_error', async (err) => {
            console.log('/services/channel/init: CONNECTION ERROR: ', err);
            this.socketClient.close();
            await new Promise(resolve => setTimeout(resolve, 10000)); // wait 10 seconds before reconnecting
            this.socketClient.connect();
        });

        // CONNECTION TIMEOUT
        this.socketClient.on('/services/channel/init: connect_timeout', (err) => {
            console.log('CONNECTION TIMEOUT: ', err);
        });

        // DISCONNECT
        this.socketClient.on('disconnect', () => {
            console.log('/services/channel/init: DISCONNECTED: ', this.socketClient.connected);
        });

        //#endregion

    }

    /* PUBLIC METHODS */

    /**
     * Create a channel.
     */
    public create (channel: Channel) {
        return this.post('/channel/create', {channel});
    }

    /**
     * Get a channel by id.
     */
    public get (pkId) {
        return this.post('/channel/get', {pkId});
    }

    /**
     * Get the child channels of the specified parent channel.
     */
    public getChildren(parent: Channel) {
        if (parent) {
            let children = this.customChannels.filter(item => item.parentPkId === parent.pkId);
            return children;
        }
        return [];
    }

    /**
     * Get the channels.
     */
    public list (query) {
        return this.post('/channel/list', { query }, undefined, undefined, true);
    }

    /**
     * Update invite status.
     */
    public membership (channelPkId, userPkId, status: string) {
        // return this.post('/channel/membership', { channelPkId: channelPkId, userPkId, status });
    }

    /**
     * Removes the entity.
     */
    public remove(channel: Channel): Observable<any> {
        return this.post('/channel/remove', { pkId:channel.pkId }, undefined, undefined, true);
    }

    /**
     * Get the channel settings.
     */
    public settings () {
        return this.post('/channel/settings', {}, undefined, undefined, true);
    }

    /**
     * Update the status of the channel.
     */
    public status(channel: Channel, status: string) {
        return this.post('/channel/status', { channel, status }, undefined, undefined, true);
    }

    /**
     * Update a channel.
     */
    public update (channel: Channel) {
        return this.post('/channel/update', { channel }, undefined, undefined, true);
    }

    /* SUPPORTING METHODS */

    /**
     * Gets the channels in memory by type.
     */
    private getChannelsInMemoryByType(type: string) {
        if (type === 'Chat') {
            return this.chatChannels;
        }
        else if (type === 'Custom') {
            return this.customChannels;
        }
        else if (type === 'Help') {
            return this.helpChannels;
        }
        return [];
    }

    /**
     * Push the specified channel to the correct channel array.
     */
    public async push(channel: Channel) {
        if (channel) {
            let cache = Cache.get();
            let current = cache.getValue(Cache.KEYS.USER);
            if (current) {
                let channels = this.getChannelsInMemoryByType(channel.type);
                if (!channels.find(c => c.pkId === channel.pkId)) {
                    channels.push(channel);
                }
            }
        }
    }

    /**
     * Pop the specified channel from the correct channel array.
     */
    public async pop(channel: Channel) {
        if (channel) {
            let channels = this.getChannelsInMemoryByType(channel.type);
            let index = channels.findIndex(c => c.pkId === channel.pkId);
            if (index > -1) {
                channels.splice(index, 1);
            }

            // if (!channel.parentPkId) {
            //     let index = this.all.findIndex(c => c.pkId === channel.pkId);
            //     if (index > -1) {
            //         this.all.splice(index, 1);
            //     }
            // }
        }
    }

    /**
     * Receive message from the server, process the message, and place it in
     * the appropriate local cache.
     */
    private async receive(data): Promise<any>  {

        if (data) {
            switch(data.type) {
                case 'Notification':
                    let notification = new Notification(data);
                    switch (notification.referenceType) {
                        case 'Channel':
                            let channel = new Channel(notification.reference);
                            if (notification.action === 'Create') {
                                this.push(channel);
                            }
                            else if (notification.action === 'Delete') {
                                this.pop(channel);
                            }
                            else if (notification.action === 'Invite') {
                                this.push(channel);
                            }
                            else if (notification.action === 'Uninvite') {
                                this.pop(channel);
                            }
                            break;
                        case 'Help':
                            // let request = new Help (notification.reference);
                            // let custom = new Channel(notification.channel);
                            // if (notification.action === 'Assign') {
                            //     this.push(custom);
                            // }
                            // else if (notification.action === 'Create') {
                            //     this.push(custom);
                            // }
                            // else if (notification.action === 'Delete') {
                            //     this.pop(custom);
                            // }
                            // else if (notification.action === 'Unassign') {
                            //     this.pop(custom);
                            // }
                            // break;
                        default:
                            break;
                    }
                    break;

                default:
                    break;

            }
        }

    }

}
