<div class="row gap-3">
    <!-- TOGGLE -->
    <!-- <div class="col-auto">
        <app-toggle-buttons [start]="chartToggle">
            <button mat-button (click)='chartToggle="chart"' value="chart">Chart</button>
            <button mat-button (click)='chartToggle="table"' value="table">Table</button>
        </app-toggle-buttons>
    </div> -->
</div>

<div class="bottom-card" style="width: 100%;  padding-bottom: 10px;">
    <div *ngIf="chartToggle === 'chart'" style="margin-top: 25px; padding-bottom: 10px;">
        <table class="outer-table" mat-table style="width:100%; margin-top: 50px;" [dataSource]="dataSource" matSortActive="name" matSortDirection="asc" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th [width]="columnWidthChart.name" mat-header-cell *matHeaderCellDef mat-sort-header
                        style="white-space: nowrap;">
                        <mat-label>
                            Name
                        </mat-label>
                    </th>
                    <td [width]="columnWidthChart.name" mat-cell *matCellDef="let asset">
                        <a (click)="gotoProcess(asset.name, asset.pkId)">
                            {{ asset.name }}
                        </a>
                    </td>
                </ng-container>
       
            <ng-container matColumnDef="alias">
                <th [width]="columnWidthChart.alias" mat-header-cell *matHeaderCellDef mat-sort-header
                    style="white-space: nowrap;">
                    <mat-label>
                        Alias
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.alias" mat-cell *matCellDef="let asset">
                    <a (click)="gotoProcess(asset.name, asset.pkId)">
                        {{ asset.alias }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="division">
                <th [width]="columnWidthChart.division" mat-header-cell *matHeaderCellDef mat-sort-header
                    style="white-space: nowrap;">
                    <mat-label>
                        {{ configuration.translate('Division') }}
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.division" mat-cell *matCellDef="let asset">
                    <div>{{ asset.metadata.division }}</div>
                </td>
            </ng-container>


            <ng-container matColumnDef="overallStatus">
                <th class="col-center" [width]="columnWidthChart.overallStatus" mat-header-cell mat-sort-header *matHeaderCellDef
                    style="white-space: nowrap;">
                    Completion %
                </th>
                <td [width]="columnWidthChart.overallStatus" mat-cell *matCellDef="let asset">
                    <div>{{asset.progressSum}}%</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="classification">
                <th [width]="columnWidthChart.classification" mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                    <mat-label>
                        Impact Level
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.classification" mat-cell *matCellDef="let asset">
                    <div>{{ asset.classification >= 0 ? LIST_IMPACT_LEVEL[asset.classification] : 'N/A' }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="atoStatus">
                <th [width]="columnWidthChart.atoStatus" mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                    <mat-label>
                        Status
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.atoStatus" mat-cell *matCellDef="let asset">
                    <div>{{ asset.atoStatus ? asset.atoStatus : 'TBD' }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="atoType">
                <th [width]="columnWidthChart.atoType" mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                    <mat-label>
                        Authorization Type
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.atoType" mat-cell *matCellDef="let asset">
                    <div>{{ asset.atoType ? asset.atoType : 'N/A' }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="expiration">
                <th [width]="columnWidthChart.expiration" mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                    <mat-label>
                        ATO Exp
                    </mat-label>
                </th>
                <td [width]="columnWidthChart.division" mat-cell *matCellDef="let asset">
                    <div>{{ asset.expiration ? (asset.expiration | date:'MM/dd/yyyy') : 'TBD' }}</div>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="actions">
                <th class="col-actions" [width]="columnWidthChart.actions" mat-header-cell *matHeaderCellDef>
                </th>
                <td class="col-actions font-size-10" [width]="columnWidthChart.actions" mat-cell
                    *matCellDef="let asset">
                    <button mat-icon-button [matMenuTriggerFor]="actions" *ngIf="actionItems?.length">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #actions="matMenu">
                        <button mat-menu-item *ngFor="let action of actionItems" matTooltipPosition="left" matTooltip="{{action.tooltip}}"
                            (click)="action.onClick(asset)">
                            <mat-icon>{{action.icon}}</mat-icon>
                            {{action.title}}
                        </button>
                    </mat-menu>
                </td>
            </ng-container> -->

            <!-- INNER TABLE -->
            <ng-container matColumnDef="details">
                <td [attr.colspan]="columnsToDisplayChart.length" mat-cell *matCellDef="let item">
                    <div class="element-detail" [@detailExpand]="item == selected ? 'expanded' : 'collapsed'">
                        <table class="inner-table" *ngIf="item === selected" #innerSort="matSort" mat-table [dataSource]="dataSourcePhases" matSortActive="name" matSortDirection="asc" matSort>
                            <div>   
                                <ng-container matColumnDef="phases">
                                    <th class="d-flex justify-content-between" [width]="columnWidthPhases.phases" mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                                        <span>Phases/Tasks</span>
                                        <span *ngIf="asset.owner" style="font-style: italic;">Asset Owner: {{asset.owner}}</span>
                                    </th>
                                    <td class="col-center" [width]="columnWidthPhases.phases" mat-cell *matCellDef="let item" style="padding: 5px;">
                                        <div class="phases-container">
                                            <div class="phase-item" *ngFor="let phase of item.phases" (click)="gotoPhase(asset, phase[0])">
                                                <div>
                                                    <span class="tasks">{{getTaskCount(asset, phase[0])}}</span>
                                                    <mat-icon class="secondary-color material-icons-outlined status-icon" *ngIf="phase[1].status === 'Passed' || phase[1].status === 'Not Required'"
                                                              matTooltip="Phase is Passed" matTooltipPosition="above">check_circle</mat-icon>
                                                    <mat-icon class="status-icon" *ngIf="phase[1].status === 'Pending Review'"
                                                              matTooltip="Phase is Pending Review" matTooltipPosition="above">hourglass_empty</mat-icon>
                                                </div>

                                                <p style="font-size: 0.625rem;">{{phase[0]}}</p>
                                                <mat-progress-bar
                                                    style="width: 68px;"
                                                    mode="determinate"
                                                    value="{{getPhaseCompletion(asset, phase[0])}}">
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                            </div>
                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayPhases"></tr>
                        <tr class="element-row" mat-row *matRowDef="let element; let i = index; columns: columnsToDisplayPhases;" id="row-{{i}}"></tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <!-- ROW -->
            <tr class="element-header" mat-header-row *matHeaderRowDef="columnsToDisplayChart"></tr>
            <tr class="element-row"
                id="row-{{i}}"
                mat-row *matRowDef="let element; let i = index; columns: columnsToDisplayChart;"
                (click)="select(element)">
            </tr>
            <tr class="detail-row" mat-row *matRowDef="let row; columns: ['details']"></tr>
        </table>
    </div>

    <!-- <div *ngIf="chartToggle === 'table'" style="margin-top: 25px;  padding-bottom: 10px;">
        <table mat-table style="width:100%; margin-top: 50px;" [dataSource]="dataSource" matSortActive="name"
            matSortDirection="asc" matSort>
            <ng-container matColumnDef="name">
                <th [width]="columnWidth.name" mat-header-cell *matHeaderCellDef mat-sort-header
                    style="white-space: nowrap;">
                    <mat-label>
                        Name
                    </mat-label>
                </th>
                <td [width]="columnWidth.name" mat-cell *matCellDef="let asset">
                    <a (click)="gotoProcess(asset.name, asset.pkId)">
                        <div>{{ asset.name }}</div>
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="alias">
                <th [width]="columnWidth.alias" mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                    Alias
                </th>
                <td [width]="columnWidth.alias" mat-cell *matCellDef="let asset">
                    {{ asset.alias }}
                </td>
            </ng-container>
            <ng-container matColumnDef="version">
                <th [width]="columnWidth.alias" mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                    Version
                </th>
                <td [width]="columnWidth.alias" mat-cell *matCellDef="let asset">
                    {{ asset.version }}
                </td>
            </ng-container>
            <ng-container matColumnDef="productType">
                <th [width]="columnWidth.productType" mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                    Type
                </th>
                <td [width]="columnWidth.productType" mat-cell *matCellDef="let asset">
                    {{ asset.type }}
                </td>
            </ng-container>

            <ng-container matColumnDef="progress">
                <th class="col-center" [width]="columnWidth.progress" mat-header-cell *matHeaderCellDef>
                        Progress
                </th>
                <td class="col-center" [width]="columnWidth.progress" mat-cell *matCellDef="let asset"
                    >
                    <div class="progress-container">
                        <div style="position: absolute;">
                            <mat-progress-spinner class="custom-spinner" [ngClass]="{'dark-spinner': theme==='dark'}"
                                value={{asset.progressSum}} diameter="45" strokeWidth="6"></mat-progress-spinner>
                        </div>
                        <div class="progress-text" [ngClass]="{'dark-text': theme==='dark'}">
                            {{asset.progressSum}}%
                        </div>
                        <div class="completed-icon" [ngClass]="{'dark-icon': theme==='dark'}"
                            *ngIf="asset.progressSum == 100">
                            <mat-icon aria-hidden="false" fontIcon="check"></mat-icon>
                        </div>
                    </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th class="col-center" [width]="columnWidth.status" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-label>
                        Status
                    </mat-label>
                </th>
                <td [width]="columnWidth.status" mat-cell *matCellDef="let asset">
                    {{ asset.status }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th class="col-actions" [width]="columnWidth.actions" mat-header-cell *matHeaderCellDef aria-label="actions">
                </th>
                <td class="col-actions font-size-10" [width]="columnWidth.actions" mat-cell *matCellDef="let asset">
                    <button mat-icon-button [matMenuTriggerFor]="actions" *ngIf="actionItems?.length">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #actions="matMenu">
                        <button mat-menu-item *ngFor="let action of actionItems" matTooltipPosition="left" matTooltip="{{action.tooltip}}"
                            (click)="action.onClick(asset)">
                            <mat-icon>{{action.icon}}</mat-icon>
                            {{action.title}}
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row class="expandable-row" tabIndex *matRowDef="let asset; columns: columnsToDisplay;"></tr>
        </table>
    </div> -->
</div>

<div style="width: 100%; padding-bottom: 25px;">
    <div *ngIf="chartToggle === 'table'" class="table-paging-container">
        <div class="row">

            <mat-paginator class="col-auto" style="background: transparent; margin: auto;" [pageSize]="25"
                [pageSizeOptions]="[5, 10, 25, 50, 100, 200]">
            </mat-paginator>
        </div>
        <div *ngIf="dataSource.filteredData?.length === 0">
            <p>No Products Found</p>
        </div>
    </div>
    <div *ngIf="chartToggle === 'chart'" class="table-paging-container">
        <div class="row">

            <mat-paginator class="col-auto" style="background: transparent;  margin: auto;" [pageSize]="25"
                [pageSizeOptions]="[5, 10, 25, 50, 100, 200]">
            </mat-paginator>
        </div>
        <div *ngIf="dataSourceChart.filteredData?.length === 0">
            <p>No Products Found</p>
        </div>
    </div>
</div>
