<div class="flex- justify-content-center align-items-center">

    <div class="d-flex flex-row align-items-center mx-3">
        <p class="itemSizeLabel px-3">Items per page:</p>
        <mat-select class="select" [(ngModel)]="pageSize" (selectionChange)="pageSizeChanged()">
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="75">75</mat-option>
        </mat-select>
    </div>
    

    <span *ngIf="pageSize >= count" class="pr-3">{{(start + 1) | number: '1.0-0' }} - {{(count) | number: '1.0-0'}} of {{count}}</span>
    <span *ngIf="pageSize < count" class="pr-3">{{(start + 1) | number: '1.0-0' }} - {{(start + pageSize) | number: '1.0-0'}} of {{count}}</span>
    <div>
        <button 
        mat-icon-button 
        (click)="pageNavigate('Previous')" 
        [disabled]="start === 0"
        aria-label="Previous">
        <mat-icon>navigate_before</mat-icon>
    </button>
    <button
        mat-icon-button
        (click)="pageNavigate('Next')" 
        aria-label="Next"
        [disabled]="end >= count">
        <mat-icon>navigate_next</mat-icon>
    </button>
    </div>
</div>