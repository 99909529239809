import { Router } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';

import config from '@app/app';
import { Asset } from '@src/shared/objects/asset';
import { Cache } from '@src/shared/objects/cache';
import { Configuration } from '@src/shared/objects/configuration';

import { AssetService } from '@src/shared/services/asset.service';
import { AnalyticsService } from '@src/shared/services/analytics.service';
import { UserService } from '@src/shared/services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
    selector: 'app-process-table',
    templateUrl: './process-table.component.html',
    styleUrls: ['./process-table.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    encapsulation: ViewEncapsulation.Emulated
})
export class ProcessTableComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    
    @ViewChild(MatSort) set matSort(ms: MatSort) { this.sort = ms; setTimeout(() => { 
        this.dataSourceChart.sort = this.sort; this.dataSource.sort = this.sort; });  }
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) { 
        this.paginator = mp; this.dataSourceChart.paginator = this.paginator;  this.dataSource.paginator = this.paginator; 
    }

    @Input() processPkId: string;
    @Input() assets: Asset[] = [];
    @Input() actionItems: {icon: string, title: string, tooltip:string, onClick: (args: any) => void}[] = [];
    @Input() objectType;
    @Input() filters;
    
    @Output() metadata = new EventEmitter<any>();

    @ViewChild("progressTable") pt;

    /* CONSTANTS */

    public LIST_IMPACT_LEVEL = ['Low', 'Moderate', 'High'];

    /* ATTRIBUTES */

    public cache;
    public config; // app.ts
    
    public configuration;
    public organization;
    public processes;
    public process;
    public phases;
    public user;
    public users = [];

    public daysToComplete = 0;

    public assetsByObjectType: {[type:string]: Asset[]} = {}
    public assets_: Asset[] = [];

    public dataSource = new MatTableDataSource();
    public columnsToDisplay = ['name', 'alias', 'version', 'productType', 'progress', 'status', 'actions'];
    public columnWidth = { name: '20%', alias: '15%', version: '5%', productType: '10%', progress: '10%', status: '20%', actions: '5%' };
    public paginator;
    public sort;

    public dataSourceChart = new MatTableDataSource();
    public columnsToDisplayChart = [ 'name', 'alias', 'division', 'overallStatus', 'classification', 'atoStatus', 'atoType', 'expiration'];
    public columnWidthChart = { name: '15%', alias: '5%', division: '5%', overallStatus: '1%', classification: '5%', atoStatus: '5%', submission: '5%', atoType: '5%', expiration: '5%'};

    public dataSourcePhases = new MatTableDataSource();
    public columnsToDisplayPhases = ['phases'];
    public columnWidthPhases = { phases: '100%' };
    public selected; // the selected asset
    public asset; // the asset
    expandedElement: null;

    public metadata_ = { readyForAto: 0 };

    public theme: string;

    public chartToggle = "chart";

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private analyticsService: AnalyticsService,
        private assetService: AssetService,
        private userService: UserService,
        private router: Router,
        ) {

        this.config = config;
        setTimeout(() => {
            config.isHeaderVisible = true;
            config.isNavigationVisible = true;
        });

        this.cache = Cache.get();
        this.configuration = new Configuration(this.cache.getValue(Cache.KEYS.CONFIGURATION));
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.processes = this.configuration.processes;
        this.user = this.cache.getValue(Cache.KEYS.USER);
        this.userService.index(this.organization.orgPkId).subscribe(result => {
            if (result) {
                this.users = result;
            }
        })
    }

    /* EVENT HANDLING */

    /**
     * On Changes.
     */
    public ngOnChanges(changes: SimpleChanges) {
        this.assetsByObjectType = {};
        for (let asset of this.assets) {
            if (!this.assetsByObjectType[asset.objectType]) {
                this.assetsByObjectType[asset.objectType] = [];
            }
            this.assetsByObjectType[asset.objectType].push(asset);
        }

        this.assets_ = this.assetsByObjectType[this.objectType];

        this.process = this.processes.find(process => process.pkId === this.processPkId);
            if (this.process) {
            this.process.phases.forEach((phase) => {
                this.daysToComplete += phase.steps.reduce((acc, step) => { 
                    acc = acc + step.recommendedDuration || 0;
                    return acc; }, 0);
            });
            this.phases = this.process.phases.filter(phase => phase.isRequired).map(phase => phase.name);
        }
         
        this.generateProgressData();
        this.dataSource.data = this.assets_;
        this.dataSourceChart.data = this.assets_;

        this.dataSource.sortingDataAccessor = (item: any, property: string): string => {
        switch (property) {
            case 'division':
                return item.metadata.division;
            case 'overallStatus': 
                return item["progressSum"];
            case 'submission': 
                if (isNaN(item[property])){
                    return 'NaN';
                }
            default: 
                return item[property];
            }
        }

    }

    /**
     * On Init.
     */
    public ngOnInit() {
        const preference = this.user?.preference;
        this.theme = preference?.theme;
        Cache.onChanges().subscribe(res => {
            if (res['key'] === 'preferences') {
                this.cache = Cache.get();
                this.user = this.cache.getValue(Cache.KEYS.USER);
                this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
                this.process = this.configuration.processes.find(process => process.pkId === this.processPkId);
                this.phases = this.process.phases.filter(phase => phase.isRequired).map(phase => phase.name);
                const preference = this.user?.preference;
                this.theme = preference?.theme;

            }
        });
    }

    /**
     * On Init.
     */
    public ngAfterViewInit(): void {

    }

    /**
     * On Destroy.
     */
    public ngOnDestroy(): void {

    }

    private getInstanceOfStepName(){
        let stepName = { authoring: '', sap: '', sar: '', submit: '' };
        this.process?.phases?.forEach((phase) => {
            phase.steps.forEach((step) => {
                if (step.referenceType === 'controls-submit') {
                    stepName.authoring = step.name.replace(/[^a-zA-Z0-9]/g, '');
                } 
                else if (step.referenceType === 'create-assessment-plan') {
                    stepName.sap = step.name.replace(/[^a-zA-Z0-9]/g, '');
                }
                else if (step.referenceType === 'build-assessment-report') {
                    stepName.sar = step.name.replace(/[^a-zA-Z0-9]/g, '');
                } 
                else if (step.referenceType === 'submit') {
                    stepName.submit = step.name.replace(/[^a-zA-Z0-9]/g, '');
                }
            });
        });
        return stepName;
    }

    /* METHODS */

    /**
     * Get process analytics for progress spinners.
     */
    private generateProgressData() {
        this.analyticsService.getProcessTrendData(this.organization.orgPkId).subscribe(analytics => {
            // console.log('/private/dashboard/process-dashboard/generateProgressData() analytics:', analytics);
            this.metadata_.readyForAto = 0;

            for (let asset of this.assets) {

                const analytic = analytics.find(analytic_ => analytic_.assetPkId === asset.pkId  &&  analytic_.processPkId === (this.processPkId || asset.processes[0]));
                if (analytic) {
                    asset["progress"] = analytic;
                    asset["phases"] = Object.entries(analytic.phases).sort((a:any, b:any) => { return a[1].position > b[1].position ? 1: a[1].position < b[1].position ? -1 : 0; });
                    asset["phases"] = asset["phases"].filter(phase => this.phases.includes(phase[0]));
                    asset["progressSum"] = Math.round(analytic.completionPercent || 0);
                }
                else {
                    const process = this.configuration.processes?.find(process_ => process_.pkId === (this.processPkId || asset.processes[0]));
                    const phases = {};
                    if (process) {
                        for (let phase of process.phases) {
                            phases[phase.name] = {
                                completionPercent: 0,
                                position: process.phases.indexOf(phase)
                            }
                        }
                    }
  
                    asset["phases"] = Object.entries(phases).sort((a:any, b:any) => { return a[1].position > b[1].position ? 1: a[1].position < b[1].position ? -1 : 0; });
                    asset["phases"] = asset["phases"].filter(phase => this.phases?.includes(phase[0]));
                    asset["progressSum"] = 0;
                }

                let stepName = this.getInstanceOfStepName();

                // Calculate Asset Status
                // Upcoming: Asset not approved or expired, phases 1/2/3 not complete
                // Ready for Assessment: Asset not approved or expired, phases 1/2/3 complete, Security Control Authoring complete
                // Assessment in Progress: Asset not approved or expired, phases 1/2/3 complete, SAP card complete
                // Ready for Authorization: Asset not approved or expired, all phases before "submit" complete
                // Authorized: Asset not expired, memo signed and approved
                asset['readyForAto'] = false;
                const isExpired = asset.metadata.expiration && new Date(asset.metadata.expiration).getTime() < new Date().getTime() ? true : false;

                if (isExpired) {
                    asset["atoStatus"] = 'Expired';
                } 
                else {
                    let phase1complete = asset["phases"][0] ? asset["phases"][0][1]?.status === "Passed" || asset["phases"][0][1]?.status === "Not Required" : false;
                    let phase2complete = asset["phases"][1] ? asset["phases"][1][1]?.status === "Passed" || asset["phases"][1][1]?.status === "Not Required" : false;
                    let phase3complete = asset["phases"][2] ? asset["phases"][2][1]?.status === "Passed" || asset["phases"][2][1]?.status === "Not Required" : false;
                    let authoringComplete = false;
                    let sapComplete = false;

                    if (!phase1complete || !phase2complete || !phase3complete) {
                        asset["atoStatus"] = 'Upcoming';
                    } 
                    else {
                        asset["phases"].forEach((phase) => {
        
                            if (phase[1].steps && Object.keys(phase[1].steps).includes(stepName.authoring) &&
                            phase[1].steps[stepName.authoring].completionPercent === 100) {
                                authoringComplete = true;
                            }

                            if (phase[1].steps && Object.keys(phase[1].steps).includes(stepName.sap) &&
                            phase[1].steps[stepName.sap].completionPercent === 100) {
                                sapComplete = true;
                            }

                       
                            if (phase[1].steps && Object.keys(phase[1].steps).includes(stepName.submit)) {    
                                if (asset['readyForAto']) {
                                    asset['atoStatus'] = 'Ready for Authorization';
                                    this.metadata_.readyForAto++;
                                } 
                                else {
                                    asset['readyForAto'] = false;
                                }
                            } 
                            else {
                                asset['readyForAto'] = phase[1].status === 'Passed';
                            }
                        });

                        if (!asset['readyForAto'] && sapComplete) {
                            asset["atoStatus"] = 'Assessment in Progress';
                        }
                        else if (!asset['readyForAto'] && authoringComplete) {
                            asset["atoStatus"] = 'Ready for Assessment';
                        }
                    }
                }

                const owner = this.users.find(user => user.pkId === asset.ownerPkId);
                asset["owner"] = owner ? `${owner.firstName} ${owner.lastName}` : '';
                asset["atoType"] = asset.metadata?.authorizationType;
            }

            this.metadata.emit(this.metadata_);

            this.refresh();
        });
    }

    /**
     * Refresh assets.
     */
    private refresh() {
        if (this.assets_) {
            if (this.filters.readyForAto.length > 0 && this.filters.readyForAto[0]) {
                this.assets_ = this.assets_.filter(asset => asset['readyForAto']);
            }

            if (this.filters.assetStatus.length > 0) {
                this.assets_ = this.assets_.filter(asset => asset['atoStatus'] === this.filters.assetStatus[0]);
            }

            if (this.filters.tasks.length > 0) {
                const tasks = this.filters.tasks.map(task => task.substring(0, task.indexOf(' Incomplete')).replace(/[^a-zA-Z0-9]/g, ''));
                this.assets_ = this.assets_.filter(asset => {
                    let found = false;
                    for (const phase of asset["phases"]) {
                        if (phase[1].steps) {
                            Object.entries(phase[1].steps).forEach((step) => {
                                if (tasks.includes(step[0]) && (!step[1]['completionPercent'] || step[1]['completionPercent'] < 100)) {
                                    found = true;
                                }
                            });
                        }
                    }
                    return found;
                });
            }
            this.dataSource.data = this.assets_;
        }
}

    /*
    * Goto to the process navigator.
    */
    public gotoProcess(name: string, pkId: string): void {
        let cache = Cache.get();
        this.assetService.getAsset(pkId).subscribe(res => {
            if (res) {
                cache.setValue(Cache.KEYS.ASSET_PKID, pkId);
                cache.setValue(Cache.KEYS.ASSET, res);
                cache.setValue(Cache.KEYS.PHASE, null);

                this.router.navigateByUrl(config.routes.PRIVATE_PROCESS_VIEW_PROCESS_DASHBOARD, { state: { data: { name } } });
            }
        });
    }

    public gotoPhase(asset, phaseName): void {
        let cache = Cache.get();

        let process = this.processes.find(a => a.pkId === (this.processPkId || asset.processes[0]));
        let phase = process.phases.find(a => a.name === phaseName);

        cache.setValue(Cache.KEYS.ASSET_PKID, asset.pkId);
        cache.setValue(Cache.KEYS.ASSET, asset);
        cache.setValue(Cache.KEYS.PROCESS, process);
        cache.setValue(Cache.KEYS.PHASE, phase);

        if (phase) {
            cache.setValue(Cache.KEYS.PHASE, phase);
            this.router.navigateByUrl(config.routes.PRIVATE_PROCESS_VIEW_PHASE + "?phase=" + phaseName);
        }
        else {
            this.router.navigateByUrl(config.routes.PRIVATE_PROCESS);
        }
    }


    /**
     * Get Completion for a phase.
     */
    public getPhaseCompletion(asset, name) {
        let completion = 0;

        if (asset.progress) {
            if (asset.progress.phases[name]) {
                if (asset.progress.phases[name].completionPercent)
                    if (asset.progress.phases[name].completionPercent != "")
                        completion = asset.progress.phases[name].completionPercent;
            }
        }

        return Math.round(completion);
    }

    /**
     * Get Task count for a phase.
     */
    public getTaskCount(asset, name) {
        let count = 0;

        if (asset.progress) {
            if (asset.progress.phases[name]) {
                if (asset.progress.phases[name].steps) {
                    const steps = Object.values(asset.progress.phases[name].steps);
                    count = steps.length;
                    for (const step of steps) {
                        if (step["completionPercent"] === 100) {
                            count--;
                        }
                    }
                }
            }
        }

        return count;
    }

    /**
     * Select a row and show or hide the nested table.
     */
    public select(asset) {
        this.selected = this.selected === asset ? null : asset;
        this.asset = asset;
        this.dataSourcePhases.data = [asset];
    };


}
