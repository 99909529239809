import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {

    /**
     * Constructor.
     */
    public constructor() { 

    }

    /**
     * On Init.
     */
    public ngOnInit() {
    }

}
