
import { Control } from './control';
import { User } from './user';

export class Asset {

    /* ATTRIBUTES */

    // ASSET

    public pkId: string;
    public parentPkId: string;
    public customPkId: string;
    public organizationPkId: string;
    public ownerPkId;
    public owner: any;
    public prettyId: string;

    public name = '';
    public alias = '';
    public description = '';
    public type: string; // 'Acquisition' | 'Asset' | 'Device' | 'Policy' | 'System' | 'Other' = 'System';
    public category: string;
    public priority: 'Low' | 'Medium' | 'High' = 'Low';
    public objectType: string;
    // public operatingModel: string; // 'Not Applicable (N/A)' | 'Government-Owned, Government-Operated (GOGO)' | 'Government-Owned, Contractor-Operated (GOCO)' | 'Contractor-Owned, Contractor-Operated (COCO)' | 'Other' = 'Not Applicable (N/A)';
    public status: string = '';
    // public securityLevel: string;
    public location: string; // Cloud, On Prem, or Hybrid
    public version: string;
    public health: number;
    // public hardwareBaseline = '';
    // public softwareBaseline = '';
    public standard: string;
    public age: number;
   
    // public isCoop: boolean;
    // public isFedramp: boolean;
    // public isPrivacy: boolean;
    // public isHighValue: boolean;
    // public isRetired: boolean;
    // public isDAR: boolean
    // public isDIT: boolean
    // public isMFA: boolean

    // public additionalDetails: any = {}; // for org-specific data (DEPRECATED / Moved to metadata)

    // CONTROLS

    //public classification: number;
    public classificationApproved: boolean;
    // public inheritanceCriteria: string;
    // public inheritanceDescription: string;
    public legacy: 'new' | 'existing' | 'renewed';

    // public isInheritable: boolean;
    // public isRequestable: boolean;
    // public isRequestableExternal: boolean;
    public publiclyAccessible: boolean;

    // public approvals: {
    //     [processPkId: string]: {
    //         date: Date,
    //         expirationDate: Date,
    //         referencePkId: string,
    //         referenceType: string,
    //         passFail: string,
    //         answers: any;
    //     }
    // } = {};

    // public submissions: { [processPkId: string]:
    //     {
    //         date: Date,
    //         submitted: boolean,
    //         referencePkId: string,
    //         referenceType: string,
    //         answers: any;
    //     }
    // } = {};

    // LIST

    public keywords: string[];
    public processes: string[] = [];
    public technology: any = {};

    // SECURITY

    public roleMap = new Map<string, any>(); // list of users mapped by role name
    public roles = []; // list of roles and users assigned to them for this asset
    public users = []; // list of authorized users who can access this asset

    // DATES

    public created: Date;
    // public startDate: Date;
    // public anticipatedAssessment: Date;
    // public expectedGoLive: Date;
    // public expiration: Date;
    // public submissionDeadline: Date;

    // Metadata
    public metadata: any;

    // BINDED DATA / LOGICAL FIELDS

    public SDLC: any;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
    }

    /* METHODS */

    /**
     * Returns the controls grouped by family as a hash map.
     */
    public getControlsGroupByFamily(controls, processPkId?): Map<string, Control> {
        let map = new Map();
        for (let control of controls) {
            if (!processPkId || processPkId === control.processPkId) {
                let key = control.family;
                if (map.has(key)) {
                    let value: any [] = map.get(key);
                    value.push(control);
                }
                else {
                    let value = [];
                    value.push(control);
                    map.set(key, value);
                }
            }
        }
        console.log(map)
        return map;
    }

    /**
     * Get the list of users mapped by role name. Note: the roles and 
     * list of authorized users must be passed in because they are already
     * filtered for the selected asset.
     */
    public getRoleMap(roles: any[], users: User[]): Map<string, User[]> {
        this.users = users;
        this.roles = roles;
        let map = new Map<string, User[]>();
        for (let role of roles) {

            // Get all of the users who have been designated the specified
            // role and then, add them to the role map.

            let key = role.name;
            for (let user of users) {
                if (user.applications[0].roles.find(r => r.rolePkId === role.pkId && r.assetPkId === this.pkId)) {
                    if (map.has(key)) {
                        let value: any [] = map.get(key);
                        value.push(user);
                    }
                    else {
                        let value = [];
                        value.push(user);
                        map.set(key, value);
                    }
                }
            }
        }

        this.roleMap = map;
        return map;
    }

    /**
     * Set keywords.
     */
    public setKeywords(keywords: Array<string>) {
        this.keywords = keywords;
    }

}
