import { Injectable } from '@angular/core';

import { Cache } from '@src/shared/objects/cache';
import { Process } from '@src/shared/objects/process';

import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessService extends AbstractService  {

    /* ATTRIBUTES */

    /* METHODS */

    /**
     * creates a new process based on processTemplate PkId
     */
    public createProcess(organizationPkId: string, assetPkId: string, templatePkId) {
        return this.post('/process/create', { organizationPkId, assetPkId, templatePkId });
    }

    /**
     * create a new process Component
     */
     public createProcessComponent(component) {
        return this.post('/process/component/create', {component});
    }

    /**
     * delete a process Component
     */
     public deleteProcessComponent(pkId) {
        return this.post('/process/component/delete', {pkId});
    }

    /**
     * edit a process Component
     */
    public editProcessComponent(component) {
        return this.post('/process/component/edit', {component});
    }

    /**
     * finds a process with assetPkId and name
     */
    public findProcess(assetPkId: string, processPkId: string, name: string) {
        return this.post('/process/find', { assetPkId, processPkId, name });
    }

    /**
     * Update process meta data (ie. completion percentage). Convert / strip
     * the process into just the metadata and saves metadata to cache and
     * database.
     */
    public updateProcess(process: Process, assetPkId) {

        const cache = Cache.get();

        // tslint:disable-next-line: max-classes-per-file
        class Metadata {
            pkId: 'string';
            assetPkId: string;
            name: string;
            phases: {
                [phaseName: string]: {
                    completionPercent: number
                    steps: {[ stepName: string]: {
                        completionPercent: number
                    }},
                    status: 'Not Submitted' | 'Pending Review' | 'Passed' | 'Failed' | '';
                    assessments: {
                        result: 'Passed' | 'Failed',
                        justification: string,
                        date: Date
                    }[];
                }
            };
        }

        let processMetadata: Metadata = new Metadata();
        const oldMetadata = cache.getValue(Cache.KEYS.PROCESS_METADATA);
        processMetadata.assetPkId = assetPkId;
        processMetadata.name = process.name;
        processMetadata.pkId = oldMetadata.pkId;
        processMetadata.phases = {};
        if (process.phases) {
            for (let phase of process.phases) {
                processMetadata.phases[phase.name.replace(/[^a-zA-Z0-9]/g, '')] = {
                    completionPercent: phase.completionPercent,
                    steps: {},
                    status: oldMetadata.phases[phase.name.replace(/[^a-zA-Z0-9]/g, '')]?.status,
                    assessments: oldMetadata.phases[phase.name.replace(/[^a-zA-Z0-9]/g, '')]?.assessments
                  };
                if (phase.steps) {
                  for (let step of phase.steps) {
                    processMetadata.phases[phase.name.replace(/[^a-zA-Z0-9]/g, '')].steps[step.name.replace(/[^a-zA-Z0-9]/g, '')]
                      = {completionPercent: step.completionPercent};
                  }
                }
              }
        }

        // since 'start' phase isn't an actual phase, it won't always be generated,
        // so we copy it from the old metadata

        if (!processMetadata.phases['Start']) {
            processMetadata.phases['Start'] = oldMetadata.phases['Start'];
        }

        cache.setValue(Cache.KEYS.PROCESS_METADATA, processMetadata);
        return this.post('/process/update', { processMetadata });
    }

    /**
     * Update the phase status or assessments without risk of overwriting any phases or steps
     */
    public updatePhaseStatus(processPkId, assetPkId, phaseName, status, assessment?) {
        return this.post('/process/phase/update', { processPkId, assetPkId, phaseName, status, assessment });
    }

    /**
     * Update the step & phase percent completion without risk of overwriting any phases or steps.
     * Optionally set the phase status as well.
     */
    public updateStepStatus(processPkId, assetPkId, phaseName, stepName, completion, status?:string) {
        return this.post('/process/step/update', { processPkId, assetPkId, phaseName, stepName, completion, status });
    }

    /**
     * Update the step & phase percent completion without risk of overwriting any phases or steps for ALL processes.
     * Optionally set the phase status as well.
     */
    public updateProcessStepForAllProcesses(currentProcessPkId, assetPkId, organizationPkId, referenceType, completion, status?:string) {
        return this.post('/process/step/updateAll', { currentProcessPkId, assetPkId, organizationPkId, referenceType, completion, status });
    }

    /**
     * Update the process Metadata.
     */
    public updateMetadata(processMetadata) {
        return this.post('/process/update', { processMetadata });
    }
}
