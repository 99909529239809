import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, } from '@angular/core';

import { Approval } from '../../objects/approval';
import { Cache } from '../../objects/cache';
import { Signature } from '../../objects/signature';
import { User } from '../../objects/user';

import { ApprovalService } from '../../services/approval.service';
import { ListService } from '../../services/list.service';
import { UserService } from '../../services/user.service';
import config from '@app/app';
import { List } from '@src/shared/objects/list';

@Component({
  selector: 'app-approve-button',
  templateUrl: './approve-button.component.html',
  styleUrls: ['./approve-button.component.less']
})
export class ApproveButtonComponent implements OnInit, OnChanges {

    @Input() item; // the item being approved
    @Input() list: List;
    @Input() listComponent: 'All' | 'Form' | 'Help' | 'Infrastructure' | 'Process' | 'Security'; // the component that is used to lookup the list
    @Input() listSystemName; // the list system name
    @Input() owners; // the list of owners who need to know when all approvals are submitted
    @Input() workers; // the list of workers who need to know when all approvals are submitted
    @Input() roles;  // A list of roles that are permitted to approve
    @Output() status  = new EventEmitter<any>();

    /* ATTRIBUTES */

    public config;
    private cache: Cache; // the sesssion cache
    private current: User; // the current user
    private configuration;
    private organization; // the current organization
    private users; // the organization's user list

    public approval: Approval; // the approval associated with this item

    public isClosed = false; // determine if all approvals have been submitted
    public isApprover = false; // determine if the current user is the approver
    public statusBadge = '0/0'; // the status badge

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private router: Router,

        private approvalService: ApprovalService,
        private listService: ListService,
        private userService: UserService) {

    }

    /* INIT */

    /**
     * On Init.
     */
     public async ngOnInit() {
        this.cache = Cache.get();
        this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.users = this.cache.getValue(Cache.KEYS.USERS);

        // NOT SURE IF NEEDED SINCE IT IS CALLED IN ONCHANGES
        // this.refreshApproval();
    }

    /**
     * On Changes.
     */
    public ngOnChanges() {
        this.refreshApproval();
    }

    /* METHODS */

    /**
     * Redirect the user to the approve component. Encrypt the item and pass it 
     * in the parameter.
     */
    public approve() {
        this.approvalService.create(this.approval).subscribe(res => {
            // Using res.pkId instead of this.approval.pkId to get the pkId of the newly created approval
            this.router.navigate([config.routes.PRIVATE_AUTHORIZATION_APPROVE + '/' + res.pkId]);
            // let path = location.pathname;
            // let path = location.href;
            // let plaintext = JSON.stringify(this.item);
            // this.encryptionService.encrypt(plaintext).subscribe(res => {
            //     let ciphertext = JSON.stringify(res.value);
            //     this.router.navigate(
            //         ['/private/authorization/approve'],  
            //         { queryParams: { 
            //             item: ciphertext,
            //             listComponent: this.listComponent,
            //             listSystemName: this.listSystemName,
            //             owners: encodeURIComponent(JSON.stringify(this.owners)),
            //             workers: encodeURIComponent(JSON.stringify(this.workers)),
            //             path: encodeURIComponent(path)
            //         } }
            //     );
            // }); 
        });

    }    

    private async refreshApproval() {
        if (this.item?.pkId) {
            this.approvalService.get({ referencePkId: this.item.pkId }).subscribe(async res => {           
                this.approval = res ? new Approval(res) : new Approval();
    
                // if a list was not injected, look up the list
                if (this.list) {
                    this.approval.list = this.list;
                }
                else if (this.listSystemName) {
                    await this.listService.init(this.organization);
                    this.list = this.listService.getList(this.listSystemName);
                }
    
                this.approval.organizationPkId = this.organization?.pkId;
                this.approval.listPkId = this.list?.pkId;
                this.approval.listComponent = this.listComponent;
                this.approval.referenceName = this.item?.name;
                this.approval.referencePkId = this.item?.pkId;
                this.approval.referenceRoute = location.href;
                this.approval.referenceType = this.item?.constructor.name;
                this.approval.reference = this.item;
                this.approval.owners = this.owners;
                this.approval.workers = this.owners;
                this.approval.updateDate = new Date();
                if (this.list?.options?.length) {
                    if (this.list.type === 'User') {
                        for (let option of this.list.options) {
                            if (option.referencePkId === this.current.pkId) { this.isApprover = true; }
                            let approver = this.approval.approvers.find(a => a.pkId === option.referencePkId);
                            if (!approver) {
                                // this.userService.getByPkId(option.referencePkId).subscribe(res => {
                                let res = this.users.find(user => user.pkId === option.referencePkId)
                                if (res) {
                                    let approver = new Signature();
                                    approver.pkId = res.pkId;
                                    approver.firstName = res.firstName;
                                    approver.middleName = res.middleName || '';
                                    approver.lastName = res.lastName;
                                    approver.email = res.email; 
                                    approver.title = this.item?.answers?.roleName || '';
                                    approver.titlePkId = this.item?.answers?.rolePkId || '';
                                    this.approval.approvers.push(approver);
                                }
                                // });
                            }
                        }
                    }
                    else if (this.list.type === 'Role') {
                        
                        for (let option of this.list.options) {
                            const role = this.current.applications[0].roles.find(role_ => {
                                if (option.referenceType ===  'Asset' && option.referencePkId) {
                                    if (role_?.assetPkIds?.includes(option.referencePkId) && role_.rolePkId === option.value)  {
                                        return true;
                                    }
                                }
                                else {
                                    if (role_.rolePkId === option.value)  {
                                        return true;
                                    }
                                }
                                return false;
                            });
    
                            if (role){this.isApprover = true;}
    
                            let approver = this.approval.approvers.find(a => a.titlePkId ===  option.value);
    
                            if (!approver) {
                                let approver = new Signature();
                                approver.pkId = '';
                                approver.firstName = option.name;
                                approver.middleName = '';
                                approver.lastName = '';
                                approver.email = ''; 
                                approver.title = option.name;
                                approver.titlePkId = option.value;
                                this.approval.approvers.push(approver);
                            }
                        }
                    }
                }
                else  {
                    // if there is no list, anyone can approve.
                    this.isApprover = true;
                }
                // console.log("/shared/components/approve-button/ngOnInit: APPROVAL = ", this.approval);
                this.refreshBadge();  
            });      
        }
    }

    /**
     * Refresh the user interface with the latest badge and approval status.
     */
    private async refreshBadge() {
        let count = 0;
        let rejected: boolean = false;
        if (this.list?.options?.length) {
            for (let option of this.list.options) {
                let approver_ = this.approval.approvers.find(a => a.pkId === option.referencePkId || (a.titlePkId === option.value && a.pkId));
                if (approver_) {
                    let approver = new Signature(approver_)
                    if (approver.isApproved()) {
                        count++;
                    } else {
                        rejected = true;
                    }
                }
            }
            this.statusBadge = `${count}/${this.list.options.length}`;
            
            if (rejected ===  true) {
                this.status.emit('Failed');
            } else if (count === this.list.options.length) {
                this.status.emit('Passed');
            } else {
                this.status.emit('Pending Review');
            }
        }
        else {
            count = this.approval.approvers.filter(approver_ => approver_.status === 'Approve')?.length;
            rejected = this.approval.approvers.filter(approver_ => approver_.status === 'Reject')?.length > 0;
            this.statusBadge = `${count}`;
            if (rejected) {
                this.status.emit('Failed');
            } else if (count === this.approval.approvers.length) {
                this.status.emit('Passed');
            }
            else {
                this.status.emit('Pending Review');
            }
        }

        this.isClosed = ((this.list?.options?.length || 0) === count);  
    }
    
}
