import { Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subscription } from 'rxjs';

import { Cache } from '../objects/cache';

/**
 * Usage:
 * (autosave)="autoSave()" [user-input]="f"
 * bind autosave to your component's save function
 * bind user-input to an NgForm so it can detect changes
 */
@Directive({
  selector: '[autosave]'
})
export class AutosaveDirective implements OnInit, OnDestroy {

    /**
     * Initializes auto save with specified interval the autosave interval 
     * in mintues.
     */
    @Input('save-time') set setTime(newTime) { if (newTime) this.time = newTime;  }

    /**
     * Initializes auto save with specified interval the autosave interval 
     * in mintues.
     */
    @Input('user-input') userInput;

    /**
     * Enable or disable mat snack bar notifications:
     * EX: <div (autosave)="saveForm(data)" [showSnack]=false >
     */
    @Input('showSnack') showSnack = true;

    /**
     * Ignore validation.
     */
    @Input('ignore-validation') ignoreValidation;

    /**
     * Check if the form is readonly
     */
    @Input('isReadonly') isReadonly: boolean = false;

    /**
     * Emits a save event.
     */
    @Output('autosave') saveEvent = new EventEmitter<any>();

    /* ATTRIBUTES */

    private changed = false;
    private didFirstSave = true;
    private formChanges: Subscription;
    private formPristine = true;
    private saveTimerID;
    private typeTimerID;
    private typeTimeout = 1.5;
    
    public defaultSaveMessage = 'Changes have been saved successfully.';
    public time = 5;
    public window: Window;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private injector: Injector,
        private snackBar: MatSnackBar) {
        this.window = this.injector.get('$window');
    }

    /* EVENT HANDLING */

    /**
     * initializes autosave procedures
     */
    public ngOnInit() {

        // Autosave regardless of anything every 5 minutes
        // this.saveTimerID = this.window.setInterval(() => {
        //     console.log('Time has passed. Saving...', this.changed);
        //     if (this.changed) this.save();
        // }, this.time * (60 * 1000));

        // Skip if form is Readonly
        if (this.isReadonly) {
            // In View mode, Don't do anything
        } else {

            // Autosave if userInput is given as a form and that form is edited
            if (this.userInput && this.userInput.form) {
                const form: NgForm = this.userInput.form as NgForm;
                this.formChanges = form.valueChanges.subscribe(update => {
                    this.formPristine = form.pristine
                    // clears the previous timeout and sets a new one
                    // it will autosave the specified amount of time AFTER you stop typing
                    this.window.clearTimeout(this.typeTimerID);
                    if ((form.dirty && form.valid) || (form.dirty && this.ignoreValidation)) {
                        this.changed = true;
                        this.typeTimerID = this.window.setTimeout(() => {
                            console.log('Form has been updated. Saving...');
                            this.save();
                        }, this.typeTimeout * 1000);
                    }
                });
            }

            this.window.onbeforeunload = async function (event) {
                if (!this.formPristine) this.save();
            }.bind(this);

        }
    }

    /**
     * destroy's the interval and the timeout when the page is exited
     * then saves one last time.
     */
    public ngOnDestroy() {
        this.formChanges?.unsubscribe();
        this.window.clearInterval(this.saveTimerID);
        this.window.clearTimeout(this.typeTimerID);
        this.window.onbeforeunload = null;
        if (!this.formPristine) this.save();
    }

    /* METHODS */

    /**
     * Emits a save event.
     */
    private save() {
        if (this.didFirstSave) {
            if (this.showSnack) { 
              this.snack(this.defaultSaveMessage); 
            }
            this.saveEvent.emit();
            this.changed = false;
            this.formPristine = true;
        } 
        else { 
            this.didFirstSave = true; 
        }
    }

    /**
     * Shows a temporary message.
     */
    public snack(message, duration = 2000) {
        this.snackBar.open(message, '', {duration, panelClass: 'mat-success'});
    }
}
