<!-- PAGE -->

<mat-accordion>
    <ng-container *ngFor="let role of roles | sortArray:'name'"> 
        <ng-container *ngTemplateOutlet="teams; context: { $implicit: role }"></ng-container>
    </ng-container>
</mat-accordion>

<!-- COMPONENTS -->

<ng-template #teams let-role>
    <mat-expansion-panel class="mat-panel-accent mb-4" #panel [expanded]="false" hideToggle="true">
        <mat-expansion-panel-header >
            <mat-panel-title>    
                <div class="w-100">                    
                    <div class="left">                    
                        <mat-label matTooltip="{{role.name}}">
                            {{ role.name.length > 21 ? role.name.toUpperCase().substring(0,21)  + '...' : role.name.toUpperCase() }}
                        </mat-label>
                    </div>    
                    <div class="right">            
                        <a (click)="openDialog(addDialog, role)" *ngIf="canAdd">
                            <mat-icon>add_circle</mat-icon>
                        </a>
                    </div>   
                    <div class="right pr-1">  
                        {{role.users?.data.length > 0 ? role.users?.data.length : ''}}
                    </div>  
                </div>   
            </mat-panel-title>
            <mat-icon class="accent-color">{{panel.expanded?'expand_less':'expand_more'}}</mat-icon>
        </mat-expansion-panel-header>           
        <div class="w-100 pt-3">
            <table class="w-100" mat-table [dataSource]="role.users">
                <ng-container matColumnDef="name">
                    <th [width]="columnWidth.name" mat-header-cell *matHeaderCellDef></th>
                    <td [width]="columnWidth.name" mat-cell *matCellDef="let user" matTooltipPosition="left"> 
                        {{user.preferredFirstName || user.firstName || user.email}} {{user.preferredLastName || user.lastName}} 
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th [width]="columnWidth.actions" mat-header-cell *matHeaderCellDef> </th>
                    <td [width]="columnWidth.actions" mat-cell *matCellDef="let user">
                        <div class="flex- justify-content-end">
                            <button mat-icon-button (click)="openDialog(deleteDialog, { user: user, role: role })" *ngIf="canRemove">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr class="example-element-row" mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
            </table>
        </div>
    </mat-expansion-panel>
</ng-template>

<!-- DIALOGS -->

<ng-template #addDialog let-data>
    <div class="center">
        <div class="editor">
            <div class="editor-title">
                <div class="title uppercase">Add {{data.name}}</div>
            </div>  
            <div class="editor-section">                  
                <form #emailForm="ngForm">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Users</mat-label>
                        <mat-select name="userList" [(ngModel)]="selected" multiple required>      
                            <div class="filter">
                                <mat-icon>filter_list</mat-icon><mat-label class="filter-icon">Filter</mat-label>
                                <input class="filter-input" matInput placeholder="Search..." (input)="filter(data, $event.target.value, emailForm)" (keydown)="$event.stopPropagation()" aria-label="filter"/>
                            </div>
                            <mat-option *ngFor="let user of this.filtered| sortArray: 'firstName'" [value]="user.email">
                                {{ user.firstName + ' ' + user.lastName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <div actions class="action-btns right">
                <button mat-stroked-button color="accent" class="mr-3" (click)="closeAll()">Cancel</button>
                <button mat-flat-button color="accent" [disabled]="!emailForm.valid" (click)="assign(selected, data)">Submit</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteDialog let-data>
    <div class="page">
        <div class="center center-horizontal">
            <div class="infobox">
                <h2 class="infobox-title">REMOVE TEAM MEMBER</h2>
                <div class='infobox-content'>
                    <p>
                        Are you sure you want to remove <b>{{ data.user.firstName || data.user.preferredFirstName }}</b> from the <b>{{ data.role.roleName }}</b> role?
                        They will lose access to work on and view this asset.
                    </p>
                </div>    
                <div class="action-btns pt-3" mat-dialog-actions>
                    <button mat-stroked-button class="mr-3" color="accent" (click)="closeAll()">Cancel</button>
                    <button mat-flat-button color="warn" (click)="delete(data.user, data.role)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-route-tracker></app-route-tracker>