import { v4 as uuidv4 } from 'uuid';

/**
 * List.
 */
export class List {

    /* CONSTANTS */

    /* ATTRIBUTES */

    // GENERAL

    public pkId: string = ''; // the object id
    // public categoryPkId: string = ''; // the category for this list
    public organizationPkId: string = ''; // the organization pkid

    public name: string = ''; // the name of the list
    public description: string = ''; // the description of the list
    public systemName: string = ''; // the system name used by the application
    public type: 'Asset' | 'Control' | 'Identifier' | 'Text' | 'Role' | 'User' = 'Text'; // the type of list
    public reference: any = {}; // the list of reference available based on the type
    public referenceAction: string = ''; // the reference action
    public referencePkId: string = ''; // the reference pkid
    public referenceType: string = ''; // the reference type
    public structure: 'Linear' | 'Hierarchical' = 'Linear'; // the structure for the option
    public options: Option[] = []; // the options in the list

    // public category: string = ''; // the category for this list
    // public component: string = ''; // the application component where this list will be linked

    // DATE

    public createDate: Date = new Date(); // create date
    public updateDate: Date = new Date(); // last update date
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuidv4(); }
    }
    
}

/**
 * List Option.
 */
class Option {
    public position: number; // the sort position
    public name: string; // the name of the item
    public value: string; // the value of the item
    public referencePkId: string; // the reference pkid
    public referenceType: string; // the reference type
    // public secondaryReferencePkId?: string; // the reference pkid
    // public secondaryReferenceType?: string; // the reference type
}