import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Cache } from '@src/shared/objects/cache';
import { Comment } from '@src/shared/objects/comment';
import { CommentService } from '@src/shared/services/comment.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit, OnChanges {

  /* ATTRIBUTES */

    @Input() isTitleVisible = true;
    @Input() referencePkId;
    @Input() referenceType;
    @Input() organizationPkId;
    @Input() assetPkId;
    @Input() processPkId;

    cache: Cache;

    asset;
    configuration;
    user;
    users;

    roleMap = {};

    comments: Comment[] = [];

    /**
     * Constructor.
     */
    constructor(private commentService: CommentService) { }

    /* INIT. */

    /**
     * On Init.
     */
    ngOnInit(): void {
      this.cache = Cache.get();

      this.asset = this.cache.getValue(Cache.KEYS.ASSET);
      this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
      this.user = this.cache.getValue(Cache.KEYS.USER);
      this.users = this.asset?.users || [];
    }

    /**
     * On Changes (Input variable changes)
     */
    ngOnChanges() {
      if (this.referencePkId && this.referenceType) {
        // tslint:disable-next-line: max-line-length
        this.commentService.getByReference(this.referencePkId, this.referenceType, this.organizationPkId, this.assetPkId, this.processPkId).subscribe(res => {
          this.comments = res;
        });
      }
    }

    /* METHODS */

    /**
     * Add new Comment.
     */
    add(ev) {
      let newComment = new Comment({
        referencePkId: this.referencePkId,
        referenceType: this.referenceType,
        text: ev.form.controls.comment.value
      });
      this.comments.push(newComment);
      this.commentService.create(newComment, this.organizationPkId, this.assetPkId, this.processPkId).subscribe(res => {
        const index = this.comments.indexOf(newComment);
        this.comments[index] = res;
      });
      ev.resetForm();
    }

    /**
     * Edit Comment.
     */
    edit(ev) {
      delete ev.edit;
      this.commentService.update(ev).subscribe(res => {});
    }

    /**
     * returns list of roles associated with the user.
     */
    getRole(pkId: string) {
      if (this.roleMap[pkId]) {
        return this.roleMap[pkId];
      } else if (this.asset && this.users && this.configuration) {
        const commenter = this.users.find(user_ => user_.pkId === pkId);
        if (commenter) {
          const roles = commenter.applications[0].roles.filter(role => role.assetPkId === this.asset.pkId);
          this.roleMap[pkId] = roles.map(role => {
            return this.configuration.roles?.find(role_ => role_.pkId === role.rolePkId).name;
          }).join(', ');
          return this.roleMap[pkId];
        }
        return '';
      }
    }

    /**
     * Delete Comment.
     */
    delete(ev) {
      this.commentService.delete(ev.pkId).subscribe(res => {});
      const index = this.comments.indexOf(ev);
      this.comments.splice(index, 1);
    }
}
