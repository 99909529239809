<div [hidden]="options.length > 0 && !isReadOnly">
    <mat-form-field  class="w-100" appearance="outline">
        <mat-label>{{labelName}}</mat-label>
        <input [name]="selectName" #inputData matInput type="text" [(ngModel)]="model" (ngModelChange)="emitChange()" [required]="required"
            maxlength="100" [disabled]="isReadOnly">
            <mat-hint align="end">{{inputData.value.length}}/100</mat-hint>
    </mat-form-field>
</div>
    
<div [hidden]="options.length === 0 || isReadOnly">
    <mat-form-field  class="w-100" appearance="outline">
        <mat-label>{{labelName}}</mat-label>
        <mat-select [name]="selectName" [placeholder]="placeholder" [(ngModel)]="model" (ngModelChange)="emitChange()" [required]="required">
            <div class="filter">
                <div *ngIf="!showSearchBar" class="d-flex align-items-center">
                    <mat-label >Add New</mat-label>
                    <button (click)="showSearchBar=true" mat-icon-button><mat-icon>add_circle</mat-icon></button>
                </div>
                <div *ngIf="showSearchBar" class="d-flex align-items-center">
                    <input  class="filter-input" 
                    name="search" 
                    matInput 
                    placeholder="Add..." 
                    [(ngModel)]="itemToAdd" 
                    (keydown)="$event.stopPropagation()" 
                    (keypress)="omitSpecialChars($event)" 
                    (paste)="omitSpecialChars($event)"
                    focus
                    />
                    <div class="d-flex justify-content-center">
                        <button mat-icon-button matSuffix (click)="addItem()" [disabled]="itemToAdd.trim() === ''"><mat-icon>add_circle</mat-icon></button>
                        <button mat-icon-button matSuffix [ngStyle]="{'color':'#FF7171'}" (click)="hideSearchBar()"><mat-icon>cancel</mat-icon></button>
                    </div>
                    
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let option of options | sortArray" [value]="option" disableOptionCentering> {{option}} </mat-option>
        </mat-select>
    </mat-form-field>
</div>
    

