
import { Answer } from './answers';
import { Cache } from './cache';
import { Configuration } from './configuration';
import { Question } from './question';
import { Questionnaire } from './questionnaire';
import { Step } from './step';
export class Phase {

    /* ATTRIBUTES */

    pkId: string;
    name: string;
    icon: string; // icon to be displayed on phase tab.
    steps: Step[] = [];
    isNameHidden: boolean; // should Name be hidden on phase tab (icon only)?
    isProgressHidden: boolean; // should progress bar be hidden from phase tab?
    isRequired: boolean;
    status: string;

    approvers: string[];
    type: string;
    roles: any[]; // contains roles with users assigned

    completionPercent = 0;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */

    /**
     * Calculate the completion percent for every step in the phase.
     * WARNING: This method should only be used for occasional synchronization purpose.
     */
    async calculateCompletions(
        questions: Question[],
        answers_: Answer[],
        questionnaires: Questionnaire[]
        ){

        const steps = [];

        //console.log(answers_)
        const cache: Cache = Cache.get();
        const configuration: Configuration = cache.getValue(Cache.KEYS.CONFIGURATION);

        for (let step of this.steps) {
            step = new Step(step);
            if (step.referenceType === 'questionnaire') {
                const questionnaire = questionnaires.find(questionnaire_=> questionnaire_.pkId === step.referencePkId);
                if (questionnaire) {
                    const questionPkIds = [];
                    for (let section of questionnaire.sections) {
                        for (let question of section.questions) {
                            questionPkIds.push(question);
                        }
                    }
                    const questions_ = questions.filter(question => questionPkIds.includes(question.pkId));
                    //console.log(questionnaire.pkId)
                    const answers = answers_.find(answer_ => answer_.questionnairePkId === questionnaire.pkId);
                    if (answers) {
                        const result = await step.getPercentComplete(questions_, answers.answers);
                        step.completionPercent = result;
                        console.log('completion', step.completionPercent)
                    }
                    else {
                        step.completionPercent = 0;
                    }
    
                }
            } 
            steps.push(step)
        }
        return steps
    }

}
