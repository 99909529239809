// tslint:disable-next-line: max-line-length
import { AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';


@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ToggleButtonsComponent implements OnInit, AfterViewInit {
  @Output() buttonToggleClick: EventEmitter<any> = new EventEmitter();

  @Input('buttons') buttons;
  @Input() start: string | number;

  @ContentChildren(MatButton) button: QueryList<MatButton>;
  @ViewChild('buttonBackground') toggleBackgroundButton: ElementRef;

  selectedValue;
  selectedIndex;
  widths = [];

  constructor() { }

  ngAfterViewInit() {
    this.button.first._elementRef.nativeElement.style.borderRadius = `500px`;
    this.button.last._elementRef.nativeElement.style.borderRadius = `500px`;
    this.button.forEach((_button, index) => {
      if (
          (!this.start && index === 0) ||
          (this.start === _button._elementRef.nativeElement.value)
      ) {
        _button._elementRef.nativeElement.classList.add('selected-button');
        this.selectedIndex = index;
      }
      this.widths.push(_button._elementRef.nativeElement.offsetWidth);
      this.toggleBackgroundButton.nativeElement.style.left = this.getPosition();
      this.toggleBackgroundButton.nativeElement.style.width = this.getWidth();

      _button._elementRef.nativeElement.addEventListener('click', () => {
        this.button.forEach(__button => {
          __button._elementRef.nativeElement.classList.remove('selected-button');

        });
        this.buttonToggleClick.emit(_button._elementRef.nativeElement.value);
        this.selectedValue = _button._elementRef.nativeElement.value;
        this.selectedIndex = index;
        _button._elementRef.nativeElement.classList.add('selected-button');
        this.toggleBackgroundButton.nativeElement.style.left = this.getPosition();
        this.toggleBackgroundButton.nativeElement.style.width = this.getWidth();
      });
    });
  }

  ngOnInit() { }

  toggleClick(event, name) {
    console.log(event);
    this.buttonToggleClick.emit(name);
  }

  getPosition() {
    let widthValue = 0;
    for (let i = 0; i < this.selectedIndex; i++) {
      widthValue = widthValue + this.widths[i];
    }
    return `${widthValue - 1}px`;
  }

  getWidth() {
    const widthPixels = this.widths[this.selectedIndex];
    return `${widthPixels + 2}px`;
  }

}
