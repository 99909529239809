import { Pipe, PipeTransform } from '@angular/core';
/**
 * Take an property key
 * Return item array if key match and true, filtered out if false
 */
@Pipe({
    name: 'booleanFilter'
})
export class BooleanFilterPipe implements PipeTransform {
    transform(array: any[], key: string): any {
        if (!array || !key) {
            return array;
        }
        // filter items array, keep items with isActive == true
        return array.filter(item => item[key] === true);
    }
}
