<div class="mb-4">
    <div>
        <span class="uppercase table-title">Incomplete Forms ({{incompleteForms.length}})</span>
    </div>
    <table class="mt-4" mat-table [dataSource]="incompleteFormsDataSource" matSort>
        <ng-container matColumnDef="name">
            <th [width]="columnWidths.name" mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>
                    Name
                </div>
            </th>
            <td [width]="columnWidths.name" mat-cell *matCellDef="let item">
                <a class="uppercase" (click)="gotoComponent(item.phase, item)">
                    {{item.name}}
                </a>
            </td>
        </ng-container>  
        <ng-container matColumnDef="displayType">
            <th [width]="columnWidths.displayType" mat-header-cell *matHeaderCellDef mat-sort-header> 
                <div class="col-left">
                    Type
                </div> 
            </th>
            <td [width]="columnWidths.displayType" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.displayType | titlecase}}
                </div>
            </td>
        </ng-container>       
        <ng-container matColumnDef="phase">
            <th [width]="columnWidths.phase" mat-header-cell *matHeaderCellDef mat-sort-header> 
                <div class="col-left">
                    Phase                 
                </div>
            </th>
            <td [width]="columnWidths.phase" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.phase}}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="completionPercent">
            <th [width]="columnWidths.completionPercent" mat-header-cell *matHeaderCellDef mat-sort-header> 
                <div class="col-left">
                    Completion
                </div>
            </th>
            <td [width]="columnWidths.completionPercent" mat-cell *matCellDef="let item">
                <div class="col-left">
                    {{item.completionPercent | number: '1.0-0'}}%
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row tabIndex *matRowDef="let item; columns: columnsToDisplay;"></tr>
    </table>
    <div class="show-more-less gap-2 mt-1">
        <div class="show-btn" (click)="show('less', 'forms')" *ngIf="incompleteForms_.length > 5">Show less</div>
        <div class="show-btn" (click)="show('more', 'forms')" *ngIf="incompleteForms_.length < incompleteForms.length">Show more</div>
        <div class="show-btn" (click)="show('all', 'forms')" *ngIf="incompleteForms_.length < incompleteForms.length">Show All</div>
    </div>
</div>


<!-- LIST OF INCOMPLETE CONTROLS TABLE -->
<div class="">
    <div>
        <span class="uppercase table-title">Controls Not Implemented ({{incompleteControls.length}})</span>
    </div>
    <table class="mt-4" mat-table [dataSource]="incompleteControlsDataDource" #controlsSort="matSort" matSort>
        <ng-container matColumnDef="id">
            <th [width]="controlsColumnWidths.id" mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>
                    ID
                </div>
            </th>
            <td [width]="controlsColumnWidths.id" mat-cell *matCellDef="let control">
                <a class="uppercase" (click)="gotoControl(control)">
                    {{control.readable}}
                </a>
            </td>
        </ng-container>  
        <ng-container matColumnDef="title">
            <th [width]="controlsColumnWidths.title" mat-header-cell *matHeaderCellDef mat-sort-header> 
                <div class="col-left">
                    Name
                </div> 
            </th>
            <td [width]="controlsColumnWidths.title" mat-cell *matCellDef="let control">
                <a class="col-left" (click)="gotoControl(control)">
                    {{control.title}}
                </a>
            </td>
        </ng-container>       
        <ng-container matColumnDef="level">
            <th [width]="controlsColumnWidths.level" mat-header-cell *matHeaderCellDef mat-sort-header> 
                <div class="col-left">
                    Impact Level
                </div> 
            </th>
            <td [width]="controlsColumnWidths.level" mat-cell *matCellDef="let control">
                <div class="col-left" >
                    {{LIST_IMPACT_LEVEL[control.level]}}
                </div>
            </td>
        </ng-container> 
        <ng-container matColumnDef="overlays">
            <th [width]="controlsColumnWidths.overlays" mat-header-cell *matHeaderCellDef> 
                <div class="col-left">
                    Overlays
                </div> 
            </th>
            <td [width]="controlsColumnWidths.overlays" mat-cell *matCellDef="let control">
                <div class="icons-cell" >
                <span *ngFor="let overlay of control.overlays | keyvalue"
                    class="material-icons-outlined secondary-color"
                    matTooltip="{{overlay.key}}">
                    {{overlay.value?.icon}}
                </span>
            </div>
            </td>
        </ng-container> 
        <ng-container matColumnDef="status">
            <th [width]="controlsColumnWidths.status" mat-header-cell *matHeaderCellDef> 
                <div>
                    STATUS
                </div>
            </th>
            <td [width]="controlsColumnWidths.status" mat-cell *matCellDef="let control">
                <div>
                    {{control.status}}
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="controlsColumnsToDisplay"></tr>
        <tr mat-row tabIndex *matRowDef="let control; columns: controlsColumnsToDisplay;"></tr>
    </table>
    <div class="show-more-less gap-2 mt-1">
        <div class="show-btn" (click)="show('less', 'controls')" *ngIf="incompleteControls_.length > 5">Show less</div>
        <div class="show-btn" (click)="show('more', 'controls')" *ngIf="incompleteControls_.length < incompleteControls.length">Show more</div>
        <div class="show-btn" (click)="show('all', 'controls')" *ngIf="incompleteControls_.length < incompleteControls.length">Show All</div>
    </div>

    <div class="p-3" *ngIf="!incompleteControlsDataDource?.filteredData.length">There are no Controls not implemented for this System.</div>
</div>