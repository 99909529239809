
<div id="tableContainer">
    <!-- TABLE -->
    <div class="table-responsive">
        <table class="w-100" mat-table [dataSource]="dataSource" matSortActive="active" matSortDirection="asc" multiTemplateDataRows matSort (matSortChange)="sortData($event)">
            <div *ngFor="let column of columnsToDisplay; let i = index">
                <ng-container [matColumnDef]="column?.title ? column.title : column">                
                    <!-- Headers -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="columnMap[column]?.options || specialColumns.includes(column)" style="white-space: nowrap;" [width]="columnWidths[column]" aria-label="options">                
                        <!-- NORMAL HEADER -->
                        <div *ngIf="!headerTemplate">
                            <!-- TITLE -->
                            <div *ngIf="!columnMap[column]?.options && !columnMap[column]?.isHidden">
                                {{columnMap[column] ? columnMap[column].title : column}} 
                            </div>
                            <!-- FILTERS -->
                            <div *ngIf="columnMap[column]?.options">
                                {{columnMap[column].title ? columnMap[column].title : column }} 
                                <mat-form-field  class="no-underline" >
                                    <mat-select [(ngModel)]="filters[column]" (selectionChange)="applyFilter()" multiple [compareWith]="compare"> 
                                        <mat-option *ngFor = "let option of columnMap[column].options" [value]="option">
                                            {{ option }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> 
                            </div> 
                        </div>
                        <!-- INJECTED HEADER -->
                        <div *ngIf="headerTemplate">
                            <ng-container *ngTemplateOutlet="headerTemplate; context: {column: column, i: i, columnMap: columnMap}"></ng-container>
                        </div>
                    </th>
                    <!-- ROWS -->    
                    <td mat-cell *matCellDef="let item" [width]="columnWidths[column]" [ngClass]="columnMap[column]?.class ? columnMap[column]?.class : (globalCellClassGetter ? globalCellClassGetter(item, column, rawDataMap): {})">
                        <!-- NORMAL COLUMN -->
                        <div *ngIf="!specialColumns.includes(column)">                        
                            <!-- GET DATA FROM INJECTED METHOD  -->
                            <div *ngIf="columnMap[column]?.getValue"
                                [ngClass]="isTruncateCell ? 'truncate-text-vertical wrap-text mt-3 mb-3' : ''">
                                {{columnMap[column]?.pipe ? columnMap[column].pipe.transform(columnMap[column].getValue(item, column, rawDataMap), columnMap[column].pipeArgs) : columnMap[column].getValue(item, column, rawDataMap)}}
                            </div>
                            <!-- NORMAL GET DATA -->
                            <div *ngIf="!columnMap[column]?.getValue" 
                                [ngClass]="isTruncateCell ? 'truncate-text-vertical wrap-text mt-3 mb-3' : ''">
                                {{columnMap[column]?.pipe ? columnMap[column].pipe.transform(item[column], columnMap[column].pipeArgs) : item[column]}}
                            </div>                        
                        </div>
                        <!-- EXPAND ROW COLUMN -->
                        <div *ngIf="columnMap[column]?.title ? columnMap[column].title === 'expand' : column === 'expand'">
                            <mat-icon>
                                {{item === expandedElement ? 'expand_less' : 'expand_more'}}
                            </mat-icon>
                        </div>
                        <!-- ACTIONS MENU COLUMN -->
                        <div class="center" *ngIf="column === 'actions'">
                            <button mat-icon-button [matMenuTriggerFor]="actions"><mat-icon>more_horiz</mat-icon></button>
                            <mat-menu #actions="matMenu">
                                <button mat-menu-item *ngFor="let menuItem of actionItems" (click)="menuItem.action ? emit(menuItem.action, rawDataMap[item.mappingPkId] || item) : menuItem.onClick(rawDataMap[item.mappingPkId] || item)">
                                    <mat-icon>{{menuItem.icon}}</mat-icon>
                                    {{menuItem.title}}
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>
            </div>
            <!-- EXPANDED DETAILS -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let item" [attr.colspan]="columnsToDisplay.length + 3" >
                    <div *ngIf="item === expandedElement" >
                        <ng-template [ngTemplateOutlet]="expandedDetailsTemplate" [ngTemplateOutletContext]="{item:item}"></ng-template>
                    </div>
                </td>
            </ng-container>    
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" (click)="expandElement(element)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [style.display]="row === expandedElement ? 'revert' : 'none'" class="example-detail-row"></tr>
        </table>
    </div>
    
    <!-- DEFAULT PAGINATION -->
    <div class="flex- justify-content-center" *ngIf="isUsingDefaultPaginator && !isScrollTrigger">
        <mat-paginator (page)="pageChange($event)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100, 200, 500]"></mat-paginator>
    </div>
    <!-- CUSTOM PAGINATION -->
    <div class="flex- justify-content-center align-items-center" *ngIf="!isUsingDefaultPaginator && !isScrollTrigger">
        <span class="pr-3">Page {{(end/pageSize) | number: '1.0-0' }} of {{roundUp(count/pageSize) | number: '1.0-0'}}</span>
        <div>
            <button 
            mat-icon-button 
            (click)="pageNavigate('Previous')" 
            [disabled]="start === 0"
            aria-label="Previous">
            <mat-icon>navigate_before</mat-icon>
        </button>
        <button
            mat-icon-button
            (click)="pageNavigate('Next')" 
            aria-label="Next"
            [disabled]="end >= count">
            <mat-icon>navigate_next</mat-icon>
        </button>
        </div>
        <span class="pl-3">Total Items: {{count | number}}</span>
    </div>
</div>

