import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.less']
})
export class DialogComponent implements OnInit {

    @Input() width: string;
    @Input() showX: boolean = true;
  
    /* CONSTRUCTOR */

    /**
     * Constructor.
     * @param dialog 
     */
    constructor(private dialog: MatDialog) { 
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    ngOnInit() {
    }

    /* UTILITIES */

    /**
     * Close dialog.
     */
    closeAll() {
        this.dialog.closeAll();
    }

}
