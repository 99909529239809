import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { ChartStyler } from '@src/shared/objects/chart-styler';
import { Cache } from '@src/shared/objects/cache';

/**
 * Pie Chart
 * Example: 
 * Input Data:
 */
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.less']
})
export class PieChartComponent implements OnInit, OnChanges {

    @Input() data = [];

    @Input() title = '';
    @Input() xLabel = '';
    @Input() yLabel = '';

    @Input() height = 250;
  
    /* ATTRIBUTES */
  
    public chart; // the chart object
    private chartOptions: Highcharts.Options; // the options used to render the chart

    private theme = ChartStyler.get();
  
    /* CONSTRUCTOR */
  
    /**
     * Constructor.
     */
    public constructor() {
    }
  
    /* EVENT HANDLING */
  
    /**
     * On Init.
     */
    public ngOnInit(): void {
        Cache.onChanges().subscribe(res => {
            if (res?.key === 'preferences') {
              this.theme = ChartStyler.get();
              this.generateChart();
            }
        });
    }
  
    /**
     * On Change.
     */
    public ngOnChanges(): void {
        this.generateChart();
    }
  
    /* METHODS */
  
    /**
     * Generate Chart.
     */
    public generateChart() {
        this.chartOptions = {
            chart: {
                margin: [50, 0, 15, 70],
                backgroundColor: 'transparent',
                height: this.height,
            },
            title: {
                text: this.title,
                y: 35,
                align: 'left'
            },
            
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                align: 'left',
                verticalAlign: 'middle',
                itemMarginBottom: 7,
                itemStyle: {
                    width: 120,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }  
            },
            plotOptions: {
                pie: {
                    center: ["65%", "50%"],
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    animation: {
                        duration: 0
                    },
                    borderWidth: 0,
                    colors: this.theme.color.pieSlices01
                }
            },
            series: [{
                type: 'pie',
                name: this.title,
                innerSize: '40%',
                size: 140,
                data: this.data
            }]

        };
        this.chart = new Chart(this.chartOptions);
    }

}
