<mat-form-field class="w-fill pt-2">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-chip-grid class="mt-5"
        #chipList
        [componentIf]="{val: securityRoute}" 
        [showAsDisabled]='true' 
        [matElement]='chipList'>
        <div>
            <mat-chip-row *ngFor="let item of data" [removable]="!isReadonly" (removed)="removeChip(item)" style="z-index: 2" handle>
                <span class="chip-content">{{item}}</span>
                <mat-icon *ngIf="!isReadonly" matChipRemove>cancel</mat-icon>
            </mat-chip-row>
        </div>
        <input
            class="pt-2 pb-2" 
            [placeholder]="isReadonly ? '' : 'ENTER or COMMA for new Item'"
            ngModel
            [name]="name"
            [readonly]="isReadonly"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addChip($event)"
            style="z-index: 3"
            aria-label="item">
    </mat-chip-grid>
</mat-form-field>