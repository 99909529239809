import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { MainModule } from './main/main.module';

if (environment.production) {
  enableProdMode();
}

if (!environment.enableDebug) {
  window.console.log = function() {};
  window.console.error = function() {};
}

platformBrowserDynamic().bootstrapModule(MainModule);
