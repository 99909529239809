<button class="topic-button" *ngIf="topics_?.length > 0" 
    mat-mini-fab color="primary" 
    aria-label="Trainging Library"
    #menuTrigger="matMenuTrigger"  
    [matMenuTriggerFor]="menu"
    (click)="topics_.length === 1 ? select(topics_[0]) : menuTrigger.openMenu()">
    <mat-icon>question_mark</mat-icon>
</button>

<mat-menu #menu="matMenu">
    <h5 class="pl-3 pr-3 underline">TRAINING LIBRARY</h5>
    <button mat-menu-item *ngFor="let topic of topics_ | sortArray: 'title'" 
        (click)="select(topic)">
        {{topic.title}}
    </button>
</mat-menu>