import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Channel } from '@src/shared/objects/channel';
import { User } from '@src/shared/objects/user';
import { ChannelService } from '@src/shared/services/channel.service';



@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.less']
})
export class InviteComponent implements OnInit {

    @Input() user: User;
    @Input() channel: Channel;

    @Output() eventEmitter = new EventEmitter<any>();

    /* ATTRIBUTES */

    isVisible = true;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
      private channelService: ChannelService) { 

    }

    /**
     * On Init.
     */
    public ngOnInit(): void {
    }

    /* METHODS */

    /**
     * Update membership status for a channel. 
     */
    public updateInvite(status: string) {
        if (this.user) {
            if (this.channel) {
                let member = this.channel.members.find(item => item.userPkId === this.user.pkId);
                if (member) {
                    member.status = (status === "Accepted") ? "Accepted" : "Rejected";
                    this.channelService.status(this.channel, status).subscribe(res => {
                        this.isVisible = false;
                        this.eventEmitter.emit({ type: 'Channel', reference : this.channel, status : status });
                    });
                }
                else {
                    member = { 
                        userPkId : this.user.pkId,
                        status : (status === "Accepted") ? "Accepted" : "Rejected",
                        createDate : new Date(),
                        updateDate : new Date()
                    };

                    this.channel.members.push(member);
                    this.channelService.update(this.channel).subscribe(res => {
                        this.isVisible = false;
                        this.eventEmitter.emit({ type: 'Channel', reference : this.channel, status : status });
                    });
                }
            }
        }
    }

}
