import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
  /**
   * Transforms a number above 999 to have a suffix examples:
   * 1000->'1k'
   * 59103822 -> '59M'
   * 3141592653 -> '3.141B' with arg 3
   * @param value the number to be transformed
   * @param args first arg dictates how many decimal places to show
   */
  transform(value: any, args?: any): any {
    // k = thousands
    // M = Millions
    // B = Billions
    // T = Trillions
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
    const absValue = Math.abs(value);

    if (Number.isNaN(value)) {
      return null;
    }

    if (absValue < 1000) {
      return value;
    }

    const exp = Math.floor(Math.log(absValue) / Math.log(1000));

    const parsedValue =
      (absValue / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];

    return value < 0 ? '-' + parsedValue : parsedValue;
  }
}
