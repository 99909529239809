import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { MaterialModule } from '@src/shared/material.module';
import { SharedModule } from '@src/shared/shared.module';
import { DefaultComponent } from './_/default/default.component';

import { RequestComponent } from './_/request/request.component';
import { MenuComponent } from './_/menu/menu.component';
import { InviteComponent } from './_/invite/invite.component';

const routes: Routes = [
];

@NgModule({
    declarations: [
        ChatComponent,
        DefaultComponent,
        RequestComponent,
        MenuComponent,
        InviteComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
          SharedModule
    ],
    exports: [
        ChatComponent,
    ]
})
export class ChatModule { 
  
}
