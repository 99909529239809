import { ChartModule } from 'angular-highcharts';
import { CommonModule } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { NgxEditorModule } from 'ngx-editor';
import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';

import { Asset } from '@src/shared/objects/asset';

// PIPES

import { ArrayFilterPipe } from '@src/shared/pipes/array-filter.pipe';
import { ArrayListPipe } from '@src/shared/pipes/array-list.pipe';
import { ArraySortPipe } from '@src/shared/pipes/array-sort.pipe';
import { BooleanFilterPipe } from '@src/shared/pipes/boolean-filter.pipe';
import { DebugPipe } from '@src/shared/pipes/debug.pipe';
import { KeySafePipe } from '@src/shared/pipes/key-safe.pipe';
import { MoneyTransformPipe } from '@src/shared/pipes/money-transform.pipe';
import { NameFilterPipe } from '@src/shared/pipes/name-filter.pipe';
import { NumberSuffixPipe } from '@src/shared/pipes/number-suffix.pipe';
import { ReadablePipe } from '@src/shared/pipes/readable-id.pipe';
import { RegexTransformPipe } from '@src/shared/pipes/regex.pipe';
import { RomanNumeralPipe } from '@src/shared/pipes/roman-numeral.pipe';
import { ShowHidePipe } from '@src/shared/pipes/showHide.pipe';
import { SortArrayPipe } from '@src/shared/pipes/sort-array.pipe';
import { StripHTMLPipe } from '@src/shared/pipes/strip-html.pipe';

// DIRECTIVES

import { AutosaveDirective } from '@src/shared/directives/autosave.directive';
import { CardFlipDirective } from '@src/shared/directives/card-flip.directive';
import { CdkDetailRowDirective } from '@src/shared/directives/cdk-detail-row.directive';
import { ComponentIfDirective } from '@src/shared/directives/component-if.directive';
import { EqualsToDirective } from '@src/shared/directives/equals-to.directive';
import { FilteringDirective } from '@src/shared/directives/filtering.directive';
import { InputMaskDirective } from '@src/shared/directives/input-mask.directive';
import { NotificationsComponent } from '@src/shared/components/notifications/notifications.component';
import { ResizableDirective } from '@src/shared/directives/resizable.directive';
import { RouterIfDirective } from '@src/shared/directives/router-if.directive';
import { RouterLinkIfDirective } from '@src/shared/directives/router-link-if.directive';
import { TabIndexDirective } from '@src/shared/directives/tab-index.directive';
import { UniqueCheckDirective } from '@src/shared/directives/unique-check.directive';

// BUSINESS COMPONENTS

import { ApproveButtonComponent } from '@src/shared/components/approve-button/approve-button.component';
import { AssetSelectorComponent } from '@src/shared/components/asset-selector/asset-selector.component';
import { AssetSummaryComponent } from './components/asset-summary/asset-summary.component';
import { AssetTeamComponent } from '@src/shared/components/asset-team/asset-team.component';
import { MissionToRiskTableComponent } from '@src/shared/components/mission-to-risk-table/mission-to-risk-table.component';

// GENERIC COMPONENTS

import { BarChartComponent } from '@src/shared/components/bar-chart/bar-chart.component';
import { BreadcrumbComponent } from '@src/shared/components/breadcrumb/breadcrumb.component';
import { BackToTopComponent } from '@src/shared/components/back-to-top/back-to-top.component';
import { CommentComponent } from '@src/shared/components/comment/comment.component';
import { ChangeHistoryComponent } from './components/change-history/change-history.component';
import { ChipListComponent } from '@src/shared/components/chip-list/chip-list.component';
import { CloseButtonComponent } from '@src/shared/components/close-button/close-button.component';
import { DialogComponent } from '@src/shared/components/dialog/dialog.component';
import { EditorComponent } from '@src/shared/components/editor/editor.component';
import { ExportButtonComponent } from '@src/shared/components/export-button/export-button.component';
import { FileComponent } from '@src/shared/components/file/file.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { IconPickerComponent } from '@src/shared/components/icon-picker/icon-picker.component';
import { ImageInputComponent } from '@src/shared/components/image-input/image-input.component';
import { ImageViewerComponent } from '@src/shared/components/image-viewer/image-viewer.component';
import { ImportSpreadsheetComponent } from '@src/shared/components/import-spreadsheet/import-spreadsheet.component';
import { IncompleteProcessTableComponent } from '@src/shared/components/incomplete-process-table/incomplete-process-table.component';
import { InfinitePaginatorComponent } from '@src/shared/components/infinite-paginator/infinite-paginator.component';
import { InfiniteSortComponent } from '@src/shared/components/infinite-sort/infinite-sort.component';
import { InfiniteTableComponent } from '@src/shared/components/infinite-table/infinite-table.component';
import { InlineAlertComponent } from '@src/shared/components/inline-alert/inline-alert.component';
import { LoadingSpinnerComponent } from '@src/shared/components/loading-spinner/loading-spinner.component';
import { NonChipComponent } from '@src/shared/components/non-chip/non-chip.component';
import { ObjectFieldEditorComponent } from '@src/shared/components/object-field-editor/object-field-editor.component';
import { ObjectTransformEditorComponent } from '@src/shared/components/object-transform-editor/object-transform-editor.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PhaseStatusComponent } from '@src/shared/components/phase-status/phase-status.component';
import { ProcessCardComponent } from '@src/shared/components/process-card/process-card.component';
import { ProcessTableComponent } from '@src/shared/components/process-table/process-table.component';
import { RiskAcceptanceTableComponent } from '@src/shared/components/risk-acceptance-table/risk-acceptance-table.component';
import { RouteTrackerComponent } from '@src/shared/components/route-tracker/route-tracker.component';
import { SearchableDropDown } from '@src/shared/components/searchable-dropdown/searchable-dropdown.component';
import { SearchBarComponent } from '@src/shared/components/search-bar/search-bar.component';
import { SignatureInputComponent } from '@src/shared/components/signature-input/signature-input.component';
import { ScreenModeComponent } from '@src/shared/components/screen-mode/screen-mode.component';
import { StackedSnackbarsComponent } from '@src/shared/components/stacked-snackbars/stacked-snackbars.component';
import { StepperStepComponent } from '@src/shared/components/stepper/step/step.component';
import { StepperComponent } from '@src/shared/components/stepper/stepper.component';
import { TabNavigatorComponent } from '@src/shared/components/tab-navigator/tab-navigator.component';
import { TreeComponent } from '@src/shared/components/tree/tree.component';
import { SuggestionDropDown } from '@src/shared/components/suggestion-dropdown/suggestion-dropdown.component';
import { ToggleButtonsComponent } from '@src/shared/components/toggle-buttons/toggle-buttons.component';
import { TopicSelectorComponent } from './components/topic-selector/topic-selector.component';
import { TopicTableComponent } from './components/topic-table/topic-table.component';
import { UploaderComponent } from '@src/shared/components/uploader/uploader.component';
import { UploadDialogComponent } from '@src/shared/components/upload-dialog/upload-dialog.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { WindowComponent } from '@src/shared/components/window/window.component';


@NgModule({
    imports: [
        ChartModule,
        CommonModule,
        EditorModule,
        FileUploadModule,
        FormsModule,
        MaterialModule,
        NgxEditorModule,
        PortalModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    declarations: [
        ApproveButtonComponent,        
        AssetSelectorComponent,
        AssetSummaryComponent,
        AssetTeamComponent,
        MissionToRiskTableComponent,

        BarChartComponent,
        BreadcrumbComponent,
        BackToTopComponent,
        CommentComponent,
        ChangeHistoryComponent,
        ChipListComponent,
        CloseButtonComponent,
        DialogComponent,
        EditorComponent,
        ExportButtonComponent,
        FileComponent,
        GaugeChartComponent,
        IconPickerComponent,
        ImageInputComponent,
        ImageViewerComponent,
        ImportSpreadsheetComponent,
        IncompleteProcessTableComponent,
        InfinitePaginatorComponent,
        InfiniteSortComponent,
        InfiniteTableComponent,
        InlineAlertComponent,
        LoadingSpinnerComponent,
        NonChipComponent,
        NotificationsComponent,
        ObjectFieldEditorComponent,
        ObjectTransformEditorComponent,
        PieChartComponent,
        PhaseStatusComponent,
        ProcessCardComponent,
        ProcessTableComponent,
        RiskAcceptanceTableComponent,
        RouteTrackerComponent,
        SearchableDropDown,
        SearchBarComponent,
        SignatureInputComponent,
        ScreenModeComponent,
        StepperComponent,
        StepperStepComponent,
        StackedSnackbarsComponent,
        SuggestionDropDown,
        TabNavigatorComponent,
        TreeComponent,
        ToggleButtonsComponent,
        TopicSelectorComponent,
        TopicTableComponent,
        UploadDialogComponent,
        UploaderComponent,
        UserSearchComponent,
        WindowComponent,
        
        ArrayFilterPipe,
        ArrayListPipe,
        ArraySortPipe,
        BooleanFilterPipe,
        DebugPipe,
        KeySafePipe,
        MoneyTransformPipe,
        NameFilterPipe,
        NumberSuffixPipe,
        ShowHidePipe,
        ReadablePipe,
        RegexTransformPipe,
        RomanNumeralPipe,
        SortArrayPipe,
        StripHTMLPipe,

        AutosaveDirective,
        CardFlipDirective,
        CdkDetailRowDirective,
        ComponentIfDirective,
        EqualsToDirective,
        FilteringDirective,
        InputMaskDirective,
        ResizableDirective,
        RouterLinkIfDirective,
        RouterIfDirective,
        TabIndexDirective,
        UniqueCheckDirective,
        
    ],
    exports: [
        ApproveButtonComponent,
        AssetSelectorComponent,
        AssetSummaryComponent,
        AssetTeamComponent,
        MissionToRiskTableComponent,

        BarChartComponent,
        BreadcrumbComponent,
        BackToTopComponent,
        CommentComponent,
        ChangeHistoryComponent,
        ChipListComponent,
        CloseButtonComponent,
        DialogComponent,
        EditorComponent,
        ExportButtonComponent,
        FileComponent,
        GaugeChartComponent,
        IconPickerComponent,
        ImportSpreadsheetComponent,
        InfinitePaginatorComponent,
        InfiniteSortComponent,
        InfiniteTableComponent,
        InlineAlertComponent,
        ImageInputComponent,
        ImageViewerComponent,
        IncompleteProcessTableComponent,
        LoadingSpinnerComponent,
        NonChipComponent,
        NotificationsComponent,
        ObjectFieldEditorComponent,
        ObjectTransformEditorComponent,
        PieChartComponent,
        PhaseStatusComponent,
        ProcessCardComponent,
        ProcessTableComponent,
        RiskAcceptanceTableComponent,
        RouteTrackerComponent,
        SearchableDropDown,
        SearchBarComponent,
        ScreenModeComponent,
        SignatureInputComponent,
        StepperComponent,
        StepperStepComponent,
        StackedSnackbarsComponent,
        SuggestionDropDown,
        TabNavigatorComponent,
        TreeComponent,
        ToggleButtonsComponent,
        TopicSelectorComponent,
        TopicTableComponent,
        UploadDialogComponent,
        UploaderComponent,
        UserSearchComponent,
        WindowComponent,

        ArrayFilterPipe,
        ArrayListPipe,
        ArraySortPipe,
        BooleanFilterPipe,
        DebugPipe,
        KeySafePipe,
        NameFilterPipe,
        NumberSuffixPipe,
        ShowHidePipe,
        ReadablePipe,
        ResizableDirective,
        RomanNumeralPipe,
        RegexTransformPipe,
        SortArrayPipe,
        StripHTMLPipe,

        AutosaveDirective,
        CardFlipDirective,
        CdkDetailRowDirective,
        ComponentIfDirective,
        EqualsToDirective,
        InputMaskDirective,
        FilteringDirective,
        RouterIfDirective,
        RouterLinkIfDirective,
        TabIndexDirective,
        UniqueCheckDirective,
        NgxEditorModule
    ],
    providers: [
        NumberSuffixPipe,
        { provide: '$window', useValue: window },
        { provide: '$document', useValue: document },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
      ],
      exports: [
        Asset
      ]
    };
  }
}
