import _ from 'lodash';
import { ConfigurationService } from '../services/configuration.service';
import { QuestionnaireService } from '../services/questionnaire.service';
import { Answer } from './answers';
import { Cache } from './cache';
import { Configuration } from './configuration';

import { Phase } from './phase';
import { Question } from './question';
import { Questionnaire } from './questionnaire';
import { Step } from './step';


export class Process {

    /* ATTRIBUTES */

    pkId: string;
    name: string;
    phases: Phase[];
    isActive = true;
    completionPercent: number;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */


    /**
     * Calculate the completion percent for every phase in the process.
     * WARNING: This method should only be used for occasional synchronization purpose.
     */
     async calculateCompletions(questions: Question[], answer: Answer[], questionnaires: Questionnaire[]){
        const phases = [];
        for (let phase of this.phases) {
            phase = new Phase(phase);
            phase.steps =  await phase.calculateCompletions(questions, answer, questionnaires);
            phases.push(phase);
        }
        this.phases = phases;
        console.log(this)
    }


    /**
     * find phase where a particular component is located using referenceType.
     */
    findComponent(referenceType: string, name?: string) {
        for (let phase of this.phases) {
            for (let step of phase.steps) {
                if (step.referenceType === referenceType && (name ? name === step.name : true)) {
                    return {phase, step};
                }
            }
        }
    }

}
