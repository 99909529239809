<div>
    <div class="pb-5">        
        <p>Our team is available to help!</p>
        <p>How can we assist you today?</p>
    </div>
    <div class="pb-3">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select name="type" [(ngModel)]="help.type" required>
                <mat-option *ngFor="let type of this.helpService.LIST_HELP_TYPE | arraySort" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="pb-3">
        <mat-form-field class="w-100" appearance="outline" required>
            <mat-label>Title / Topic</mat-label>
            <input #chatTitle matInput type="text" placeholder="Enter Ticket Title" [(ngModel)]="help.name" required>
        </mat-form-field>        
    </div>
    <div class="pb-3">
        <mat-form-field class="w-100 textarea-label" appearance="outline" required>
            <mat-label>Description</mat-label>
            <textarea name="message" matInput type="text" [(ngModel)]="help.description" placeholder="Describe your issue." required> 
            </textarea>
        </mat-form-field>        
    </div>
    <div class="pb-3">
        <div class="d-flex justify-content-center" *ngIf="isValid()">
            <button class="btn-round" mat-button (click)="send()">Create Request</button>
        </div>
    </div>
</div>
