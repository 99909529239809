import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// import { Help } from 'src/app/shared/objects/help';
import { HelpService } from '@src/shared/services/help.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.less']
})
export class RequestComponent implements OnInit {

    @Input() help;
    @Output() eventEmitter = new EventEmitter<any>();

    /* ATTRIBUTES */

    // help: Help = new Help();

    /* CONSTRUCTOR */

    /**
     * Contructor.
     */
    public constructor(
        public helpService: HelpService) {
    }

    /**
     * OnInit.
     */
    public ngOnInit(): void {
    }

    /* METHODS */

    /**
     * Returns whether the form is valid.
     */
    public isValid() {
        return (
            this.help.name?.length > 0 && 
            this.help.description?.length > 0 &&
            this.help.type?.length > 0
        );
    }

    /**
     * Emit send event.
     */
    public send() {
        this.eventEmitter.emit({ type : 'Help', reference : this.help });
    }

}
