import { Injectable } from '@angular/core';

import { AbstractService } from '@src/shared/services/abstract.service';
import { lastValueFrom } from 'rxjs';
import { ExportConfig } from '../objects/export/export-config';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends AbstractService {


    public isExporting: boolean = false;
    /* EXPORT BUILDER METHODS */

    /**
     * Delete export configuration.
     */
    public delete(pkId: string) {
        return this.post("/export/delete", { pkId });
    }

    /**
     * Download the export.
     */
    public async download(pkId: string, filter?) {
        const data = await lastValueFrom(this.post("/export/download", { pkId, filter }, { isDownload: true }));
        this.downloadFromBlob(data.file, data.filename, data.contentType);
    }

    /**
     * Get export configuration.
     */
    public get(pkId: string) {
        return this.post("/export/get", { pkId });
    }

    /**
     * List export configurations.
     */
    public list(organizationPkId: string) {
        return this.post("/export/list", { organizationPkId });
    }

    /**
     * List By Route.
     */
     public listByRoute(route: string) {
        return this.post("/export/listByRoute", { route });
    }

    /**
     * Update export configuration.
     */
    public update(exportConfig: ExportConfig) {
        return this.post("/export/update", { exportConfig });
    }

    /* OLD EXPORT METHODS */

    /**
     * Export the entire asset to the Open Security Controls Assessment Language 
     * (OSCAL) format.
     */
    public exportAsset(assetPkId: string, processPkId: string) {
        return this.post('/export/asset', { assetPkId, processPkId }, { isDownload: true }).subscribe(data => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Export asset dashboard data as CSV.
     */
     public exportAssetDashboard(organizationPkId: string, organizationName: string, filters: any, keyword: string) {
        return this.post('/export/asset/dashboard', { organizationPkId, organizationName, filters, keyword },
            { isDownload: true }).subscribe(data => {
                this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Export controls.
     */
    public async exportControls(assetPkId: string, processPkId: string, organizationPkId: string) {
        const data = await lastValueFrom(this.post("/export/controls", { assetPkId, processPkId, organizationPkId }, { isDownload: true }));
        this.downloadFromBlob(data.file, data.filename, data.contentType);
    }

    public exportFindings(organizationPkId: string, query, filters) {
        return this.post('/export/findings', { organizationPkId, query, filters}, { isDownload: true }).subscribe(data => {
            this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }


    // /**
    //  * Export Findings.
    //  */
    // public exportFindings(organizationPkId: string, assetPkId, isPoam: boolean, pkIds?: string[]) {
    //     return this.post('/export/findings', { organizationPkId, assetPkId, pkIds, isPoam}, { isDownload: true }).subscribe(data => {
    //         this.downloadFromBlob(data.file, data.filename, data.contentType);
    //     });
    // }

    // /**
    //  * Export Findings All assets.
    //  */
    // public exportFindingsAll(organizationPkId: string, isPoam: boolean, filters: any, query: any, pkIds?: string[]) {
    //     return this.post('/export/findings/all', { organizationPkId, pkIds, filters, query, isPoam }, { isDownload: true }).subscribe(data => {
    //         this.downloadFromBlob(data.file, data.filename, data.contentType);
    //     });
    // }

    /**
     * Export the Finding's Closure Package
     */
    public async exportFindingPackage(findingPkId: string, additionalData?: any) {
        const data = await lastValueFrom(this.post('/export/findingPackage', { findingPkId }, { isDownload: true }));
        this.downloadFromBlob(data.file, data.filename, data.contentType);
    }

    /**
     * Export the form (questionnaire) to a pdf document.
     */
    public async exportQuestionnaire(assetPkId: string, processPkId: string, filePkId: string, questionnairePkId: string, toPDF?: boolean, additionalData?: any) {
        const data = await lastValueFrom(this.post("/export/questionnaire", { assetPkId, processPkId, filePkId, questionnairePkId, toPDF, additionalData }, { isDownload: true }));
        const arrayBuffer = await data.file.arrayBuffer()
        if (!toPDF) this.downloadFromBlob(data.file, data.filename, data.contentType);
        return arrayBuffer;
    }

    /**
     * Export controls.
     */
    public async exportSrtm(organizationPkId: string, assetPkId: string, processPkId: string, assessmentPlanPkId: string) {
        const data = await lastValueFrom(this.post("/export/srtm", { organizationPkId, assetPkId, processPkId, assessmentPlanPkId }, { isDownload: true }));
        this.downloadFromBlob(data.file, data.filename, data.contentType);
    }

    /**
     * Export Summary
     */
    public exportSummary(organizationPkId: string, assetPkId: string, processPkId: string) {
        return this.post('/export/summary', { organizationPkId, assetPkId, processPkId },
            {isDownload: true}).subscribe(data => {
                this.isExporting = false;
                this.downloadFromBlob(data.file, data.filename, data.contentType);
        });
    }

    /**
     * Export technology.
     */
    public async exportTechnology(organizationPkId: string, assetPkId: string) {
        const data = await lastValueFrom(this.post('/export/technology', { organizationPkId, assetPkId }, { isDownload: true}));
        return this.downloadFromBlob(data.file, data.filename, data.contentType);
    }

}
