<!-- 
<button class="close-btn" *ngIf="showX" mat-icon-button (click)="closeAll()">
    <mat-icon class="material-icons-outlined">close</mat-icon>
</button> 
-->

<mat-icon class="close-btn" (click)="closeAll()">close</mat-icon>

<div class="center">
    <div class="dialog">
        <div class="dialog-title">
            <mat-card-title>
                <h3 class="uppercase">
                    <ng-content select="[title]"></ng-content>
                </h3>
            </mat-card-title>
        </div>
        <div class="dialog-section">        
            <ng-content></ng-content>
        </div>
        <div class="dialog-section">        
            <ng-content select="[actions]"></ng-content>
        </div>
    </div>
</div>

<!--
<div [class]="width">
    <div class="align-items-center flex- justify-content-between">
        <mat-card-title class="mb-0">
            <h6 class="uppercase">
                <ng-content select="[title]"></ng-content>
            </h6>
        </mat-card-title>
        <button mat-icon-button (click)="closeAll()" color="primary">
            <mat-icon class="material-icons-outlined">close</mat-icon>
        </button>
    </div>
</div>
<div [class]="width">
    <ng-content></ng-content>
    <div class="mt-3">
        <ng-content select="[actions]"></ng-content>
    </div>
</div> 
-->
