import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';

import { ErrorComponent } from '@src/main/core/error/error.component';

import { AuthenticationService } from '@src/shared/services/authentication.service';
import { NavigationService } from '@src/shared/services/navigation.service';
import { OrganizationService } from '@src/shared/services/organization.service';
import { SecurityService } from '@src/shared/services/security.service';

@Component({
  selector: 'main-app',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('225ms 2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: '1' }))
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: '0' }))
      ])
    ])
  ]
})

/**
 * Main
 */
export class MainComponent implements OnInit, OnChanges {

    @ViewChild(ErrorComponent) errorComponent: ErrorComponent;

    /* CONSTANTS */

    /* ATTRIBUTES */

    public config; // app.ts
    public cache: Cache; // the session cache
    public organization; // the current organization

    public backgroundColor: string;
    public idleState = 'Not Started.';
    public timedOut = false;
    public lastPing?: Date = null;

    public keepAliveDelay = 1000 * 10;
    public lastInterupt = new Date().getTime();

    public cssUrl: string;

    /* CONSTRUCTORS */

    /**
     * Constructor.
     */
    public constructor(
        private idle: Idle,
        private keepalive: Keepalive,
        private router: Router,
        private title: Title,

        private authenticationService: AuthenticationService,
        private navigationService: NavigationService,
        private organizationService: OrganizationService,
        private securityService: SecurityService) {
          
        this.config = config; // app.ts
        this.backgroundColor = this.config?.backgroundColor;
        title.setTitle(config.appName.charAt(0).toUpperCase() + config.appName.slice(1));

        //#region WATCH / TIMER

        // We have to start the watch and keep track of how long the user has 
        // been idled. The system listens for clicks, scrolls, and events defined 
        // in the interrupt sources to determine whether the user is using the 
        // system.

        this.idle.setIdle(14 * 60); // 14 min idle time for production
        this.idle.setTimeout(1 * 60); // 1 minute countdown before auto logout
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // sets the default interrupts like clicks, scrolls, touches to the document

        // this.idle.setIdle(10); // 10 seconds idle time for development
        // this.idle.setTimeout(30); // 30 second countdown before auto logout
        // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // sets the default interrupts like clicks, scrolls, touches to the document

        // When the user has been idled for more than the allowable time
        // period, automatically route him/her to the timeout component to
        // allow him or her to continue the session.

        this.idle.onIdleStart.subscribe(() => {
            this.idleState = 'Master: You\'ve gone idle!'
            this.router.navigate(['timeout']);
            // console.log(this.idleState);
            // this.childModal.show();
        });

        /// OnInterrupt, call keepAlive route to reset session Cookie
        this.idle.onInterrupt.subscribe(() => {
            const now = new Date().getTime();
            if ((now - this.lastInterupt) > this.keepAliveDelay) {
              if (!location.pathname.startsWith('/public')) {
                this.authenticationService.keepAlive().subscribe(res => {
                  // console.log('keepAlive', res.timeStamp);
                });
              }
              this.lastInterupt = now;
            }
        });

        // When the user is no longer idle, we must reset the watch so 
        // that it can restart the timer.

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = 'Master: You are no longer idle.'
            // console.log(this.idleState);
            this.reset();
        });

        this.keepalive.interval(5); // sets the ping interval to 5 seconds
        this.keepalive.onPing.subscribe(() => {
            this.lastPing = new Date()
        });

        this.reset();

        //#endregion
        
    }

    /* EVENT HANDLING */

    /**
     * On Changes.
     */
    public ngOnChanges() {
    }

    /**
     * On Init.
     */
    public ngOnInit() {

        //#region KEEPALIVE

        // If the user is not on a public page then when we the application loads we must 
        // call the keepalive route to immediatly check that the user has an active session. 
        // If the user is not authenticated then user will be redirected to login, if the 
        // domain is known, or to an error page if the domain is not known.

        if (!location.pathname.startsWith('/public') && !location.pathname.startsWith('/signin')) {
            this.authenticationService.keepAlive().subscribe();
        }

        //#endregion
    }

    /* METHODS */

    /**
     * Reset the watch.
     */
    public reset() {
        // console.log("Master: Reset watch()!");
        this.idle.watch();
        this.idleState = 'Started the watch.';
        this.timedOut = false;
    }

}
