
<table class="asset-mtr-table" mat-table [dataSource]="dataSource" matSortActive="name" matSortDirection="asc" matSort multiTemplateDataRows>
    <!-- COLUMN: MISSION -->
    <div>                
        <ng-container matColumnDef="name">
            <th class="col-left font-size-9" [width]="missionColumnWidth.name" mat-header-cell *matHeaderCellDef> 
                <mat-label matTooltip="Name of Asset">
                    MISSION
                </mat-label>
            </th>
            <td class="col-left font-size-9" [width]="missionColumnWidth.name" mat-cell *matCellDef="let item">
                {{ item.name }}
            </td>
        </ng-container>
    </div>
    <!-- COLUMN: FUNCTIONS -->
    <div *ngFor="let functionName of functionNames; let i = index">                
        <ng-container [matColumnDef]="functionName">
            <th class="col-center font-size-9" [width]="missionColumnWidth.function" mat-header-cell *matHeaderCellDef> 
                <mat-label matTooltip="Function">
                    {{ functionName }}
                </mat-label>
            </th>
            <td class="col-center font-size-9 py-2" [width]="missionColumnWidth.function" mat-cell *matCellDef="let item">
                <div class="risk-score-highlight">
                    {{ item.functions[functionName]?.sum || 0 }}
                </div>
            </td>
        </ng-container>
    </div>
    <!-- EXPANDED ROWS -->
    <ng-container matColumnDef="details">
        <td mat-cell *matCellDef="let element" [attr.colspan]="missionColumnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="element == selected ? 'expanded' : 'collapsed'"> 
                <div *ngIf="element === selected">     
                    <span *ngFor="let control of assetData.controls">
                        <a (click)="gotoControl(element, control)">{{ control.id | readable }}</a>
                        <span *ngIf="control?.findings?.length">
                            (<a *ngFor="let findingRef of control.findings; let i = index" 
                                [routerLink]="[config.routes.PRIVATE_CONTROL_EDIT_FINDING, findingRef.pkId]"
                                [matTooltip]="findingRef.name || 'Finding name not provided.'">
                                {{ findingRef.displayName || ('P-' + (i+1)) }}{{i+1 < control.findings.length ? ', ' : ''}}
                            </a>)
                        </span>
                    </span>
                </div>
            </div>
        </td>
    </ng-container>
    <!-- ROW -->
    <tr mat-header-row *matHeaderRowDef="missionColumnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: missionColumnsToDisplay" 
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="select(element);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['details']" class="example-detail-row"></tr>
</table>

<div class="align-center p-3" *ngIf="!dataSource?.data?.length">
    No Mission data available for this asset.
</div>

