<button  
    class="export-button ml-3"
    *ngIf="exports?.length"
    mat-flat-button 
    color="primary"
    [componentIf]="" 
    [showAsDisabled]="true"
    [disabled]="isExporting"
    [matMenuTriggerFor]="exportMenu">
    <div *ngIf='!isExporting'>
        Export 
    </div>

    <div *ngIf='isExporting'>
        Exporting
        <div class="wavy">
            <span style="--i:1">.</span>
            <span style="--i:2">.</span>
            <span style="--i:3">.</span>
        </div>
    </div>
</button> 

<mat-menu yPosition="below" #exportMenu="matMenu">
    <button *ngFor="let item of exports" mat-menu-item (click)="export(item)">{{item.name}}</button>
    <button *ngFor="let item of data" mat-menu-item (click)="item.callback()">{{item.name}}</button>
</mat-menu>