import { v4 as uuidv4 } from 'uuid';
import { Message } from './message';

export class Chat extends Message {

    /* ATTRIBUTES */

    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
     public constructor(json?) {
        super(json);
        Object.assign(this, json || {});
        this.type = "Chat";
    }
    
}