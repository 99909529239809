import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { RoleGuard } from '@src/shared/guards/role-guard.guard';

@Directive({
  selector: '[routerIf]'
})
export class RouterIfDirective {

  routerAnd: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleGuard: RoleGuard
  ) { }

  /**
   * Only relevent when inputting an array to routerIf.
   * Using this option changes the logic so routerIf shows the div only when the user has access to
   * ALL the items in the array
   *
   * Option Usage:
   * <div *routerIf="['/items/all', '/items/some'];and"></div>
   * // add ";and" at the end of the array;
   */
  @Input('routerIfAnd') set setRouterAnd(value) {
    this.routerAnd = true;
  }

  /**
   * removes the refrence element from the DOM if the current user doesn't have access to that route
   * @param val route string, string or boolean array. Checks the single string if a string is given.
   *  Checks all strings and shows the element if the user has access to one of these by default.
   *  Can also be provided with booleans as a string array. Use ngIf if using a single boolean.
   *  If a route string is prefixed with "!", it will show the element if the user does not have access to that route.
   * Examples:
   * <div *routerIf="'/items/all'"></div>
   * // div will be shown only if role guard allows the user to access /items/all
   *
   * <div *routerIf="['/items/all', '/items/some', user.isRoot()]"></div>
   * // div will be shown only if role guard allows the user to access /items/all or /items/some or user isRoot() returns true
   *
   * <div *routerIf="['/items/all', '/items/some'];and"></div>
   * // div will be shown only if user has access to /items/all AND /items/some
   */
  @Input() set routerIf(val: string | (string | boolean)[]) {

    this.viewContainer.createEmbeddedView(this.templateRef);

    // const isArray = Array.isArray || function (arr: any): boolean {
    //   return Object.prototype.toString.call( arr ) === '[object Array]';
    // };
    // /* If the input is an array hide the element if all items in the list are false */
    // if (isArray(val)) {
    //   val = val as (string | boolean)[];
    //   const bools: boolean[] = val.filter(item => typeof item === 'boolean' || item == null) as boolean[];
    //   const strings: string[] = val.filter(item => typeof item !== 'boolean' && item != null) as string[];
    //   console.log(bools, strings);
    //   this.roleGuard.checkRoutes(strings).then((results: boolean[]) => {
    //     results = [...results, ...bools];
    //     results = results.map((result, index) => /^!/g.test((val as string[])[index]) ? !result : result);
    //     const filterFunction = this.routerAnd ? 'every' : 'some';
    //     console.log(filterFunction);
    //     if (results && results[filterFunction](result => result)) {
    //       this.viewContainer.createEmbeddedView(this.templateRef);
    //     } else {
    //       this.viewContainer.clear();
    //     }
    //   });
    // /* If the input is NOT an array hide/show the element if the user has access */
    // } 
    // else {
    //   this.roleGuard.checkRoute(val as string).then(result => {
    //       result = /^!/g.test(val as string) ? !result : result;
    //       if (result) {
    //         this.viewContainer.createEmbeddedView(this.templateRef);
    //       } else {
    //         this.viewContainer.clear();
    //       }
    //     });
    // }
  }
}
