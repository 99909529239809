<div class="mx-3 my-3 container" (mouseenter)="hoveringOverOutsideButton=true" (mouseleave)="hoveringOverOutsideButton=false">
    <button 
        class="card-process-btn"
        mat-raised-button 
        style="width: 200px; height: 200px;"
        [disabled]="cardDisabled"
        (click)="cardClick.emit($event)">
        <div class="row">
            <div class="percent- col d-flex align-items-center justify-content-center" *ngIf="!complete" style="height: 3rem;">
                <ng-content select="[percentage-done]"></ng-content>
            </div>
            <div class="col mb-3 svg-icon" *ngIf="complete" style="height: 2rem;">
                <svg  width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="19" fill="#11818F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.43 21.4396L27.77 10.0996L32 14.3296L16.43 29.8996L8 21.4696L12.23 17.2396L16.43 21.4396ZM29.17 14.3296L27.77 12.9296L16.43 24.2696L12.23 20.0696L10.83 21.4696L16.43 27.0696L29.17 14.3296Z" fill="white"/>
                </svg>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex align-items-center justify-content-center" style="min-height: 3rem; font-weight: 300;">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="secondary-buttons">
                <button mat-button (click)="stopProp($event); secondaryButtonClick.emit($event);" aria-label="view">{{ secondaryButton }}</button>
                <button mat-button (click)="stopProp($event); exportData.emit($event);" *ngIf="export" [disabled]="exporting">EXPORT</button>
            </div>
        </div>
    </button> 
    <mat-progress-bar class="export-progress-bar" mode="indeterminate" *ngIf="exporting"></mat-progress-bar>

    <!-- <div class="row relative mr-4 no-gutters align-center">
        <div *ngIf="export" class="">
            <div class="card-process-export-btn" *ngIf="hoveringOverOutsideButton" [@navAnimation]>
                <ng-content class="red" select="[outside-button]"></ng-content>
            </div>
        </div>
    </div>
    <div class="secondary-btn pt-2" *ngIf="secondaryButton">
        <a (click)="secondaryButtonClick.emit($event)">{{ secondaryButton }}</a> 
    </div> -->
</div>
