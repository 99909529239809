
<!-- CHAT | MINIMIZED -->

<!-- CHAT | MINIMIZED | CHATBOX -->
<div class="boundary" *ngIf="current">
    <!-- CHANNEL -->
    <div class="chat-box" *ngIf='isOpen && this.selectedChannel'>
        <!-- TITLE -->
        <div class="chat-header" cdkDragHandle>
            <div class="row w-100 nopadding">
                <div class="col nopadding">
                    <span class="channel-name" matTooltip="{{ this.selectedChannel?.name }}" *ngIf="this.selectedChannel">
                        {{ this.selectedChannel?.type !== 'Custom' ? this.selectedChannel?.name : this.selectedChannel?.reference?.name }}
                    </span>
                </div>
                <div class="col-4 nopadding">
                    <div class="right d-flex">
                        <mat-icon class="channel-icon" *ngIf="selectedChannel?.parentPkId" (click)="redirect(selectedChannel)">info</mat-icon>
                        <mat-icon class="channel-icon maximize" *ngIf="!isFullScreen" (click)="maximize()">open_in_full</mat-icon>
                        <mat-icon class="channel-icon" (click)="close()">close</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- INVITED, BUT NOT ACCEPTED -->
        <div class="chat-container" *ngIf="!selectedChannel?.isViewable(current)">          
            <div class="h-100 chat-container-invite">
                <app-invite [user]="current" [channel]="selectedChannel" (eventEmitter)="send($event)">
                </app-invite>
            </div>
        </div>
        <!-- CHANNEL IS VIEWABLE OR INVITED ACCEPTED -->
        <div class="chat-container" *ngIf="selectedChannel?.isViewable(current)">
            <!-- CHAT -->
            <div class="h-100 chat-container-default" *ngIf="selectedChannel?.type === 'Chat'">
                <app-default [user]="current" [users]="users" [messages]="messageService.messages[selectedChannel.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>            
            <!-- CUSTOM -->
            <div class="h-100 chat-container-default" *ngIf="selectedChannel?.type === 'Custom'">                
                <app-default [user]="current" [users]="users" [messages]="messageService.messages[selectedChannel.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>
            <!-- HELP -->
            <div class="h-100 chat-container-request" *ngIf="selectedChannel?.type === 'Help'">
                <app-request [help]="help" (eventEmitter)="send($event)">
                </app-request>
            </div>
        </div>
    </div>
    <!-- CONTEXT -->
    <div class="chat-box" *ngIf='isOpen && !this.selectedChannel && this.context'>
        <!-- TITLE -->
        <div class="chat-header" cdkDragHandle>
            <div class="row w-100 nopadding">
                <div class="col nopadding">
                    <span class="channel-name">
                        {{ this.context?.reference?.name }}
                    </span>
                </div>
                <div class="col-4 nopadding">
                    <div class="right d-flex">
                        <mat-icon class="channel-icon" *ngIf="selectedChannel?.parentPkId" (click)="redirect(selectedChannel)">info</mat-icon>
                        <mat-icon class="channel-icon maximize" *ngIf="!isFullScreen" (click)="maximize()">open_in_full</mat-icon>
                        <mat-icon class="channel-icon" (click)="close()">close</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- CHAT -->
        <div class="chat-container">
            <div class="h-100 chat-container-default" *ngIf="this.context">
                <app-default [user]="current" [users]="users" [messages]="messageService.messages[this.context.reference.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>    
        </div>
    </div>
</div>

<!-- CHAT | MINIMIZED | MENU -->
<div class="chat-menu">        
    <app-menu 
        [(globalChatChannel)]="channelService.globalChatChannel"
        [(globalHelpChannel)]="channelService.globalHelpChannel"
        [context]="this.context"
        [(isOpen)]="this.isMenuOpen" 
        (select)="changeChannel($event); open();">
    </app-menu>
</div>

<!-- CHAT | MINIMIZED | ICON -->
<div class="chat-fab" cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".footer">
    <button mat-button (click)="openMenu()" aria-label="chat-button">
        <svg width="60" height="53" viewBox="0 0 78 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="77.3252" height="40.5299" rx="20.2649" fill="url(#paint0_linear_12225_111817)"/>
            <path d="M12.6245 52.865L15.3294 37.8934L33.1393 37.9836L12.6245 52.865Z" fill="url(#paint1_linear_12225_111817)"/>
            <rect x="8.88108" y="10.8811" width="60.9378" height="18.3878" rx="9.19392" fill="#F6FDFD" stroke="#F6FDFD" stroke-width="1.76217"/>
            <circle cx="23.2048" cy="19.5375" r="4.5" fill="url(#paint2_linear_12225_111817)"/>
            <circle cx="55.3193" cy="19.5375" r="4.5" fill="url(#paint3_linear_12225_111817)"/>
            <defs>
                <linearGradient id="paint0_linear_12225_111817" x1="38.6626" y1="0" x2="38.6626" y2="40.5299" gradientUnits="userSpaceOnUse">
                <stop stop-color="#35CFE2"/>
                <stop offset="0.609375" stop-color="#24A9BA"/>
                <stop offset="1" stop-color="#1799A9"/>
                </linearGradient>
                <linearGradient id="paint1_linear_12225_111817" x1="22.8422" y1="52.9325" x2="22.9413" y2="37.9163" gradientUnits="userSpaceOnUse">
                <stop stop-color="#35CFE2"/>
                <stop offset="0.609375" stop-color="#24A9BA"/>
                <stop offset="1" stop-color="#1799A9"/>
                </linearGradient>
                <linearGradient id="paint2_linear_12225_111817" x1="23.2048" y1="15.0375" x2="23.2048" y2="24.0375" gradientUnits="userSpaceOnUse">
                <stop stop-color="#35CFE2"/>
                <stop offset="0.609375" stop-color="#24A9BA"/>
                <stop offset="1" stop-color="#1799A9"/>
                </linearGradient>
                <linearGradient id="paint3_linear_12225_111817" x1="55.3193" y1="15.0375" x2="55.3193" y2="24.0375" gradientUnits="userSpaceOnUse">
                <stop stop-color="#35CFE2"/>
                <stop offset="0.609375" stop-color="#24A9BA"/>
                <stop offset="1" stop-color="#1799A9"/>
                </linearGradient>
            </defs>
        </svg>
    </button>
</div>

<!-- CHAT | MAXIMIZED -->

<ng-template #maximized let-data>
    <!-- CHANNEL -->
    <div class="chat-box-full m-3" *ngIf='isOpen && this.selectedChannel'>
        <!-- TITLE -->
        <div class="chat-header">
            <div class="row w-100 nopadding">
                <div class="col nopadding">                            
                    <span class="channel-name" matTooltip="{{selectedChannel?.name}}">
                        {{ selectedChannel?.type !== 'Custom' ? selectedChannel?.name : selectedChannel?.reference?.name }}
                    </span>
                </div>
                <div class="col-2 nopadding">
                    <div class="right">
                        <mat-icon class="channel-icon" *ngIf="selectedChannel?.parentPkId" (click)="redirect(selectedChannel)">info</mat-icon>
                        <mat-icon class="channel-icon" *ngIf="isFullScreen" (click)="minimize()">close_fullscreen</mat-icon>
                        <mat-icon class="channel-icon" (click)="close()">close</mat-icon>
                    </div>
                </div>
            </div>
        </div> 
        <!-- INVITED, BUT NOT ACCEPTED -->
        <div class=" chat-container-invite" *ngIf="!selectedChannel?.isViewable(current)">          
            <div class="h-100">
                <app-invite [user]="current" [channel]="selectedChannel" (eventEmitter)="send($event)">
                </app-invite>
            </div>
        </div>
        <!-- CHANNEL IS VIEWABLE OR INVITED ACCEPTED -->
        <div class="chat-container" *ngIf="selectedChannel?.isViewable(current)">
            <!-- CHAT -->
            <div class="h-100 chat-container-default" *ngIf="selectedChannel?.type === 'Chat'">                               
                <app-default [user]="current" [users]="users" [messages]="messageService.messages[selectedChannel.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>        
            <!-- CUSTOM -->
            <div class="h-100 chat-container-default" *ngIf="selectedChannel?.type === 'Custom'">                
                <app-default 
                    [user]="current" [users]="users" [messages]="messageService.messages[selectedChannel.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>
            <!-- HELP -->
            <div class="h-100 chat-container-request" *ngIf="selectedChannel?.type === 'Help'">
                <app-request [help]="help" (eventEmitter)="send($event)">
                </app-request>
            </div>
        </div>
    </div>
    <!-- CONTEXT -->
    <div class="chat-box-full m-3" *ngIf='isOpen && !this.selectedChannel && this.context'>
        <!-- TITLE -->
        <div class="chat-header" cdkDragHandle>
            <div class="row w-100 nopadding">
                <div class="col nopadding">
                    <span class="channel-name">
                        {{ this.context?.reference?.name }}
                    </span>
                </div>
                <div class="col-4 nopadding">
                    <div class="right d-flex">
                        <mat-icon class="channel-icon" *ngIf="selectedChannel?.parentPkId" (click)="redirect(selectedChannel)">info</mat-icon>
                        <mat-icon class="channel-icon" *ngIf="isFullScreen" (click)="minimize()">close_fullscreen</mat-icon>
                        <mat-icon class="channel-icon" (click)="close()">close</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- CHAT -->
        <div class="chat-container">
            <div class="h-100 chat-container-default" *ngIf="this.context">
                <app-default [user]="current" [users]="users" [messages]="messageService.messages[this.context.reference.pkId]" [scrollToSubject]="scrollToSubject" (eventEmitter)="send($event)">
                </app-default>
            </div>    
        </div>
    </div>
</ng-template>
