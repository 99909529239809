import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'process-card',
  templateUrl: './process-card.component.html',
  styleUrls: ['./process-card.component.less'],
  animations: [
    trigger('navAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)'}),
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)'}),
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class ProcessCardComponent implements OnInit {
  
    @Output() cardClick: EventEmitter<any> = new EventEmitter();
    @Output() secondaryButtonClick: EventEmitter<any> = new EventEmitter();
    @Output() exportData: EventEmitter<any> = new EventEmitter();

    @Input('complete') complete;
    @Input('export') export: boolean; // show or hide export button 
    @Input('exporting') exporting: boolean; // is the questionnaire being exported (display progress bar)
    @Input('disabled') set setCardDisabled(cardDisabled) { this.cardDisabled = cardDisabled; }

    @Input('secondaryButton') secondaryButton: string;

    /* ATTRIBUTES */

    cardDisabled = false;
    hoveringOverOutsideButton = false;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor() { 
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    ngOnInit() { 
    }

    /**
     * Stop propagating the event.
     */
    stopProp(event) {
        event.stopPropagation();
    }
}
