import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Cache } from '@src/shared/objects/cache';
import { Configuration } from '@src/shared/objects/configuration';

import { IService } from '@src/shared/services/service.interface';

import { ConfigurationService } from '@src/shared/services/configuration.service';
import { ListService } from '@src/shared/services/list.service';
import { SecurityService } from '@src/shared/services/security.service';
import { UserService } from '@src/shared/services/user.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.less'],
})
export class UserSearchComponent implements OnInit, OnDestroy {

    @Input() appearance: 'fill' | 'outline' = 'outline';
    @Input() viewType: 'input' | 'button' | 'icon' = 'input'; // is view type a button or an input
    
    @Input() label:string; // the label for the text field
    @Input() reference:any; // the reference data
    @Input() property:string; // the reference property
    @Input() service:IService; // the service to call to save the changes and can be null
    @Input() users: any[] // user dataset to use if one is provided

    @Input() isDisabled:boolean = false; // is the button disabled
    @Input() isRequired:boolean = false; // is the field required
    @Input() isReadonly:boolean = false; // is the field read only 
    @Input() isFullWidth:boolean = false; // is the field full width of the screen
    @Input() withRoles: boolean = false; // do we also search for the user's roles

    @Output() onSelected = new EventEmitter<any>(); // Emit event when one or multipler users are selected and the reference is saved

    // @Output() updateCompletion = new EventEmitter();
    
    /* ATTRIBUTES */

    public config; // the app.ts

    public asset; // the selected asset
    public cache: Cache; // the session cache
    public configuration; // the organization configuration
    public current; // the current user
    public index = [];  // an index of all the users in the organization
    public organization; // the current organization
    public user; // the user being invited to join the organization
    public roles = []; // user's roles
    public role; // selected role

    // public users = []; // the list of users that can access this asset
    // public users_ = []; // filtered users list

    public keyword = ''; // the search term
    public keywordSubject: Subject<string> = new Subject<string>();

    private clone; // the clone of the reference data for canceling operations

    public result; // the result to show in the search
    public selected; // selected index
    public text = ''; // the text to display in the text input

    public isLoading = true; // used to show or hide the loading spinner
    public isList = false; // is the reference data a list of strings rather than a string

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        public snackBar: MatSnackBar,

        private configurationService: ConfigurationService,
        private listService: ListService,
        private securityService: SecurityService,
        private userService: UserService) {

            
        this.keywordSubject.pipe(debounceTime(1000)).subscribe(result => {
            this.search();
        });

    }

    /**
     * On Init.
     */
    public async ngOnInit() {

        this.isLoading = true;

        // Get the cached data and render the component using it. All of the
        // cached data is set either by the master component for each module or
        // when (at) the point when it changes.

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.configuration = new Configuration (this.cache.getValue(Cache.KEYS.CONFIGURATION));
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);

        // The isList variable is used by the system to determine how to process the 
        // select values from the search and subsequent selection of the user from the 
        // result set.

        this.isList = (this.reference[this.property] instanceof Array);

        // We are using the local storage to cache large data and improve system 
        // performance. The list of users for large organizations must be cached and 
        // lazily initialized since this component is used by many other components.
        
        let local = Cache.get();
        let value = local.getValue(Cache.KEYS.USERS);
        this.index = this.users?.length > 0 ? this.users : value || [];
        this.refresh();

        // await this.listService.init(this.organization.pkId); 
        // this.roles = await lastValueFrom (this.configurationService.getRoles(this.organization.orgPkId));
        // if (this.asset) { this.users = await lastValueFrom (this.securityService.getUsersByAsset(this.organization.orgPkId, this.asset.pkId)); }

        this.isLoading = false;
    }

    /**
     * On Changes
     */
    public ngOnChanges(): void {
        let local = Cache.get();
        let value = local.getValue(Cache.KEYS.USERS);
        this.index = this.users?.length > 0 ? this.users : value || [];
    }


    /**
     * On Destroy.
     */
    public ngOnDestroy() {
    }

    /**
     * Activate the search using debounce.
     */
    public keywordChange(event) {
        this.keywordSubject.next(event);
    }

    /* METHODS */       

    /**
     * Cancel the edit and restore original reference value.
     */
    public cancel() {
        this.reference[this.property] = this.clone[this.property]
        this.refresh();
        this.close();
    }

    /**
     * Clone and strip the object of all properties except those specified.
     */
    public cloneAndStrip (object:any, properties:string[]) {
        let clone = Object.assign({}, object)
        if (clone) {
            let keys = Object.keys(clone);
            for (let key of keys) {
                if (!properties.includes(key)) {
                    delete clone[key];
                }
            }
            return clone;
        }
        return null;
    }

    /**
     * Initialize data for editing when wanting to select a new User (and Role) 
     * Display the selected user on top of the user list and display list without the search
     * Display role if `withRoles` is true and populate roles list
     */
    public initEdit () {
        this.result = this.index.sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
        this.result = this.result.sort((a, b) => {
            if (this.isSelected(a) && !this.isSelected(b)) {
                return -1;
            } else if (!this.isSelected(a) && this.isSelected(b)) {
                return 1
            } else if (this.isSelected(a) && this.isSelected(b)) { 
                return (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)
            } else {
                return (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)
            }
        })

        if (this.withRoles && this.reference['rolePkId']) {
            this.userService.getByPkId(this.reference[this.property]).subscribe((res) => {
                this.roles = this.configuration.roles.filter(role => {
                    return [...new Set(res.applications[0].roles
                        .filter(role => role.organizationPkId === this.organization.orgPkId)
                        .map(role => role.rolePkId)
                    )].includes(role.pkId)
                });
                this.role = this.configuration.roles.find(role => role.pkId === this.reference['rolePkId']);
            });
        }
    }

    /**
     * Return whether the item in the search result has already been selected.
     */
    public isSelected(item) {        
        if (item) {
            return this.isList ? 
                this.reference[this.property]?.includes(item.pkId) : 
                this.reference[this.property] === item.pkId;
        }
        return false;
    }

    /**
     * Refresh the user interface with the latest data.
     */
    public refresh() {
        this.text = '';
        let index = 0;
        let ids = this.isList ? this.reference[this.property] : [this.reference[this.property]];
        for (let id of ids) {
            let user = this.index.find(i => i.pkId === id);
            if (user) {
                index++;
                if (index < ids.length) {
                    this.text += user.firstName + ' ' + user.lastName + ', ';
                }
                else {
                    this.text += user.firstName + ' ' + user.lastName;
                }
            }
        }
    }

    /**
     * Reset the reference data model.
     */
    public reset() {
        this.keyword = "";
        if (this.reference) {
            this.reference[this.property] = this.isList ? [] : '';
            this.refresh();
            this.save();
        }
    }

    /**
     * Save the changes.
     */
    public save() {
        if (this.withRoles && this.role) {
            this.reference['roleName'] = this.role.name;
            this.reference['rolePkId'] = this.role.pkId;
        }
        if (this.service) {
            let properties = [ 'pkId', this.property ];
            let clone = this.cloneAndStrip(this.reference, properties);
            this.service.update(this.reference).subscribe(res => {
                this.close();
                this.onSelected.emit();
            });
        }
        else {
            this.close();
            this.onSelected.emit(this.reference);
        }
    }

    /**
     * Search for an item in the index. The search event is triggered upon a keyup 
     * event in the search input field. Ideally, we must debounce the event, giving 
     * the user around 150ms to trigger the envent. I don't have time to add in 
     * debouncing but it shoudl be done in the future.
     */
    public search() {
        if (this.keyword) {
            this.result = this.index.filter(i => {
                let key = i.firstName + ' ' + i.lastName;
                key = key.toLowerCase();
                return key.includes(this.keyword.toLowerCase());
            }).sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
        }
        else {
            this.result = [];
        }
    }

    /**
     * Select the item in the search result. If the item has been checked then, get the 
     * user from the database and invite that user to the organization. If the item has 
     * been unchecked, then reset the data with the proper default values.
     */
    public select(event, user) {
        if (user) {
            if (event.checked) {
                if (this.isList) { 
                    this.reference[this.property].push(user.pkId); 
                }
                else {
                    this.reference[this.property] = user.pkId;
                    if (this.withRoles) {
                        this.role = null;
                        this.userService.getByPkId(user.pkId).subscribe((res) => {
                            this.roles = this.configuration.roles.filter(role => {
                                return [...new Set(res.applications[0].roles
                                    .filter(role => role.organizationPkId === this.organization.orgPkId)
                                    .map(role => role.rolePkId)
                                )].includes(role.pkId)
                            });
                        });
                    }
                }
            }
            else {
                if (this.isList) {
                    this.reference[this.property] = this.reference[this.property].filter(d => d !== user.pkId);
                }
                else {
                    this.reference[this.property] = '';
                }
            }
            this.refresh();
        }
    }
    
    // UTILITIES

    /**
     * Close the dialog.
     */
    public close() {
        this.keyword = '';
        this.roles = [];
        this.role = null;
        this.search();
        this.dialog.closeAll();
    }

    /**
     * Opens a dialog with a given template.
     */
    public openDialog(component, data?) {

        // Clone the reference data so that we can restore it upon canceling the 
        // changes.

        this.clone = JSON.parse(JSON.stringify(this.reference));
        // this.clone = Object.assign({}, this.reference);
        
        if (this.reference[this.property].length > 0) {
            this.initEdit();
        }

        // Set the dialog properties and open the dialog using those properties.
        // All dialogs are inteded to fill the entire screen. The dialog styles can
        // be found in the global styles.

        const properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: data, };
        const dialogRef = this.dialog.open(component, properties);

        // After the dialog is opened and closed, process the returned data
        // and perform cleanup operations.

        dialogRef.afterClosed().subscribe((result) => {            
        });
    }
}
