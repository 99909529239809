import { Injectable } from "@angular/core";

import { Approval } from "../objects/approval";
import { Signature } from "../objects/signature";

import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: "root",
})
export class ApprovalService extends AbstractService {

    /* METHODS */

    /**
     * Create or update the approver signature in the approval record.
     */
    public approve(approval: Approval, approver: Signature, isRoleBased?: boolean) {
        return this.post("/approval/approve", { approval, approver, isRoleBased });
    }

    /**
     * Get all of the approvals for the reference object.
     */
    public all(referencePkId) {
        return this.post("/approval/all", { referencePkId });
    }

    /**
     * Create entity.
     */
    public create(entity: Approval) {
        return this.post("/approval/create", { entity });
    }

    /**
     * Delete entity.
     */
    public delete(pkId: string) {
        return this.post("/approval/remove", { pkId });
    }

    /**
     * Get entities for a given asset.
     */
    public get(query) {
        return this.post("/approval/get", { query });
    }

    /**
     * Get entities.
     */
    public list(query) {
        return this.post("/approval/list", { query });
    }

    /**
     * Save the entity.
     */
    public update(pkId: string, update) {
        return this.post("/approval/update", { pkId, update });
    }

    /**
     * Save the entity.
     */
    public reset(referencePkId: string) {
        return this.post("/approval/reset", { referencePkId });
    }

}









