import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[resizable]'
})
export class ResizableDirective implements OnInit {
  /**
   * Directive used to make mat-table columns resizable.
   * Usage: Add as attribute to a mat-table.
   * Example: <table mat-table resizable [dataSource]="dataSource" matSort>
   */

  pressed = false;
  currentResizeIndex: number;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  thead;
  tbody;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    setTimeout(() => {
      const children = this.el.nativeElement.children;
      this.thead = children[0].children[0];
      this.tbody = children[1].children[0];

      if (this.thead) {
        for (let i = 0; i < this.thead.children.length - 1; i++) {
          const dragHandle = document.createElement('div');
          dragHandle.classList.add('resizable-handle');
          this.thead.children[i].classList.add('relative');
          this.thead.children[i].appendChild(dragHandle);
          this.renderer.listen(dragHandle, 'mousedown', this.onResizeColumn.bind(this, i));
          this.renderer.listen(dragHandle, 'click', this.stopPropagation.bind(this));
        }
      }
    });
  }

  /**
   * Event handler that stops event propagation.
   * Used to stop propagation of mat sort header click event.
   * So clicking the drag bar doesn't sort the column.
   * @param event event
   */
  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   * Mouse down event handler, registers mouse move even on document
   * Registers mouse up event to cancel mouse move event
   * @param index index of the column to move
   * @param event mouse down event
   */
  onResizeColumn(index: number, event: any) {
    this.currentResizeIndex = index;
    this.pressed = true;
    event.preventDefault();
    event.stopPropagation();
    this.mouseMove(index);
  }

  /**
   * mouse Move handler. Updates the size of the column in % to the mouse position.
   * @param index index of the column to resize
   */
  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      document.body.style.cursor = 'col-resize';
      if (this.pressed && event.buttons ) {
        if ( this.currentResizeIndex === index ) {
          const currentWidth = this.thead.children[index].clientWidth;
          const currentX = this.thead.children[index].getBoundingClientRect().right;
          const mouseX = event.pageX + 20;
          const newHandleX = mouseX + currentWidth;
          const newCurrentWidth = newHandleX - currentX;
          const newCurrentPWidth = newCurrentWidth / this.el.nativeElement.clientWidth;
          this.thead.children[index].style.width = (newCurrentPWidth * 100) + '%';
        }
      }
    });
    this.resizableMouseup = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.pressed) {
        document.body.style.cursor = '';
        this.pressed = false;
        this.currentResizeIndex = -1;
        this.resizableMousemove();
        this.resizableMouseup();
      }
    });
  }

}
