import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArray'
})
export class SortArrayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const keyName = args && args.length && args[0] ? args[0] : undefined;
    const sortedValue = (value || []).sort((a, b) => {
      if (keyName) return a[keyName] < b[keyName] ? -1 : a[keyName] > b[keyName] ? 1 : 0;
      else return a < b ? -1 : a > b ? 1 : 0;
    });
    return sortedValue;
  }

}
