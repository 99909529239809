<!-- PAGE -->

<mat-form-field *ngIf="viewType === 'input'" [ngClass]="[ 'person', this.appearance === 'fill' ? 'person-fill' : '', this.isFullWidth ? 'w-100' : 'w-150px' ]" appearance="{{ this.appearance }}">
    <mat-label *ngIf="this.label">{{ this.label }}</mat-label>
    <input name="person" #person matInput type="text" (click)="openDialog(searchDialog)" [(ngModel)]="this.text" maxLength="100" [disabled]="this.isReadonly" readonly="true" [required]="this.isRequired">
    <mat-icon matSuffix (click)="openDialog(searchDialog)" *ngIf="!this.isReadonly">person_search</mat-icon>
    <mat-icon matSuffix *ngIf="this.isReadonly">person_search</mat-icon>
</mat-form-field>  

<button
    *ngIf="viewType === 'button'" 
    mat-flat-button 
    type="button"
    color="accent"
    [disabled]="this.isDisabled"
    (click)="openDialog(searchDialog)">
    {{this.label}}
</button>

<span 
    *ngIf="viewType === 'icon'"
    class="material-icons-outlined"
    style="cursor: pointer"
    (click)="openDialog(searchDialog)">
    {{this.label}}
</span>

<!-- DIALOGS -->

<ng-template #searchDialog let-data>
    <div class="center">
        <form #form="ngForm">
            <div class="editor">
                <div class="editor-title">
                    <div class="title">FIND PERSON</div>
                </div>     
                <div class="editor-section nopadding">
                    <div class="items">
                        <div class="item">
                            <div class="editor-info">
                                <p>
                                    Enter the person's name that you are searching for in the search box below. The system will
                                    return the list of people matching the search term. Then, select the person you
                                    are interested in.
                                </p>
                            </div>  
                        </div>
                        <!-- SELECT USER(S) -->
                        <div class="item">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Search</mat-label>
                                <input matInput type="text" name="search" [(ngModel)]="this.keyword" (ngModelChange)="keywordChange($event)">
                            </mat-form-field>
                        </div>
                        <div class="item">
                            <div class="search-result" *ngIf="result">
                                <mat-list class="search-result-list" name="results" *ngFor="let item of result">
                                    <mat-list-item class="search-result-list-item">
                                        <mat-checkbox (change)="select($event,item)" [checked]="isSelected(item)">{{ item.firstName + ' ' + item.lastName }}</mat-checkbox>
                                    </mat-list-item>
                                </mat-list> 
                            </div>
                        </div>          
                        <!-- SELECT ROLE -->
                        <div class="item" *ngIf="withRoles && roles.length > 0 && reference[property]">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Select Role*</mat-label>
                                <mat-select [(ngModel)]="role" name="role" placeholder="">
                                    <mat-option *ngFor="let role of roles | arraySort: 'name'" [value]="role">
                                        {{ role.name }} ({{role.alias}})
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="item ml-2" *ngIf="withRoles && roles.length < 1 && reference[property]">
                            This User has no roles assigned to them.
                        </div>                   
                    </div>
                </div>  
                <div class="action-btns align-items-center pb-5">
                    <div class="container-fluid">
                        <div class="row gap-3">
                            <button mat-stroked-button color="accent" type="button" (click)="cancel()">CANCEL</button>
                            <button mat-flat-button color="accent" type="button" [disabled]="this.reference[this.property].length < 1" (click)="reset()">RESET</button>
                            <button mat-flat-button color="accent" type="button" [disabled]="this.reference[this.property].length < 1 || (withRoles && !this.role)" (click)="save()">SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>  
    </div>
</ng-template> 

<app-route-tracker></app-route-tracker>