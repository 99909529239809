
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

/**
 * chip-list
 */
export class ChipListComponent implements OnInit  {

    @Input() data;
    @Output() dataChange = new EventEmitter<any>();

    @Input() label;
    @Input() name;

    @Input() isReadonly: boolean;

    @Input() securityRoute = '';

    @Input() allowDefaultDelete = true; // allow default deletion behavior when `x` is clicked on a chip
    @Output() deleteChip = new EventEmitter <any>();

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    /**
     * Constructor.
     */
    constructor() {

    }

    /**
     * On Init.
     */
    ngOnInit(): void {
    }

    /* UTILITY METHODS */

    /**
     * addChip
     * used for adding multiple selections
     */
    addChip(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add keyword
        if ((value || '').trim() && !this.data?.includes(value || '')) {
            if (!this.data) {this.data = []; }
            this.data.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.dataChange.emit(this.data);
    }

    /**
     * removeChip
     * removes chip from added selections
     */
    removeChip(choice: string): void {
        if (this.allowDefaultDelete) {
            this.data = this.data.filter(item => item !== choice);
            this.dataChange.emit(this.data);
        } else {
            this.deleteChip.emit(choice);
        }

    }

}
