import _ from 'lodash';

import { Cache } from '@src/shared/objects/cache';
import { Questionnaire } from './questionnaire';
import { Answer } from './answers';

export class Step {

    /* ATTRIBUTES */

    name: string;

    route: string;

    connections = {};
    defaultStep: boolean;
    referenceType: string;
    referencePkId;

    templatePkId;

    roles: string[]; // To be used for role based access for forms

    recommendedDuration = 7;
    active: boolean;


    // not to be saved in db
    answeredQuestions;
    totalQuestions;
    incompleteQuestions = [];
    completionPercent = 0;
    private questionnaire;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */

    /**
     * Return whether or not the step is done.
     */
    isComplete(asset, status?): boolean {
        if (this)  {
            let result;
            switch (this.referenceType) {
                case 'data': // fips 199
                    result = asset.data?.length ? true : false;
                    return result;
                case 'businessprocess':
                    result = asset.business ? !!asset.business.contingency?.length : false;
                    return result;
                case 'technology':
                    result = asset.technology && Object.keys(asset.technology).length ?
                        Object.keys(asset.technology).reduce((acc, key) => {
                        acc = acc && (!!(asset.technology[key] as string | []).length || key === 'diagrams') ;
                        return acc;
                        }, true) : false;
                    return result;
                case 'questionnaire':
                    return this.answeredQuestions >= this.totalQuestions;
                default:
                    return status === 'Approved' ? true : false;
            }
        }

        return false;
    }

    /**
     * Return list of incomplete questions (calculated during getPercentComplete);
     */
    async getIncompleteQuestions() {
        return this.incompleteQuestions;
    }

    /**
     * Calculate the percent complete of the step, which depends on the
     * type of step.
     */
    async getPercentComplete(questions?, answers?, controls?, questionnaire?) {

        // Get the cached data and render the component using it. All of the
        // cached data is set either by the master component for each module or
        // when (at) the point when it changes.

        const cache = Cache.get();

        const asset = cache.getValue(Cache.KEYS.ASSET);
        //const configuration = await cache.getValue(Cache.KEYS.CONFIGURATION);
        //const process = cache.getValue(Cache.KEYS.PROCESS);
        const assessmentPlan = cache.getValue(Cache.KEYS.ASSESSMENT_PLAN);

        // STEP: EDIT QUESTIONNAIRE
        // If the step is a form or questionnaire, the percent complete is
        // calculated by dividing the total number of answered questions by
        // the total number of questions.

        if (this.referenceType === 'questionnaire') {
            const answer = new Answer({answers});
            //const questionnaire = new Questionnaire(configuration.questionnaires.find(element => element.pkId === this.referencePkId));
            this.completionPercent = answer.getPercentComplete(questions, questionnaire);
            this.incompleteQuestions = answer.getIncompleteQuestions();
        }

        if (controls) {
            //controls = controls.filter(control => control.processPkId === process.pkId);

            let controlsCompleted = 0;
            let controlsAssigned = 0;
            let generatedControls = controls.length;

            // STEP: EDIT CONTROLS (Authoring Controls)
            // If the step is a control or collection of controls, the percent
            // complete is determined by dividing the total number of controls that
            // were submitted by the total number of controls assigned to
            // the asset.

            if (this.referenceType === 'controls-submit') {
                let authored = 0;
                controls.forEach(control => {
                     if (control.status === 'Passed' || control.status === 'Pending Review' || control.status === 'Failed') {
                         authored++;
                    }
                });
                // step.percentDone = ((controlsCompleted / (generatedControls || 1)) * 100).toFixed(0);
                this.completionPercent =
                    ((authored / (generatedControls || 1)) * 100);
            }

            // STEP: REVIEW CONTROLS (Security Control Review)
            // The percent complete is determined by diving the number of controls 
            // that passed/failed the assessment by the total number of controls 
            // in the assessment plan.

            if (this.referenceType === 'controls-review') {
                const controls_ = controls.filter(control => assessmentPlan?.controlIds.includes(control.id));
                controls_.forEach(control => { if (control.status === 'Passed' || control.status === 'Failed') { controlsCompleted++; } });
                // step.percentDone = ((controlsCompleted / (generatedControls || 1)) * 100).toFixed(0);
                this.completionPercent =
                    // ((controlsCompleted / (generatedControls || 1)) * 100);
                    ((controlsCompleted / (controls_.length || 1)) * 100);
            }

            // STEP: ASSIGNED CONTROLS
            // If the step is to assign the control families, then the percent
            // complete is determined by dividing the the total number of controls
            // assigned to an author divided by the total number of controls
            // assigned to the asset.

            if (this.referenceType === 'assign-control-families') {
                for (let control of controls) {
                    if (control.assessorPkId && control.authorPkId) {
                         controlsAssigned ++;
                    }
                }

                this.completionPercent =
                    (controlsAssigned / (generatedControls || 1) * 100);
            }

        }

        // STEP: Fips 199, technology, bia
        // If the step is an `all or nothing` step, then isComplete()
        // is used to determine if it is completed.
        // completionPercent will always be 0 or 100
        if (['data', 'technology', 'businessprocess'].includes(this.referenceType)) {
            this.completionPercent =
                this.isComplete(asset) ? 100 : 0;
        }

        return this.completionPercent;
    }

    /* UTILITIES */

}
