<div class="breadcrumb">        
    <div class="title" *ngIf="breadcrumb?.back">
        <a class="link uppercase" [routerLink]="breadcrumb.back.link" aria-label="navigation">{{ breadcrumb.back.name }}</a>
    </div>   
    <div class="subtitle" *ngIf="breadcrumb?.items.length > 0">   
        <a class="link uppercase" *ngFor="let item of breadcrumb?.items; index as i; first as isFirst; trackBy: trackByLink" [routerLink]="item.link" [queryParams]="item.params">
            <span class="" *ngIf="!isFirst"> / </span>{{ item.name }}
        </a>
    </div>
</div>
