import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keySafe'
})
// tslint:disable: no-bitwise
export class KeySafePipe implements PipeTransform {

    /**
     * Remove Special characters and spaces from string
     * to make it safe to use as a key
     */
  transform(key: any, ...args: any[]): any {
    if (key && key.replace) {
      return key.replace(/[^a-zA-Z0-9]/g, '');
    } else return key;
  }

}
