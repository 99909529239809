<div class="center">
    <div class="info">
        <div class="info-title">
            <div class="title">ERROR 404</div>
            <div class="subtitle uppercase">The page you were looking found wasn't found :(</div>
        </div>  
        <div class="info-section">            
        </div>
    </div>
</div>
