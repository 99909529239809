<div>
    <!-- CHANNELS -->
    <div class="main-menu" id="channelMenu" *ngIf="isOpen">
        <button class="close" mat-icon-button (click)="close();">
            <mat-icon>close</mat-icon>
        </button>
        <mat-list #channels>
            <!-- GLOBAL CHANNELS -->
            <div>                    
                <mat-list-item class="channel" (click)="selectChannel(globalChatChannel)">
                    <mat-icon class="channel-icon" mat-list-icon>public</mat-icon>
                    <span class="channel-name">Global Chat</span>
                </mat-list-item>
                <mat-list-item class="channel" (click)="openSubMenu(globalHelpChannel)">
                    <mat-icon class="channel-icon" mat-list-icon>help</mat-icon>
                    <span class="channel-name">Global Help</span>
                </mat-list-item>
                <mat-divider class="mt-3 mb-3"></mat-divider>
            </div>
            <!-- SELECTED CONTEXT -->
            <div *ngIf="this.context">
                <mat-list-item class="channel" (click)="selectChannel(null)">
                    <mat-icon class="channel-icon" mat-list-icon>circle</mat-icon>
                    <span class="channel-name">{{ this.context.reference?.name }}</span>
                </mat-list-item>
                <mat-divider class="mt-3 mb-3"></mat-divider>
            </div>
            <!-- HELP CHANNELS -->
            <div>                    
                <mat-list-item class="channel" (click)="openSubMenu(helpChannel)" *ngFor="let helpChannel of channelService.helpChannels | arraySort:'name'">
                    <mat-icon class="channel-icon" mat-list-icon>help</mat-icon>
                    <span class="channel-name">{{helpChannel.name}}</span>
                </mat-list-item>
                <mat-divider class="mt-3 mb-3"></mat-divider>
            </div>
            <!-- CHAT CHANNELS -->
            <div>                    
                <mat-list-item class="channel" (click)="selectChannel(channel)" *ngFor="let channel of channelService.chatChannels | arraySort:'name'">
                    <mat-icon class="channel-icon" mat-list-icon>{{channel.isPrivate ? 'lock' : 'lock_open'}}</mat-icon>
                    <span class="channel-name">{{channel.name}}</span>
                </mat-list-item>
            </div>
        </mat-list>
    </div>
    <!-- HELP -->    
    <div class="sub-menu" id="subMenu" *ngIf="selected && isSubMenuOpen">
        <button class="close" (click)="closeSubMenu();" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-list #helpChannels>
            <mat-list-item class="channel" (click)="selectChannel(selected)">
                <div class="d-flex align-items-center justify-content-between">                
                    <div>
                        <h5 class="ellipsis">{{ selected.name }}</h5>
                    </div>
                    <div>
                        <mat-icon class="add" mat-list-icon>add_circle</mat-icon>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="ticket" *ngFor="let child of getChildren(selected) | arraySort:'reference?.name'" (click)="selectChannel(child)">
                <span class="ellipsis">{{ child.reference?.name }}</span>
            </mat-list-item>
        </mat-list>
    </div>
</div>
