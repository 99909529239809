import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractService } from '@src/shared/services/abstract.service';
import { Comment } from '../objects/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends AbstractService {

    /* CONSTRUCTOR */

    /* METHODS */

    /**
     * Create comment.
     */
    public create(comment: Comment, organizationPkId: string, assetPkId: string, processPkId: string): Observable<any> {
        return this.post('/comment/create', {comment, organizationPkId, assetPkId, processPkId});
    }

    /**
     * Delete comment.
     */
    public delete(pkId: string): Observable<any> {
        return this.post('/comment/delete', {pkId});
    }

    /**
     * get comments by reference.
     */
    public getByReference(referencePkId: string, referenceType: string, organizationPkId?: string, assetPkId?: string, processPkId?: string): Observable<any> {
        return this.post('/comment/getByReference', {referencePkId, referenceType, organizationPkId, assetPkId, processPkId});
    }

    /**
     * Update comment.
     */
     public update(comment: Comment): Observable<any> {
        return this.post('/comment/update', {comment});
    }

}
