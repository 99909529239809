import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * Directive used for making elements focasable by keyboard
 * as well as adding click events when keyboard keys are pressed
 */
@Directive({
  selector: '[tabIndex]'
})
export class TabIndexDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.tabIndex = 0;
    this.el.nativeElement.onkeydown = (event) => {
      if (event.keyCode === 13) {
        // this.el.nativeElement.click();
        this.el.nativeElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      }
    };
  }
}
