import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

    /* CONSTANTS */
    public users;

    /* METHODS */  

    // ROLE

    /**
     * assign the specified role to the user.
     */
    public createRole(userPkId: string, organizationPkId: string, assetPkId: string, rolePkId: string) {
        return this.post('/user/role/create', { userPkId, organizationPkId, assetPkId, rolePkId });
    }

    /**
     * assign the specified role to the user.
     */
    public assignRoles(userPkId: string, rolePkIds: string, assetPkIds: string, organizationPkId: string) {
        return this.post('/user/role/assign', { userPkId, organizationPkId, assetPkIds, rolePkIds });
    }

    /**
     * Delete the specified role from the user.
     */
    public deleteRole(userPkId: string, organizationPkId: string, assetPkId: string, rolePkId: string) {
        return this.post('/user/role/delete', { userPkId, organizationPkId, assetPkId, rolePkId });
    }

    // USER

    /**
     * Gets all Admins.
     */
    public connections(): Observable<any> {
        return this.post('/user/connections', {}, undefined, undefined, true);
    }

    /**
     * Get the authenticated user.
     */
    public current() {
        return this.post('/user');
    }

    /**
     * assign the specified role to the user.
     */
    public getByPkId(pkId: string) {
        return this.post('/user/get', { pkId });
    }

    /**
     * Get the index of all users in the system.
     */
    public index(organizationPkId) {
        return this.post('/user/index', {organizationPkId});
    }

    /**
     * Get the index of all users in the system.
     */
    public list(organizationPkId) {
        return this.post('/user/list', {organizationPkId});
    }

}
