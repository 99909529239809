import {v4 as uuid} from 'uuid';

export class Message {

    /* ATTRIBUTES */

    // GENERAL

    pkId = '';    
    channel: any = {};
    channelPkId = '';
    channelName = '';  
    type: 'Chat' | 'Email' | 'Help' | 'Log' | 'Message' | 'Notification' | 'Status' | 'Text' = 'Message'; 
    text: string = ''; // the unencrypted text for chats, email, log, and notifications
    link: string = ''; // a link for redirecting the message
    metadata: { [key: string]: any } = {}; // additionally metadata for processing the message
    isSystem: boolean = false; // whether the message is meant for system use only
    

    // FROM

    fromPkId: string = '';
    fromName: string = '';
    fromEmail: string = '';

    fromApplicationPkId = '';
    fromApplicationName = '';
    fromOrganizationPkId = '';
    fromOrganizationName = '';

    // TO

    toPkId: string = '';
    toName: string = '';
    toEmail: string = '';

    toPkIds: string[] = [];
    toNames: string[] = [];
    toEmails: string[] = [];
    toPhones: string[] = [];

    toApplicationPkId = ''; 
    toApplicationName = '';    
    toOrganizationPkId = '';
    toOrganizationName = '';  

    // DATA

    action: 'Approve' | 'Assign' | 'Create' | 'Connect' | 'Delete' | 'Invite' | 'Publish' | 'Read' | 'Publish' | 'Unassign' | 'Unapprove' | 'Uninvite' | 'Update' | '' = ''; // the action to perform with the reference payload
    part: number = 1; // part if the message is split
    total: number = 1; // total number of parts if message is split
    reference: any = {}; // payload containing the data object
    referencePkId: string = ''; // the id of the object in the payload
    referenceType: string = ''; // the type of the object in the payload such as Chat, Email, Help, Incident, Log, Notification, Text, etc.
    isHidden: boolean = false;
    isViewed: boolean = false;

    // DATE

    createDate: Date = new Date();
    updateDate: Date = new Date();

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuid(); }
        this.type = "Message";
    }

    /* METHODS */

    /**
     * Copy the data in the message into this object. 
     */
    public copy (message: Message) {
        
        let pkId = this.pkId;
        let type = this.type;

        Object.assign (this, message);

        this.pkId = pkId;
        this.type = type;
        
    }

}


