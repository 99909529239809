import { Pipe, PipeTransform } from '@angular/core';
/**
 * Takes in array of objects, a keyword, and an array of field names.
 * Returns array of objects filtered by a keyword
 */
@Pipe({
  name: 'debug'
})
export class DebugPipe implements PipeTransform {

  private lastItem;

  transform(item: any, isVisible?): any {
    if (item !== this.lastItem) {
        console.log({
            value: item,
            type: typeof item,
            length: item?.length,
            isNaN: isNaN(item)
        });
        this.lastItem = item;
    }

    return isVisible ? ('DEBUG PIPE: ' + item) : '';
  }

}
