
<app-close-button *ngIf="!isUploading"></app-close-button>

<div class="center">
    <div class="editor">
        <div class="editor-title">
            <h3 class="uppercase">Import {{importType}}</h3>
        </div>
    
        <div class="editor-section">
            <!-- SHEET SELECTION IN CASE OF MULTIPLE SHEETS -->
    
            <div class="pt-3 pb-3 mb-3" *ngIf="workSheets?.length > 1">
                <h5>SHEET SELECTION</h5>
                <mat-button-toggle-group *ngIf="workSheets?.length" name="sheetSelect" aria-label="Sheet" [value]="0">
                    <mat-button-toggle *ngFor="let sheet of workSheets; let i = index" (click)="changeSheet(i)" [value]="i">{{sheet.name}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
    
            <!-- FILE UPLOADER -->
    
            <div [hidden]="data && columns" [ngStyle]="isUploading && {'filter':'Blur(1px'}">
                <app-uploader uploadLabel 
                    (addFile)="uploadFile()" 
                    (completeItem)="uploadedData($event)"
                    (uploading)="isUploading = $event"
                    [route]="api" 
                    [buildForm]="buildForm()"
                    [uploaderOptions]="options"
                    [uploadLabel]="undefined"
                    [isSingleRequestMode]="false">
                </app-uploader>
            </div>
    
            <!-- DATA TABLE -->
    
            <div *ngIf="validWorksheet === false" class="table-message-container">
                <div class="table-error-message">
                    <span>Errors found on {{invalidRows | i18nPlural: rowNb }}. Hover 
                        over the highlighted values to learn more. Click the 'X' at the top right of the screen to return to the previous page and 
                        try importing again.
                    </span>
                </div>
                <div *ngIf="dataSource?.data?.length" class="table-checkbox">
                    <mat-checkbox name="errorsOnly" (change)="showErrorsOnly($event.checked ?  'true' : '')">
                        Show only rows containing errors
                    </mat-checkbox>
                </div>
            </div>
            
            <div *ngIf="data" class="page-section nopadding x-scroll">
                <div *ngIf="validWorksheet === true" class="table-success-message mb-5">
                    <span>
                        
                         Your import was successful. You can review imported data below. Click the 'X' at the top right of the screen to return to previous page.
                    </span>
                </div>
                <div class="row nopadding">
                    
                    <div *ngIf="dataSource?.data?.length > 0" class="col nopadding table-container">
                        <table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" style="width:100%" >
    
                            <ng-container matColumnDef="errors">
                                <th class="col-text truncate-text theader-cell" mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell class="col-text truncate-text table-cell" *matCellDef="let item">
                                    <div class="cell-content">
                                        <mat-icon *ngIf="!item['hasError']" class="secondary-color material-icons-outlined">check_circle</mat-icon>
                                        <mat-icon *ngIf="item['hasError']" class="warn-color material-icons-outlined" matTooltip="{{item.errorNumber | i18nPlural: errorNb }} on this row">report_problem</mat-icon>
                                    </div>
                                </td>
                            </ng-container>
    
                            <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{column}}">
                                <th class="col-text truncate-text theader-cell" mat-header-cell mat-sort-header *matHeaderCellDef> 
                                    <span>{{column}}</span>
                                </th>
                                <td class="col-text table-cell" [ngClass]="{'error': item[column]?.error}" mat-cell *matCellDef="let item">
                                    <div class="cell-content">
                                        <span class="truncate-text">{{item[column]?.value}}&nbsp;</span>
                                        <mat-icon *ngIf="item[column]?.error" class="warn-color material-icons-outlined" matTooltip="{{item[column]?.error.message}}">report_problem</mat-icon>
                                    </div>
                                </td>
                            </ng-container>      
    
                                            
                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                        </table>
                        <div class="flex- justify-content-center" *ngIf="dataSource?.data?.length">
                            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 100, 200]"></mat-paginator>
                        </div>
                    </div>
                </div>
                <div *ngIf="!dataSource.data || dataSource?.data?.length === 0" class="row nopadding">
                    No data to display
                </div>
            </div>

        </div>
    </div>




</div>

<app-loading-spinner class="loader" [isLoading]="isUploading"></app-loading-spinner>
<app-route-tracker></app-route-tracker>