import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { ChannelService } from '@src/shared/services/channel.service';
import { HelpService } from 'src/shared/services/help.service';
import { MessageService } from 'src/shared/services/message.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

    @Input() context;
    @Input() isOpen;
    @Input() globalChatChannel;
    @Input() globalHelpChannel;

    @Output() isOpenChange = new EventEmitter<boolean>();    
    @Output() select = new EventEmitter();

    // Event listener for detecting clicks outside of the menus
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.isOpen) {
            const mainMenu = document.getElementById('mainMenu');
            if (mainMenu) {
                const subMenu = document.getElementById('subMenu');
                if(!mainMenu.contains(event.target) && !subMenu?.contains(event.target)) {
                    // initially clicking to open the chat must be ignored
                    if (this.isClosable) {
                        this.close();
                    }
                    else {
                        this.isClosable = true;
                        this.isOpen = true;
                    }
                }
            }
        }
    }

    /* ATTRIBUTES */

    public isClosable = false;
    public isSubMenuOpen = false;    
    public selected; // the selected help channel

    /* CONSTRUCTOR */

    /**
     * Constructor
     */
    public constructor(
        public channelService: ChannelService,
        public helpService: HelpService,
        public messageService: MessageService) { 
    }

    /**
     * On Init.
     */
    public ngOnInit(): void {
    }

    /* METHODS */

    /**
     * Get the help requests for the specified parent channel.
     */
    public getChildren(parent) {
        if (parent) {
            let children = this.channelService.getChildren(parent);
            return children;
        }
        return [];
    }

    /**
     * Select Channel.
     */
    public selectChannel(channel) {
        this.close();
        this.select.emit(channel);
    }

    // MAIN MENU

    /**
     * Close Menu
     */
    public close() {
        this.isClosable = false;
        this.isSubMenuOpen = false;
        this.isOpen = false;
        this.isOpenChange.emit(false);
    }

    // SUB MENU

    /**
     * Go back to the main menu;
     */
    public closeSubMenu() {
        this.isSubMenuOpen = false;
        this.isOpen = true;
        this.selected = null;
    }

    /**
     * Open help menu.
     */
    public openSubMenu(channel) {
        this.isSubMenuOpen = true;
        this.isOpen = false;
        this.selected = channel;
    }

    // UTILITES


}
