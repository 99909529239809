// OPEN SOURCE

import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { ChartModule } from 'angular-highcharts';
import { CookieService } from 'ngx-cookie-service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import config from '@app/app';

import { ChatModule } from './chat/chat.module';
import { MaterialModule } from '@src/shared/material.module';
import { SharedModule } from '@src/shared/shared.module';

import { MainComponent } from '@src/main/main.component';
import { PrivateComponent } from '@app/private/private.component';
import { PublicComponent } from '@app/public/public.component';

import { CustomReuseStrategy } from '@src/shared/routing/routing';
import { ErrorComponent } from '@src/main/core/error/error.component';
import { FooterComponent } from '@src/main/core/footer/footer.component';
import { GlobalErrorHandler } from '@src/shared/objects/global-error-handler';
import { HeaderComponent } from '@src/main/core/header/header.component';
import { LogoutComponent } from '@src/main/core/logout/logout.component';
import { NavigationComponent } from '@src/main/core/navigation/navigation.component';
import { NotFoundComponent } from '@src/main/core/not-found/not-found.component';
import { SigninComponent } from '@src/main/core/signin/signin.component';
import { StartupSpinnerComponent } from '@src/shared/components/startup-spinner/startup-spinner.component';
import { TimeoutComponent } from '@src/main/core/timeout/timeout.component';

import { AbstractService } from '@src/shared/services/abstract.service';
import { SecurityService } from '@src/shared/services/security.service';

const routes: Routes = [
    { path: '', loadChildren: () => import('@app/app.module').then(m => m.AppModule) },
    { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
    { path: 'private', component: PrivateComponent, loadChildren: () => import('@app/private/private.module').then(m => m.PrivateModule) },
    { path: 'public', component: PublicComponent, loadChildren: () => import('@app/public/public.module').then(m => m.PublicModule) },
    { path: 'signin', component: SigninComponent, pathMatch: 'full' },
    { path: 'timeout', component: TimeoutComponent, pathMatch: 'full' },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    declarations: [
        ErrorComponent,
        FooterComponent,
        HeaderComponent,
        LogoutComponent,
        MainComponent,
        NavigationComponent,
        NotFoundComponent,
        StartupSpinnerComponent,
        TimeoutComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ChatModule,
        ChartModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        NgIdleKeepaliveModule.forRoot(),
        SharedModule.forRoot(),
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {})
    ],
    exports: [
        HeaderComponent,
        NavigationComponent
    ],
    providers: [
        AbstractService,
        CookieService,
        SecurityService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ],
    bootstrap: [
        MainComponent
    ]
})

/**
 * The main module is the primary module that is first loaded by the
 * application. The main module defines a set of common routes and then
 * delegates the '' route to the application that is built on top of
 * the common code infrastructure.
 */
export class MainModule {

    /**
     * Constructor.
     */
    public constructor(overlayContainer: OverlayContainer) {
        // Add app name to overlay for easy CSS control
        const appName = config.appName.replace(/\s/g, '') || '';
        overlayContainer.getContainerElement().classList.add('app-custom-theme', appName);
    }
}
