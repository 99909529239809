import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'suggestion-dropdown',
  templateUrl: './suggestion-dropdown.component.html',
  styleUrls: ['./suggestion-dropdown.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
  
})
export class SuggestionDropDown implements OnInit, OnChanges{

    @Input() isReadOnly;
    @Input() objects: any[]; // list of items to populate dropdown list
    @Input() labelName; // the configuration for the organization
    @Input() model; // the ngModel to use for list
    @Input() placeholder;
    @Input() required;
    @Input() selectName;
    @Input() field;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    /* ATTRIBUTES */


    public itemToAdd = ''; // keyword for search
    public showSearchBar = false;
    public options = [];

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() {

    }

    /* INIT */

    /**
     * On Init.
     */
    public async ngOnInit() {
        this.options = [];
        this.itemToAdd = '';
        this.showSearchBar = false;
          
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        for (let obj of this.objects) {
            let value;
            if (this.field) {
                value = this.field.split('.').reduce((a, b) => {
                    return a ? a[b] : '';
                    }, obj);
            }
            else {
                value = obj;
            }

            if (value && !this.options.includes(value)) {
                this.options.push(value)
            }
        }
        this.options.sort();
    }

    /* METHODS */

    /**
     * Adds the value that was typed in the filtered search bar
     * to the list as well as makes the added value the currently
     * selected value.
     */
    public addItem() {
        if (this.itemToAdd.trim() === '') {
            this.hideSearchBar();
            return;
        }
        if (this.options.includes(this.itemToAdd)) {
            this.showSearchBar = false;
            return;
        }
        this.options.push(this.itemToAdd)
        this.hideSearchBar();
        this.model = this.itemToAdd;
        this.itemToAdd = '';
        this.options.sort();
        this.emitChange();
    }
    
    /**
     * Notifies parent element of the change 
     */
    public emitChange() {
        this.modelChange.emit(this.model);
    }

    /**
     * Hides the search bar in the dropdown menu
     */
    public hideSearchBar(ev?) {
        if (ev){
            console.log(ev)
        }
        this.showSearchBar = false;
    }

    /**
     * Prevents users from typing special characters and/or pasting text with special characters 
     */
    public omitSpecialChars(event) {
        if (event.type === 'keypress') {
            var char = event.charCode;  //         k = event.keyCode;  (Both can be used)
            return((char > 64 && char < 91) || (char > 96 && char < 123) || char == 8 || char == 32 || (char >= 48 && char <= 57)); 
        }
        if (event.type === 'paste') {
            var pastedData = event.clipboardData.getData('text/plain');
            for (let char in pastedData) {
                if (!(char.charCodeAt(0) > 64 && char.charCodeAt(0) < 91) || !(char.charCodeAt(0) > 96 && char.charCodeAt(0) < 123) || 
                    !(char.charCodeAt(0) == 8 || char.charCodeAt(0) == 32) || !(char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)) {
                        return false;
                }
            }
        }
    }
}