<div class="selector" >
    <mat-icon (click)="drawer.toggle()">menu</mat-icon>
</div>

<mat-drawer-container class="drawer-container" autosize>
    <mat-drawer class="p-4 drawer" #drawer mode="over" position="end" color="primary">
        <div class="popout">
            <button class="close-btn right" mat-icon-button (click)="drawer.toggle()">
                <mat-icon class="material-icons-outlined">close</mat-icon>
            </button>
            <div class="popout-section">
                <div class="popout-section-title">                    
                    <h4>ASSETS</h4>
                </div>
                <div>
                    <div 
                        class="asset" 
                        [ngClass]="asset.pkId === asset_.pkId ? 'asset-selected' : ''" 
                        *ngFor="let asset_ of assets | arraySort:'name'"
                        (click)="select(asset_)">
                        {{ asset_.name }}
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer>
  </mat-drawer-container>