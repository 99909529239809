import { Injectable } from '@angular/core';

import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService extends AbstractService {

    /* ATTRIBUTES */

    // When handling large scale data sets in the analytics on the client, filtered data in one
    // component may be shared with another component. The variable belwo is a locally cached data
    // tha this shared by components

    public data: any = {}; // data shared between anlytics components

    /* METHODS */

    //#region GET DATA

    
    /**
     * Get asset trend data.
     */
    public get(type:string, organizationPkId: string, referencePkId?:string, referenceType?:string) {
        return this.post('/analytics/data', { type, organizationPkId, referencePkId, referenceType });
    }

    /**
     * Get asset trend data.
     */
    public getAssetTrendData(organizationPkId: string, type:'ASSET.TREND' | 'ASSET.TREND.ACTIVITY' = 'ASSET.TREND', date?: Date) {
        return this.post('/analytics/data', { type, organizationPkId, date });
    }

    /**
     * Get compliance data.
     */
    public getComplianceData(organizationPkId: string) {
        return this.post('/analytics/data', { type:'COMPLIANCE', organizationPkId });
    }

    /**
     * Get confidence data.
     */
    public getConfidenceData(assetPkId: string, processPkId: string, controlId, part) {
        return this.post('/analytics/data', { type:'CONFIDENCE', assetPkId, processPkId, controlId, part});
    }

    /**
     * Get Control data.
     */
    public getControlData(organizationPkId: string, type:string = 'CONTROL') {
        return this.post('/analytics/data', { type, organizationPkId });
    }

    /**
     * Get Mission Risk data for Mission to Risk dashboard.
     */
    public getMissionRiskData(organizationPkId: string, type:string = 'MISSION.RISK', assetPkId?: string) {
        return this.post('/analytics/data', { type, organizationPkId, assetPkId });
    }

     /**
     * Get ConMon Analytics for the line graph on the ConMon page.
     */
    public getConMonAnalytics(organizationPkId: string, assetPkId: string) {
        return this.post('/analytics/getConMonAnalytics', {organizationPkId, assetPkId})
    }

    /**
     * Go through controls and calculates average confidence data for each control family.
     * Also needs to return finding history/control completion status. Used to populate confidence 
     * percentage data and burndown and workload charts on process dashboard.
     */
    public getProcessData(assetPkId: string, processPkId: string) {
        return this.post('/analytics/data', { type:'PROCESS', assetPkId, processPkId });
    }

    /**
     * Get Questionnaire data.
     */
    public getQuestionnaireData(organizationPkId: string, questionnairePkId: string) {
        return this.post('/analytics/data', { type:'QUESTIONNAIRE', organizationPkId, questionnairePkId });
    }

    /**
     * Get Questionnaire data.
     */
    public synchronize(organizationPkId: string, type: string, assetPkId?: string) {
        return this.post('/analytics/synchronize', { type, organizationPkId, assetPkId });
    }
    

    /**
     * Update asset trend data/SDLC.
     */
    public updateAssetTrendData(organizationPkId: string, assetPkId?: string, poamActivity?: any) {
        return this.post('/analytics/data/update', { type:'ASSET.TREND', organizationPkId, assetPkId, poamActivity });
    }

    /**
     * Update compliance data.
     */
    public updateComplianceData(organizationPkId, analytics?: any) {
        return this.post('/analytics/data/update', { type:'COMPLIANCE', organizationPkId, analytics });
    }

    /**
     * Update confidence data.
     */
    public updateConfidenceData(assetPkId, processPkId, control, part, analytics?: any) {
        return this.post('/analytics/data/update', { type:'CONFIDENCE', assetPkId, processPkId, control, part, analytics });
    }

    /**
     * Update Mission to Risk data.
     */
    public updateMissionRiskData(organizationPkId) {
        return this.post('/analytics/data/update', { type:'MISSION.RISK.ALL', organizationPkId });
    }

    /**
     * Update process data.
     */
    public updateProcessData(assetPkId, processPkId, analytics?: any) {
        return this.post('/analytics/data/update', { type:'PROCESS', assetPkId, processPkId, analytics });
    }


    /**
     * Get Process trend data.
     */
    public getProcessTrendData(organizationPkId: string, date?: Date) {
        return this.post('/analytics/data', { type:'PROCESS.TREND', organizationPkId, date });
    }

    /**
     * Update security data.
     */
    public updateThreatData(organizationPkId, analytics?: any) {
        return this.post('/analytics/data/update', { type:'THREAT', organizationPkId, analytics });
    }

    //#endregion

}
