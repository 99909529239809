import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import config from '@app/app';

import { User } from '@src/shared/objects/user';

import { MessageService } from '@src/shared/services/message.service';
import { UserService } from '@src/shared/services/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit {

    @ViewChild(MatSort) set matSort(ms: MatSort) {
      this.sort = ms;
      this.dataSource.sort = this.sort;
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
      this.paginator = mp;
      this.dataSource.paginator = this.paginator;
    }

    /* ATTRIBUTES */

    public config; // app.ts;

    public user: User; // current user

    public columnsToDisplay = [ 'fromName', 'content', 'organizationName', 'createDate', 'updateDate', 'actions'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public paginator;
    public sort;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        private router: Router,
        public messageService: MessageService,
        private userService: UserService) {

    }

    /* EVENT HANDLING */

    /**
     * Init
     */
    public ngOnInit(): void {
        this.config = config;
        this.userService.current().subscribe(res => { 
            this.user = res; 
            this.dataSource.data = this.messageService.getNotifications();
        });
    }

    /* METHODS */
    
    //#region NOTIFICATIONS

    /**
     * Clear all notifications
     */
    public clearAll() {
        this.messageService.clearAll();
        this.dataSource.data = this.messageService.getNotifications();
    }

    /**
     * Filter the table based on the value typed ito the search filter.
     */
    public filter(value) {
        if (value) {
            let filtered = this.messageService.getNotifications().filter(function f(notification) {
                let term = value.toLowerCase();
                let index = 
                  notification.text.toLowerCase() + ' ' +
                  new Date(notification.createDate).toDateString().toLowerCase() + ' ' +
                  new Date(notification.updateDate).toDateString().toLowerCase() + ' ' +
                  notification.fromOrganizationName.toLowerCase() + ' ' +
                  notification.fromApplicationName.toLowerCase();

                if (index.includes(term)){
                    return true; 
                } 
                else { 
                    return false; 
                }
            });           
            this.dataSource.data = filtered;
        }
        else {
            this.dataSource.data = this.messageService.getNotifications();
        }
    }

    /**
     * Remove the notification
     */
    public ignore(notification) {
        this.messageService.removeNotification(notification);
    }

    /**
     * Mark all as read
     */
    public markAllAsRead() {
        this.messageService.markNotificationAllAsRead();
        this.dataSource.data = this.messageService.getNotifications();
    }

    /**
     * Open the component such as chat, help, etc. so that the user can see
     * the data attached to the notification.
     */
    public open(notification) {
        // this.messageService.markNotificationAsRead(notification.pkId);
        // if (notification) {
        //     switch(notification.referenceType) {
        //         case 'Channel':
        //             this.messageService.openChat(notification.channelPkId, undefined);
        //             this.close();
        //             break;
        //         case 'Chat':
        //             if (notification.channelPkId && notification.referencePkId) {
        //                 this.messageService.openChat(notification.channelPkId, notification.referencePkId);
        //                 this.close();
        //             }
        //             break;
        //         case 'Help':
        //             this.route.navigate(['/private/help/view-request/' + notification.reference.pkId]);
        //             this.messageService.openChat(notification.channelPkId, undefined);
        //             this.close();
        //             break;
        //         default: 
        //             break;
        //     }
        // }
    }

    /**
     * Publish.
     */
    public publish(notification) {
        if (notification) {
            // console.log("/shared/components/notification/publish: NOTIFICATION = ", notification);
            // http://localhost:4210/private/control/create-finding?category=finding
            this.router.navigateByUrl(config.routes.PRIVATE_CONTROL_CREATE_FINDING, { state: { finding: notification.reference }});
            this.messageService.removeNotification(notification);
        }
    }

    /**
     * Update membership status for a channel. 
     */
    public updateInvite(item, status) {
        // this.messageService.markNotificationAsRead(item.pkId);
        // item.metadata['isPendingInvite'] = false;
        // this.messageService.updateMembership(item.metadata['New Channel'], this.user.pkId, status).subscribe(res => {
        // });
    }

    //#endregion

    //#region UTILITIES

    /**
     * Close window.
     */
    public close() {
        this.dialog.closeAll();
    }

    /**
     * Opens a dialog with a given settings.
     */
    public openDialog(template) {
        this.dataSource.data = this.messageService.getNotifications();
        let properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: false};
        let dialog_ = this.dialog.open(template, properties);
    }

    //#endregion

}
