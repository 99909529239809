
import { Catalog } from './catalog';
import { Control } from './control';
import { Process } from './process';
import { Question } from './question';
import { Questionnaire } from './questionnaire';
import { User } from './user';

export class Configuration {

    /* CONSTANTS */


    /* FIELDS */

    public pkId: string;
    public name: string;
    public acronym: string;
    public status: 'Active' | 'Inactive' = 'Active';

    public overlays: any;
    public standards: any;
    public terms: any;

    public catalogs: Catalog[] = [];
    public dashboards: any[] = []; 
    public documents: any[] = [];
    public financials: any[] = [];
    public processes: Process[] = [];
    public questions: Question[] = [];
    public questionnaires: Questionnaire[] = [];
    public roles: any[] = [];
    public routes: any[] = [];

    public securityCode: string = '';
    
    /* ATTRIBUTES */

    public users: any[] = []; // this of authorized users for this organization
    public technologMap = new Map<string, any>(); // list of technologies mapped by control id

    /* CONSTRUCTOR */
    
    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
    }

    /* METHODS */

    /**
     * Load all of the pre-approved technologies and index them by control 
     * id so that the table can look up the pre-approved technologies by 
     * control id.
     */
    public getTechnologyMap() {
        if (this.standards.technology?.length) {
            for (let technology of this.standards.technology) {    
                for (let key of technology.controls) {
                    if (this.technologMap.has(key)) {
                        let value: any [] = this.technologMap.get(key);
                        let data = { name : technology.name, isStandard : true }; // add a flag to indicate whether it is a standard technology
                        value.push(data);
                    }
                    else {
                        let value = [];
                        let data = { name : technology.name, isStandard : true }
                        value.push(data);
                        this.technologMap.set(key, value);
                    }
                }
            }
        }

        return this.technologMap;
    }

    /**
     * Translate the term into the customer term if the mapping exists.
     */
    public translate (term) {
        if (term) {            
            if (this.terms) {
                let value = this.terms[term];
                return value ? value : term;
            }
        }
        return term;
    }

}
