
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import config from '@app/app';
import { Asset } from '@src/shared/objects/asset';
import { Cache } from '@src/shared/objects/cache';
import { Configuration } from '@src/shared/objects/configuration';
import { AnalyticsService } from '@src/shared/services/analytics.service';


@Component({
  selector: 'app-mission-to-risk-table',
  templateUrl: './mission-to-risk-table.component.html',
  styleUrls: ['./mission-to-risk-table.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.Emulated
})

export class MissionToRiskTableComponent implements OnInit {

    @Output()
    goToControl: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatSort, {static: false}) sort: MatSort;

    public config; // app.ts
    public cache: Cache; // the session cache
    public configuration; // the organization configuration

    public selected: any; // the selected mission
    expandedElement: null;

    asset: Asset;
    private analytics = [];
    public assetData: any;
    public missions:any = [];
    
    private assetMap: any = {};
  
    public missionColumnsToDisplay = ['name'];
    public missionColumnWidth = { name: '50%', function: '10%' };
    public functionNames = ['identify', 'protect', 'detect', 'respond', 'recover'];

    dataSource: MatTableDataSource<any> = new MatTableDataSource();

    constructor(
        private analyticsService: AnalyticsService
    ) { 
        this.config = config;
    }

    ngOnInit() {
        this.cache = Cache.get();
        this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.configuration = new Configuration(this.configuration);
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.assetMap[this.asset.pkId] = this.asset;
        this.missionColumnsToDisplay = [...this.missionColumnsToDisplay, ...this.functionNames];

        this.analyticsService.getMissionRiskData(this.asset.organizationPkId, 'MISSION.RISK.ASSET', this.asset.pkId).subscribe(res => {
            this.analytics = res;
            this.missions = this.groupData();
            this.dataSource = new MatTableDataSource(this.missions);
            this.dataSource.sort = this.sort;
        })

    }

    /**
     * Group analytics data by mission.
     */
    public groupData() {
        const groups: {name: string, functions:any, items: any[] }[] = [];
        for (let analytic of this.analytics) {
            for (let assetMission of analytic.missions) {
                let mission_ = groups.find(item_ => item_.name === assetMission.name);
                if (!mission_) {
                    mission_ = {
                        name: assetMission.name,
                        items: [],
                        functions: {}
                    };
                    groups.push(mission_);
                }
                mission_.items.push(analytic);

                for (let assetFunction of analytic.functions) {
                    let function_ = mission_.functions[assetFunction.name];
                    if (!function_) {
                        function_ = {
                            name: assetFunction.name,
                            count: 0,
                            normalized: 0,
                            max: 0,
                            min: 100,
                            sum: 0,
                        };
                        mission_.functions[assetFunction.name] = function_;
                    }

                    const alignment =  (this.assetMap[analytic.assetPkId]?.metadata?.missions?.[assetMission.name] / 100) || 0;
                    const assetFunctionScore = Math.round(assetFunction.sum * alignment);

                    function_.count++;
                    function_.sum += assetFunctionScore;
                    if (assetFunctionScore > function_.max) {
                        function_.max = assetFunctionScore;
                    }
                    if (assetFunctionScore < function_.min) {
                        function_.min = assetFunctionScore;
                    }
                }
            }
        }

        return groups;
    }


    /**
     * Goto Control.
     */
    public gotoControl(item, control) {
        this.goToControl.emit(control);
    }
       
    /**
     * Select a row and show or hide the nested table.
     */
    public select(mission) {
        this.selected = this.selected === mission ? null : mission;
        this.assetData = {
            assetPkId: this.asset.pkId,
            name: this.asset.name,
            mission: mission.name,
            controls: mission.items.find(i => i.assetPkId === this.asset.pkId).controls
        }
    };

}
