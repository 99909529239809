
import { Observable, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';

import { Cache } from '@src/shared/objects/cache';
import { Process } from '@src/shared/objects/process';

import { AbstractService } from '@src/shared/services/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends AbstractService {

    /* ATTRIBUTES */
    
    public cache: Cache; // the session cache
    public configuration; // the organization configuration

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(injector: Injector) {
        super(injector);
    }

    /* METHODS */
  
    //#region ___

    /**
     * Copy the specified processes, frameworks, controls, forms, and fields and return
     * the appropriate components to be cached in the client.
     */
    public copy(data): Observable<any> {         
        return this.post('/configuration/copy', data);
    }

    /**
     * Get the index of all organizations.
     */
    public list(): Observable<any[]> {
        return this.post('/configuration/list');
    }   
    
    /**
     * Get the index of all organizations limiting results to desired query and projection
     */
    public listOrganizations(query: any, projection: any): Observable<any[]> {
        return this.post('/configuration/listOrganizations', {query, projection});
    }   

    /**
     * Get the list of public processes and components that other organizations can copy from
     * when setting up their organization.
     */
    public public(): Observable<any> {
        return this.post('/configuration/public');
    }

    /**
     * Get the organization configurations.
     */
    public settings(pkId: string, cache = true): Observable<any> {
        return this.post('/configuration/settings', { pkId });
    }

    //#endregion

    //#region DASHBOARD

    /**
     * Update the predefined lists.
     */
     public updateDashboardConfig(orgPkId: string, dashboards) {        
        return this.post('/configuration/dashboard/update', { orgPkId, dashboards });
    }

    //#endregion

    //#region PROCESS

    /**
     * Create a process. 
     */
    public createProcess(pkId: string, process: any): Observable<Process> {
        return this.post('/configuration/process/create', {pkId, process}).pipe(
            map(response => new Process(response))
        );
    }

    /**
     * Delete the process from the configuration.
     */
    public deleteProcess(orgPkId: string, processPkId: string) {
        return this.post('/configuration/process/delete', { orgPkId, processPkId });
    }    
    
    /**
     * Find active processes.
     */
    public findSelectableProcess(orgPkId: string): Observable<any> {
        return this.post('/configuration/process/get', { pkId: orgPkId });
    }

    /**
     * Get the list of prebuilt software components to be used within each 
     * of the phase of the process.
     */
    public getComponents() {
        return this.post('/configuration/process/component/list');
    }

    /**
     * Sanitize process.
     */
    public sanitizeProcess(orgPkId: string, processPkId: string) {
        return this.post('/configuration/process/sanitize', { orgPkId, processPkId});
    }

    /**
     * Update the process.
     */
    public updateProcess(orgPkId: string, process: any): Observable<any> {
        return this.post('/configuration/process/update', { orgPkId, process });
    }

    //#endregion

    //#region SECURITY

    //#region SECURITY : ROLES

    /**
     * Create role.
     */
    public createRole(organizationPkId: string, name: string, alias: string): Observable<any> {
        return this.post('/security/role/create', { organizationPkId, name, alias });
    }

    /**
     * Delete role.
     */
    public deleteRole(rolePkId: string): Observable<any> {
        return this.post('/security/role/delete', { pkId: rolePkId });
    }

    /**
     * Get the actives roles.
     */
    public getRoles(organizationPkId: string): Observable<any> {
        return this.post('/security/role/list', { organizationPkId });
    }

    /**
     * Update role.
     */
    public updateRole(role): Observable<any> {
        return this.post('/security/role/update', role );
    }

    //#endregion

    //#region SECURITY : ROUTES

    /**
     * Create Route.
     */
    public createRoute(organizationPkId: string, name: string): Observable<any> {
        return this.post('/security/route/create', { organizationPkId, name });
    }

    /**
     * delete Route.
     */
     public deleteRoute(route): Observable<any> {
        return this.post('/security/route/delete', { pkId: route.pkId });
    }

    /**
     * Get all of the routes for the components in the system.
     */
    public getRoutes(): Observable<any> {
        return this.post('/security/route/list');
    }

    /**
     * Get all of the routes for the components in the system.
     */
     public updateRoute(route): Observable<any> {
        return this.post('/security/route/update', route);
    }

    //#endregion

    //#endregion

    //#region STANDARDS

    /**
     * Update the pre-approved technology standards for each organization so
     * that authors can selected from the preapproved standard when writing
     * the implementation statements. 
     */
    public updateApprovedTechnology(orgPkId: string, technology: any) {
        return this.post('/configuration/standard/technology/update', { orgPkId, technology });
    }

    //#endregion
    
    //#region TERMS

    // /**
    //  * Translate the term into the customer term if the mapping exists.
    //  */
    // public translate (term) {
    //     if (term) {            
    //         let cache = Cache.get();
    //         let configuration = cache.getValue(Cache.KEYS.CONFIGURATION);
    //         if (configuration) {
    //             if (configuration.terms) {
    //                 let value = configuration.terms[term];
    //                 return value ? value : term;
    //             }
    //         }
    //     }
    // }

    /**
     * Update the data map used to translate application terms to customer terms.
     */
    public updateOverlays(orgPkId: string, overlays: any) {        
        return this.post('/configuration/overlays/update', { orgPkId, overlays });
    }

    /**
     * Update the data map used to translate application terms to customer terms.
     */
    public updateTerms(orgPkId: string, terms: any) {        
        return this.post('/configuration/terms/update', { orgPkId, terms });
    }

    //#endregion
   
}