<!-- CHAT MESSAGES -->
<div class="chat-messages" [ngClass]="isFullScreen ? 'chat-messages-full' : ''" #chatScrollContainer>
    <div>
        <div *ngFor="let message of messages" [id]="message.pkId">
            <div *ngIf="message.fromPkId === user.pkId">
                <div class="from-me">
                    <div class="message">
                        <span 
                            [innerHTML]="message.text" 
                            [title]="message.createDate | date:'short'">
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="message.fromPkId !== user.pkId">
                <div class="from-others" *ngIf="!isRobot(message.fromName)">
                    <div class="row nopadding" style="clear:both;">
                        <div class="">                            
                            <div class="icon">
                                <span 
                                    [innerHTML]="getInitials(message.fromName)" 
                                    [title]="message.fromName"></span>
                            </div>      
                        </div>
                        <div class="col-10 nopadding">                            
                            <div class="message">
                                <span 
                                    [innerHTML]="message.text" 
                                    [title]="message.createDate | date:'short'">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="from-robot" *ngIf="isRobot(message.fromName)">
                    <div class="row nopadding" style="clear:both;">
                        <div class="">                 
                            <div class="icon" matTooltip="{{message.fromName}}">
                             </div>
                        </div>
                        <div class="col-10 nopadding">                            
                            <div class="message">
                                <span 
                                    [innerHTML]="message.text" 
                                    [title]="message.createDate | date:'short'">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CHAT SEND -->
<div class="chat-send">
    <mat-form-field class="w-100 search" appearance="outline">
        <!-- CHAT INPUT-->
        <textarea 
            class="chat-input"
            #chatTA 
            #pingTrigger="matAutocompleteTrigger"
            matInput 
            cdkTextareaAutosize 
            type="text" 
            placeholder="Enter Message..."
            [(ngModel)]="chatModel"
            [formControl]="chatControl"
            (keyup)="keyUpEvent($event, chatTA, pingTrigger)" 
            (keydown)="keyDownEvent($event, ping)" 
            [matAutocompleteDisabled]="!isAutocompleteEnabled" 
            [matAutocomplete]="ping">
        </textarea>
        <!-- SEND ICON -->
        <button matSuffix mat-icon-button aria-label="Send" (click)="send(chatTA)">
            <mat-icon>send</mat-icon>
        </button>
        <!-- USERS TO TAG -->
        <mat-autocomplete autoActiveFirstOption #ping="matAutocomplete" (optionSelected)="select($event)">
            <mat-option *ngFor="let user of users_ | async | arraySort:'firstName'" [value]="user">
                {{user.firstName}} {{user.lastName}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>


