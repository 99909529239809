<div class="center">  
    <div class="info">
        <div class="info-title">Goodbye!</div>
        <div class='info-section'>
            <p>
                We hope that you enjoyed using our platform. If you have any ideas to improve the
                user experience, please share your ideas using the chat. Are you sure you want 
                to log out?
            </p>
        </div>    
        <div class="d-flex justify-content-end align-items-center mt-4">
            <button mat-stroked-button color="accent" class="mr-3" (click)="cancel()">CANCEL</button>
            <button mat-flat-button color="accent" (click)="logout()">LOG OUT</button>
        </div>
    </div>
</div>
