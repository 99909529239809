import { Pipe, PipeTransform } from '@angular/core';
/**
 * Takes in array of objects
 * Returns array of objects sorted in ascending order
 * @args Specifies sort-by value
 */
@Pipe({
  name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const keyName = args && args.length && args[0] ? args[0] : undefined;
    const direction = args && args.length && args[1] ? args[1] : 'asc';
    const sortedValue = (value || []).sort((a, b) => {
      if (direction === 'asc') {
        if (keyName) { return a[keyName] < b[keyName] ? -1 : a[keyName] > b[keyName] ? 1 : 0; }
        else { return a < b ? -1 : a > b ? 1 : 0; }
      }
      else if (direction === 'desc') {
        if (keyName) { return a[keyName] > b[keyName] ? -1 : a[keyName] < b[keyName] ? 1 : 0; }
        else { return a > b ? -1 : a < b ? 1 : 0; }
      }

    });
    return sortedValue;
  }

}
