<!-- DROP ZONE -->
<div [class]="customClass">
  <div class="drop-zone" ng2FileDrop [ngClass]="{'file-drop-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" *ngIf="!upload">
      <div class="w-100">      
          <label class="upload-label"> 
              <div class="upload-hitbox">
                  <div class="upload-center">
                    <span style="display:block;"><mat-icon>file_upload</mat-icon> &nbsp; {{dropZoneText}}</span>
                    <span class="" *ngIf="uploader?.options?.allowedMimeType">File Types Accepted: {{fileTypes.join(", ")}}</span>
                  </div>
              </div>
              <input class="choose-file" type="file" ng2FileSelect [(uploader)]="uploader" multiple>
          </label>
      </div>    
  </div>
  <!-- ERROR FILES -->
  <table class="table mt-3 w-100" style="border-collapse: collapse;" *ngIf="showDetails && errorFiles.length > 0">
      <tbody>
          <tr *ngFor="let item of errorFiles" class="row col-12 no-gutters mb-3 align-items-center file-table-row error-row">
              <td class="p-2 truncate-text col-4"[matTooltip]=" item?.name" >{{ item.name }}</td>
              <td class="col-4 d-flex align-items-center highlight-red">
                  <mat-icon class="material-symbols-outlined warn-color mr-2" mat-icon-small>error</mat-icon>
                  <span class="pb-1">{{ item.error }}</span>
              </td>
              <td class="col-2 p-2 d-flex justify-content-end highlight-red">Error</td>
              <td class="d-flex justify-content-end p-2 col-2" nowrap>
                  <button mat-button type="button" (click)="remove('error', item)">
                      <mat-icon>delete_outline</mat-icon>
                  </button>
              </td>
          </tr>
      </tbody>
  </table>
  <!-- UPLOADED FILES -->
  <table class="table mt-3 w-100" style="border-collapse: collapse;" *ngIf="showDetails && uploadedFiles.length > 0">
      <tbody>
          <tr *ngFor="let item of uploadedFiles" class="row col-12 no-gutters mb-3 align-items-center file-table-row">
              <td class="p-2 truncate-text col-4"[matTooltip]=" item?.name" >{{ item.name }}</td>
              <td class="col-3 d-flex align-items-center">
              </td>
              <td class="col-3 p-3 d-flex justify-content-end">
                  <span class="mr-2" *ngIf="item.status !== 'In Queue'"><b>{{item.progress}}%</b></span>
                  <span class="mr-2" *ngIf="item.status !== 'In Queue'">{{item.size * (item.progress / 100) | number:'1.1-1'}}/{{item.size | number:'1.1-1'}} MB</span>
                  <span class="" style="color: #80869B">{{item.status}}</span>
              </td>
              <td class="d-flex justify-content-end p-2 col-2" nowrap>
                  <button mat-button type="button" (click)="remove('uploaded', item)" *ngIf="item.status === 'In Queue'">
                      <mat-icon>delete_outline</mat-icon>
                  </button>
              </td>
          </tr>
      </tbody>
  </table>
  <!-- UPLOADER QUEUE -->
  <table class="table my-3 w-100" style="border-collapse: collapse;" *ngIf="uploadedFiles.length === 0">
      <tbody>
          <tr *ngFor="let item of uploader.queue" class="row col-12 no-gutters mb-3 align-items-center file-table-row">
              <td class="p-2 truncate-text col-4"[matTooltip]=" item?.file?.rawFile['name']" >{{ item?.file?.rawFile['name'] }}</td>
              <td class="col-4"></td>
              <td class="p-2 col-2 d-flex justify-content-end">In Queue</td>
              <td class="d-flex justify-content-end p-2 col-2" nowrap>
                  <button mat-button type="button" [disabled]="isDisabled" (click)="item.remove()">
                      <mat-icon>delete_outline</mat-icon>
                  </button>
              </td>
          </tr>
      </tbody>
  </table>
</div>

<button mat-flat-button color="primary" type="button" *ngIf="uploadLabel" (click)="uploadAll()" [disabled]="isDisabled" [hidden]="!uploader.getNotUploadedItems().length">
    {{uploadLabel || 'Upload'}}
</button>
