import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter'
})
export class NameFilterPipe implements PipeTransform {

  transform(items: any[], searchName: string): any[] {
     if (!items) { return []; }
     if (!searchName) {return items; }

     searchName = searchName.toLowerCase();

     return items.filter( it => {
        const name = it.firstName + ' ' + it.lastName;
        return name.toLowerCase().includes(searchName);
     });
  }

}
