import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { Topic } from '@src/shared/objects/topic';

import { User } from '@src/shared/objects/user';

import { AuthenticationService } from '@src/shared/services/authentication.service';
import { MessageService } from '@src/shared/services/message.service';
import { TopicService } from '@src/shared/services/topic.service';
import { Tree } from '@src/shared/objects/tree';


@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    animations: []
})
export class HeaderComponent implements OnInit, OnDestroy {

    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChild('successSubmitDialog') successSubmitDialog: TemplateRef<any>;

    // Close the menu upon clicking outside of the component.
    @HostListener("document:click", ['$event'])
    clickOut (event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isMenuOpenForAccount = false;
            this.isMenuOpenForMobile = false;
            this.isMenuOpenForNotification = false;
            this.closeSecondary();
        }
    };

    /* CONSTANTS */

    public GLOBAL_ROUTES = {
        HELP: '/private/help',
        HOME: "/private/authentication/home",
        PROFILE: "/private/user/profile",
        SETTINGS: "/private/system"
    };

    /* ATTRIBUTES  */

    public cache; // the session cache

    public config; // the application configuration
    public navigation; // the navigation
    public current: User; // the current user
    public organization: any; // the current organization
    public preference; // the user preference

    public backgroundColor: string; // the background color
    public environment = ''; // the environment where the application is operating

    public isMenuOpenForAccount = false; // whether the notification menu open is open
    public isMenuOpenForMobile = false; // whether in mobile mode and menu is open
    public isMenuOpenForMobileSecondary = false; // whether in mobile mode and secondary menu is open
    public isMenuOpenForNotification = false; // whether the notification menu open is open

    public snackbarSubject = new Subject<{title:string, content:string, route:string, icon: string}>();

    public parent: any;

    private topics: Topic[];
    public topicTree: Tree;

    /* CONSTRUCTOR */

    /**
     * Constructor. 
     */
    public constructor(
        private dialog: MatDialog,
        private eRef: ElementRef,
        private router: Router,
        private authenticationService: AuthenticationService,
        public messageService: MessageService,
        private topicService: TopicService) {

        let url = location.href.toLocaleLowerCase();
        if (url) {
            if (url.includes('localhost')) {
                this.environment = 'Localhost';
            }
            else if (url.includes('demo')) {
                this.environment = 'Demo';
            }
            else if (url.includes('test')) {
                this.environment = 'Test';
            }
            else if (url.includes('staging')) {
                this.environment = 'Staging';
            }
        }
    }

    /* EVENT HANDLING */

    /**
     * Initialize component.
     */
    public async ngOnInit() {

        this.config = config;
        this.backgroundColor = this.config?.backgroundColor;
        this.navigation = Object.assign(this.config.navigation);

        // listen for changes to the cache object. 
        // fix for name and organization not showing on initial load.
        Cache.changeObservable.subscribe(changes => {
            if (changes.key === 'user' || changes.key === 'organization') {
                this.cache = Cache.get();
                this.current = this.cache.getValue(Cache.KEYS.USER);
                this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
            }
        });

        this.cache = Cache.get();
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);

        // this.messageService.notificationSubject.subscribe(res => {
        //     if (res.referenceType === 'Alert') {
        //         this.snackbarSubject.next({
        //             title: `Alert from ${res.reference?.hardwareName}`,
        //             content: `${res.reference?.description}`,
        //             route: this.config.routes.PRIVATE_DASHBOARD_CONTROL,
        //             icon: 'report_problem',
        //         });
        //     }
        // })

        Cache.changeObservable.subscribe(changes => {
            if (changes.key === 'user') {
              this.preference = this.authenticationService.getPreference();
            }
        }); 
          
        this.preference = this.authenticationService.getPreference();
    }

    /**
     * Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    // MENU

    /**
     * Close the menu;
     */
    public close () {
        this.closeSecondary();
        this.isMenuOpenForAccount = false;
        this.isMenuOpenForMobile = false;
        this.isMenuOpenForNotification = false;
    }

    /**
     * Close Secondary the menu;
     */
    public closeSecondary () {
        this.isMenuOpenForMobileSecondary = false;
        this.parent = undefined;
    }

    /**
     * Get Topics.
     */
    public getTopics() {
        if (config.navigation.some(item_ => item_['type'] === 'Training')) {
            this.topicService.list('Training').subscribe(res => {
              this.topics = res;
              this.topicTree = new Tree();
              this.topicTree.createTree(this.topics);
            });
        }
    }

    /**
     * Open the menu.
     */
    public open() {
        setTimeout(() => {
            this.isMenuOpenForMobile = true;
            this.isMenuOpenForAccount = false;
            this.isMenuOpenForNotification = false;
        })
    }

    /**
     * Select parent item.
     */
    public selectParent(item) {
        if (this.parent === item) {
            this.parent = undefined;
            this.isMenuOpenForMobileSecondary= false;
        }
        else {
            this.parent = item;
            if (item?.type === 'Training') {
                this.getTopics();
            }
            this.isMenuOpenForMobileSecondary = true;
        }
    }

    // NAVIGATION

    /**
     * Redirect to the login microservice so that the user can browse his or 
     * her applications.
     */
    public gotoHelp() {
        this.authenticationService.redirect(this.GLOBAL_ROUTES.HELP).subscribe(result => {
            if (result && result.domain) window.location = result.domain;
        });
    }

    /**
     * Redirect to the login microservice so that the user can browse his or 
     * her applications.
     */
    public gotoHome() {
        this.authenticationService.redirect(this.GLOBAL_ROUTES.HOME).subscribe(result => {
            if (result && result.domain) window.location = result.domain;
        });
    }

    /**
     * Redirect to the login microservice so that the user can edit his or 
     * her profile.
     */
    public gotoProfile() {
        this.authenticationService.redirect(this.GLOBAL_ROUTES.PROFILE).subscribe(result => {
            if (result && result.domain) window.location = result.domain;
        });
    }

    /**
     * Redirect to the login microservice so that the user can configure or 
     * manage the system.
     */
    public gotoSettings() {
        this.authenticationService.redirect(this.GLOBAL_ROUTES.SETTINGS).subscribe(result => {
            if (result && result.domain) window.location = result.domain;
        });
    }

    /**
     * Navigate to a route.
     */
    public navigate(route:string) {
        this.router.navigate([route]);
        this.close();
    }

    // THEME

    /**
     * Toggle Theme between light mode and dark mode.
     */
    public toggleTheme(ev) {
      if (ev.checked) {
          this.preference.theme = 'dark';
      } 
      else {
          this.preference.theme = 'light';
      }
      this.authenticationService.setPreference(this.preference);
    }

    // UTILITIES

    /**
     * Close all dialogs.
     */
    public closeAll() {
        this.dialog.closeAll();
    }

    /**
     * Opens a dialog with a given template.
     */
    public openDialog(component, data?, panelClass?: string) {

        // Set the dialog properties and open the dialog using those properties.
        // All dialogs are inteded to fill the entire screen. The dialog styles can
        // be found in the global styles.

        let properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: data };
        const dialogRef = this.dialog.open(component, properties);

        // After the dialog is opened and closed, process the returned data
        // and perform cleanup operations.

        dialogRef.afterClosed().subscribe(result => {
        });
    }

}
