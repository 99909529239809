import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { AbstractControl, ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import config from '@app/app';

import { Asset } from '@src/shared/objects/asset';
import { List } from '@src/shared/objects/list';
import { Utility } from '@src/shared/objects/utility';

import { AssetService } from '@src/shared/services/asset.service';
import { ListService } from '@src/shared/services/list.service';

import { RegularExpressions } from '@src/shared/objects/regular-expressions';

@Component({
  selector: 'app-asset-summary',
  templateUrl: './asset-summary.component.html',
  styleUrls: ['./asset-summary.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AssetSummaryComponent implements OnInit, OnDestroy {

    @ViewChild('editor') editor;

    @Input() asset: Asset; // the asset
    @Input() assets: Asset[]; // list of asset indices used for preventing duplicate name / alias
    @Input() cache; // the session cache
    @Input() config; // the application configuration
    @Input() configuration; // the configuration for the organization
    @Input() organization; // the organization tied to the domain name
    @Input() processes; // the list of the asset's processes
    @Input() users; // all users in organization that can be set as asset owner;
    @Input() divisions; 

    @Input() isReadonly;
    
    /* CONSTANTS */

    public LIST_ASSET_CATEGORY: List; // = ['General Support System', 'Minor Application', 'Major Application', 'National Security System', 'Other'];
    public LIST_ASSET_TYPE: List; // = ['Asset', 'Device', 'Policy', 'System', 'Platform', 'Application','Infrastructure', 'Other'];
    public LIST_LOCATIONS: List; // = ['Cloud', 'On Prem', 'Hybrid'];
    public LIST_OPERATING_MODEL: List; // = ['Not Applicable (N/A)', 'Government-Owned, Government-Operated (GOGO)', 'Government-Owned, Contractor-Operated (GOCO)', 'Contractor-Owned, Contractor-Operated (COCO)', 'Other'];
    public LIST_OPERATION_STATUS: List; // = ['Plan', 'Analysis', 'Design', 'Implement', 'Maintenance', 'Retired', 'Other'];
    public LIST_SECURITY_LEVEL: List; // 'Sensitive but Classified (SBU)', 'Unclassified'
    
    public REGEX = RegularExpressions;

    /* ATTRIBUTES */

    public action; // the user action
    public assetCategory; // the asset category
    public assetType; // the asset type

    public utility = Utility;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private dialog: MatDialog,
        public form: NgForm,
        private location: Location,

        private assetService: AssetService,
        private listService: ListService) {

        this.config = config;
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public async ngOnInit() {
        await this.listService.init(this.organization.orgPkId);
        this.LIST_ASSET_CATEGORY = this.listService.getList('assetCategory');
        this.LIST_ASSET_TYPE = this.listService.getList('assetType');
        this.LIST_LOCATIONS = this.listService.getList('locations');
        this.LIST_OPERATING_MODEL = this.listService.getList('operatingModel');
        this.LIST_OPERATION_STATUS = this.listService.getList('operatingStatus');
        this.LIST_SECURITY_LEVEL = this.listService.getList('securityLevel');
        
        if (!this.asset.metadata) {
            this.asset.metadata = {};
        }
        this.form.control.markAllAsTouched();
    }

    /**
     * On Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    // ASSET

    /**
     * Add an empty location record to the array so that the user can 
     * fill it in.
     */
    public addLocation(location) {
        if (this.asset.metadata) {
            if (!this.asset.metadata.systemLocations) {
                this.asset.metadata.systemLocations = [];
            }
            this.asset.metadata.systemLocations.push(location);
        }
        this.closeDialogs();
    }
    
    /**
     * Add an empty location record to the array so that the user can 
     * fill it in.
     */
    public addSubnet(subnet) {
        if (this.asset.metadata) {
            if (!this.asset.metadata.systemSubnets) {
                this.asset.metadata.systemSubnets = [];
            }
            this.asset.metadata.systemSubnets.push(subnet);
        }
        this.closeDialogs();
    }

    /**
     * Close.
     */
    public close() {
        this.location.back();
    }

    /**
     * Checks if asset name, alias, and custom id is valid (ie. no duplicates)
     */
    public checkValidity() {
        this.form.form.markAsPristine();

        if (this.assets?.length) {
            for (let asset_ of this.assets) {
                if (asset_?.name?.toLowerCase() === this.asset?.name?.toLowerCase() && asset_?.pkId !== this.asset.pkId) {
                    this.form.controls['name'].setErrors({notUnique: true})
                }
                if (asset_?.alias?.toLowerCase() === this.asset?.alias?.toLowerCase() && asset_?.pkId !== this.asset.pkId) {
                    this.form.controls['alias'].setErrors({notUnique: true})
                }
                if (asset_?.customPkId?.toLowerCase() === this.asset?.customPkId?.toLowerCase() && asset_?.pkId !== this.asset.pkId && this.asset?.customPkId?.toLowerCase() !== "") {
                    this.form.controls['customPkId'].setErrors({notUnique: true})
                }
                
            }
        }
    }

    /**
     * Delete location from the array of On Prem/Hybrid system locations.
     */
    public deleteLocation(index) {
        this.asset.metadata.systemLocations.splice(index, 1);
        this.save();
    }

    /**
     * Delete subnet from the array of system subnets.
     */
    public deleteSubnet(index) {
        this.asset.metadata.systemSubnets.splice(index, 1);
        this.save();
    }

    /**
     * Save.
     */
    public save() {

        if (!this.isReadonly) {
            this.asset.metadata.isRetired = this.asset.status === 'Retired' ? true : false;
            let data = {
                organizationPkId : this.asset.organizationPkId,
                name : this.asset.name,
                alias : this.asset.alias,
                customPkId: this.asset.customPkId,
                ownerPkId: this.asset.ownerPkId,
                type : this.asset.type,
                category : this.asset.category,
                description: this.asset.description,
                version : this.asset.version,
                location : this.asset.location,
                metadata: this.asset.metadata,
                objectType: this.asset.objectType,
                operatingModel: this.asset.metadata.operatingModel,
                status: this.asset.status,
                securityLevel: this.asset.metadata.securityLevel,
                hardwareBaseline : this.asset.metadata.hardwareBaseline,
                softwareBaseline : this.asset.metadata.softwareBaseline,
            };
    
            this.assetService.updateAsset(this.asset.pkId, data).subscribe(result => {
                this.cache.save();
            });
        }
    }

    public getPercentComplete() {
        if (this.form) {
            let invalidFields = 0;
            let requiredFields = 0;
            for (let controlKey of Object.keys(this.form.controls)) {
                const control = this.form.controls[controlKey];
                if (control.validator?.({} as AbstractControl)?.required) {
                    requiredFields ++;
                    if (!control.value) {
                        invalidFields ++;
                    }
                }
            }
            let percent = ((requiredFields - invalidFields) / requiredFields)*100;
            return percent;
        }
    }

    /**
     * Update values.
     */
    public update(field, value) {
        switch (field) {
            case 'description':
                this.asset.description = value;
        }

        this.save();
    }

    // UTILITIES

    /**
     * Close.
     */
    public closeDialogs() {
        this.dialog.closeAll();
    }

    /**
     * Opens a dialog with a given template.
     */
    public openDialog(component, user?) {

        // Set the dialog properties and open the dialog using those properties.
        // All dialogs are inteded to fill the entire screen. The dialog styles can
        // be found in the global styles.

        let properties = { width: '100vw', height: '100vh', backdropClass: 'backdrop', panelClass: '', disableClose: true, data: user  };
        const dialogRef = this.dialog.open(component, properties);

        // After the dialog is opened and closed, process the returned data
        // and perform cleanup operations.

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
