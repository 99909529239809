
export class Tree {

    /* ATTRIBUTES */

    public nodes: Node[] = []


    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */

    /**
     * Create Tree.
     */
    public createTree(items: any[]) {
        const rootItems = items.filter(item_ => !item_.parentPkId).sort((a, b) => (a.order||999) - (b.order||999));
        for (let rootItem of rootItems) {
            const rootNode = new Node();
            rootNode.item = rootItem;
            this.nodes.push(rootNode);
            const childItems = items.filter(item_ => item_.parentPkId === rootItem.pkId).sort((a, b) => (a.order||999) - (b.order||999));
            for (let childItem of childItems) {
                this.addChild(items, rootNode, childItem);
            }
        }
        return this;
    }

    /**
     * Find Node in tree.
     */
    public findNode(pkId: string, nodes?: Node[]): Node {
        if (!nodes) {
            nodes = this.nodes;
        } 

        for (let node of nodes) {
            if (node.item?.pkId === pkId) {
                return node;
            }
            else {
                if (node.children?.length) {
                    const result = this.findNode(pkId, node.children);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    }
    

    /**
     * Get Tree from Node.
     */
    public getTreeFromNode(pkId: string, nodes?: Node[]) {
        if (!nodes) {
            nodes = this.nodes;
        } 

        for (let node of nodes) {
            if (node.item?.pkId === pkId) {
                const tree_ = new Tree();
                tree_.nodes = [node];
                return tree_;
            }
            else {
                if (node.children?.length) {
                    const result = this.getTreeFromNode(pkId, node.children);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    }


    /* PRIVATE METHODS */

    /**
     * Add child to the tree.
     */
    private addChild(items: any[], parentNode: Node, childItem:any) {
        const childNode = new Node();
        childNode.item = childItem;
        parentNode.children.push(childNode);
        const grandChildItems = items.filter(item_ => item_.parentPkId === childItem.pkId).sort((a, b) => (a.order||999) - (b.order||999));
        for (let grandChildItem of grandChildItems) {
            this.addChild(items, childNode, grandChildItem);
        }
    }
}

class Node {

    /* ATTRIBUTES */

    public item: any; // at a minimum, item should be an object containing pkId and parentPkId fields.
    public children: Node[] = [];
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json);
    }

}