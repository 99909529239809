import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Cache } from '@src/shared/objects/cache';

import { AssetService } from '@src/shared/services/asset.service';

@Component({
  selector: 'app-asset-selector',
  templateUrl: './asset-selector.component.html',
  styleUrls: ['./asset-selector.component.less']
})
export class AssetSelectorComponent implements OnInit {
    
    @Input() route;

    // ATTRIBUTES

    public cache; // local session cache

    public asset; // the selected asset
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization
    public phase; // the selected phase
    public process; // the process assigned to the asset
    public step; // the selected step

    public assets = []; 

    // CONSTRUCTOR

    /**
     * Constructor.
     */
    public constructor(
        private location: Location,
        private router: Router,
        
        private assetService: AssetService,) {

    }

    /**
     * On Init.
     */
    public ngOnInit() {

        // Get all of the cached data and render the selected questionnaire.
        // Since the list of questions is large, we need to check if they are
        // null before continuing.

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        // this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.step = this.cache.getValue(Cache.KEYS.STEP);
        this.current = this.cache.getValue(Cache.KEYS.USER);

        if (this.current) {
            // console.log("/shared/components/asset-selector/ngOnInit: ORGANIZATION = ", this.organization);
            this.assetService.listAssets(this.organization.orgPkId).subscribe(res => {
                this.assets = res.filter(asset_ => asset_.status !== 'Retired' && asset_.processes?.includes(this.process.pkId));
                // console.log("/shared/components/asset-selector/ngOnInit: ASSETS = ", this.assets);
            });
        }

    }

    // METHODS

    /**
     * Select asset.
     */
    public select(asset) {
        console.log("/shared/components/asset-selector/select: ASSET = ", asset);
        if (asset) {          
            let cache = Cache.get();
            cache.setValue(Cache.KEYS.ASSET_PKID, asset.pkId);
            cache.setValue(Cache.KEYS.ASSET, asset);
            window.location.reload();
        }
    }

}
