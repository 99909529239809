import { Pipe, PipeTransform } from '@angular/core';
/**
 * Takes in array of objects, a keyword, and an array of field names.
 * Returns array of objects filtered by a keyword
 */
@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(array: any[], keyword: string, fields:string[]): any {
    if (keyword) {
        const family = keyword.slice(0, 2).toUpperCase(); // ex. AC-2(13) => AC
        const remaining = keyword.slice(3); // ex. AC-2(13) => 2(13)
        const split = remaining.replace(')', '').split('('); // ex. 2(13) => ['2', '13']
        const control = split[0]?.length === 2 ? split[0] : split[0]?.length === 1 ? '0' + split[0] : '00'; // ex. ['2','13)'] => 02
        const enhancement = split[1]?.length === 2 ? split[1] : split[1]?.length === 1 ? '0' + split[1] : '00'; // ex. ['2','13)'] => 13
        const id = (family + control + enhancement).substring(0, keyword.length);
    
        return (array || []).filter(item => {
            for (let field of fields) {
                if (item[field]?.toLowerCase().includes(keyword.toLowerCase()) || item[field]?.toLowerCase().includes(id.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }
    else {
        return array;
    }

  }

}
