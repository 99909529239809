import { Input } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { Cache } from '@src/shared/objects/cache';
import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.less'],
    viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}]
})
export class EditorComponent implements OnInit {

    @Input() content: any;
    @Input() height: number;
    @Input() maxlength: any;
    @Input() name = 'editor';
    @Input() placeholder: '';
    @Input() readonly = false;
    @Input() editorLabel = "";

    @Output() updatedContent = new EventEmitter<any>();

    /* CONSTANTS */

    public TINYMCE_CONFIG;  

    /* ATTRIBUTES */

    public asset; // the selected asset
    public cache; // local session cache
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization
    public phase; // the selected phase
    public process; // the process assigned to the asset
    public step; // the selected step

    public editing = false;
    public styles: any;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() {
    }

    /* EVENT HANDLING */

    /**
     * On Init
     */
    public async ngOnInit() {
        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.step = this.cache.getValue(Cache.KEYS.STEP);

        this.TINYMCE_CONFIG = {
            height: this.height,
            menubar: false,
            toolbar: [
              'version link bold italic underline strikethrough blockquote styles image alignment lineheight lists table backcolor'
            ],
            toolbar_groups: {
                version: {
                  icon: 'action-next',
                  tooltip: 'version control',
                  items: 'undo redo copy cut paste pastetext remove selectall'
                },
                alignment: {
                  icon: 'accordion',
                  tooltip: 'alignment',
                  items: 'alignleft aligncenter alignright alignjustify indent outdent'
                },
                lists: {
                  icon: 'checklist',
                  tooltip: 'lists',
                  items: 'bullist numlist'
                },
            },
            
            promotion: false,
            apiKey: "no-api-key",
            base_url: '/tinymce',
            suffix: '.min',
            branding: false,
            plugins: 'searchreplace autolink autosave directionality table code help wordcount link lists advlist image',
        };
    }  

    /**
     * Handle content change.
     */
    public contentChanged(ev) {
        this.content = ev;
        this.updatedContent.emit(this.content);
    }

    /* METHODS */

    // EDITOR

    /**
     * Initialize the editor.
     */
    public initializeEditor() {
        let editorToolbarArray = document.querySelectorAll('.tox-editor-header'); 
        let statusBarArray = document.querySelectorAll('.tox-statusbar');
        let editorToolbars: NodeListOf<HTMLElement> = document.querySelectorAll('.tox-editor-header');
        let statusBars:  NodeListOf<HTMLElement> = document.querySelectorAll('.tox-statusbar');

        editorToolbars.forEach((editorToolbar) => {
            if (editorToolbar) {
                editorToolbar.style.display = 'none';
            }
        });
        
        editorToolbarArray.forEach((editorToolbar) => {
            if (editorToolbar instanceof HTMLElement) {
                editorToolbar.style.display = 'none';
            }
        });

        statusBars.forEach((statusBar) => {
            if (statusBar) {
              statusBar.style.display = "none";
            }
        });

        statusBarArray.forEach((statusBar) => {
          if (statusBar instanceof HTMLElement) {
            statusBar.style.display = "none";
          }
        });
    }

    // TOOLBAR
    
    /**
     * Hide toolbar.
     */
    public hideToolbar(){
        let editorToolbarArray = document.querySelectorAll('.tox-editor-header')
        let statusBarArray = document.querySelectorAll('.tox-statusbar');

        editorToolbarArray.forEach((editorToolbar) => {
            if (editorToolbar instanceof HTMLElement && !this.readonly) {
                editorToolbar.style.display = 'none'
            }
        })

        statusBarArray.forEach((statusBar) => {
            if (statusBar instanceof HTMLElement && !this.readonly) {
                statusBar.style.display = 'none'
            }
        });
    }

    /**
     * Show toolbar.
     */
    public showToolbar() {
        let editorToolbarArray = document.querySelectorAll('.tox-editor-header')
        let statusBarArray = document.querySelectorAll('.tox-statusbar');

        editorToolbarArray.forEach((editorToolbar) => {
            if (editorToolbar instanceof HTMLElement && (editorToolbar?.style.display === 'none')) {
                this.TINYMCE_CONFIG = {
                    menubar: false,
                    toolbar: [
                      'version link bold italic underline strikethrough blockquote styles image alignment lineheight lists table'
                    ],
                    toolbar_groups: {
                        version: {
                            icon: 'action-next',
                            tooltip: 'version control',
                            items: 'undo redo copy cut paste pastetext remove selectall'
                        },
                        alignment: {
                            icon: 'accordion',
                            tooltip: 'alignment',
                            items: 'alignleft aligncenter alignright alignjustify indent outdent'
                        },
                        lists: {
                            icon: 'checklist',
                            tooltip: 'lists',
                            items: 'bullist numlist'
                        },
                    },
                    promotion: false,
                    apiKey: "no-api-key",
                    base_url: '/tinymce',
                    suffix: '.min',
                    branding: false,
                    plugins: 'searchreplace autolink autosave directionality table code help wordcount link lists advlist image',
                };

                if (editorToolbar && !this.readonly) {
                    editorToolbar.style.display = '';
                }
            }
        })

        statusBarArray.forEach((statusBar) => {
            if (statusBar instanceof HTMLElement && !this.readonly) {
                statusBar.style.display = 'inline'
            }
        });
    }
}