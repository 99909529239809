<div *ngIf="this.topics">
    <!--FILTERS -->
    <div class="filters mb-5">
        <div class="container-fluid nopadding">
            <div class="row no-gutters">
                <div class="clickable d-flex align-items-center pb-2" >
                    <mat-icon>filter_list</mat-icon><b> Filter</b>
                </div>
            </div>
            <div class="row no-gutters gap-3">
                <div class="col no-gutters">
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput name="search" [(ngModel)]="keyword" (ngModelChange)="subject.next($event)" placeholder="Search By Title" aria-label="search">
                        <button class="clear-text" matSuffix mat-icon-button aria-label="Clear" *ngIf="keyword" (click)="clearKeyword(); search();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div> 
                <div class="col-auto no-gutters">
                    <button  
                        class=""
                        mat-flat-button 
                        color="primary"
                        [componentIf]="{val: config.routes.PRIVATE_TOPIC_EDIT}"
                        (click)="this.gotoLink('Create')">
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- LIST -->
    <div>                
        <div class="list" *ngFor="let topic of this.topics_" (click)="this.gotoLink('View', topic)">
            <div class="row no-gutters gap-3">
                <div class="col no-gutters d-flex align-items-center">                
                    {{ topic.title }}
                </div>
                <div class="col-auto no-gutters">
                    <button mat-icon-button [matMenuTriggerFor]="actions" (click)="$event.stopPropagation()">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #actions="matMenu">
                        <button color="primary"
                            mat-menu-item
                            (click)="this.gotoLink('View', topic)">
                            <mat-icon>visibility</mat-icon>
                            VIEW
                        </button>
                        <button color="primary"
                            mat-menu-item
                            [componentIf]="{val: this.editLink}"
                            (click)="this.gotoLink('Edit', topic)">
                            <mat-icon>edit</mat-icon>
                            EDIT
                        </button>
                        <button color="primary"
                            aria-label="Remove topic"
                            mat-menu-item
                            [componentIf]="{val: this.removeLink}"
                            (click)="this.gotoLink('Remove', topic)">
                            <mat-icon>delete</mat-icon>
                            DELETE
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <!-- CARDS -->
    <!-- 
        <div class="cards">
            <div class="card" *ngFor="let topic of topics_" [routerLink]="config.routes.PRIVATE_TOPIC_VIEW + '/' + topic.pkId">
                <div class="video-player" [ngClass]="primarySources[topic.pkId] ? '' : 'video-player-placeholder'">
                    <video *ngIf="primarySources[topic.pkId]" controls width="100%" height="100%">
                        <source [src]="primarySources[topic.pkId]" type="video/webm" />
                    </video>
                </div>
                <div class="section p-3">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="title truncate-text">
                                {{topic.title}}
                            </div>
                            <div class="subtitle">
                                {{topic.subtitle}}
                            </div>        
                        </div>
                        <div class="col-lg-1">
                            <button mat-icon-button [matMenuTriggerFor]="actions" (click)="$event.stopPropagation()"><mat-icon>more_vert</mat-icon></button>
                            <mat-menu #actions="matMenu">
                                <button color="primary"
                                    mat-menu-item
                                    [routerLink]="config.routes.PRIVATE_TOPIC_VIEW + '/' + topic.pkId">
                                    <mat-icon>visibility</mat-icon>
                                    VIEW
                                </button>
                                <button color="primary"
                                    mat-menu-item
                                    [componentIf]="{val: config.routes.PRIVATE_TOPIC_EDIT}"
                                    [routerLink]="config.routes.PRIVATE_TOPIC_EDIT + '/' + topic.pkId">
                                    <mat-icon>edit</mat-icon>
                                    EDIT
                                </button>
                                <button color="primary"
                                    aria-label="Remove topic"
                                    mat-menu-item
                                    [componentIf]="{val: config.routes.PRIVATE_TOPIC_REMOVE}"
                                    [routerLink]="config.routes.PRIVATE_TOPIC_REMOVE + '/' + topic.pkId">
                                    <mat-icon>delete</mat-icon>
                                    DELETE
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        -->
</div>

<!-- COMPONENTS -->

<ng-template #treeNode let-data>
    <mat-list-item class="pb-2">
        <a [routerLink]="config.routes.PRIVATE_TOPIC_VIEW + '/' + data.topic?.pkId">{{data.topic?.title}}</a>
    </mat-list-item>
    <ng-container *ngIf="data.children?.length > 0">
        <mat-list class="ml-3 p-0">
            <ng-container
                *ngFor="let child of data.children"
                [ngTemplateOutlet]="treeNode"
                [ngTemplateOutletContext]="{ $implicit: child }">
            </ng-container>
        </mat-list>
    </ng-container>
</ng-template>
