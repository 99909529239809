<button 
    mat-flat-button color="accent"
    (click)="approve()" 
    [matBadge]="statusBadge" 
    [matBadgeHidden]="!statusBadge" 
    matBadgePosition="after" 
    matBadgeColor="warn"
    [disabled]="!isApprover"
    [hidden]="!isApprover">
    APPROVE
</button>