import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Cache } from '@src/shared/objects/cache';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.less']
})
export class TreeComponent implements OnInit {
    
    @Input() content: any;

    // CONSTANTS


    // ATTRIBUTES

    public cache; // local session cache

    public asset; // the selected asset
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization
    public phase; // the selected phase
    public process; // the process assigned to the asset
    public step; // the selected step

    // CONSTRUCTOR

    /**
     * Constructor.
     */
    public constructor(
        private location: Location,
        private router: Router) {

    }

    /**
     * On Init.
     */
    public ngOnInit() {

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        // this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.step = this.cache.getValue(Cache.KEYS.STEP);
        this.current = this.cache.getValue(Cache.KEYS.USER);

        if (this.current) {
            console.log("/shared/components/tree/ngOnInit: CURRENT = ", this.current);
        }

    }

    // METHODS



    
}
