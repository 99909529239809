import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnChanges, OnDestroy, OnInit} from "@angular/core";
import { Subject, debounceTime } from "rxjs";

import config from "@app/app";

import { Cache } from "@src/shared/objects/cache";
import { Topic } from "@src/shared/objects/topic";
import { Tree } from "@src/shared/objects/tree";

import { TopicService } from "@src/shared/services/topic.service";
import { FileService } from "@src/shared/services/file.service";

@Component({
  selector: "app-topic-table",
  templateUrl: "./topic-table.component.html",
  styleUrls: ['./topic-table.component.less']
})
export class TopicTableComponent implements OnChanges, OnInit, OnDestroy {

    @Input() type: 'Entertainment' | 'Strategy' | 'Training'; // the type of content
    @Input() topics: Topic[]; // the list of topics to display

    @Input() browseLink; // the browseLink
    @Input() editLink; // the edit link
    @Input() removeLink; // the remove link
    @Input() viewLink; // the view link    

    /* ATTRIBUTES */

    public config; // app.ts
    private cache; // the session cache

    public asset; // the selected asset
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization
    public phase; // the selected phase
    public processes; // the processes configured for the organization
    public process; // the process assigned to the asset

    public keyword = ''; // keyword used in the search
    public subject = new Subject<string>();

    public topics_: Topic[] = [];
    public tree: Tree;

    public primarySources = {}; // map of topic.pkId to primary video source url
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private route: ActivatedRoute,
        private router: Router,

        private fileService: FileService,
        private topicService: TopicService) {

        this.config = config;
        this.config.isHeaderVisible = true;
        this.config.isNavigationVisible = true;

        this.subject.pipe(debounceTime(1000)).subscribe(result => {
            this.search();
        });

    }

    /* EVENT HANDLING */

    /**
     * On Changes.
     */
    public ngOnChanges() {
        this.refresh();
    }

    /**
     * Init.
     */
    public ngOnInit() {

        // We will initialize the asset here so that all asset components can
        // use the cache to get access to the data. The most performance intensive
        // data include the configuration for the organization.

        this.cache = Cache.get();
        
        this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.current = this.cache.getValue(Cache.KEYS.USER);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.processes = this.configuration.processes;
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);

        this.refresh();
    }

    /**
     * Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Clear keyword search bar.
     */
    public clearKeyword() {
        this.keyword = '';
    }

    /**
     * Get Video sources.
     * TODO: Re-implement to be more efficient. This will be very slow when there are many topics. writing this way as proof of concept...
     */
    public getSources() {
        for (let topic of this.topics) {
            this.fileService.getByReference(topic.pkId, 'topic').subscribe(files => {
                for (let file of files) {
                    if (file.metadata?.['type'] === 'Primary Video') {
                        this.fileService.getPresignedUrl(file.pkId).subscribe(data => {
                            this.primarySources[topic.pkId] = data?.url;
                        })
                    }
                }
            });
        }
    }


    /**
     * Redirect the user to the specified link.
     */
    public gotoLink(
        type:'Browse' | 'Create' | 'Edit' | 'Remove' | 'View', 
        topic?: Topic) {
                  
        switch (type) {
            case 'Browse':                    
                this.router.navigate([this.browseLink]);
                break;

            case 'Create':
                let params = { parentPkId: ''};
                this.router.navigate([this.editLink + '/' + '0'], { queryParams: params });
                break;

            case 'Edit':
                if (topic) {
                    this.router.navigate([this.editLink + '/' + topic.pkId]);
                    break;
                }

            case 'Remove':
                if (topic) {                        
                    this.router.navigate([this.removeLink + '/' + topic.pkId]);
                    break;
                }

            case 'View':    
                if (topic) {
                    this.router.navigate([this.viewLink + '/' + topic.pkId]);
                    break;
                }
        }
    }

    /**
     * Refresh the user interface with the latest data.
     */
    public refresh() {
        this.topics_ = this.topics; 
        this.topics_ = this.topics;        
        this.tree = new Tree();
        this.tree.createTree(this.topics_);
        // this.getSources();
    }

    /**
     * Search.
     */
    public search() {
        this.topics_ = this.topics.filter(topic_ => topic_.title?.toLowerCase().includes(this.keyword?.toLowerCase()));
    }

}
