import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regex'
})
export class RegexTransformPipe implements PipeTransform {
  transform(text:string, regex, flags) {
    regex = new RegExp(regex, flags)
    const result = text.match(regex)?.toString();
    return result ? result : text;
  }
}
