<mat-accordion>
    <mat-expansion-panel class="mat-panel-accent" #processStatus [expanded]="true" hideToggle="true">
        <mat-expansion-panel-header >
            <mat-panel-title>
                <h5>PROCESS</h5>
            </mat-panel-title>
            <mat-icon class="accent-color">{{processStatus.expanded?'expand_less':'expand_more'}}</mat-icon>
        </mat-expansion-panel-header>
        <div class="row mb-2" *ngIf="process">
            <b><span style="text-transform:uppercase">{{process.name}}</span></b>
        </div>
        <div class="row mb-2" *ngIf="currentControlPackage">
            <b>{{currentControlPackage.name}}</b>
        </div>
        <div class="row mb-2" *ngIf="currentClassification">
            Classification: <span class="" style="text-transform:uppercase"><b>{{currentClassification}}</b></span>
        </div>
        <div class="row">
            <div class="phase-progress" *ngIf="phase">
                <div class="text-center">
                    PHASE {{ (phaseIndex || 0) + 1 | romanNumeral}} : {{ phase.status ? phase.status : 'In Progress'}}
                </div>
                <div style="color:black; font-size:10px">
                    {{phaseProgress}}%
                    <mat-progress-bar mode="determinate" value="{{phaseProgress}}"></mat-progress-bar>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>