<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="material-icons-outlined" [ngStyle]="{'color': color}" *ngIf="type === 'icon'">{{icon ? icon : default}}</mat-icon>
    <span *ngIf="type === 'emoji'" [innerHTML]='icon'></span>
  </button>
  <mat-menu class="menu" #menu="matMenu">
    <div class="container">
        <mat-form-field class="w-100" appearance="outline" (click)="$event.stopPropagation()">
            <input matInput autocomplete="off" name="search" [(ngModel)]="keyword" placeholder="Filter by Keyword" (keyup)="search()" aria-label="search">
            <button matSuffix mat-icon-button aria-label="Clear" *ngIf="keyword" (click)="keyword = ''; search();">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div *ngFor="let item of library_">
            <button *ngIf="type === 'icon'" (click)="select(item)" mat-icon-button>
                <mat-icon class="material-icons-outlined" [ngStyle]="{'color': color}">{{item}}</mat-icon>
            </button>
            <button *ngIf="type === 'emoji'" (click)="select(item)" mat-icon-button>
                <span [innerHTML]='item'></span>
            </button>
        </div>
    </div>
  </mat-menu>