<div *ngIf="isVisible">
    <div *ngIf="this.channel">
        <div *ngIf="this.channel.type !== 'Custom'">
            You have been invited to <b>{{ this.channel.name }}</b>.
        </div>
        <div *ngIf="this.channel.type === 'Custom'">
            You have been invited or assigned to this channel. You have to accept to see the
            chats in this channel.
        </div>
        <div class="text-center pt-4">
            <a href="javascript:void(0);" (click)="updateInvite('Accepted')">Accept</a> / <a href="javascript:void(0);" (click)="updateInvite('Rejected')">Reject</a>
        </div>    
    </div>
</div>
