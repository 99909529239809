import { Directive, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[ngEqualsTo]',
  providers: [{provide: NG_VALIDATORS, useExisting: EqualsToDirective, multi: true}]
})
export class EqualsToDirective implements Validator {
  @Input('ngEqualsTo') equalsTo: any;
  validate(control: UntypedFormControl): ValidationErrors {

    if (!this.equalsTo || !this.equalsTo.value) {
      return null;
    }
    if (!control || !control.value) {
      return null;
    }
    // valifate equalsTo
    if (control.value !== this.equalsTo.value) {
      return { 'equalsTo': { message: `${this.getControlName(control)} must equal to ${this.equalsTo.name}` } };
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error("Method not implemented.");

  }

  constructor() { }

  getControlName(control: AbstractControl): string {
    let controlName: string = null;
    const parent = control['_parent'];

    // only such parent, which is FormGroup, has a dictionary
    // with control-names as a key and a form-control as a value
    if (parent instanceof UntypedFormGroup) {
      // now we will iterate those keys (i.e. names of controls)
      Object.keys(parent.controls).forEach((name) => {
        // and compare the passed control and
        // a child control of a parent - with provided name (we iterate them all)
        if (control === parent.controls[name]) {
          // both are same: control passed to Validator
          //  and this child - are the same references
          controlName = name;
        }
      });
    }
    // we either found a name or simply return null
    return controlName;
  }
}
