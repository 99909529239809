import { v4 as uuidv4 } from 'uuid';

import { Access } from './access';
import { Message } from './message';

export class Help {

    /* CONSTANTS */

    /* ATTRIBUTES */

    // GENERAL

    public pkId: string = ''; // the object id
    public organizationPkId: string = ''; // the organization pkid for which this help request belongs to
    public channelPkId: string = ''; // used to store the child proxy channel id
    public parentChannelPkId: string = ''; // used to store the primary parent channel id

    public assigneePkId: string = ''; // assignee id
    public assigneeName: string = ''; // assignee name
    public submitterPkId: string = ''; // submitter id
    public submitterName: string = ''; // submitter name
    public userPkId: string = ''; // the user for which this request is for
    public userName: string = ''; // the user name

    public name: string = ''; // title of the request
    public description: string = ''; // description of the request
    public type: 'Access' | 'Application' | 'Bug' | 'Change' | 'Equipment' | 'Feature' | 'Help' | '' = 'Help';  // the type of help request
    public ticketNumber: string = ''; // ticket number    
    public urgency: 'High' | 'Medium' | 'Low' = 'Low'; // sense of urgency

    public priority: 'High' | 'Medium' | 'Low' = 'Low'; // priority
    public status: 'Open' | 'Submit' | 'Approve' | 'In Progress' | 'Reject' | 'Close' = 'Open'; // status
    public impact: 'Organization' | 'Division' | 'Office' | 'Team' = 'Team'; // scope of impact
    public origin: 'Chat' | 'Email' | 'Phone' | 'Web' = 'Web'; // origin of the request

    public isLocked: boolean = false; // determines if the request has been submitted and locked
    public isApproved: boolean = false; // whether the request has been approved by everyone

    public access: Access = new Access(); // the requested access to infrastructure components
    public application: any = {}; // the requested application role based access control
    public change: any = {}; // the change request
    public equipment: any = {}; // the requested equipment
    public feature: any = {}; // the requested feature
    public bug: any = {}; // the error, fault, or flaw

    // DATE

    public createDate: Date = new Date(); // create date
    public updateDate: Date = new Date(); // last update date    
    public desiredCompletionDate: Date = new Date(); // desired completion date

    public submitDate: Date; // the date the help request was submitted
    public approveDate: Date; // the date the help request was approved by all required people
    public inProgressDate: Date; // the date the help request was assigned and the assignee started working on it
    public rejectDate: Date; // the date the help request was rejected
    public closeDate: Date; // the date the help request was closed
    
    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(json?) {
        Object.assign(this, json || {});
        if (!this.pkId) { this.pkId = uuidv4(); }
        if (!this.ticketNumber) { this.ticketNumber = Date.now().toString(); }

        if (this.type === 'Access') {
            this.access.organizationPkId = this.organizationPkId;
            this.access.userPkId = this.userPkId;
            this.access.userName = this.userName;
            this.access.ticketNumber = this.ticketNumber; 
        }
    }
    
}