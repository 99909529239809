import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Cache } from "../objects/cache";

import { SecurityService } from "../services/security.service";

@Injectable({ providedIn: "root" })
export class RoleGuard implements CanActivate {

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private securityService: SecurityService) {
    }

    /* METHODS */

    /**
     * Can Activate.
     */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        let asset;
        let checkAsset = route.data['checkAsset'];
        if (checkAsset) { let cache = Cache.get(); asset = cache.getValue(Cache.KEYS.ASSET); }
        let isAllowed = await this.securityService.checkRoute(state.url, asset);
        return isAllowed;
    }

}
