<!-- LARGE DEVICES -->
<div class="header-full" *ngIf="current && organization">
    <div class="environment blink">
        {{ environment }}
    </div>
    <div class="organization">
        {{ organization.orgName }}
    </div>
    <div class="welcome">
        Welcome, {{ current.firstName }}
    </div>
    <div class="icons">
        <button mat-icon-button class="nav-button" (click)="gotoHome();">
            <mat-icon class="material-icons-outlined" aria-label="My Applications" aria-hidden="false" matTooltip="My Applications">
                apps
            </mat-icon>
        </button>
        <button mat-icon-button class="nav-button" (click)="gotoHelp();">
            <mat-icon class="material-icons-outlined" aria-label="Help" aria-hidden="false" matTooltip="Help">
                help_outline
            </mat-icon>
        </button>
        <button mat-icon-button class="nav-button" (click)="gotoSettings();">
            <mat-icon class="material-icons-outlined" aria-label="Settings" aria-hidden="false" matTooltip="Settings">
                settings
            </mat-icon>
        </button>
        <button mat-icon-button class="nav-button" (click)="this.isMenuOpenForNotification = !this.isMenuOpenForNotification">
            <div class="red-dot" *ngIf="messageService.getNotificationsNotYetRead().length">
                {{ messageService.getNotificationsNotYetRead().length }}
            </div>
            <mat-icon class="material-icons-outlined" aria-label="Notifications" aria-hidden="false" matTooltip="Notifications">
                notifications
            </mat-icon> 
        </button>
        <button mat-icon-button class="nav-button" (click)="this.isMenuOpenForAccount = !this.isMenuOpenForAccount; this.isMenuOpenForNotification = false;">
            <mat-icon class="material-icons-outlined" aria-label="User" aria-hidden="false" matTooltip="Account">
                account_circle
            </mat-icon> 
        </button>
        <button mat-icon-button class="nav-button" routerLink="/logout" routerLinkActive="active">
            <mat-icon class="material-icons-outlined" aria-label="Logout" aria-hidden="false" matTooltip="Logout">
                power_settings_new 
            </mat-icon>
        </button>
    </div>
</div>
<!-- SMALL DEVICES -->
<div class="header-small" *ngIf="current && organization">
    <div class="icon">          
        <button mat-icon-button (click)="open();" *ngIf="!isMenuOpenForMobile" aria-label="Main Menu">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="main-menu" *ngIf="isMenuOpenForMobile">
        <div class="icon">                
            <button mat-icon-button (click)="close();">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <!-- GENERAL -->
        <div class="main-menu-overview">                
            <div class="organization">{{ organization.orgName }}</div>
            <div class="welcome">Welcome, {{ current.firstName }}</div>
            <div class="environment blink">{{ environment }}</div>
        </div>
        <mat-list class="pt-0 pr-3 pb-3 pl-3">
            <mat-list-item>                
                <div class="divider"></div>
            </mat-list-item>
            <mat-list-item class="pb-3" (click)="gotoHome();">
                <div  class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined mr-3" aria-label="My Applications" aria-hidden="false" matTooltip="My Applications">
                        apps
                    </mat-icon>
                    <span>HOME</span>
                </div>
            </mat-list-item>
            <mat-list-item class="pb-3" (click)="gotoHelp();">
                <div  class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined mr-3" aria-label="Help" aria-hidden="false" matTooltip="Help">
                        help
                    </mat-icon>
                    <span>HELP</span>
                </div>
            </mat-list-item>
            <mat-list-item class="pb-3" (click)="gotoSettings();" *ngIf="current.isSysAdmin">
                <div  class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined mr-3" aria-label="Settings" aria-hidden="false" matTooltip="Settings">
                        settings
                    </mat-icon>
                    <span>GLOBAL SETTINGS</span>
                </div>
            </mat-list-item>
            <mat-list-item (click)="gotoProfile()" routerLinkActive="active">
                <div  class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined mr-3" aria-label="Profile" aria-hidden="false" matTooltip="Profile">
                        account_circle 
                    </mat-icon>
                    <span>PROFILE</span>
                </div>
            </mat-list-item>
            <!-- DIVIDER -->
            <mat-list-item>                
                <div class="divider"></div>
            </mat-list-item>
            <!-- MODULES FROM SIDE NAV -->
            <ng-container *ngFor="let item of config.navigation">
                <!-- LINK -->
                <div *ngIf="(!item.type || item.type === 'Link') && !item.children">
                    <mat-list-item class="pb-3" [componentIf]="{val: item.permissioned ? item.link : '*'}" (click)="navigate(item.link)">
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-3">circle</mat-icon>
                            <span class="uppercase" *ngIf="item.title.toLowerCase() !== 'settings'">{{ item.title }}</span>
                            <span class="uppercase" *ngIf="item.title.toLowerCase() === 'settings'">APPLICATION SETTINGS</span>
                        </div>
                    </mat-list-item>
                </div>
                <!-- PARENT -->
                <div *ngIf="item?.children?.length > 0">
                    <mat-list-item class="pb-3" [componentIf]="{val: item.permissioned ? item.link : '*'}" (click)="selectParent(item)">
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-3">circle</mat-icon>
                            <span class="uppercase">{{ item.title }}</span>

                        </div>
                    </mat-list-item>
                </div>
            </ng-container>
            <!-- DIVIDER -->
            <mat-list-item>                
                <div class="divider"></div>
            </mat-list-item>
            <!-- NOTIFICATIONS -->
            <app-notifications></app-notifications>
            <!-- DIVIDER -->
            <mat-list-item>                 
                <div class="divider"></div>
            </mat-list-item>
            <!-- MODE -->
            <mat-list-item class="pb-3" *ngIf="config.isDarkModeEnabled">                 
                <mat-slide-toggle 
                    class="uppercase"
                    [checked]="preference?.theme === 'dark'" 
                    (change)="toggleTheme($event)"
                    labelPosition = 'before'>
                    {{preference?.theme || 'Light'}} Mode
                </mat-slide-toggle>
            </mat-list-item>
            <!-- DIVIDER -->
            <mat-list-item>                 
                <div class="divider"></div>
            </mat-list-item>
            <!-- LOGOUT -->
            <mat-list-item class="pb-3" (click)="close();" routerLink="/logout" routerLinkActive="active">
                <div  class="d-flex align-items-center">
                    <mat-icon class="material-icons-outlined mr-3" aria-label="Profile" aria-hidden="false" matTooltip="My Applications">
                        power_settings_new 
                    </mat-icon>
                    <span>LOGOUT</span>
                </div>
            </mat-list-item>  
        </mat-list>
    </div>

    <div class="main-menu" *ngIf="isMenuOpenForMobileSecondary">
        <div class="icon">                
            <button mat-icon-button (click)="closeSecondary();">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <mat-list class="pt-0 pr-3 pb-3 pl-3" *ngIf="parent?.children?.length">
            <!-- MODULES FROM SIDE NAV -->
            <ng-container *ngFor="let item of parent.children">
                <!-- LINK -->
                <div *ngIf="!item.type">
                    <mat-list-item class="pb-3" [componentIf]="{val: item.permissioned ? item.link : '*'}" (click)="navigate(item.link)">
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-3">circle</mat-icon>
                            <span class="uppercase" *ngIf="item.title.toLowerCase() !== 'settings'">{{ item.title }}</span>
                        </div>
                    </mat-list-item>
                </div>
                <div *ngIf="item.type === 'Training'">
                    <mat-list *ngIf="topicTree">
                        <mat-list-item class="pb-3">
                            <a class="bold" (click)="navigate(config.routes.PRIVATE_TOPIC_BROWSE)">LEARNING CENTER</a>
                        </mat-list-item>
                        <ng-container
                            *ngFor="let node of topicTree.nodes"
                            [ngTemplateOutlet]="treeNode"
                            [ngTemplateOutletContext]="{ $implicit: node }">
                        </ng-container>
                    </mat-list>    
                </div>
            </ng-container>

        </mat-list>
    </div>
</div>

<!-- COMPONENTS -->

<!-- NOTIFICATIONS -->
<div class="menu" *ngIf="isMenuOpenForNotification">
    <app-notifications></app-notifications>
</div>

<!-- ACCOUNT -->
<div class="menu" *ngIf="isMenuOpenForAccount">
    <div class="account">
        <div class="account-title">
            ACCOUNT
        </div>
        <div class="account-item">
            <a class="d-flex align-items-center " (click)="gotoProfile()">
                <mat-icon>person</mat-icon>
                <span>Edit Profile</span>
            </a>
        </div>
        <!--
        <div 
            class="account-item"
            *ngIf="this.current.isSysAdmin || this.current.isOrgAdmin(this.organization) || this.current.isAppAdmin(this.organization)">         
            <a routerLink="/private/organization/settings/{{ this.organization.pkId }}" routerLinkActive="active">
                <mat-icon>corporate_fare</mat-icon>
                <span>Edit Organization</span>
            </a>
        </div> 
        -->
        <div class="account-item">
        </div>
        <div class="account-item">
        </div>
    </div>
</div>

<!-- TRAINING TREE -->
<ng-template #treeNode let-data>
    <mat-list-item class="pb-2">
        <a (click)="navigate(config.routes.PRIVATE_TOPIC_VIEW + '/' + data.item?.pkId)">{{data.item?.title}}</a>
    </mat-list-item>
    <!-- NOTE: Only showing parent level now - 12/21/2023 -->
    <!-- <ng-container *ngIf="data.children?.length > 0">
        <mat-list class="ml-3 p-0">
            <ng-container
                *ngFor="let child of data.children"
                [ngTemplateOutlet]="treeNode"
                [ngTemplateOutletContext]="{ $implicit: child }">
            </ng-container>
        </mat-list>
    </ng-container> -->
</ng-template>
