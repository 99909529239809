<ng-container *ngIf="isViewMode; else viewMode">
      <div [ngClass]="viewModeClass">
          <span class="name" [ngClass]="nameClass" [ngStyle]="nameStyle">{{caption || placeholder}}: </span>
          <figure class="frame1" *ngIf="value">
              <!-- <img *ngIf="!isImageSvg() && (value || defaultImage)" [src]="value || defaultImage" />
              <div *ngIf="isImageSvg() && value" class="svg logo-img" [innerHTML]="sanitize(value)"></div> -->
              <app-image-viewer [image]="value" [background]="background">
              </app-image-viewer>
          </figure>
      </div>
  </ng-container>
  <ng-template #viewMode>
      <div class="name" [ngClass]="nameClass" [ngStyle]="nameStyle">{{caption || placeholder}}:</div>
      <div *ngIf="hint">{{hint}}</div>
      <div class="w-100">            
        <figure class="frame">
            <div>
                <button class="upload-btn" *ngIf="!readonly && !value" mat-button color="primary" (click)="fileUploader.click()">
                    <span class="material-icons">file_upload</span>
                    {{ placeholder }}
                </button>
                <button class="reset-btn" *ngIf="!readonly && value" mat-button color="primary" (click)="resetImage()">
                    <span class="material-icons">close</span>
                </button>
                <input (change)="pickImage($event)" type="file" onclick="this.value = null" #fileUploader name="fileUploader" style="display: none;" aria-label="icon-file-image">
            </div>
            <div>                    
                <app-image-viewer [image]="value" [background]="background">
                </app-image-viewer>
            </div>
        </figure>
      </div>
  </ng-template>