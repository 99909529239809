import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-infinite-paginator',
  templateUrl: './infinite-paginator.component.html',
  styleUrls: ['./infinite-paginator.component.less'],
})
export class InfinitePaginatorComponent {

    /* INPUTS */

    // REQUIRED

    @Input() count = 0; // number of total items

    // OPTIONAL

    @Input() buffer = 1; // ratio of data to get, vs currently viewable at a time
    @Input() pageSize = 25; // items to display per page

    /* OUTPUTS */

    @Output() action = new EventEmitter<{action:string, data: any}>();

    /* COMPONENTS */


    /* ATTRIBUTES */

    public limit = this.pageSize * this.buffer; // number of entries to get at a time (page size * buffer)
    public start: number = 0;
    public end: number = this.start + this.limit;

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() {        
    }

    /* EVENT HANDLING */


    /* PUBLIC METHODS */

    /**
     * Send action event to parent.
     */
    public emit(action, data) {
        this.action.emit({action, data});
    }

    /**
     * Navigate table pages.
     */
    public pageNavigate(direction: 'Previous' | 'Next') {
        if (direction === 'Previous') {
            this.limit = this.pageSize * this.buffer;
            if (this.start - (this.pageSize + this.buffer) < 0) {
                this.start = 0;
            } 
            else {
                this.start -= this.pageSize;
            }
            this.end = this.start + (this.pageSize * this.buffer);
            this.emit('string', {start: this.start, limit: this.limit});
        }
        else {
            this.limit = this.pageSize * this.buffer;
            this.start += (this.pageSize * this.buffer);
            this.end = this.start + (this.pageSize * this.buffer);
            this.emit('string', {start: this.start, limit: this.limit});
        }
    }

    public pageSizeChanged() {
        this.limit = this.pageSize * this.buffer;
        this.emit('string', {start: this.start, limit: this.limit});
    }

    /**
     * Round up number.
     */
    public roundUp(value: number): number {
        return Math.ceil(value);
    }

    /* PRIVATE METHODS */    

}
