import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { Topic } from '@src/shared/objects/topic';

import { TopicService } from '@src/shared/services/topic.service';

@Component({
  selector: 'app-topic-selector',
  templateUrl: './topic-selector.component.html',
  styleUrls: ['./topic-selector.component.less']
})
export class TopicSelectorComponent implements OnInit {
    
    @Input() route;

    /* ATTRIBUTES */

    public cache; // local session cache
    private config;

    public asset; // the selected asset
    public configuration; // the organization configuration
    public current; // the current user
    public organization; // the organization
    public phase; // the selected phase
    public process; // the process assigned to the asset
    public step; // the selected step

    public topics: Topic[];
    public topics_: Topic[];

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private location: Location,
        private router: Router,
        
        private topicService: TopicService) {
            this.config = config
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit() {

        // Get all of the cached data and render the selected questionnaire.
        // Since the list of questions is large, we need to check if they are
        // null before continuing.

        this.cache = Cache.get();
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.phase = this.cache.getValue(Cache.KEYS.PHASE);
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.step = this.cache.getValue(Cache.KEYS.STEP);
        this.current = this.cache.getValue(Cache.KEYS.USER);


        if (this.current) {
            this.topicService.list('Training').subscribe(res => {
                this.topics = res;
                this.topics_ = [];
                for (let topic of this.topics) {
                    if (topic.routes?.includes(this.route)) {
                        if (topic.roles?.length > 0) {
                            const role = this.current.applications[0]?.roles?.find(role_ =>  {
                                const hasRole = topic.roles.includes(role_.pkId);
                                const isCurrentAsset = role_.assetPkIds?.includes(this.asset.pkId);
                                const isAssetOwner = this.asset?.ownerPkId === this.current.pkId;
                                return (hasRole && isCurrentAsset) || (isAssetOwner || this.current.isSysAdmin);
                            });
                            if (role) {
                                this.topics_.push(topic);
                            }
                        }
                        else {
                            this.topics_.push(topic);
                        }
                    }
                }
            });
        }

    }

    /* METHODS */

    /**
     * Select topic to navigate to.
     */
    public select(topic: Topic) {
        this.router.navigate([config.routes['PRIVATE_TOPIC_VIEW'] + '/' + topic.pkId]);
    }

}
