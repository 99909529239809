import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import config from '@app/app';

import { Cache } from '@src/shared/objects/cache';
import { UploaderComponent } from '@src/shared/components/uploader/uploader.component';

@Component({
  selector: 'app-import-spreadsheet',
  templateUrl: './import-spreadsheet.component.html',
  styleUrls: ['./import-spreadsheet.component.less']
})
export class ImportSpreadsheetComponent implements OnInit {

    @ViewChild(UploaderComponent) upload: UploaderComponent;

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.dataSource.sort = this.sort;
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.dataSource.paginator = this.paginator;
    }

    /* ATTRIBUTES */

    public cache: Cache; // the session cache
    public config; // app.ts
    public configuration; // the organization configuration
    public process;
    public asset;
    public assetPkId: string;
    public assessmentPlan: any;

    public columns = [];
    public columnsToDisplay = [];
    public data;
    public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    public paginator;
    public sort;

    public invalidRows = 0;
    public sheetIndex = 0;
    public validWorksheet = true;
    public workSheets = [];

    public type = 'spreadsheet';
    public importType: string;

    // allowed file types based on the import type
    public spreadsheetOptions = {allowedMimeType:['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']};
    public oscalOptions = {allowedMimeType:['application/json']};
    // set spreadsheet file types as the default
    public options = this.spreadsheetOptions;

    public spreadsheetApi: string;
    public api: string;

    public isUploading = false;

    private hardwareService;

    // Plural Pipes
    public rowNb: {[k: string]: string} = {
        '=1' : '1 row',
        'other': '# rows'
    }
    public errorNb: {[k: string]: string} = {
        '=1' : '1 error',
        'other': '# errors'
    }

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(
        private route: ActivatedRoute,
    ) {
        this.config = config;
        
        setTimeout(() => {
            config.isHeaderVisible = false;
            config.isNavigationVisible = false;
        });


        this.hardwareService = config['services']?.hardwareService ? inject(config['services']?.hardwareService) : undefined;

        // get query params
        this.route.queryParams.subscribe(params => {
            this.type = params['type'];
            this.options = this.spreadsheetOptions;
            this.importType = params['importType'];

            this.spreadsheetApi = '/' + this.importType + '/import';
            this.api = this.spreadsheetApi;
        });
    }

    /* EVENTS */

    /**
     * On Init.
     */
    ngOnInit(): void {
        this.cache = Cache.get();
        this.configuration = this.cache.getValue(Cache.KEYS.CONFIGURATION);
        this.assetPkId = this.cache.getValue(Cache.KEYS.ASSET_PKID);
        this.process = this.cache.getValue(Cache.KEYS.PROCESS);
        this.asset = this.cache.getValue(Cache.KEYS.ASSET);
        this.assessmentPlan = this.cache.getValue(Cache.KEYS.ASSESSMENT_PLAN) || null;
    }

    /* METHODS */

    /**
     * Build File form with any needed metaData.
     * (data will be in body of request)
     */
    public buildForm(): any {
        return {
            assetPkId: this.assetPkId,
            processPkId: this.process.pkId,
            assessmentPlanPkId: this.assessmentPlan?.pkId
        };
    }

    /**
     * Change the current sheet being viewed.
     */
    public changeSheet(index) {
        this.sheetIndex = index;
        this.columns = this.workSheets[this.sheetIndex].columns;
        this.data = this.workSheets[this.sheetIndex].data;
        this.dataSource.data = this.data;

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        
        this.validWorksheet = this.workSheets[this.sheetIndex].valid;
        this.invalidRows = this.data.filter(row => row.hasError).length;

        // icon column
        this.columnsToDisplay = JSON.parse(JSON.stringify(this.columns));
        this.columnsToDisplay.unshift('errors');
    }

    /**
     * Match worksheet data with potential errors found through validation
     */
    public matchData(worksheet: any[]) {
        for (const sheet of worksheet) {
            for (let i = 0; i < sheet['data'].length; i++) {
                for (const [key, value] of Object.entries(sheet.data[i])) {
                    if (key !== 'hasError' && key !== 'errorNumber') { sheet.data[i][key] = { value: value } }
                    if (sheet.errors?.length > 0 && sheet.errors[i]?.map(e => e.columnName).includes(key)) {
                        const errIndex = sheet.errors[i]?.map(e => e.columnName).indexOf(key)
                        sheet.data[i].errorNumber += 1;
                        if (!sheet.data[i][key].error) { 
                            sheet.data[i][key].error = sheet.errors[i][errIndex];
                        } else {
                            sheet.data[i][key].error.message = sheet.data[i][key].error.message + '\n' + sheet.errors[i][errIndex].message;
                        }
                    }
                }
            }
        }
        return worksheet;
    }


    /**
     * Display rows containing errors or all rows
     */
    public showErrorsOnly(filter: string) {
        this.dataSource.filter = filter;
     }

    /**
     * Converted file data returned from upload component.
     */
    public uploadedData(ev) {
        // this.isUploading = false;

        this.dataSource.filterPredicate = (data: any, filter: string) => !filter || data.hasError == Boolean(filter);
        this.dataSource.sortingDataAccessor = (item, property) => {
            return item[property]?.value;
        };
        if (ev && JSON.parse(ev).length > 0) {
            this.workSheets = this.matchData(JSON.parse(ev));
            this.changeSheet(0);
            
            if (this.importType === 'technology') {
                this.hardwareService?.getCompletion(this.assetPkId).subscribe(res => {});
            }

        }

        // this.columns = this.workSheets[this.sheetIndex].columns;
        // this.data = this.workSheets[this.sheetIndex].data;
        // this.validWorksheet = this.workSheets[this.sheetIndex].valid;
        // this.invalidRows = this.data.filter(row => row.hasError).length;

        // this.columnsToDisplay = JSON.parse(JSON.stringify(this.columns));
        // this.columnsToDisplay.unshift('errors');

        // this.dataSource.data = this.data;
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
    }

    /**
     * Triggers file upload.
     */
    public uploadFile() {
        // this.isUploading = true;
        this.upload.uploadAll();
    }

}
