import { Injectable, Injector } from '@angular/core';

import { Help } from '../objects/help';

import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService extends AbstractService {

    /* ATTRIBUTES */

    public LIST_HELP_STATUS = ['Open', 'Submit', 'Approve', 'In Progress', 'Reject', 'Close' ];
    public LIST_HELP_TYPE = [ 'Application', 'Bug', 'Change', 'Feature', 'Help' ];
    // public LIST_HELP_TYPE = [ 'Access', 'Application', 'Bug', 'Change', 'Equipment', 'Feature', 'Help' ];
    public LIST_HELP_TYPE_REQUIRE_APPROVAL = [ 'Access', 'Application', 'Bug', 'Change', 'Equipment', 'Feature' ];

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    constructor(
        private i: Injector) {
        super(i);
    }

    /* METHODS */

    /**
     * Assign a user to a help request.
     */
    public assign(request) {
        // console.log("/services/help/assign: REQUEST = ", request);
        return this.post('/help/assign', { request }, undefined, undefined, true);
    }

    /**
     * Create a help request.
     */
    public create (help: Help) {
        return this.post('/help/create', { help }, undefined, undefined, true);
    }

    /**
     * Get a help request.
     */
    public get (pkId: string) {
        return this.post('/help/get', { pkId }, undefined, undefined, true);
    }

    /**
     * List all help requests.
     */
    public list(query?) {
        return this.post('/help/list', { query }, undefined, undefined, true);
    }

    /**
     * Remove the help request.
     */
    public remove(request) {
        return this.post('/help/remove', { request }, undefined, undefined, true);
    }

    /**
     * Get settings for help channel.
     */
    public settings(channelPkId: string) {
        return this.post('/help/settings', { channelPkId }, undefined, undefined, true);
    }

    /**
     * Update the help request.
     */
    public update (help: Help) {
        return this.post('/help/create', { help }, undefined, undefined, true);
    }

}
