import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { io } from 'socket.io-client';

import { Chat } from '../objects/chat';
import { Log } from '../objects/log';
import { Message } from '../objects/message';
import { Notification } from '../objects/notification';

import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { Cache } from '../objects/cache';
import { User } from '../objects/user';
import { Subject } from 'rxjs';
import GlobalConstants from '../global.constants';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends AbstractService {

    @Output() openMessage = new EventEmitter<{channelPkId: string, messagePkId: string}>(); // an event used to open components to show the message
    @Output() receiveMessage = new EventEmitter<{channelPkId: string, messagePkId: string}>(); //an event used to scroll to the most recent message

    /* ATTRIBUTES */

    private cache: Cache;
    private organizationPkId: string;

    private socketClientForCommunication; // the socket client to the communication service
    private socketClientForApplication; // the socket client for to the application server

    public helpRequests = []; // open help requests
    public helpMessages: { [ key:string ]: Message[] } = {}; // help messages

    public messages: { [ key:string ]: Message[] } = {}; // chat messages keyed by channel pkid
    private notifications: Notification[] = []; // notifications for the user that is cached in local storage

    public notificationSubject = new Subject<any>();
    public contextChatSubject = new Subject<Message>();

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        private i: Injector,
        private authenticationService: AuthenticationService) {
        super(i);

        this.init();
    }

    /**
     * Initialize.
     */
    public async init() {

        this.cache = Cache.get();
        this.organizationPkId = this.cache.getValue(Cache.KEYS.ORGANIZATION_PKID);

        //#region NOTIFICATIONS

        // Load the notificaiton from the local cache. Then, get the notification
        // for the current user from the server. Lastly, save the server notification
        // into the cache.

        if (localStorage["notifications"]) {
            this.notifications = JSON.parse(localStorage["notifications"]);
        }

        this.getNotificationsFromServer(GlobalConstants.APPLICATION_PKID).subscribe(result => {
            if (result) {
                for (let notification of result) {
                    if (!this.notifications.find(notification_ => notification_.pkId === notification.pkId)) {
                        this.notifications.push(notification);
                    }
                }
                localStorage.setItem('notifications', JSON.stringify(this.notifications));
            }
        });

        //#endregion

        //#region SOCKET.IO FOR APPLICATION

        this.socketClientForApplication = io (
            this.authenticationService.getSrvUrl(), this.authenticationService.getSocketSettings()
        );

        // CONNECT
        this.socketClientForApplication.on("connect", () => {
            console.log("/services/message/init: CONNECTED APPLICATION.SOCKET.IO: ", this.socketClientForApplication.connected);
            this.socketClientForApplication.on("message", (data) => {
                if (typeof data !== 'object') { data = JSON.parse(data); }
                this.receive(data);
            });
        });

        // CONNECTION ERROR
        this.socketClientForApplication.on("connect_error", async (err) => {
            // console.log("/services/message/init: CONNECTION ERROR: ", err);
            this.socketClientForApplication.close();
            await new Promise(resolve => setTimeout(resolve, 10000)); // wait 10 seconds before reconnecting
            this.socketClientForApplication.connect();
        });

        // CONNECTION TIMEOUT
        this.socketClientForApplication.on("/services/message/init: connect_timeout", (err) => {
            // console.log("CONNECTION TIMEOUT: ", err);
        });

        // DISCONNECT
        this.socketClientForApplication.on("disconnect", () => {
            // console.log("/services/message/init: DISCONNECTED: ", this.socketClientForApplication.connected);
        });

        //#endregion

        //#endregion

        //#region SOCKET.IO FOR COMMUNICATION SERVICE

        this.socketClientForCommunication = io (
            this.authenticationService.getComUrl(), this.authenticationService.getSocketSettings()
        );

        // CONNECT
        this.socketClientForCommunication.on("connect", () => {
            console.log("/services/message/init: CONNECTED COMMUNICATION.SOCKET.IO: ", this.socketClientForCommunication.connected);
            this.socketClientForCommunication.on("message", (data) => {
                if (typeof data !== 'object') { data = JSON.parse(data); }
                this.receive(data);
            });
        });

        // CONNECTION ERROR
        this.socketClientForCommunication.on("connect_error", async (err) => {
            // console.log("/services/message/init: CONNECTION ERROR: ", err);
            this.socketClientForCommunication.close();
            await new Promise(resolve => setTimeout(resolve, 10000)); // wait 10 seconds before reconnecting
            this.socketClientForCommunication.connect();
        });

        // CONNECTION TIMEOUT
        this.socketClientForCommunication.on("/services/message/init: connect_timeout", (err) => {
            // console.log("CONNECTION TIMEOUT: ", err);
        });

        // DISCONNECT
        this.socketClientForCommunication.on("disconnect", () => {
            // console.log("/services/message/init: DISCONNECTED: ", this.socketClientForCommunication.connected);
        });

        //#endregion

    }

    /* PUBLIC METHODS */

    //#region CHAT

    /**
     * Send a chat message to the specified channel or for the specified context. A channel 
     * is a container used to define security generic access controls for a discussion or help 
     * topic. On the other hand, a context is just a reference to any object in the system. 
     * When a context is provided, the chat can be attached to any object in the system such 
     * as asset, control, finding, organization, user, etc. 
     */
    public async sendChat (
        type: 'Channel' | 'Context',
        object: any,
        text: string,
        toPkIds?: string[]) {

        let chat = new Chat();
        switch (type) {
            case 'Channel':
                chat.toPkIds = toPkIds;
                chat.action = "Create";
                chat.channelPkId = object.pkId;
                chat.channelName = object.name;
                chat.channel = object;
                chat.text = text;
                this.send(chat);
                break;

            case 'Context':
                chat.toPkIds = toPkIds;
                chat.action = "Create";
                chat.referencePkId = object.reference?.pkId;
                chat.referenceType = object.referenceType;
                chat.reference = object.reference;
                chat.text = text;
                this.send(chat);
                break;

            default:
                break;
        }
    }

    /**
     * Open the chat to a specified channel and message.
     */
    public openChat(channelPkId, messagePkId) {
        this.openMessage.emit({ channelPkId, messagePkId });
    }
    //#endregion

    //#region LOG

    /**
     * Send event to log collection via socket io connection.
     */
    public log(logMessage: Log ) {
        this.send(logMessage);
    }

    //#endregion

    //#region MESSAGE

    /**
     * Get a message by type and pkid.
     */
    public getByPkId (pkId, type) {
        return this.post('/message/get', { pkId, type }, undefined, undefined, true);
    }

    /**
     * Get messages for a given type and object. The type tells
     * the system which collection to lookup and the reference pkid is
     * object id.
     */
    public getMessagesByChannel (channel) {
        return this.post('/message/messages', { type:'Channel', channel: channel }, undefined, undefined, true);
    }

    /**
     * Get the messages for the current context for the chat.
     */
    public getMessagesByContext (context) {
        return this.post('/message/messages', { type:'Context', context: context }, undefined, undefined, true);
    }

    /**
    * Removes the entity.
    */
    public remove(message:Message) {
        message.action = 'Delete';
        this.send(message);
        return this.post('/message/remove', { pkId: message.pkId, type: message.type }, undefined, undefined, true);
    }

    /**
     * Update message.
     */
    public updateMessage(message: Message) {
        message.action = 'Update';
        this.send(message);
    }

    //#endregion

    //#region NOTIFICATION

    /**
     * Clear all notifications.
     */
    public clearAll() {
        const notifications_ = this.getNotifications();
        this.notifications = this.notifications.filter(notification_ => !notifications_.find(notification__ => notification__.pkId === notification_.pkId));
        localStorage.setItem('notifications', JSON.stringify(this.notifications));
    }

    /**
     * Get notifications filtered by organization.
     */
    public getNotifications() {
        if (!this.organizationPkId) {
            this.cache = Cache.get();
            this.organizationPkId = this.cache.getValue(Cache.KEYS.ORGANIZATION_PKID);
        }

        let organizationNotifications = [];
        let allOrganizationNotifications = (this.notifications || []).filter(notification_ =>  !notification_.toOrganizationPkId || notification_.toOrganizationPkId === 'All');
        if (this.organizationPkId) {
            organizationNotifications = (this.notifications || []).filter(notification_ => notification_.toOrganizationPkId === this.organizationPkId);
        }
        return [...organizationNotifications, ... allOrganizationNotifications];
    }

    /**
     * Get notifications for the current user from the server.
     */
    public getNotificationsFromServer(applicationPkId?: string) {
        return this.post('/message/notifications', { applicationPkId }, undefined, undefined, true);
    }

    /**
     * Get the notifications that have not been read.
     */
    public getNotificationsNotYetRead() {
        return this.getNotifications().filter(item => !item.isViewed);
    }

    /**
     * Mark notification as read.
     */
    public markNotificationAsRead(pkId) {
        let notification = this.notifications.find(item => item.pkId == pkId);
        if (notification) {
            notification.isViewed = true;
            // console.log("/service/message/markAsRead: NOTIFICATION = ", notification);
            localStorage.setItem('notifications', JSON.stringify(this.notifications));
        }
    }

    /**
     * Mark all notifications as read.
     */
    public markNotificationAllAsRead() {
        for (let notification of this.getNotifications()) {
            let notification_ = this.notifications.find(notification__ => notification__.pkId === notification.pkId);
            if (notification_) {
                notification_.isViewed = true;
            }
        }
        localStorage.setItem('notifications', JSON.stringify(this.notifications));
    }

    /**
     * Remove the notification from local storage.
     */
    public removeNotification (notification) {
        if (notification) {
            this.notifications = this.notifications.filter(item => item.pkId != notification.pkId);
            localStorage.setItem('notifications', JSON.stringify(this.notifications));
        } 
    }

    //#endregion

    //#region SOCKET.IO

    /**
     * Receive message from the server, process the message, and place it in
     * the appropriate local cache.
     */
    private async receive(data): Promise<any>  {
        if (data) {
            switch(data.type) {

                case 'Chat':
                    // Check if the chat already exists in the cache. If not, then add it to
                    // the cache to refresh the chat component. If the chat did not come from the
                    // current user, then add it to the local notification cache.

                    let chat = new Chat (data);
                    if (chat.channelPkId) {
                        if (!this.messages[chat.channelPkId]) { this.messages[chat.channelPkId] = []; }
                        if (!this.messages[chat.channelPkId].find(m => m.pkId === chat.pkId)) {
                            this.messages[chat.channelPkId].push(chat);
                            this.receiveMessage.emit({channelPkId: chat.channelPkId, messagePkId: chat.pkId})
                        }
                    }
                    else if (chat.referencePkId) {
                        if (!this.messages[chat.referencePkId]) { this.messages[chat.referencePkId] = []; }
                        if (!this.messages[chat.referencePkId].find(m => m.pkId === chat.pkId)) {
                            this.messages[chat.referencePkId].push(chat);
                        }
                        this.contextChatSubject.next(chat);
                    }
                    break;

                case 'Notification':
                    let notification = new Notification(data);
                    if (!this.notifications.find(item => item.pkId === notification.pkId)) {
                        this.notifications.push(notification);
                        this.notificationSubject.next(notification);
                        localStorage["notifications"] = JSON.stringify(this.notifications);
                    }
                    break;

                default:
                    break;

            }
        }
    }

    /**
     * Send the message to the socket.io running on the core service
     * so that it can be processed centrally.
     */
    private async send(message: Message) {
        let cache = Cache.get();
        let user = cache.getValue(Cache.KEYS.USER);

        if (user) {
            user = new User(user);
            let organization = cache.getValue(Cache.KEYS.ORGANIZATION);
            if (organization) {
                message.fromPkId = user.pkId;
                message.fromName = user.getFullName();
                message.fromEmail = user.email;
                this.socketClientForCommunication.emit('message', JSON.stringify(message));
            }
        }
    }

    //#endregion

}
