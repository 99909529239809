<div class="editor-subsection">
    <div class="editor-subtitle" *ngIf="isTitleVisible">
        <h5>COMMENTS</h5>
    </div>
    <div class="pt-3 pb-3" style="border-bottom: 1px solid rgba(35, 44, 81, 0.12);" *ngFor="let comment of comments">
        <button class="right"*ngIf="user.pkId === comment.user?.pkId" mat-icon-button [matMenuTriggerData]="{comment:comment}" [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
        <p class="pb-1">
            <b 
                matTooltip="{{getRole(comment.user?.pkId)}}"
                matTooltipPosition="right">
            {{comment.user?.name}}
        </b>  
            <span class="text-small pl-3">{{comment.createDate | date:'medium'}}</span>
        </p>
        <p *ngIf="comment.edit !== true">{{comment.text}}</p>
        <mat-form-field *ngIf="comment.edit === true" class="w-100 pt-3 pb-3 textarea-label" appearance="outline">
            <textarea  
                name="comment"
                class="textarea-80"
                [(ngModel)]='comment.text'
                cdkTextareaAutosize
                matInput
                ngModel
                maxlength="10000">
            </textarea>
            <span class="word-count" *ngIf="comment.edit === true">{{comment.text.length}}/10000</span>
        </mat-form-field>
        <button *ngIf="comment.edit === true" mat-flat-button color="accent" (click)="edit(comment)">EDIT</button>
    </div>
    <form #form="ngForm">
        <mat-form-field class="w-100 pt-3 pb-3 textarea-label" appearance="outline">
            <mat-label>Comment</mat-label>
            <textarea  
                name="comment"
                class="textarea-80"
                cdkTextareaAutosize
                matInput
                ngModel
                maxlength="10000">
            </textarea>
            <span class="word-count" *ngIf="form?.controls?.comment?.value?.length > 0">{{form?.controls?.comment?.value?.length}}/10000</span>
        </mat-form-field>
    </form>
    <div class="col nopadding">
        <div class="pb-4" actions>
            <button mat-flat-button color="accent" [disabled]="!form?.controls?.comment?.value" (click)="add(form)">Add Comment</button>
        </div> 
    </div>

</div>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-data="comment">
        <button mat-menu-item (click)="data.edit = true">Edit</button>
        <button mat-menu-item (click)="delete(data)">Delete</button>
    </ng-template>
  </mat-menu>