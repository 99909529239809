import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartStyler } from '@src/shared/objects/chart-styler';
import { Cache } from '@src/shared/objects/cache';

/**
 * Guage Chart
 * Example: Used on Archangel's Asset Dashboard.
 * Input Data:
 * 'total' is the large text in the center of the spinner
 * 'inner' contains data for the inner progress circle
 * 'outer' contains data for the outer progress circle
 */

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.less']
})
export class GaugeChartComponent implements OnInit, OnChanges {

    @Input() total;
    @Input() inner: {count: number, percent: number, label: string} = {count: 0, percent: 0, label: ''};
    @Input() outer: {count: number, percent: number, label: string} = {count: 0, percent: 0, label: ''};

    /* ATTRIBUTES */

    public chart: Chart; // the chart object

    private theme = ChartStyler.get();
    

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor() { 
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit(): void {     
      Cache.onChanges().subscribe(res => {
        if (res?.key === 'preferences') {
          this.theme = ChartStyler.get();
          this.chart = this.generateSpinner();
        }
      });

      this.chart = this.generateSpinner();
    }

    /**
     * On Change.
     */
    public ngOnChanges(): void {
        this.chart = this.generateSpinner();
    }

    /* METHODS */

    public generateSpinner() {

        const textRenderer = (chart) => {
            const newX = chart.plotWidth / 2 + chart.plotLeft,
            newY = chart.plotHeight / 2 + chart.plotTop;
            const percent = isNaN(this.outer.percent) ? 0 : this.outer.percent.toFixed(0);
            const text = `${(this.total.toFixed(0) || 0)}<br><span class='sub-text'>${percent}%</span>`;
            if (chart.heading) {
            return chart.heading.attr({
                x: newX,
                y: newY + 10,
                width: '100%',
                height: '100%'
            });
            }
            chart.heading = chart.renderer
                .text(text, newX, newY)
                .attr({
                    align: 'center'
                }).addClass('large-gauge-text')
                .add();
            };

        return new Chart({
            chart: {
                type: 'solidgauge',
                width: 150,
                height: 150,
                backgroundColor: null,
                spacing: [0, 0, 0, 0],
                events: {
                    load: function () {
                        textRenderer(this);
                    },
                    redraw: function () {
                        textRenderer(this);
                    }
                }
            },

            credits: { enabled: false },
            title: {
                text: ''
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [
                    {
                        outerRadius: '90%',
                        innerRadius: '70%',
                        backgroundColor: this.theme?.color?.gaugeTrack01,
                        borderWidth: 0
                    },
                    {
                        outerRadius: '90%',
                        innerRadius: '70%',
                        backgroundColor: this.theme?.gradients?.gradient01,
                        borderWidth: 0
                    },
                    {
                        outerRadius: '55%',
                        innerRadius: '40%',
                        backgroundColor: this.theme?.color?.gaugeTrack01,
                        borderWidth: 0
                    },
                    {
                        outerRadius: '55%',
                        innerRadius: '40%',
                        backgroundColor: this.theme?.gradients?.gradient02,
                        borderWidth: 0
                    },
                ]
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: [],
                stops: [
                    [0.5, {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: this.theme?.stops?.gaugeGt75 || [[0, '#11818F'], [1, '#9AE2EC']]
                    }]
                ]
            },
            tooltip: {
                enabled: true,
                outside: true,
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'square',
                    stickyTracking: false,
                    rounded: false
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            series: [
                {
                    name: this.inner.label,
                    type: 'solidgauge',
                    data: [
                    {
                        color: '#0260EE',
                        radius: '55%',
                        innerRadius: '40%',
                        y: this.inner.percent
                    }
                    ],
                    zIndex: 10000,

                    tooltip: {
                        headerFormat:
                        `
                        <p class="uppercase">&nbsp;<b>${this.inner.count}</b></p><br/>
                        `,
                        pointFormat: `${this.inner.label}`,
                        footerFormat: 
                          `
                          `
                    },
                },
                
                {
                    name: this.outer.label,
                    type: 'solidgauge',
                    data: [
                    {

                        color: '#0260EE',
                        radius: '90%',
                        innerRadius: '70%',
                        y: this.outer.percent
                    }
                    ],
                    zIndex: 10000,
                    tooltip: {
                        headerFormat:
                        `
                        <p class="uppercase">&nbsp;<b>${this.outer.count}</b></p><br/>
                        `,
                        pointFormat: `${this.outer.label}`,
                        footerFormat: 
                          `
                          `
                    },
                }
            ],
            exporting: {
            enabled: false
            }
        });
    }

}
