import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Transform } from '@src/shared/objects/transformation';

import { Cache } from '../../objects/cache';

@Component({
  selector: 'app-object-transform-editor',
  templateUrl: './object-transform-editor.component.html',
  styleUrls: ['./object-transform-editor.component.less']
})
export class ObjectTransformEditorComponent implements OnInit, OnDestroy {

    @Input() annotation; // the annotation text header
    @Input() isActionable = true; // show or hide the action button
    @Input() object: any; // the data object
    @Input() transformations: Transform[] = [];

    /* ATTRIBUTES */

    public cache: Cache; // the session cache
    public organization; // the organization
    public user; // the current user

    /* CONSTRUCTOR */

    /**
     * Constructor.
     */
    public constructor(
        public snackBar: MatSnackBar) {
    }

    /* EVENT HANDLING */

    /**
     * On Init.
     */
    public ngOnInit() {

        // Get all of the selected organization, configuration, and user from
        // the cache. The selected organization is passed from the authentication
        // service upon starting the application.

        this.cache = Cache.get();
        this.organization = this.cache.getValue(Cache.KEYS.ORGANIZATION);
        this.user = this.cache.getValue(Cache.KEYS.USER);
        
        if (!this.transformations) {
            this.transformations = [new Transform()];
        }
    }

    /**
     * On Destroy.
     */
    public ngOnDestroy() {
    }

    /* METHODS */

    /**
     * Add.
     */
    public add() {
        this.transformations.push(new Transform())
    }

    /**
     * Add operation.
     */
    public addOperation(transform: Transform) {
        transform.pipeline.push({action: '', argument: ''});
    }

    /**
     * Delete the key and corresponding value.
     */
    public delete(index) {
        this.transformations.splice(index, 1);
    }

    /**
     * Delete operation from mapping.
     */
    public deleteOperation(item: Transform, index) {
        item.pipeline.splice(index, 1);
    }

    /**
     * Save.
     */
    public save() {
    }

}
