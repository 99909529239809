
import { Component, Input, OnInit} from '@angular/core';
import { Change } from '@src/shared/objects/change';
import { ChangeService } from '@src/shared/services/change.service';

@Component({
  selector: 'app-change-history',
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.less'],
})

/**
 * chip-list
 */
export class ChangeHistoryComponent implements OnInit  {

    /* ATTRIBUTES */
    
    @Input() configuration;
    @Input() referencePkId;
    @Input() referenceType;
    @Input() limit;


    public changes: Change[] = [];

    /**
     * Constructor.
     */
    public constructor(private changeService: ChangeService) {

    }

    /**
     * On Init.
     */
    ngOnInit(): void {
        this.changeService.list(this.referenceType, this.referencePkId, this.limit).subscribe(res => {
            this.changes = res;
        });
    }

    /* METHODS */



}
