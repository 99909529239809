import { Question } from "./question";
import { Questionnaire } from "./questionnaire";
import _ from 'lodash';
export class Answer {

    /* ATTRIBTES */

    pkId: string;
    assetPkId: string;
    processPkId: string;
    questionnairePkId: string;

    answers: { [key: string]: any  } = { };

    date: Date = new Date();

    private incompleteQuestions = [];

    /* CONSTRUCTOR */
    
    /**
     * Constructor.
     */
    constructor(json?) {
        Object.assign(this, json);
    }

    /* METHODS */

    /**
     * Calculate the percent complete of the step, which depends on the
     * type of step.
     */
    public getPercentComplete(questions: Question[], questionnaire: Questionnaire , isRequiredOnly?) {

        let answeredQuestions = [];
        this.incompleteQuestions = [];
        let nonApplicableCount = 0; // count of questions that should not be used for percent completion
        let descriptionQuestions = 0; // count of description questions
        let totalQuestions = 0;
        let requiredCount = 0; // count of the visible questions marked as required
        let requiredCompletedCount = 0; // count of the completed visible questions marked as required

        let questionnaire_ = {sections:[]};

        // Bind questions to questionnaire
        questionnaire?.sections?.forEach(section => {
            const section_ =  {... section}
            section_.questions = section.questions.map(questionPkId => {
                // if data is already binded (typically if from cache), questionPkId wil contain full question
                const question = questions.find(question_ => question_.pkId === (questionPkId?.pkId || questionPkId));
                return question;
            });
            questionnaire_.sections.push(section_);
        });

        const questionMap = {};
        for (let s of questionnaire_.sections) {
            for (let q of s.questions) {
                if (q && questionMap[q.pkId]) {
                    questionMap[q.pkId] = q;
                }
            }
        }

        let validQuestions = [];
        const completedQuestions = []; // to track which valid questions have been completed

        if (questionnaire_) {
            for (let section of questionnaire_.sections) {
                // if section not visible, consider questions "completed" and add questionPkIds to completedQuestions
                if (!section.visible) {
                    for (let question of section.questions) {
                        completedQuestions.push(question?.pkId);
                    }
                } else {
                    for (let question of section.questions) {
                        if (question?.visible) {
                            const foundQuestion = questions.find(question_ => question_.pkId === question
                                || question_.pkId === question?.pkId);
                            if (foundQuestion) {
                                if (foundQuestion.type === 'Description' && foundQuestion.visible) {
                                    validQuestions.push(foundQuestion);
                                    descriptionQuestions ++;
                                    totalQuestions ++;
                                    completedQuestions.push(question?.pkId);
                                } else if (foundQuestion.visible) {
                                    validQuestions.push(foundQuestion);
                                    totalQuestions ++;
                                    if (foundQuestion.isRequired) {
                                        requiredCount ++;
                                    }
                                }
                            } else {
                                // console.log('not found:', question);
                            }
                        } else {
                            completedQuestions.push(question?.pkId);
                        }
                    }
                }
            }
        }

        this.incompleteQuestions = validQuestions; // initialize incomplete questions to list of all valid questions
        answeredQuestions = Object.keys(this.answers).filter(key => {
            if (!key.includes('-comment') && key !== 'lastEdit') {
                const foundQuestion = validQuestions.find(question => question.pkId === key);
                // don't include hidden questions
                if (!foundQuestion || !foundQuestion.visible) {
                    return false;
                }
                // signature type questions
                if (foundQuestion.type === 'Signature') {
                    if (this.answers[key]?.signature && this.answers[key]?.signatureDate || this.answers[key] !== 'Reject' && this.answers[key] !== "") {
                        completedQuestions.push(key);
                        if (foundQuestion.isRequired) {requiredCompletedCount++}
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                // date type questions
                if (foundQuestion.type === 'Date') {
                    if (foundQuestion.isDateRange) {
                        if (this.answers[key]?.start && this.answers[key]?.end) {
                            completedQuestions.push(key);
                            if (foundQuestion.isRequired) {requiredCompletedCount++}
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        if (this.answers[key]?.start) {
                            completedQuestions.push(key);
                            if (foundQuestion.isRequired) {requiredCompletedCount++}
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
                // if not array answer, and has value
                if (!Array.isArray(this.answers[key]) && this.answers[key]) {
                    completedQuestions.push(key);
                    if (foundQuestion.isRequired) {requiredCompletedCount++}
                    return true;
                }
                // if answers[key] is an array
                else if (Array.isArray(this.answers[key])) {
                    const arrayLength = this.answers[key].length;
                    // check if array of arrays (grid table question)
                    if (Array.isArray(this.answers[key][0])) {
                        for (let row of this.answers[key]) {
                            for (let cell of row) {
                                if (cell) {
                                    completedQuestions.push(key);
                                    if (foundQuestion.isRequired) {requiredCompletedCount++}
                                    return true;
                                }
                            }
                        }
                        return false;
                    } 
                    else {
                        // is single array of answers
                        for (let answer of this.answers[key]) {
                            if (answer) {
                                // last entry is completed
                                completedQuestions.push(key);
                                if (foundQuestion.isRequired) {requiredCompletedCount++}
                                return true;
                            }
                        }
                        return false;
                    }
                } 
                else {
                    return false;
                }
            } 
            else {
                nonApplicableCount ++;
                return false;
            }
        });

        // Remove completed questions from list of incomplete questions
        for (const questionPkId of completedQuestions) {
            this.incompleteQuestions = this.incompleteQuestions.filter(incomplete => incomplete.pkId !== questionPkId);
        }
        //console.log('missing questions:', validQuestions.filter(question_ => !this.answeredQuestions.includes(question_.pkId)));

        // step.percentDone = ((step.answeredQuestions / step.totalQuestions) * 100).toFixed(0);
        // console.log(questionnaire.name, answeredQuestions.length, '/', totalQuestions);
        //console.log(questionnaire.name, requiredCount, requiredCompletedCount)

        if (isRequiredOnly) {
            return _.clamp((requiredCompletedCount / requiredCount) * 100, 0, 100);
        }
        else {
               return  _.clamp(((answeredQuestions.length + descriptionQuestions) / totalQuestions) * 100, 0, 100);
        }
    }

    /* UTILITIES */

    /**
     * Get array of incomplete questions.
     * getPercentComplete() must be run prior.
     */
    public getIncompleteQuestions() {
        return this.incompleteQuestions;
    }

}
